import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { TestedPartyDetailsTabsProps } from './TestedPartyDetailsTabs.proptype';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: '1.375em',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    '& .Mui-selected': {
      color: theme.palette.primary.dark
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

const TestedPartyDetailsTabs = ({ selectedKey, tabs, onSelectTab }: TestedPartyDetailsTabsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const selected = tabs.find(({ key }) => key === selectedKey);

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tab}
        onChange={(event, value) => {
          onSelectTab(value);
          setTab(value);
        }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.key} label={t(`analysis:${tab.label}`)} />
        ))}
      </Tabs>
      <Box className={classes.paperContainer}>{selected?.content}</Box>
    </>
  );
};

export default TestedPartyDetailsTabs;
