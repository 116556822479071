import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, IconButton, makeStyles, Paper, useTheme } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { LocalReportVersionsModalProps } from './LocalReportVersionsModal.prototypes';
import { LocalReportVersionsTable } from './LocalReportVersionsTable';
import { CenteredProgress } from '../../components';
import appColors from '../../styles/appColors';
import tokens from '../../styles/designTokens';
import { body3, titleSmallPrimary } from '../../styles/typography';
import { ReportsIcon } from '../../svgs';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%'
    }
  },
  mainArea: {
    background: tokens.neutral70,
    paddingTop: '1.75rem',
    height: '82%',
    position: 'relative'
  },
  card: {
    height: '90%',
    position: 'relative',
    padding: '2rem',
    margin: '0 2.875rem',
    background: tokens.white,
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.12)'
  },
  pageTitle: {
    ...titleSmallPrimary
  },
  reportName: {
    ...body3
  },
  headerFooterWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.125rem 1.688rem'
  },
  footerWrapper: {
    marginLeft: '5rem',
    marginRight: '5rem'
  },
  headerIcon: {
    backgroundColor: tokens.product15,
    borderRadius: '50%',
    padding: 0,
    height: '2.25rem',
    width: '2.25rem',
    marginRight: '0.5rem',
    '& svg path': {
      fill: appColors.reports.active.text
    }
  },
  buttons: {
    height: '2.25rem',
    minWidth: '7.125rem',
    border: `1px solid ${tokens.product100}`,
    color: tokens.product100
  },
  confirmButton: {
    color: tokens.white,
    background: tokens.product100
  },
  titleLabel: {
    fontFamily: 'Basis Grotesque Pro Medium',
    fontSize: '1.125rem'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  centeredProgress: {
    margin: '4rem 0 2rem 0'
  }
}));

export const LocalReportVersionsModal = ({
  reportVersions,
  reportName,
  startDeleteVersion,
  updateActiveVersion,
  downloadReportVersion,
  onClose
}: LocalReportVersionsModalProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const sortedReportVersions = [...reportVersions].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

  return (
    <Dialog fullWidth open className={classes.root} maxWidth="lg" onClose={onClose}>
      <Box className={classes.headerFooterWrapper}>
        <IconButton className={classes.headerIcon}>
          <ReportsIcon />
        </IconButton>
        <span className={classes.titleLabel}>{t('reports:manage-versions')}</span>
        <IconButton data-testid="close-icon-button" className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.mainArea}>
        <Paper className={classes.card}>
          <div className={classes.pageTitle}>Version Control</div>
          <div className={classes.reportName}>{reportName}</div>

          {reportVersions.length > 0 ? (
            <LocalReportVersionsTable
              reportVersions={sortedReportVersions}
              updateActiveVersion={updateActiveVersion}
              startDeleteVersion={startDeleteVersion}
              downloadReportVersion={downloadReportVersion}
            />
          ) : (
            <div className={classes.centeredProgress}>
              <CenteredProgress />
            </div>
          )}
        </Paper>
      </Box>
      <Box className={`${classes.headerFooterWrapper} ${classes.footerWrapper}`}>
        <Button className={`${classes.buttons} ${classes.confirmButton}`} onClick={onClose}>
          {t('reports:action-done')}
        </Button>
      </Box>
    </Dialog>
  );
};
