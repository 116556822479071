import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectorProps } from './LocalReportCreationModal.proptypes';
import { createPrimaryTradingPartnersMap, fetchLanguages, fetchTemplates } from '../../redux/localReports';
import { fetchTransactions } from '../../redux/transactions';
import { selectTransactionsList, selectWorkingContainer } from '../../selectors';
import {
  selectLanguageList,
  selectPrimaryEntitiesList,
  selectPrimaryTradingPartnersMap,
  selectSelectedPrimaryEntities,
  selectSelectedTradingPartners,
  selectSelectedTransactions,
  selectSetupData,
  selectSummariesData,
  selectTemplateList
} from '../../selectors/localReports';

const Connector = ({
  title,
  jurisdictionId,
  jurisdictionName,
  component: Component,
  onClose,
  onSubmit
}: ConnectorProps) => {
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactionsList);
  const primaryTradingPartnersMap = useSelector(selectPrimaryTradingPartnersMap);
  const setupData = useSelector(selectSetupData);
  const summariesData = useSelector(selectSummariesData);
  const selectedTransactions = useSelector(selectSelectedTransactions);
  const selectedPrimaryEntities = useSelector(selectSelectedPrimaryEntities);
  const selectedTradingPartners = useSelector(selectSelectedTradingPartners);

  const container = useSelector(selectWorkingContainer);
  const primaryEntities = useSelector(selectPrimaryEntitiesList);
  const templates = useSelector(selectTemplateList);
  const languages = useSelector(selectLanguageList);

  useEffect(() => {
    if (!transactions) {
      dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (transactions && !primaryTradingPartnersMap) {
      dispatch(createPrimaryTradingPartnersMap(jurisdictionId));
    }
  }, [dispatch, jurisdictionId, transactions, primaryTradingPartnersMap]);

  useEffect(() => {
    if (templates.length === 0 && primaryEntities.length > 0) {
      dispatch(fetchTemplates(primaryEntities[0].entityId));
    }
  }, [dispatch, templates, primaryEntities]);

  useEffect(() => {
    if (!languages || languages.length === 0) {
      dispatch(fetchLanguages({ jurisdictionId, taxYear: container?.taxYear! }));
    }
  }, [dispatch, jurisdictionId, languages, container]);

  const stageCompleteness = [
    setupData && setupData.reportTitle.length > 0 && setupData.currency !== '',
    selectedPrimaryEntities.length > 0 && selectedTradingPartners.length > 0,
    selectedTransactions.length > 0,
    summariesData.conclusion.length > 0 && summariesData.executive.length > 0
  ];

  return (
    <Component
      title={title}
      jurisdictionId={jurisdictionId}
      jurisdictionName={jurisdictionName}
      stageCompleteness={stageCompleteness}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default Connector;
