import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import { Import } from './Import';
import { Upload } from './Upload';
import { TPModal, TPModalActions } from '../../../components';
import { ImportMapping } from '../../../models';
import { UploadIcon } from '../../../svgs';
import { ImportModalProps } from '../ImportModal.proptype';

const useStyles = makeStyles((theme) => ({
  cancelButtonOnImport: {
    marginRight: 'auto'
  },
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
    '& svg': {
      fill: theme.palette.primary.contrastText
    }
  }
}));

export const ImportModal = ({
  namespace,
  loading,
  mappings,
  allowedMappings,
  disableMappings,
  onClose,
  onUpload,
  onImport
}: ImportModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [file, setFile] = useState<File | null | undefined>();
  const [editedMappings, setEditedMappings] = useState<ImportMapping[] | undefined>();

  useEffect(() => {
    setFile(null);
    setEditedMappings(mappings);
  }, [mappings]);

  const uploadActions: TPModalActions[] = [
    { name: t('action-cancel'), handler: onClose },
    {
      name: t('action-upload'),
      id: `button-newui-import-${namespace}-modal-upload`,
      variant: 'contained',
      color: 'primary',
      disabled: !file,
      handler: () => {
        onUpload(file!);
      }
    }
  ];

  const importActions: TPModalActions[] = [
    {
      name: t('action-back'),
      variant: 'outlined',
      className: classes.cancelButtonOnImport,
      handler: () => {
        setEditedMappings(undefined); // eslint-disable-line unicorn/no-useless-undefined
      }
    },
    { name: t('action-cancel'), handler: onClose },
    {
      name: t('action-import'),
      id: `button-newui-import-${namespace}-modal-import`,
      variant: 'contained',
      color: 'primary',
      handler: () => {
        if (editedMappings) {
          onImport(editedMappings);
        }
      }
    }
  ];

  return (
    <TPModal
      isOpen
      maxWidth="md"
      title={
        <span className={classes.modalTitleWrapper}>
          <IconButton className={classes.headerIcon}>
            <UploadIcon />
          </IconButton>
          <Typography variant="h6">{t(`${namespace}:action-import`)}</Typography>
        </span>
      }
      actions={editedMappings ? importActions : uploadActions}
      onClose={onClose}
    >
      {editedMappings ? (
        <Import
          namespace={namespace}
          mappings={editedMappings}
          allowedMappings={allowedMappings}
          disableMappings={disableMappings}
          onChangeMapping={setEditedMappings}
        />
      ) : (
        <Upload namespace={namespace} loading={loading} file={file} onSelectFile={setFile} />
      )}
    </TPModal>
  );
};
