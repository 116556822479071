import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles } from '@material-ui/core';
import { AnalysesTable, AnalysesSplashScreen } from '.';
import { CenteredProgress, ContentLayout } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { AnalysisProps } from '../Analysis.proptype';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    width: '100%',
    whiteSpace: 'nowrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));

export const Analysis = (props: AnalysisProps) => {
  const { studies, onAddSingle } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box height={1}>
      {studies === null ? (
        <CenteredProgress />
      ) : studies?.length ? (
        <ContentLayout
          title={t('analysis:title')}
          actions={
            <Box className={classes.buttonContainer}>
              {documentationReviewAccess(
                <Button
                  color="primary"
                  variant="contained"
                  id="button-newui-analysis-screen-create-study"
                  onClick={onAddSingle}
                >
                  {t('analysis:action-new')}
                </Button>
              )}
            </Box>
          }
          main={
            <Box className={classes.container}>
              <AnalysesTable {...props} />
            </Box>
          }
        />
      ) : (
        <AnalysesSplashScreen onAddSingle={onAddSingle} />
      )}
    </Box>
  );
};
