import { useState, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ImportAllowedMappings, ImportMapping } from '../../models';
import { fetchTransactions } from '../../redux/transactions';
import { uploadFile, triggerImport } from '../../utils';
import { ImportModalProps } from '../ImportModal';

interface ConnectorProps {
  component: ComponentType<ImportModalProps>;
  onClose: () => void;
}

const kind = 'transactions';

const Connector = ({ component: Component, onClose }: ConnectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [hash, setHash] = useState('');
  const [mappings, setMappings] = useState<ImportMapping[] | undefined>();
  const [allowedMappings, setAllowedMappings] = useState<ImportAllowedMappings[] | undefined>();

  return (
    <Component
      disableMappings
      namespace={kind}
      loading={loading}
      mappings={mappings}
      allowedMappings={allowedMappings}
      onClose={onClose}
      onUpload={async (file) => {
        try {
          setLoading(true);
          const { hash, mappings, allowedMappings } = await uploadFile(kind, file)!;

          setHash(hash);
          setMappings(mappings);
          setAllowedMappings(allowedMappings);
        } finally {
          setLoading(false);
        }
      }}
      onImport={async (mappings) => {
        try {
          await triggerImport(kind, hash, mappings, t);
          onClose();

          dispatch(fetchTransactions());
        } catch {}
      }}
    />
  );
};

export default Connector;
