import { createMuiTheme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { AtRule } from 'csstype';
import { body1, body2 } from './typography';

declare module '@material-ui/core/styles/createPalette' {}

export interface ThemeTokens {
  core1: string;
  core2: string;
  core3: string;
  neutral100: string;
  neutral90: string;
  neutral80: string;
  neutral70: string;
  neutral60: string;
  product100: string;
  product90: string;
  product25: string;
  product15: string;
  black: string;
  white: string;
  bodyFont: AtRule.FontFace;
  titleFont: AtRule.FontFace;
  subtitleFont: AtRule.FontFace;
  mediumFont: AtRule.FontFace;
  baseFontSize: number;
  spacing: number;
}

export const buildTheme = (tokens: ThemeTokens) => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: tokens.bodyFont.fontFamily,
      fontSize: tokens.baseFontSize,
      button: {
        textTransform: 'none'
      },
      h1: {
        fontFamily: tokens.titleFont.fontFamily,
        fontSize: '1.5rem',
        color: tokens.core3
      },
      h2: {
        fontFamily: tokens.subtitleFont.fontFamily,
        fontSize: '1.3rem',
        color: tokens.core3
      },
      h3: {
        fontFamily: tokens.titleFont.fontFamily,
        fontSize: '0.9rem',
        color: tokens.core3
      },
      caption: {
        ...body1
      }
    },
    palette: {
      common: {
        white: tokens.white,
        black: tokens.black
      },
      background: {
        default: tokens.white,
        paper: tokens.neutral60
      },
      text: {
        primary: tokens.core1,
        secondary: tokens.core2
      },
      primary: {
        main: tokens.product90,
        light: tokens.product25,
        dark: tokens.product100,
        contrastText: tokens.white
      },
      info: {
        main: '#7C76CC',
        light: '#E6E4F6',
        dark: '#707983'
      },
      warning: {
        main: '#F4CD52',
        light: '#FEF7E0',
        dark: '#82703A'
      },
      secondary: {
        main: tokens.neutral90,
        light: tokens.neutral80,
        dark: tokens.neutral100,
        contrastText: tokens.black
      },
      divider: tokens.neutral90,
      action: {
        active: tokens.product25,
        hover: tokens.product15,
        selected: tokens.product15,
        selectedOpacity: 1,
        focus: tokens.product15
      }
    },
    spacing: (factor) => factor * tokens.spacing
  });
  return createMuiTheme({
    ...theme,
    overrides: ({
      MuiAutocomplete: {
        inputRoot: {
          display: 'flex',
          alignItems: 'center',
          height: '2.25rem',
          '&[class*="MuiOutlinedInput-root"]': {
            padding: '0 0.75rem',
            '& $input': {
              border: 'none',
              padding: '0',
              '&:first-child': {
                padding: '0'
              }
            }
          }
        },
        popupIndicator: {
          color: theme.palette.text.secondary
        },
        paper: {
          boxShadow: theme.shadows[8]
        }
      },
      MuiButton: {
        root: {
          height: '2.25rem',
          padding: theme.spacing(0, 1)
        },
        contained: {
          boxShadow: 'none'
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none'
          }
        },
        containedSecondary: {
          backgroundColor: theme.palette.secondary.light,
          color: tokens.core1,
          '&:hover': {
            backgroundColor: tokens.neutral70,
            boxShadow: 'none'
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.dark
          }
        },
        outlined: {
          ...body2
        },
        endIcon: {
          marginLeft: 'auto'
        }
      },
      MuiButtonGroup: {
        contained: {
          boxShadow: 'none',
          '& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child)': {
            borderRightColor: 'transparent'
          }
        }
      },
      MuiCard: {
        root: {
          borderRadius: theme.shape.borderRadius,
          boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 8%)'
        }
      },
      MuiCardHeader: {
        title: {
          color: theme.palette.text.primary,
          fontSize: theme.typography.caption.fontSize
        },
        subheader: {
          color: theme.palette.text.secondary
        }
      },
      MuiChip: {
        root: {
          borderRadius: '0.25rem'
        },
        colorPrimary: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main
        }
      },
      MuiCssBaseline: {
        '@global': {
          'html, body, #root': {
            height: '100%',
            margin: 0,
            padding: 0
          },
          '@font-face': [tokens.bodyFont, tokens.titleFont, tokens.subtitleFont, tokens.mediumFont]
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: theme.palette.background.default
        }
      },
      MuiIconButton: {
        root: {
          color: theme.palette.text.secondary
        }
      },
      MuiInputBase: {
        root: {
          padding: '0 0.75rem',
          height: '2.25rem',
          ...body1
        },
        input: {
          height: '2.25rem',
          paddingTop: 0,
          paddingBottom: 0,
          '&[type=number]': {
            appearance: 'textfield',
            textAlign: 'right',
            '&::-webkit-clear-button, &::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              display: 'none'
            }
          },
          '&:placeholder': {
            color: theme.palette.text.secondary
          }
        }
      },
      MuiMobileStepper: {
        root: {
          justifyContent: 'center'
        }
      },
      MuiOutlinedInput: {
        root: {
          padding: 0
        },
        input: {
          padding: '0 0.75rem',
          borderRight: `1px solid ${theme.palette.divider}`,
          color: theme.palette.text.secondary
        },
        inputMarginDense: {
          paddingTop: 0,
          paddingBottom: 0
        },
        adornedEnd: {
          color: theme.palette.secondary.dark,
          cursor: 'pointer',
          paddingRight: theme.spacing(0.7)
        }
      },
      MuiRadio: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#3C2A8E'
          }
        }
      },
      MuiSelect: {
        icon: {
          color: theme.palette.text.primary
        }
      },
      MuiTabs: {
        root: {
          '& .MuiTab-root': {
            minWidth: 0,
            padding: theme.spacing(0.5, 1.5)
          }
        },
        scroller: {
          borderBottom: `1px solid ${theme.palette.divider}`,

          '& .Mui-selected': {
            fontWeight: theme.typography.fontWeightBold
          }
        }
      },
      MuiTable: {
        stickyHeader: {
          '& .MuiTableCell-head': {
            lineHeight: '1.125rem'
          },
          '& .MuiTableCell-body:first-child': {
            position: 'sticky',
            top: 0
          }
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: `1px solid ${theme.palette.divider}`,
          color: theme.palette.text.primary
        }
      },
      MuiTableRow: {
        root: {
          '&.Mui-selected .MuiTableCell-body:first-child': {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiToggleButton: {
        root: {
          ...body1,
          color: tokens.core1,
          backgroundColor: tokens.neutral80,
          height: '2.25rem',
          '&.Mui-selected': {
            color: tokens.product100,
            backgroundColor: tokens.product25
          }
        }
      },
      MuiTypography: {
        body1: {
          fontSize: 'inherit',
          fontFamily: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit'
        }
      }
    } as unknown) as Overrides
  });
};
