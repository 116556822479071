import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { CenteredProgress } from '../../../../../components';
import { Editor } from '../../../../../components/Editor';
import { RangeResults } from '../../../../../models';
import { selectFinalRangeResults, selectRangeDiscussionText } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { body3, body2, body1 } from '../../../../../styles/typography';
import { InRangeIcon, OutOfRangeIcon } from '../../../../../svgs';
import useDebounce from '../../../../../utils/debounce';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { Table } from '../../../../Table';
import { StickyColsDefinition } from '../../../../Table/Table.proptype';
import { FinalRangeProps } from '../FinalRange.proptype';

const inRangeStatus = 'In Range Status';
const rangeStatus = 'Range Status';
const testedParty = 'Tested Party';
const testedPartyPli = 'Tested Party PLI';
const inRange = 'in_range_status.in_range';
const notInRange = 'in_range_status.not_in_range';

const useStyles = makeStyles((theme) => ({
  boxSection: {
    height: '100%',
    overflow: 'auto'
  },
  tableWrapper: {
    overflow: 'auto',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-head.left.MuiTableCell-stickyHeader': {
      textAlign: 'left'
    }
  },
  scrollTable: {
    width: '100%',
    marginBottom: '3.188rem'
  },
  headingRow: {
    ...body3,
    display: 'flex',
    margin: '0.75rem 0'
  },
  headingRow2: {
    ...body3,
    display: 'flex',
    alignItems: 'left'
  },
  subHeadingRow: {
    ...body1,
    marginBottom: '0.875rem'
  },
  rowLabel: {
    ...body2,
    textAlign: 'left'
  },
  pliLabel: {
    ...body2,
    color: tokens.product100,
    textAlign: 'left'
  },
  rangeChip: {
    ...body1,
    padding: '0.438rem',
    borderRadius: '0.25rem'
  },
  inRange: {
    backgroundColor: tokens.product15,
    color: tokens.product100,
    '& g': {
      fill: tokens.product100
    }
  },
  inRangeIcon: {
    color: tokens.product100,
    '& g': {
      fill: tokens.product100
    }
  },
  outOfRange: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    '& g': {
      fill: theme.palette.warning.dark
    }
  },
  centeredIcon: {
    position: 'relative',
    left: '75%',
    color: tokens.product100
  },
  editorWrapper: {
    height: '17.188rem'
  }
}));

export const FinalRange = ({ pliFormat, pliId, handleOnRangeDiscussion }: FinalRangeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [discussion, setDiscussion] = useState('');
  const finalRangeResults: RangeResults | undefined = useSelector(selectFinalRangeResults);
  const finalRangeDiscussion: string | undefined = useSelector(selectRangeDiscussionText);
  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  useEffect(() => {
    setDiscussion(finalRangeDiscussion ?? '');
  }, [finalRangeDiscussion]);

  const [handleEditorAutoSave, clearDebounce] = useDebounce(() => {
    handleOnRangeDiscussion(discussion);
  }, 3000);

  useEffect(() => {
    handleEditorAutoSave();
  }, [discussion, handleEditorAutoSave]);

  const onChangeEditor = useCallback(
    (value = '') => {
      clearDebounce();
      setDiscussion(value);
    },
    [clearDebounce]
  );

  const rangeStatuses = finalRangeResults?.data.find((result: any) => result.rowLabel === 'In Range Status');

  const displayResults: Array<Record<string, HTMLElement | string | number>> = (finalRangeResults?.data ?? []).map(
    (result: any) => {
      const displayableResults: any = {};
      Object.keys(result).forEach((key) => {
        if (typeof result[key] === 'number') {
          displayableResults[key] = formatPliDisplayValue(formatType, roundingValue, result[key]);
          if (result.rowLabel === testedParty) {
            if (rangeStatuses && rangeStatuses[key] === inRange) {
              displayableResults[key] = (
                <span className={`${classes.inRange} ${classes.rangeChip}`}>{displayableResults[key]}</span>
              );
            }

            if (rangeStatuses && rangeStatuses[key] === notInRange) {
              displayableResults[key] = (
                <span className={`${classes.outOfRange} ${classes.rangeChip}`}>{displayableResults[key]}</span>
              );
            }
          }
        } else if (typeof result[key] === 'string') {
          let label = result[key];
          if (label === testedParty) {
            label = testedPartyPli;
          } else if (label === inRangeStatus) {
            label = rangeStatus;
          }

          if (label === inRange) {
            label = (
              <span className={`${classes.centeredIcon} ${classes.inRangeIcon}`}>
                <InRangeIcon />
              </span>
            );
          } else if (label === notInRange) {
            label = (
              <span className={classes.centeredIcon}>
                <OutOfRangeIcon />
              </span>
            );
          }

          displayableResults[key] = (
            <div className={label === testedPartyPli || label === rangeStatus ? classes.pliLabel : classes.rowLabel}>
              {label}
            </div>
          );
        }
      });
      return displayableResults;
    }
  );

  const stickyCols: StickyColsDefinition = {};
  const displayColumns = (finalRangeResults?.columns ?? []).map((column) => {
    if (column.key === 'average') {
      stickyCols[column.key] = {
        side: 'right',
        position: 0
      };

      return { key: column.key, header: `${column.label} (%)`, width: '8.75rem' };
    }

    if (column.key === 'rowLabel') {
      stickyCols[column.key] = {
        side: 'left',
        position: 0
      };

      return {
        key: column.key,
        header: <span className={classes.rowLabel}>{column.label}</span>,
        width: '8.75rem'
      };
    }

    return { key: column.key, header: column.label, width: '7rem' };
  });

  return (
    <div className={classes.boxSection}>
      <div className={classes.headingRow}>{t('analysis:final-range')}</div>
      {finalRangeResults?.columns ? (
        <div className={classes.tableWrapper}>
          <Table
            className={classes.scrollTable}
            data={displayResults}
            columns={displayColumns}
            stickyCols={stickyCols}
          />
        </div>
      ) : (
        <CenteredProgress />
      )}
      <span className={classes.headingRow2}>{t('analysis:range-results-discussion')}</span>
      <div className={classes.subHeadingRow}>{t('analysis:range-results-discussion-description')}</div>
      <div className={classes.editorWrapper}>
        <Editor
          theme="TransferPricing"
          value={discussion}
          init={{ height: '100%' }}
          onEditorChange={onChangeEditor}
          onBlur={() => {
            handleEditorAutoSave(discussion);
          }}
        />
      </div>
    </div>
  );
};
