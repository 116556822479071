import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import {
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Tooltip
} from '@material-ui/core';
import clsx from 'clsx';
import styled from 'styled-components'; // used non-standard styling to override external NumberFormat component's styling
import { hasEditAccess } from '../../../utils';
import { TableBodyProps } from '../FinancialTable.proptype';

const useStyles = makeStyles<Theme>((theme) => ({
  inputContainer: {
    padding: theme.spacing(1),
    height: '100%',
    '&.addRedBorder': {
      border: `1px solid ${theme.palette.error.main}`
    }
  },
  tHeader: {
    fontWeight: 'bolder'
  },
  table: {
    tableLayout: 'fixed',
    '& .tr': {
      '& > .td': {
        '&:not(:first-child)': {
          border: `1px solid ${theme.palette.divider}`,
          textAlign: 'right'
        }
      }
    }
  },
  cell: {
    padding: 0
  },
  input: {
    padding: 0
  }
}));

// on the external component NumberFormat, we use the non-standard styled-components library
const NumberStyle = styled(NumberFormat)`
  font: inherit;
  border: none;
  width: 100%;
  text-align: right;
  font-size: 16px;
  outline: none;
  background-color: #ffffff;
`;

function formatStringAsNumber(val: string) {
  if (Number.isNaN(val) || val === '') {
    return '';
  }

  return Number(val.replace(/,/g, ''));
}

const displayWithDecimals = (val: string | number): string => {
  if (typeof val === 'number') {
    return val.toFixed(2);
  }

  return val;
};

export const FinancialTableBody = ({ data, years, update, footer, onSaveData }: TableBodyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table component="div" className={clsx(classes.table)}>
        <TableBody component="div">
          <TableRow component="div" className="tr">
            <TableCell component="div" className={clsx(classes.tHeader, 'td')} scope="row" />
            {years.map((year) => (
              <TableCell key={year} component="div" className={clsx(classes.tHeader, 'td')} scope="row">
                {year}
              </TableCell>
            ))}
          </TableRow>
          {data.map((financial: any, index) => (
            <TableRow key={financial.name} component="div" className="tr">
              <TableCell component="div" className={clsx('td')} scope="dataRow">
                {t(`analysis:pba-financial-${String(financial.name)}`)}
              </TableCell>
              {years.map((year) => (
                <TableCell key={year} component="div" className={clsx(classes.cell, 'td')} scope="dataRow">
                  <div
                    className={clsx(classes.inputContainer, {
                      addRedBorder: Boolean(financial[year]?.showRedBorder)
                    })}
                  >
                    {financial[year]?.notAvailable === true ? (
                      <Tooltip title={t('entities:tooltip-no-conversion-rate') ?? ''}>
                        <Input
                          disabled
                          disableUnderline
                          fullWidth
                          inputProps={{ className: classes.input }}
                          placeholder={t('label-not-applicable')}
                        />
                      </Tooltip>
                    ) : (
                      <NumberStyle
                        thousandSeparator
                        placeholder={financial[year]?.isAllowed === false ? '' : t('placeholder-add-amount')}
                        decimalScale={2}
                        disabled={!hasEditAccess() || financial[year]?.isAllowed === false}
                        value={displayWithDecimals(financial[year]?.value)}
                        onBlur={(event) => {
                          update(index, year, formatStringAsNumber(event.target.value));
                          onSaveData();
                        }}
                        onChange={(event) => {
                          update(index, year, formatStringAsNumber(event.target.value));
                        }}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
          {footer ? footer : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
