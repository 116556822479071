import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { GenericDropDown, GenericDropDownItem } from '../../../components/GenericDropDown';
import tokens from '../../../styles/designTokens';
import { DownloadIcon, NewVersionIcon } from '../../../svgs';
import { canDownloadReport, hasEditAccess, hasReportsOnlyAccess } from '../../../utils';
import { ReportStatus } from '../LocalReports.proptype';

export interface ActionsDropDownProps {
  anchorEl: Element | null;
  isFailedReport: boolean;
  onClose: () => void;
  onNewReportVersion: () => void;
  onDownload: () => void;
  onManageVersions: () => void;
  openReportModal: () => void;
  startDeleteReport: () => void;
  status: ReportStatus;
}

export const ActionsDropDown = ({
  anchorEl,
  isFailedReport,
  onClose,
  onNewReportVersion,
  onDownload,
  onManageVersions,
  openReportModal,
  startDeleteReport,
  status
}: ActionsDropDownProps) => {
  const { t } = useTranslation();

  let items: Array<GenericDropDownItem | ReactNode> = [];

  if (hasReportsOnlyAccess()) {
    if (canDownloadReport(status)) {
      items.push({
        text: t('reports:action-download'),
        icon: <DownloadIcon />,
        onClick: onDownload
      });
    }

    items.push({
      text: t('reports:action-manage-versions'),
      onClick: onManageVersions
    });
  } else if (hasEditAccess()) {
    items = [
      {
        text: t('reports:action-new-report-version'),
        icon: <NewVersionIcon style={{ color: tokens.core2 }} />,
        onClick: () => {
          onNewReportVersion();
          onClose();
        }
      },
      {
        text: t('reports:action-download'),
        icon: <DownloadIcon style={{ color: tokens.core2 }} />,
        disabled: isFailedReport,
        onClick: () => {
          onDownload();
          onClose();
        }
      },
      <Divider key="divider" variant="middle" />,
      {
        text: t('reports:action-manage-versions'),
        onClick: () => {
          onManageVersions();
          onClose();
        }
      },
      {
        text: t('reports:action-upload-version'),
        onClick: () => {
          openReportModal();
          onClose();
        }
      },
      {
        text: t('reports:action-delete-report'),
        onClick: () => {
          startDeleteReport();
          onClose();
        }
      }
    ];
  }

  return (
    <GenericDropDown
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      items={items}
      anchorEl={anchorEl}
      minWidth="12.313rem"
      onClose={onClose}
    />
  );
};
