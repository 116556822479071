import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, IconButton, makeStyles, Tooltip, withStyles } from '@material-ui/core';
import { Launch as LaunchIcon } from '@material-ui/icons';
import tokens from '../../../styles/designTokens';

export interface FilingTimingDialogProps {
  children: ReactElement;
  filingTimingInfo?: string;
  url: string;
  onOpen: () => void;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  bodyWithData: {
    letterSpacing: '0.0156rem',
    padding: '1.5rem 1.6875rem 1.5rem 1.5rem',
    width: '21.18rem'
  },
  bodyWithoutData: {
    fontSize: '0.875rem',
    letterSpacing: '0.0156rem',
    lineHeight: '1.3rem',
    padding: '1rem',
    width: '21.18rem'
  },
  header: {
    color: tokens.core1,
    fontSize: '1rem'
  },
  content: {
    color: tokens.core2,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    marginTop: '1.4rem'
  },
  footer: {
    color: tokens.core2,
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.8rem',
    fontFamily: tokens.mediumFont.fontFamily,
    '& .icon': {
      marginLeft: '0.5rem'
    },
    fontSize: '0.875rem'
  }
}));

export const FilingTimingDialog = ({ children, filingTimingInfo, url, onOpen, onClose }: FilingTimingDialogProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 11px 0 rgba(0,0,0,0.12)',
      margin: 0,
      width: '23rem',
      minWidth: '23rem'
    }
  }))(Tooltip);

  return (
    <HtmlTooltip
      interactive
      placement="bottom-start"
      enterDelay={0}
      title={
        filingTimingInfo ? (
          <Box className={styles.bodyWithData}>
            <Box className={styles.header}>{t('reports:label-jurisdiction-filing-timing')}</Box>
            <Box className={styles.content}>{filingTimingInfo}</Box>
            <Box className={styles.footer}>
              {t('reports:label-view-global-guide')}
              <IconButton size="small" component={Link} to={url} className="icon">
                <LaunchIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box className={styles.bodyWithoutData}>{t('reports:label-regulation-info-not-available')}</Box>
        )
      }
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </HtmlTooltip>
  );
};
