import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, Box, makeStyles, Typography } from '@material-ui/core';
import { ArrowDropUp, ArrowRight } from '@material-ui/icons';
import { FunctionalAnalysisAddNewInput } from './components/FunctionalAnalysisAddNewInput';
import { FunctionalAnalysisDiscussionEditor } from './components/FunctionalAnalysisDiscussionEditor';
import { FunctionalAnalysisListItem } from './components/FunctionalAnalysisListItem';
import { FunctionalAnalysisCharacteristic, FunctionalAnalysisTableProps } from './FunctionalAnalysisTable.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto'
  },
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0
    }
  },
  summary: {
    background: theme.palette.background.default,
    borderBottom: `0.0625rem solid ${theme.palette.divider}`,
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0
    }
  },
  summaryContent: {
    margin: theme.spacing(1, 0),
    order: 2,
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0)
    }
  },
  summaryButton: {
    padding: theme.spacing(0.5),
    margin: 0
  },
  listContainer: {
    overflowY: 'hidden',
    overflow: 'scroll',
    overflowX: 'hidden'
  },
  functionAnalysisContainer: {
    display: 'flex',
    padding: theme.spacing(0, 3),
    justifyContent: 'space-between',
    borderTop: `0.0625rem solid ${theme.palette.secondary.light}`
  },
  functionalAnalysisDropdowns: {
    width: '45%',
    marginRight: theme.spacing(3),
    maxHeight: '64vh',
    overflow: 'scroll',
    overflowX: 'hidden'
  },

  listHeaderText1: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '8%'
    },
    marginLeft: '5%',
    color: theme.palette.text.secondary
  },
  listHeaderText2: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '8%'
    },
    marginLeft: '15%',
    color: theme.palette.text.secondary
  },
  listHeaderText3: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '1%'
    },
    marginLeft: '2%',
    color: theme.palette.text.secondary
  }
}));

type functionalAnalysisCharacteristicsKeys = 'Functions' | 'Risks' | 'Assets';

export const FunctionalAnalysisTable = ({
  functionalAnalysisCharacteristics,
  editFunctionalAnalysisCharacteristic,
  deleteFunctionalAnalysisCharacteristic,
  createNewCharacteristic,
  selectedAnalysis,
  setSelectedAnalysis,
  expandedSections,
  setExpandedSections,
  setCurrentSection,
  currentSection
}: FunctionalAnalysisTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box margin="1rem">
        <Typography display="inline" className={classes.listHeaderText1}>
          {t('analysis:functional_characteristic.characteristic')}
        </Typography>
        <Typography display="inline" className={classes.listHeaderText2}>
          {t('analysis:functional_characteristic.tested_party')}
        </Typography>
        <Typography display="inline" className={classes.listHeaderText3}>
          {t('analysis:functional_characteristic.other_participants')}
        </Typography>
      </Box>

      <Box className={classes.functionAnalysisContainer}>
        <Box className={classes.functionalAnalysisDropdowns}>
          {functionalAnalysisCharacteristics
            ? Object.keys(functionalAnalysisCharacteristics).map((key, index) => {
                const isExpanded = expandedSections.includes(String(index));
                return (
                  <Accordion
                    key={`functionalAnalysisHeader-${String(index)}`}
                    square
                    expanded={isExpanded}
                    classes={{ root: classes.accordion }}
                    onChange={(event, expanded) => {
                      const newExpanedSections = expanded
                        ? `${expandedSections}${String(index)}`
                        : expandedSections.replace(String(index), '');

                      setExpandedSections(newExpanedSections);
                    }}
                  >
                    <AccordionSummary
                      classes={{
                        root: classes.summary,
                        content: classes.summaryContent,
                        expandIcon: classes.summaryButton
                      }}
                      expandIcon={isExpanded ? <ArrowDropUp /> : <ArrowRight />}
                    >
                      <Typography>{key}</Typography>
                    </AccordionSummary>
                    <Box className={classes.listContainer}>
                      {functionalAnalysisCharacteristics
                        ? functionalAnalysisCharacteristics[
                            key as functionalAnalysisCharacteristicsKeys
                          ].map((item: FunctionalAnalysisCharacteristic) => (
                            <FunctionalAnalysisListItem
                              key={`functionalAnalysisItem-${item.pbaFunctionalAnalysisDataId}`}
                              item={item}
                              setCurrentSection={setCurrentSection}
                              selectedAnalysis={selectedAnalysis}
                              setSelectedAnalysis={setSelectedAnalysis}
                              editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
                              areaId={index + 1}
                              deleteFunctionalAnalysisCharacteristic={deleteFunctionalAnalysisCharacteristic}
                              functionalAnalysisCharacteristics={
                                functionalAnalysisCharacteristics[key as functionalAnalysisCharacteristicsKeys]
                              }
                            />
                          ))
                        : null}
                      <FunctionalAnalysisAddNewInput
                        setSelectedAnalysis={setSelectedAnalysis}
                        areaId={index + 1}
                        createNewCharacteristic={createNewCharacteristic}
                        functionalAnalysisCharacteristicsArea={
                          functionalAnalysisCharacteristics[key as functionalAnalysisCharacteristicsKeys]
                        }
                      />
                    </Box>
                  </Accordion>
                );
              })
            : null}
        </Box>
        <FunctionalAnalysisDiscussionEditor
          selectedAnalysis={selectedAnalysis}
          currentSection={currentSection}
          editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
        />
      </Box>
    </>
  );
};
