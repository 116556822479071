import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Button, IconButton, useTheme } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  LocalReportCreationTabsContainerActions,
  LocalReportCreationTabsContainerProps
} from './LocalReportCreationTabsContainer.proptype';
import { ArrowNavigationTabsBar } from '../../components/ArrowNavigationTabsBar/ArrowNavigationTabsBar';
import tokens from '../../styles/designTokens';
import { ReportsIcon } from '../../svgs';
import { hasEditAccess, hasReportsOnlyAccess } from '../../utils/hasAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  rootContent: {
    backgroundColor: tokens.neutral70,
    height: '100%',
    overflow: 'auto',
    padding: '1.43rem 4.75rem 2.37rem 4.75rem'
  },
  modalTitleWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    padding: theme.spacing(1.5, 2)
  },
  titleLabel: {
    fontFamily: 'Basis Grotesque Pro Medium',
    fontSize: '1.125rem'
  },
  entityLabel: {
    marginLeft: '1rem',
    fontSize: '1.125rem',
    color: tokens.core2
  },
  headerIcon: {
    backgroundColor: tokens.product15,
    borderRadius: '50%',
    padding: 0,
    height: '2.25rem',
    width: '2.25rem',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: tokens.product15
    },
    '& svg path': {
      fill: theme.palette.primary.main
    }
  },
  actionsWrapper: {
    marginTop: 'auto',
    padding: theme.spacing(2, 7),
    backgroundColor: theme.palette.common.white,
    display: 'flex'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  leftButton: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    marginRight: 'auto'
  },
  cancelButton: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: '1rem'
  },
  rightButton: {
    paddingLeft: '3rem',
    paddingRight: '3rem'
  }
}));

export const LocalReportCreationTabsContainer = ({
  title,
  jurisdictionName,
  tabs,
  stageCompleteness,
  onCancel,
  onSubmit
}: LocalReportCreationTabsContainerProps) => {
  const classes = useStyles();
  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].key);
  const selectedIndex = tabs.findIndex((tab) => tab.key === selectedTabKey);
  const selected = tabs[selectedIndex];
  const { t } = useTranslation();
  const theme = useTheme();

  const onSubmitHandler = () => {
    onSubmit();
  };

  const onBackPressed = () => {
    setSelectedTabKey(tabs[selectedIndex - 1].key);
  };

  const onNextPressed = () => {
    setSelectedTabKey(tabs[selectedIndex + 1].key);
  };

  const onTabClick = (key: string) => {
    setSelectedTabKey(key);
  };

  const disableNextButton = (): boolean => {
    return !stageCompleteness[selectedIndex];
  };

  const disableCreateReportButton = (): boolean => {
    return !stageCompleteness.every((stage) => stage);
  };

  const actions = [
    selectedIndex === 0
      ? null
      : {
          name: t('action-back'),
          variant: 'outlined',
          color: 'primary',
          className: classes.leftButton,
          handler: onBackPressed
        },
    {
      name: t('action-cancel'),
      color: 'primary',
      className: classes.cancelButton,
      handler: onCancel
    },
    selectedIndex === tabs.length - 1
      ? {
          name: t('reports:action-create-report'), // todo: need to remove?
          variant: 'contained',
          color: 'primary',
          disabled: disableCreateReportButton(),
          className: classes.rightButton,
          handler: onSubmitHandler
        }
      : {
          name: t('action-next'),
          variant: 'contained',
          color: 'primary',
          disabled: disableNextButton(),
          className: classes.rightButton,
          handler: onNextPressed
        }
  ].filter(Boolean) as LocalReportCreationTabsContainerActions[];

  const createOnTabClickHandler = (index: number): ((key: string) => void) | undefined => {
    if (index === 0 || stageCompleteness[index] || stageCompleteness[index - 1]) {
      return onTabClick;
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.modalTitleWrapper}>
        <IconButton className={classes.headerIcon}>
          <ReportsIcon />
        </IconButton>
        {hasEditAccess() && !hasReportsOnlyAccess() && <span className={classes.titleLabel}>{title}</span>}
        <span className={classes.entityLabel}>{jurisdictionName}</span>
        <IconButton className={classes.closeIcon} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>

      <ArrowNavigationTabsBar
        tabs={tabs.map((tab, index) => ({
          ...tab,
          onTabClick: createOnTabClickHandler(index)
        }))}
        selectedTab={selectedTabKey}
        selectedTabColor={tokens.product15}
        selectedFontColor={theme.palette.primary.main}
      />

      <Box className={classes.rootContent}>{selected?.component}</Box>

      <Box className={classes.actionsWrapper}>
        {actions.map(({ name, handler, ...otherProps }) => (
          <Button key={name} onClick={handler} {...otherProps}>
            {name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
