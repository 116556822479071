import { createSelector } from 'reselect';
import { selectCountries } from './baseData';
import { Country } from '../models';
import { RootState } from '../redux';

export const selectPBAsList = (state: RootState) => {
  return state.pbas.pbas;
};

export const selectPBAName = (pbaId: number) => createSelector(selectPBA(pbaId), (pba) => pba?.name ?? '');

export const selectPBA = (pbaId: number | null | undefined) =>
  createSelector(
    selectPBAsList,
    (pbas) =>
      (pbas ?? []).find((pba) => {
        return pba.pbaId === pbaId;
      }) ?? null
  );

export const selectCurrentPBA = (state: RootState) => state.pbas?.currentPBA;

export const selectTestedPartyFinancial = (state: RootState) => state.pbas.balancesheetFinancials;
export const selectPBAPli = (state: RootState) => state.pbas?.pbaPli;

export const selectCurrentTestedParty = (state: RootState) => state.pbas?.currentTestedParty;

export const selectPbaJurisdictions = (state: RootState) => state.pbas.pbaJurisdiction;

export const selectFunctionalAnalysisCharacteristics = (state: RootState) =>
  state.pbas.functionalAnalysisCharacteristics;

export const selectTestedPartyAdditionalFinancialInfo = (state: RootState) => state.pbas.additionalFinancialInfo;

export const selectJurisdictionDescription = (jurisdictionId: number | null | undefined) =>
  createSelector(selectPbaJurisdictions, (jurisdictions) => {
    const pbaJurisdiction = (jurisdictions ?? []).find((jurisdiction) => {
      return jurisdiction.jurisdictionId === jurisdictionId;
    });
    return pbaJurisdiction?.pbaJurisdictionInfo ?? null;
  });

export const selectPBAJurisdictionById = (jurisdictionId: number | null | undefined) =>
  createSelector(
    selectPbaJurisdictions,
    (jurisdictions) =>
      (jurisdictions ?? []).find((jurisdiction) => {
        return jurisdiction.jurisdictionId === jurisdictionId;
      }) ?? null
  );

export const selectPbaJurisdictionCountry = createSelector(
  selectPbaJurisdictions,
  selectCountries,
  (jurisdictions, countries) => {
    let pbaCountries: Country[] = [];
    (jurisdictions ?? []).forEach((jurisdiction) => {
      const country = countries.find((country) => {
        return country.countryId === jurisdiction.jurisdictionId;
      });
      pbaCountries = country ? [country, ...pbaCountries] : pbaCountries;
    });
    return pbaCountries;
  }
);

export const selectJurisdictionPlis = (jurisdictionId: number | null | undefined) =>
  createSelector(selectPbaJurisdictions, (jurisdictions) => {
    const pbaJurisdiction = (jurisdictions ?? []).find((jurisdiction) => {
      return jurisdiction.jurisdictionId === jurisdictionId;
    });
    return pbaJurisdiction?.jurisdictionPli?.data ?? null;
  });

export const selectJurisdictionPliFormat = (jurisdictionId: number | null | undefined) =>
  createSelector(selectPbaJurisdictions, (jurisdictions) => {
    const pbaJurisdiction = (jurisdictions ?? []).find((jurisdiction) => {
      return jurisdiction.jurisdictionId === jurisdictionId;
    });
    return pbaJurisdiction?.jurisdictionPliFormat ?? null;
  });

export const selectInitialRangeComparables = (state: RootState) => state.pbas?.initialRangeComparables;

export const selectInitialRangeComparablesWithRejections = (state: RootState) =>
  state.pbas?.initialRangeComparablesWithRejections;

export const selectInitialRangeResults = (state: RootState) => state.pbas?.initialRangeResults;

export const selectAppiedBulkRejections = (state: RootState) => state.pbas?.appliedBulkRejections;

export const selectNonAppiedBulkRejections = (state: RootState) => state.pbas?.nonAppliedBulkRejections;

export const selectInitialRange = createSelector(selectInitialRangeResults, (initialRangeResults) => {
  return initialRangeResults;
});

export const selectFinalRangeComparables = (state: RootState) => state.pbas?.finalRangeComparables;

export const selectFinalRangeResults = (state: RootState) => state.pbas?.finalRangeResults;

export const selectRangeDiscussion = (state: RootState) => state.pbas?.finalRangeDiscussion;

export const selectRangeDiscussionText = createSelector(selectRangeDiscussion, (finalRangeDiscussion) => {
  return finalRangeDiscussion?.rangeDiscussion;
});

export const selectcsStatus = (state: RootState) => state.pbas?.csStatus;

export const selectFineTuningCompanies = (state: RootState) => state.pbas?.fineTuningCompanies;

export const selectRejectionReasons = (state: RootState) => state.pbas?.rejectionReasons;

export const selectCurrenctFineTuningCompany = (state: RootState) => state.pbas?.selectedFineTuningCompany;

export const selectFineTuningCompaniesWithYearlyAvgs = createSelector(
  selectFineTuningCompanies,
  selectInitialRangeComparables,
  (comps, yealryAvgs) => {
    const obj: any = [];
    (yealryAvgs?.data ?? []).forEach((year: any) => {
      const fineTuningComp = (comps?.data ?? []).find((comp: any) => {
        return Number(comp.sourceId) === Number(year.sourceId);
      });
      if (fineTuningComp) {
        const compData = { ...year, ...fineTuningComp };
        obj.push(compData);
      }
    });

    const fineTuningColumns = (comps?.columns ?? []).filter((comp: any) => {
      return comp.key === 'compStatus';
    });

    const yearlyAvgColumns = yealryAvgs?.columns ?? [];

    return {
      data: obj,
      columns: [...yearlyAvgColumns, ...fineTuningColumns]
    };
  }
);

export const fineTuningCounts = createSelector(selectFineTuningCompanies, (fineTuningComps) => {
  const total = (fineTuningComps?.data ?? []).length - 1;
  let rejected = 0;
  let accepted = 0;
  (fineTuningComps?.data ?? []).forEach((comp: any) => {
    if (comp.compStatus === 'global.accepted' || comp.compStatus === 'global.accepted_and_reviewed') {
      accepted++;
    }

    if (comp.compStatus === 'global.rejected') {
      rejected++;
    }
  });
  return {
    total,
    accepted,
    rejected
  };
});

export const selectFinancialInfoCompany = (state: RootState) => state.pbas?.financialInfoCompany;
export const selectPliInfoCompany = (state: RootState) => state.pbas?.pliInfoCompany;
