import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/index';
import { Authentication } from './hocs/authenticate';
import { BaseDataLoader } from './hocs/baseDataLoader';
import { Configuration } from './hocs/configuration';
import LaunchDarklyFeatureFlagInit from './hocs/LaunchDarkly';
import { Rum } from './hocs/Rum';
import { WalkMe } from './hocs/walkme';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { setupI18n } from './utils';

void setupI18n();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Configuration>
        <Authentication>
          <>
            <Rum />
            <WalkMe />
            <BaseDataLoader>
              <LaunchDarklyFeatureFlagInit>
                <App />
              </LaunchDarklyFeatureFlagInit>
            </BaseDataLoader>
          </>
        </Authentication>
      </Configuration>
    </Provider>
  </React.StrictMode>,
  document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
