import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';
import { StudyTabsContent, StudyDashboardWithTabsProps } from './components';
import { StudyDashboardTab, StudyDashboardProps } from './StudyDashboard.proptype';
import { PrimaryFunction, ProfitBasedAnalysis, Transaction, TransactionBasedAnalysis } from '../../models';
import { fetchEntities } from '../../redux/entities';
import {
  fetchPBAs,
  savePBA,
  deletePBA,
  createTestedParty,
  updatePbaTransactions
} from '../../redux/profitBasedAnalyses';
import { fetchStudies } from '../../redux/studies';
import { fetchTBAs, saveTBA, deleteTBA } from '../../redux/transactionBasedAnalyses';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectEntitiesList,
  selectPrimaryFunctions,
  selectPBAsList,
  selectStudiesList,
  selectStudy,
  selectTBAsList,
  selectTransactionsList,
  selectUPECurrency
} from '../../selectors';
import { AppDispatch } from '../../store';
import { handleRoleRedirect } from '../../utils';
import { ProfitBasedAnalysisAddModal } from '../ProfitBasedAnalysisAddModal';
import { StudyTabsEditModal } from '../StudyTabsEditModal';

interface ConnectorProps extends StudyDashboardProps {
  component: ComponentType<StudyDashboardWithTabsProps>;
}

const { ProfitBasedAnalysisTab, TransactionBasedAnalysisTab } = StudyDashboardTab;

const getPrimaryFunctionOptions = (primaryFunctions: PrimaryFunction[], t: TFunction) =>
  primaryFunctions.map((primaryFunction: PrimaryFunction) => ({
    title: t(`entities:primary-function-${primaryFunction.name}`),
    value: primaryFunction.primaryFunctionId
  }));

const Connector = ({ studyId, component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState(ProfitBasedAnalysisTab);
  const [isNewPBaModalOpen, setIsNewPBAModalOpen] = useState(false);
  const [isStudyTabsModalOpen, setIsStudyTabsModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState<ProfitBasedAnalysis | TransactionBasedAnalysis | null>(null);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const primaryFunctions = useSelector(selectPrimaryFunctions);
  const transactions = useSelector(selectTransactionsList);
  const entities = useSelector(selectEntitiesList);
  const upeCurrency = useSelector(selectUPECurrency);
  const studies = useSelector(selectStudiesList);
  const study = useSelector(selectStudy(studyId));
  const isPBA = selectedKey === ProfitBasedAnalysisTab;
  const pbas = useSelector(selectPBAsList);
  const tbas = useSelector(selectTBAsList);

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (transactions === null) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    setLoading(true);
    if (studies === null) {
      void dispatch(fetchStudies());
    }
  }, [studies, dispatch]);

  useEffect(() => {
    if (isLoading) {
      void dispatch(fetchPBAs(studyId));
      void dispatch(fetchTBAs(studyId));
    }
  }, [isLoading, studyId, dispatch]);

  useEffect(() => {
    if ((pbas && isPBA) || (tbas && !isPBA)) {
      setLoading(false);
    }
  }, [pbas, isPBA, tbas]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleClose = () => {
    setSelectedElement(null);
    setIsStudyTabsModalOpen(false);
    setIsNewPBAModalOpen(false);
  };

  const handleSubmit = async (data: ProfitBasedAnalysis | TransactionBasedAnalysis) => {
    const { name } = data;
    if (isPBA) {
      const pba = { ...selectedElement, name, description: selectedElement?.description ?? '' };
      unwrapResult(await dispatch(savePBA({ pba, t })));
      void dispatch(fetchPBAs(studyId));
    } else {
      const tba = { ...selectedElement, name, description: selectedElement?.description ?? '' };
      unwrapResult(await dispatch(saveTBA({ tba, t })));
      void dispatch(fetchTBAs(studyId));
    }

    handleClose();
  };

  const handleSubmitNewPBA = async (data: any) => {
    const {
      name,
      otherPrimaryFunction,
      primaryFunction,
      testedPartyJustification,
      testedPartyName,
      segmentType,
      testedParty
    } = data;
    const pba = {
      name,
      description: '',
      study: { studyId: study?.studyId },
      primaryLegalEntity: { entityId: testedParty?.entityId },
      method: 1
    };
    const createdPbaInfo = unwrapResult(await dispatch(savePBA({ pba, t })));
    const { pbaId } = createdPbaInfo;
    const testedPartyData = {
      justification: testedPartyJustification,
      name: testedPartyName,
      otherPrimaryFunction,
      primaryFunction: { primaryFunctionId: primaryFunction },
      segmentType: segmentType.value
    };
    unwrapResult(await dispatch(createTestedParty({ pbaId, testedParty: testedPartyData })));

    const promises: any = [];

    data.transactions.forEach((transaction: Transaction) => {
      const params = { transactionId: transaction.transactionId, pbaId };
      promises.push(dispatch(updatePbaTransactions(params)));
    });

    await Promise.all(promises);

    void dispatch(fetchPBAs(studyId));
    handleClose();
  };

  const handleEdit = async (dataId: number) => {
    if (isPBA) {
      const pba = pbas?.find((s) => s.pbaId === dataId) ?? null;
      setSelectedElement(pba);
      setIsStudyTabsModalOpen(true);
    } else {
      const tba = tbas?.find((s) => s.tbaId === dataId) ?? null;
      setSelectedElement(tba);
      setIsStudyTabsModalOpen(true);
    }
  };

  const handleDelete = async (dataId: number) => {
    if (isPBA) {
      unwrapResult(await dispatch(deletePBA(dataId)));
      void dispatch(fetchPBAs(studyId));
    } else {
      unwrapResult(await dispatch(deleteTBA(dataId)));
      void dispatch(fetchTBAs(studyId));
    }
  };

  const tabs = [
    {
      key: ProfitBasedAnalysisTab,
      label: ProfitBasedAnalysisTab,
      disabled: false,
      content: (
        <StudyTabsContent
          isLoading={isLoading}
          data={pbas}
          editElement={handleEdit}
          deleteElement={handleDelete}
          selectedKey={selectedKey}
          onAddSingle={() => {
            setIsNewPBAModalOpen(true);
          }}
        />
      ),
      createButtonText: t('analysis:action-new-profit-based-analysis')
    },
    {
      key: TransactionBasedAnalysisTab,
      label: TransactionBasedAnalysisTab,
      disabled: true,
      content: (
        <StudyTabsContent
          isLoading={isLoading}
          data={tbas}
          editElement={handleEdit}
          deleteElement={handleDelete}
          selectedKey={selectedKey}
        />
      ),
      createButtonText: t('analysis:action-new-transaction-based-analysis')
    }
  ];

  return (
    <>
      <Component
        title={t('analysis:title-dashboard')}
        study={study}
        pbas={pbas}
        selectedKey={selectedKey}
        tabs={tabs}
        onSelectTab={(tab: number) => {
          setSelectedKey(tabs[tab].key);
        }}
        onAddSingle={() => {
          setIsNewPBAModalOpen(true);
        }}
        onCreateTba={() => {
          handleRoleRedirect(
            `${window.location.origin}/legacy/local-files/study/${String(studyId)}/redirect;activeTab=2`
          );
        }}
      />
      {isNewPBaModalOpen && (
        <ProfitBasedAnalysisAddModal
          entities={entities}
          upeCurrency={upeCurrency}
          study={study}
          transactions={transactions}
          primaryFunctionOptions={getPrimaryFunctionOptions(primaryFunctions, t)}
          onClose={handleClose}
          onSubmit={handleSubmitNewPBA}
        />
      )}
      {isStudyTabsModalOpen && (
        <StudyTabsEditModal element={selectedElement} isPBA={isPBA} onClose={handleClose} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default Connector;
