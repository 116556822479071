import { useDispatch, useSelector } from 'react-redux';
import { ConnectorProps } from './EntitiesTab.proptype';
import { actions as localReportsActions } from '../../../redux/localReports';
import { PrimaryEntityItem, TradingPartnerItem } from '../../../redux/localReports/localReports.proptype';
import * as localReportsSelectors from '../../../selectors/localReports';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const primaryEntitiesList = useSelector(localReportsSelectors.selectPrimaryEntitiesList);
  const selectedPrimaryEntities = useSelector(localReportsSelectors.selectSelectedPrimaryEntities);
  const tradingPartnersList = useSelector(localReportsSelectors.selectTradingPartnersList);
  const selectedTradingPartners = useSelector(localReportsSelectors.selectSelectedTradingPartners);

  const onPrimaryEntitiesSelectionChange = (selectedIds: number[]) => {
    dispatch(localReportsActions.setSelectedPrimaryEntities(selectedIds));
  };

  const onTradingPartnersSelectionChange = (selectedIds: number[]) => {
    dispatch(localReportsActions.setSelectedTradingPartners(selectedIds));
  };

  return (
    <Component
      primaryEntities={(primaryEntitiesList ?? []).sort((a: PrimaryEntityItem, b: PrimaryEntityItem) =>
        a.code < b.code ? -1 : 1
      )}
      selectedPrimaries={selectedPrimaryEntities.map((entity) => entity.entityId)}
      tradingPartners={(tradingPartnersList ?? []).sort((a: TradingPartnerItem, b: TradingPartnerItem) =>
        a.code < b.code ? -1 : 1
      )}
      selectedPartners={selectedTradingPartners.map((entity) => entity.entityId)}
      onPrimaryEntitiesSelectionChange={onPrimaryEntitiesSelectionChange}
      onTradingPartnersSelectionChange={onTradingPartnersSelectionChange}
    />
  );
};

export default Connector;
