import { Tabs, Tab, makeStyles, Theme } from '@material-ui/core';
import { ArrowNavigationTabsBarProps } from './ArrowNavigationTabsBar.proptype';
import tokens from '../../styles/designTokens';

export const ArrowNavigationTabsBar = ({
  tabs,
  selectedTab,
  selectedTabColor,
  unselectedTabColor,
  disabledTabColor,
  selectedFontColor,
  unselectedFontColor,
  disabledFontColor
}: ArrowNavigationTabsBarProps) => {
  const useStyles = makeStyles((theme: Theme) => {
    const sepColor = theme.palette.divider;

    return {
      tabs: {
        minHeight: '44px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.common.white, // Outside section
        borderTop: '1px solid ' + sepColor,
        borderBottom: '1px solid ' + sepColor,
        '& .MuiTabs-flexContainer': {
          display: 'inline-flex',
          gap: '1px',
          backgroundColor: sepColor,
          clipPath: 'polygon(0 0, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0 100%)',
          '&::after': {
            content: '""',
            width: '1px'
          }
        },
        '& .MuiTabs-indicator': {
          display: 'none'
        },
        '& .MuiTabs-scroller': {
          borderBottom: 'none'
        },
        '& .MuiTab-root': {
          '.MuiTab-textColorInherit': {},
          backgroundColor: unselectedTabColor ? unselectedTabColor : theme.palette.common.white,
          color: unselectedFontColor ? unselectedFontColor : theme.palette.text.primary,
          minHeight: '44px',
          '& .MuiTab-wrapper': {
            paddingLeft: '1rem',
            paddingRight: '1.125rem'
          },
          '&:not(:first-child)': {
            marginLeft: '-19.5px',
            clipPath: 'polygon(0 0, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0 100%, 20px 50%)'
          },
          '&:first-child': {
            clipPath: 'polygon(0 0, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0 100%)'
          }
        },
        '& .Mui-selected': {
          backgroundColor: selectedTabColor ? selectedTabColor : theme.palette.common.white,
          color: selectedFontColor ? selectedFontColor : theme.palette.primary.main,
          fontFamily: tokens.mediumFont.fontFamily,
          fontWeight: 'initial'
        },
        '& .Mui-disabled': {
          backgroundColor: disabledTabColor ? disabledTabColor : theme.palette.common.white,
          color: disabledFontColor ? disabledFontColor : theme.palette.text.disabled
        }
      }
    };
  });

  const styles = useStyles();

  return (
    <Tabs className={styles.tabs} value={selectedTab} textColor="primary">
      {tabs?.map(({ key, label, disabled, className, onTabClick }) => (
        <Tab
          key={key}
          className={className}
          label={label}
          value={key}
          disabled={disabled}
          onClick={() => {
            onTabClick?.(key);
          }}
        />
      ))}
    </Tabs>
  );
};
