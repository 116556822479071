import { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid, Divider, makeStyles } from '@material-ui/core';
import { AnalysisHeader } from '../../app/AnalysisHeader';
import { AnalysisSideMenu } from '../../app/AnalysisSideMenu';
export interface LayoutProps {
  aside?: ReactElement;
  header?: ReactElement;
  main?: ReactElement;
}

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  container: {
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative'
  },
  header: {
    flexBasis: 0
  }
});

export const Layout = ({ aside, header, main }: LayoutProps) => {
  const match = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const classes = useStyles();

  return (
    <Grid container className={classes.root} direction="column" wrap="nowrap" spacing={0}>
      <Grid item xs={12} component="header" className={classes.header}>
        {match ? <AnalysisHeader /> : header}
      </Grid>
      <Divider />
      <Grid item container className={classes.container} wrap="nowrap" spacing={0}>
        <Grid item xs={3} lg={2} component="aside">
          {match ? <AnalysisSideMenu /> : aside}
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid item xs component="main" className={classes.container}>
          {main}
        </Grid>
      </Grid>
    </Grid>
  );
};
