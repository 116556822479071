import { ReactElement } from 'react';
import { Button, ListItem, ListItemText, Box, makeStyles } from '@material-ui/core';
import { PbaTbaJurisdictionStatus } from '@xbs/xbs-enums';
import { Circle } from '../../../components/Circle';
import appColors from '../../../styles/appColors';
import tokens from '../../../styles/designTokens';
import { buttonText1, buttonText2 } from '../../../styles/typography';
import { hasEditAccess } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.25rem',
    cursor: 'pointer',
    margin: theme.spacing(1, 0),
    position: 'relative',
    padding: theme.spacing(0, 1, 0, 0),
    '&:not(:last-child)::after, &:not(:first-child)::before': {
      position: 'absolute',
      height: theme.spacing(0.5),
      borderLeft: `2px solid ${theme.palette.secondary.light}`,
      left: theme.spacing(1.4)
    },
    '&::after': {
      top: '100%'
    },
    '&::before': {
      bottom: '100%'
    },
    '&.Mui-selected, .Mui-selected:hover': {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.primary.dark,
      '& > .MuiListItemText-root > .MuiListItemText-primary': {
        ...buttonText2
      }
    },
    '&.MuiListItem-gutters': {
      paddingLeft: '1rem',
      '& > .MuiListItemText-root > .MuiListItemText-primary': {
        ...buttonText1
      }
    },
    '&.MuiListItem-gutters:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  listItemsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  listIcon: {
    cursor: 'pointer',
    margin: 'auto',
    marginLeft: '0.625rem',
    height: '1.563rem',
    '&:hover': {
      '& > svg.MuiSvgIcon-root:hover': {
        borderRadius: '100%',
        backgroundColor: tokens.product15,
        fill: tokens.product100
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    width: '100%'
  },
  button: {
    width: '100%',
    backgroundColor: (props: any) => (props.isComplete ? '' : theme.palette.primary.light),
    color: (props: any) => (props.isComplete ? '' : theme.palette.primary.main)
  },
  icon: {
    minWidth: 'auto',
    padding: theme.spacing(0, 0.5),
    height: '100%'
  },
  progressIcon: {
    marginRight: '0.625rem'
  },
  progressIconPlaceholder: {
    marginLeft: '1.5rem'
  },
  buttonIcon: {
    paddingLeft: '1rem',
    paddingTop: '0.5rem'
  }
}));

interface AnalysisSideMenuItemProps {
  icon?: ReactElement | null;
  text: string;
  isButton?: boolean;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
  onIconClick?: () => void;
  onListIconClick?: () => void;
  showProgressIcon: boolean;
  isComplete: boolean;
  buttonIcon?: ReactElement | null;
  listIcon?: ReactElement | null;
  status?: number;
}

export const AnalysisSideMenuItem = ({
  icon,
  text,
  isButton,
  disabled,
  selected,
  onClick,
  onIconClick,
  onListIconClick,
  showProgressIcon,
  isComplete,
  buttonIcon,
  listIcon,
  status
}: AnalysisSideMenuItemProps) => {
  const classes = useStyles({ isComplete });

  interface ColorMap {
    [key: string]: any;
  }

  const statusColorMap = {
    [PbaTbaJurisdictionStatus.ByName.Complete.Id]: {
      color: appColors.jurisdictionStatus.complete.fill,
      border: appColors.jurisdictionStatus.complete.border
    },
    [PbaTbaJurisdictionStatus.ByName.InReview.Id]: {
      color: appColors.jurisdictionStatus.inReview.fill,
      border: appColors.jurisdictionStatus.inReview.border
    },
    [PbaTbaJurisdictionStatus.ByName.InProgress.Id]: {
      color: appColors.jurisdictionStatus.inProgress.fill,
      border: appColors.jurisdictionStatus.inProgress.border
    }
  };
  const isCompleteColorMap = {
    true: {
      color: appColors.jurisdictionStatus.complete.fill,
      border: appColors.jurisdictionStatus.complete.border
    },
    false: {
      color: appColors.jurisdictionStatus.inProgress.fill,
      border: appColors.jurisdictionStatus.inProgress.border
    }
  };

  const colorMapToUse: ColorMap = status ? statusColorMap : isCompleteColorMap;
  const colorMapKeyToUse = status ? status : isComplete.toString();

  return isButton ? (
    <ListItem className={classes.buttonContainer}>
      <Button
        endIcon={buttonIcon}
        className={classes.button}
        variant={isComplete ? 'contained' : 'outlined'}
        color={isComplete ? 'primary' : 'default'}
        disabled={(isComplete && !hasEditAccess()) ?? disabled}
        onClick={onClick}
      >
        {text}
      </Button>
      {icon && (
        <Button className={classes.icon} variant="contained" color="primary" disabled={disabled} onClick={onIconClick}>
          {icon}
        </Button>
      )}
    </ListItem>
  ) : (
    <Box className={classes.listItemsContainer}>
      <ListItem button className={classes.root} disabled={disabled} selected={selected} onClick={onClick}>
        {showProgressIcon && (
          <Box title="progressIcon" className={`${classes.progressIcon} ${isComplete ? 'complete' : 'pending'}`}>
            <Circle
              color={colorMapToUse[colorMapKeyToUse].color}
              borderColor={colorMapToUse[colorMapKeyToUse].border}
            />
          </Box>
        )}
        <ListItemText className={showProgressIcon ? '' : classes.progressIconPlaceholder} primary={text} />
      </ListItem>
      <Box className={classes.listIcon} onClick={onListIconClick}>
        {listIcon}
      </Box>
    </Box>
  );
};
