import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { ToggleButton } from '@xbs/xbs-common-ui';
import { PbaTbaJurisdictionStatus, PrimaryFunctions } from '@xbs/xbs-enums';
import { JurisdictionAnalysisInfoProps, JurisdictionPli } from './JurisdictionAnalysisInfo.proptype';
import { CenteredProgress, JurisdictionStatusDropDown } from '../../components';
import { SummaryTile } from '../../components/SummaryTile';
import tokens from '../../styles/designTokens';
import { body1, body2, body4, buttonText2, captionOverline, title3 } from '../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../utils/formatPliDisplayValue';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    height: '100%',
    width: '100%'
  },
  body4,
  pliBoxContainer: {
    borderBottom: `1px solid ${tokens.neutral90}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  title: {
    ...title3,
    paddingLeft: theme.spacing(3)
  },
  headerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  leftDataBlocks: {
    display: 'flex'
  },
  infoBlock: {
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  infoTitle: {
    ...captionOverline,
    lineHeight: '1.25rem',
    color: tokens.core2,
    textTransform: 'uppercase'
  },
  infoValue: {
    ...body1,
    color: tokens.core1,
    marginTop: theme.spacing(0.5),
    backgroundColor: tokens.neutral70,
    borderRadius: '0.25rem',
    textAlign: 'center',
    padding: '0.375rem 0.75rem'
  },
  endBlock: {
    display: 'flex',
    marginRight: theme.spacing(2)
  },
  companiesBlock: {
    paddingRight: theme.spacing(2)
  },
  compsValue: {
    ...tokens.regularFont,
    fontSize: '1.5rem',
    lineHeight: '1.625rem',
    color: theme.palette.text.primary,
    margin: '0.3rem 0 0 0',
    textAlign: 'right'
  },
  pliList: {
    padding: theme.spacing(2),
    backgroundColor: tokens.neutral70,
    height: '100%',
    width: '100%'
  },
  pliBlock: {
    alignItems: 'center',
    display: 'flex'
  },
  testedPartyPliBlock: {
    alignItems: 'center',
    display: 'flex',
    letteSpacing: '0.009rem'
  },
  testedPartPli: {
    ...captionOverline,
    paddingRight: theme.spacing(2),
    color: theme.palette.info.dark,
    textTransform: 'uppercase'
  },
  pliValue: {
    ...body2,
    color: theme.palette.primary.main,
    padding: '0.375rem 0.75rem',
    borderRadius: '0.25rem'
  },
  testedPartyStatusBlock: {
    alignItems: 'center',
    display: 'flex',
    '& .MuiBox-root': {
      height: '1.75',
      fontFamily: 'Basis Grotesque Pro',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0,
      textTransform: 'none'
    }
  },
  status: {
    color: theme.palette.info.dark,
    fontFamily: 'Basis Grotesque Pro',
    fontSize: '0.75rem',
    letterSpacing: '0.038rem',
    fontWeight: 500,
    marginRight: '1rem'
  },
  statusCompletionLabel: {
    height: '1.75rem',
    width: '6.375rem'
  },
  testedPartyRangeBlock: {
    alignItems: 'center',
    display: 'flex'
  },
  range: {
    ...captionOverline,
    color: theme.palette.info.dark,
    width: '4.5rem'
  },
  pliSwitch: {
    '& .switchBase': {
      backgrounColor: 'red'
    },
    '& .switchBase.Mui-checked': {
      backgrounColor: 'blue'
    }
  },
  viewAnalysisButton: {
    ...buttonText2,
    height: '2.216rem',
    width: '11rem',
    paddingLeft: '1.563rem',
    display: 'inline-flex',
    color: theme.palette.info.dark
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  headerTitle: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  leftBlock: {
    display: 'flex'
  },
  rightBlock: {
    display: 'flex',
    marginRight: '1.5rem'
  }
}));

export const JurisdictionAnalysisInfo = ({
  compSearchId,
  currentTestedParty,
  jurisdictionDescription,
  plis,
  jurisdictionPliFormat,
  jurisdictionStatus = PbaTbaJurisdictionStatus.ByName.Complete.Id,
  classifications,
  updateJurisdictionStatus,
  onChange
}: JurisdictionAnalysisInfoProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchJurisdictionPath: any = useRouteMatch(
    '/analysis/:studyId/pba-dashboard/:pbaId/jurisdiction/:jurisdictionId'
  );
  const jurisdictionPath: string = matchJurisdictionPath?.url ?? null;

  const formatPli = (pli: JurisdictionPli, value: number) => {
    const pliFormat = jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pli.pliTypeId);
    const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pli.pliTypeId);

    const formattedValue = formatPliDisplayValue(formatType, roundingValue, value);
    return formattedValue;
  };

  const formatClassificationsDataLabel = (): string => {
    const numberOfMajorClassifications = classifications.length;
    let numberOfMinorClassifications = 0;
    classifications.forEach((classification) => {
      numberOfMinorClassifications += (classification.minorServiceClassifications ?? []).length;
    });
    const majorText = `Major${numberOfMajorClassifications > 1 ? 's' : ''}`;
    const minorText = `Minor${numberOfMinorClassifications > 1 ? 's' : ''}`;

    return `${numberOfMajorClassifications} ${majorText} ${numberOfMinorClassifications} ${minorText}`;
  };

  const formatPliDataLabel = (plis: JurisdictionPli[]): string => {
    if (plis.length === 0) return '';
    const data = plis[0]; /* All plis have the same values for the properties displayed on the DATA header */
    const yearsText = `yr${data.years > 1 ? 's' : ''}`;
    return `${data.years} ${yearsText} ${t(`analysis:${data.averageMethod}`)} (${t(
      `analysis:${data.statMethod}`
    )})`.toUpperCase();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.pliBoxContainer}>
        <Box className={classes.headerTitle}>
          <span className={classes.leftBlock}>
            {jurisdictionDescription?.jurisdictionName && (
              <Typography className={classes.title}>
                {jurisdictionDescription?.jurisdictionName} {t('analysis:title-section-search-results')}
              </Typography>
            )}
          </span>
          <span className={classes.rightBlock}>
            <JurisdictionStatusDropDown
              jurisdictionStatus={jurisdictionStatus}
              updateJurisdictionStatus={updateJurisdictionStatus}
            />
          </span>
        </Box>
        <span className={classes.headerInfo}>
          <span className={classes.leftDataBlocks}>
            <span className={classes.infoBlock}>
              <Typography className={classes.infoTitle}>{t('analysis:title-tested-party')}</Typography>
              <Typography
                style={{
                  backgroundColor: tokens.product15,
                  textAlign: 'center',
                  borderRadius: '0.25rem',
                  color: theme.palette.primary.dark
                }}
                className={classes.infoValue}
              >
                {currentTestedParty?.name}
              </Typography>
            </span>
            <span className={classes.infoBlock}>
              <Typography className={classes.infoTitle}>
                {t('analysis:jurisdiction-comp-search-info-region-searched')}
              </Typography>
              <Typography className={classes.infoValue}>{jurisdictionDescription?.location}</Typography>
            </span>
            {jurisdictionDescription?.codeRanges && (
              <span className={classes.infoBlock}>
                <Typography className={classes.infoTitle}>
                  {t('analysis:jurisdiction-comp-search-info-industry-codes')}
                </Typography>
                <Typography className={classes.infoValue}>SIC {jurisdictionDescription?.codeRanges}</Typography>
              </span>
            )}
            {classifications.length > 0 &&
              jurisdictionDescription?.primaryFunctionId === PrimaryFunctions.ByName.ServiceProvider.Id && (
                <span className={classes.infoBlock}>
                  <Typography className={classes.infoTitle}>
                    {t('analysis:jurisdiction-comp-search-info-classifications')}
                  </Typography>
                  <Typography className={classes.infoValue}>{formatClassificationsDataLabel()}</Typography>
                </span>
              )}
            <span className={classes.infoBlock}>
              <Typography className={classes.infoTitle}>{t('analysis:jurisdiction-comp-search-info-data')}</Typography>
              <Typography className={classes.infoValue}>{formatPliDataLabel(plis)}</Typography>
            </span>
          </span>
          <span className={classes.endBlock}>
            <span className={classes.companiesBlock}>
              <Typography className={classes.infoTitle}>
                {t('analysis:jurisdiction-comp-search-info-fionas-comparables')}
              </Typography>
              <Typography className={classes.compsValue}>
                {jurisdictionDescription?.numCompsInPool &&
                  new Intl.NumberFormat().format(jurisdictionDescription.numCompsInPool)}
              </Typography>
            </span>
            <span>
              <Typography className={classes.infoTitle}>
                {t('analysis:jurisdiction-comp-search-info-private-public-searched')}
              </Typography>
              <Typography className={classes.compsValue}>
                {jurisdictionDescription?.initialCount &&
                  new Intl.NumberFormat().format(jurisdictionDescription.initialCount)}
              </Typography>
            </span>
          </span>
        </span>
      </Box>
      {plis ? (
        <Box className={classes.pliList}>
          {(plis ?? []).map((pli: JurisdictionPli, index: number) => (
            <SummaryTile
              key={pli.pli}
              data={[
                <div key="pliBlock" className={classes.pliBlock}>
                  <Typography className={classes.body4}>{t(`analysis:${String(pli.pli)}`)}</Typography>
                </div>,
                <div key="pliTestedParty" className={classes.testedPartyPliBlock}>
                  <Typography className={classes.testedPartPli}>{t('analysis:tested_party_pli')}</Typography>
                  <Typography
                    style={{
                      backgroundColor:
                        pli?.inRangeStatus === `in_range_status.not_in_range`
                          ? theme.palette.warning.light
                          : theme.palette.primary.light,
                      color:
                        pli?.inRangeStatus === `in_range_status.not_in_range`
                          ? theme.palette.warning.dark
                          : theme.palette.primary.dark
                    }}
                    className={classes.pliValue}
                  >
                    {formatPli(pli, pli?.testedPartyPliValue)}
                  </Typography>
                </div>,
                <div key="pliStatus" className={classes.testedPartyStatusBlock}>
                  <Typography className={classes.status}>{t('analysis:tested_party_pli_status')}</Typography>
                  <Typography
                    style={{
                      backgroundColor:
                        pli?.inRangeStatus === `in_range_status.not_in_range`
                          ? theme.palette.warning.light
                          : theme.palette.primary.light,
                      color:
                        pli?.inRangeStatus === `in_range_status.not_in_range`
                          ? theme.palette.warning.dark
                          : theme.palette.primary.dark
                    }}
                    className={classes.pliValue}
                  >
                    {t(`analysis:${String(pli?.inRangeStatus)}`)}
                  </Typography>
                </div>,
                <div key="pliRange" className={classes.testedPartyRangeBlock}>
                  <Typography
                    className={classes.range}
                    style={{
                      color:
                        pli?.exclude === 'global.yes'
                          ? theme.palette.secondary.contrastText
                          : theme.palette.primary.dark
                    }}
                  >
                    {pli?.exclude === 'global.yes'
                      ? t(`analysis:pli_range_excluded`)
                      : t(`analysis:pli_range_included`)}
                  </Typography>
                  <ToggleButton
                    checked={pli?.exclude === 'global.no'}
                    hasBackground={false}
                    hasBorder={false}
                    handleChange={(_, checked) => {
                      onChange(checked, index);
                    }}
                  />
                </div>,

                <div key="pliButton">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.viewAnalysisButton}
                    endIcon={<ChevronRight />}
                    onClick={() => {
                      const path = `${jurisdictionPath}/comp-search/${compSearchId!}/pli/${pli.pliTypeId}/pli-avg/${
                        pli.pliAvgTypeId
                      }`;
                      history.push(path);
                    }}
                  >
                    {t('analysis:action-view')}
                  </Button>
                </div>
              ]}
            />
          ))}
        </Box>
      ) : (
        <CenteredProgress />
      )}
    </Box>
  );
};
