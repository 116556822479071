import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BulkRejectionsTab as BulkRejectionsTabPage } from './components/BulkRejections';
import { FinalTab } from './components/FinalRange';
import { FineTuning } from './components/FineTuning';
import { InitialTab } from './components/InitialRange';
import { TuneCompSearchTab, ConnectorProps } from './TuneCompSearch.proptype';
import { PbaJurisdictionPliFormat } from '../../models';
import {
  fetchJurisdictionPlis,
  fetchPbaJurisdictions,
  fetchCurrentTestedParty,
  updatePbaJurisdictionStatus,
  fetchPBAs,
  fetchJurisdictionPliFormats,
  clearCompSearch
} from '../../redux/profitBasedAnalyses';
import {
  selectJurisdictionPlis,
  selectCurrentTestedParty,
  selectPBAJurisdictionById,
  selectCountry,
  selectPBAsList,
  selectPBA,
  selectJurisdictionPliFormat
} from '../../selectors';
import { AppDispatch } from '../../store';
import { JurisdictionPli } from '../JurisdictionAnalysisInfo/JurisdictionAnalysisInfo.proptype';

const { InititalRangeTab, BulkRejectionsTab, FineTuningTab, FinalRangeTab } = TuneCompSearchTab;

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [selectedKey, setSelectedKey] = useState(InititalRangeTab);

  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgId = Number(useParams<{ pliAvgId: string }>().pliAvgId);
  const studyId = Number(useParams<{ studyId: string }>().studyId);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId) {
      dispatch(clearCompSearch());
    }
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const pbaJurisdictionDetails = useSelector(selectPBAJurisdictionById(jurisdictionId));
  const jurisdictionPlis = useSelector(selectJurisdictionPlis(jurisdictionId));
  const jurisdictionInfo = useSelector(selectCountry(jurisdictionId));
  const pbas = useSelector(selectPBAsList);
  const pba = useSelector(selectPBA(pbaId));
  const jurisdictionStatusInfo = pba?.jurisdictionStatuses
    ? pba.jurisdictionStatuses.find((status) => status.jurisdictionId === jurisdictionId)
    : undefined;
  const selectedPli = jurisdictionPlis
    ? jurisdictionPlis.find((pli: JurisdictionPli) => pli.pliAvgTypeId === pliAvgId && pli.pliTypeId === pliId)
    : null;
  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));

  const tabs = [
    {
      key: InititalRangeTab,
      label: t('analysis:initial-range'),
      disabled: false,
      content: <InitialTab />
    },
    {
      key: BulkRejectionsTab,
      label: t('analysis:bulk-rejections'),
      disabled: false,
      content: <BulkRejectionsTabPage />
    },
    {
      key: FineTuningTab,
      label: t('analysis:fine-tuning'),
      disabled: false,
      content: <FineTuning />
    },
    {
      key: FinalRangeTab,
      label: t('analysis:final-range'),
      disabled: false,
      content: <FinalTab />
    }
  ];
  const updateJurisdictionStatus = async (status: number) => {
    if (jurisdictionStatusInfo) {
      const updatedJurisdictionStatus = {
        status,
        jurisdictionId: jurisdictionStatusInfo.jurisdictionId,
        jurisdictionIsoCode: jurisdictionStatusInfo.jurisdictionIsoCode
      };

      await dispatch(updatePbaJurisdictionStatus({ pbaId, jurisdictionStatus: updatedJurisdictionStatus }));
      void dispatch(fetchPBAs(studyId));
    }
  };

  useEffect(() => {
    if (!pbaJurisdictionDetails) {
      void dispatch(fetchPbaJurisdictions(pbaId));
    }

    if (pbaJurisdictionDetails && !jurisdictionPlis) {
      void dispatch(
        fetchJurisdictionPlis({
          compSearchId,
          jurisdictionId
        })
      );
    }
  }, [dispatch, jurisdictionId, compSearchId, jurisdictionPlis, pbaJurisdictionDetails, pbaId]);

  useEffect(() => {
    if (pbas === null) {
      void dispatch(fetchPBAs(studyId));
    }

    if (!currentTestedParty) {
      void dispatch(fetchCurrentTestedParty(pbaId));
    }
  }, [dispatch, currentTestedParty, pbas, studyId, pbaId]);

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  return (
    <Component
      jurisdictionInfo={jurisdictionInfo}
      jurisdictionStatus={jurisdictionStatusInfo}
      updateJurisdictionStatus={updateJurisdictionStatus}
      selectedPli={selectedPli}
      selectedKey={selectedKey}
      tabs={tabs}
      onSelectTab={(arg: string) => {
        const selectedTab = tabs.find((tab) => tab.key === arg);
        setSelectedKey(selectedTab?.key!);
      }}
    />
  );
};

export default Connector;
