import { Reducer } from 'redux';
import { entityFinancialInitialState } from './entityFinancial.initialState';
import {
  EntityFinancialStateInterface,
  EntityFinancialActions,
  SET_ENTITYFINANCIAL_REQUEST_STATUS,
  SET_ENTITYFINANCIAL_DATA,
  SET_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_ENTITY_ADDITIONAL_FINANCIAL_INFO
} from './entityFinancial.types';

const entityFinancialReducer: Reducer<EntityFinancialStateInterface, EntityFinancialActions> = (
  entityFinancialState = entityFinancialInitialState,
  action = {} as EntityFinancialActions
): EntityFinancialStateInterface => {
  switch (action.type) {
    case SET_ENTITY_ADDITIONAL_FINANCIAL_INFO:
      return {
        ...entityFinancialState,
        additionalFinancialInfo: action.payload
      };
    case SET_ENTITYFINANCIAL_DATA:
      return {
        ...entityFinancialState,
        entityFinancial: action.payload
      };
    case SET_ENTITYFINANCIAL_REQUEST_STATUS:
      return {
        ...entityFinancialState,
        status: action.payload.status,
        error: action.payload.error ?? null
      };
    case SET_ENTITY_FINANCIAL_COMPLETION_STATUS:
      return {
        ...entityFinancialState,
        completionStatus: action.payload
      };
    case SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS:
      return {
        ...entityFinancialState,
        status: action.payload.status,
        error: action.payload.error ?? null
      };
    case SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS: {
      const newCompletionStatus = entityFinancialState.completionStatus.map((status) => {
        return status.financialAreaId === action.payload.financialAreaId && status.taxYear === action.payload.taxYear
          ? action.payload
          : status;
      });

      const changedExisting = newCompletionStatus.findIndex(
        (status) =>
          status.financialAreaId === action.payload.financialAreaId && status.taxYear === action.payload.taxYear
      );

      return {
        ...entityFinancialState,
        completionStatus: [...newCompletionStatus, ...(changedExisting < 0 ? [action.payload] : [])]
      };
    }

    case SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS:
      return {
        ...entityFinancialState,
        status: action.payload.status,
        error: action.payload.error ?? null
      };
    default:
      return entityFinancialState;
  }
};

export default entityFinancialReducer;
