import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { AddTransactionsButton, TransactionsSplashScreen, TransactionsTable } from '.';
import { CenteredProgress, ContentLayout, SearchBox } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { Entity, Transaction } from '../../../models';
import { TransactionsProps } from '../Transactions.proptype';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    width: '100%',
    whiteSpace: 'nowrap',

    '& > :first-child': {
      width: 250
    }
  }
}));

export const Transactions = (props: TransactionsProps) => {
  const { transactions, entities, upeCurrency } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [searched, setSearched] = useState<Entity | null>(null);
  const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    setFilteredTransactions(transactions ?? []);
  }, [transactions]);

  const handleSearch = (event: any, value: Entity | null) => {
    setSearched(value);
    setFilteredTransactions(
      value
        ? (transactions ?? []).filter(({ legalEntityTransactions }) =>
            legalEntityTransactions.find(({ entity }) => entity?.entityId === value.entityId)
          )
        : transactions ?? []
    );
  };

  return (
    <Box height={1}>
      {transactions === null || entities === null ? (
        <CenteredProgress />
      ) : transactions?.length ? (
        <ContentLayout
          title={t('transactions:title')}
          actions={
            <Box className={classes.buttonContainer}>
              <SearchBox
                disableClearable={false}
                options={entities}
                value={searched}
                getOptionLabel={(entity) => entity.code}
                noOptionsText={t('entities:label-no-match')}
                onChange={handleSearch}
              />
              {documentationReviewAccess(<AddTransactionsButton {...props} />)}
            </Box>
          }
          main={<TransactionsTable {...props} transactions={filteredTransactions} upeCurrency={upeCurrency} />}
        />
      ) : (
        <TransactionsSplashScreen {...props} />
      )}
    </Box>
  );
};
