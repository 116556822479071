import { styled, Tooltip, TooltipProps } from '@material-ui/core';
import tokens from '../../styles/designTokens';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-arrow': {
    color: theme.palette.common.black
  },
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: tokens.mediumFont.fontFamily,
    fontSize: '0.7rem'
  }
}));
