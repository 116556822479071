import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { InitialTab as Component } from './components/InitialTab';
import { PbaJurisdictionPliFormat } from '../../../../models';
import {
  fetchInitialRangeComparables,
  fetchInitialRangeResults,
  fetchJurisdictionPliFormats
} from '../../../../redux/profitBasedAnalyses/index';
import { selectJurisdictionPliFormat } from '../../../../selectors';
import { AppDispatch } from '../../../../store';

const Connector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgId = Number(useParams<{ pliAvgId: string }>().pliAvgId);

  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const pliFormat = jurisdictionPliFormat
    ? jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pliId)
    : null;

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId) {
      void dispatch(
        fetchInitialRangeComparables({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
      void dispatch(
        fetchInitialRangeResults({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
    }
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  return <Component pliFormat={pliFormat} pliId={pliId} />;
};

export default Connector;
