import { ComponentType, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReportsCentralTabs, ReportsProps, ReportsTabProps } from './Reports.proptype';
import { formatReportsData } from './ReportsUtilities';
import {
  fetchComplianceCentralReportsInfo,
  fetctComplianceCentralMasterFileReportsInfo
} from '../../redux/complianceCentral';
import { fetchEntities } from '../../redux/entities';
import { fetchGlobalGuides } from '../../redux/globalGuides';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectEntitiesList,
  selectWorkingContainer,
  selectGlobalGuides,
  selectTransactionsList
} from '../../selectors';
import {
  selectComplianceCentralReportsInfo,
  selectComplianceCentralMasterFileReportsInfo
} from '../../selectors/complianceCentral';
import { AppDispatch } from '../../store';
import tokens from '../../styles/designTokens';

const { LocalReportTab } = ReportsCentralTabs;

const tabs: ReportsTabProps[] = [
  {
    key: LocalReportTab,
    label: LocalReportTab,
    disabled: false
  }
  // {
  //   key: 'Master File',
  //   label: 'Master File',
  //   disabled: true
  // }
];

const Connector = ({ component: Component }: { component: ComponentType<ReportsProps> }) => {
  const [selectedTab, setSelectedTab] = useState(LocalReportTab);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const masterFileReportsInfo = useSelector(selectComplianceCentralMasterFileReportsInfo);
  const allEntities = useSelector(selectEntitiesList);
  const allTransactions = useSelector(selectTransactionsList);
  const reportsInfo = useSelector(selectComplianceCentralReportsInfo);
  const globalGuides = useSelector(selectGlobalGuides);

  useEffect(() => {
    if (!globalGuides) {
      void dispatch(fetchGlobalGuides());
    }
  }, [dispatch, globalGuides]);
  const container = useSelector(selectWorkingContainer);

  useEffect(() => {
    if (allEntities && allTransactions) {
      setLoading(false);
    } else if (allEntities === null) {
      setLoading(true);
      void dispatch(fetchEntities());
    } else if (allTransactions === null) {
      setLoading(true);
      void dispatch(fetchTransactions());
    }
  }, [dispatch, allEntities, allTransactions]);

  useEffect(() => {
    if (reportsInfo === null) {
      void dispatch(fetchComplianceCentralReportsInfo(container!.containerId));
    }
  }, [dispatch, reportsInfo, container]);

  useEffect(() => {
    if (masterFileReportsInfo === null) {
      void dispatch(fetctComplianceCentralMasterFileReportsInfo(container!.containerId));
    }
  }, [dispatch, masterFileReportsInfo, container]);

  useEffect(() => {
    if (globalGuides === null) {
      void dispatch(fetchGlobalGuides());
    }
  }, [dispatch, globalGuides]);

  const { entityReportData, reportCounts } = useMemo(
    () => formatReportsData({ allEntities, allTransactions, reportsInfo, globalGuides }),
    [allEntities, allTransactions, reportsInfo, globalGuides]
  );

  const totalReports = reportCounts.draft + reportCounts.review + reportCounts.published + reportCounts.final;

  const jurisdictionsCircle = {
    percent: 1,
    metric: reportCounts.totalJurisdictions,
    type: 'Jurisdictions',
    color: tokens.core3
  };
  const counterCircles = [
    { percent: reportCounts.draft / totalReports, metric: reportCounts.draft, type: 'Draft', color: '#8D949C' },
    { percent: reportCounts.review / totalReports, metric: reportCounts.review, type: 'Review', color: '#00ACBB' },
    {
      percent: reportCounts.published / totalReports,
      metric: reportCounts.published,
      type: 'Published',
      color: '#6E56DB'
    },
    { percent: reportCounts.final / totalReports, metric: reportCounts.final, type: 'Final', color: tokens.product100 }
  ];
  return (
    <Component
      entityReportData={entityReportData}
      isLoading={isLoading}
      tabs={tabs}
      selectedTab={selectedTab}
      masterFileReportsInfo={masterFileReportsInfo ?? []}
      counterCircles={counterCircles}
      jurisdictionsCircle={jurisdictionsCircle}
      onSelectTab={(tab: number) => {
        setSelectedTab(tabs[tab].key as ReportsCentralTabs);
      }}
      onNavigate={(path) => {
        history.push(path);
      }}
    />
  );
};

export default Connector;
