import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { StudyTabsMenu, StudyTabsDirectLink } from '.';
import { SortOrder, SearchAndSort } from '../../../components';
import { JurisdictionStatusLabel } from '../../../components/JurisdictionStatusLabel';
import { ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../../models';
import { SegmentTypeEnum } from '../../../models/profitBasedAnalyses.interface';
import { filterData, sortData } from '../../../services/filtering';
import { body2 } from '../../../styles/typography';
import { Table } from '../../Table';
import { TableColumnDef } from '../../Table/Table.proptype';
import { StudyDashboardTab, StudyTabsTableProps } from '../StudyDashboard.proptype';

const { ProfitBasedAnalysisTab, TransactionBasedAnalysisTab } = StudyDashboardTab;

interface StudyTabsColumnsHeadersProps {
  [ProfitBasedAnalysisTab: string]: TableColumnDef[];
  [TransactionBasedAnalysisTab]: TableColumnDef[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1)
  },
  country: {
    padding: theme.spacing(0, 2)
  },
  progressContainer: {
    display: 'flex',
    flexShrink: 0,
    gap: theme.spacing(0.5),
    margin: theme.spacing(0.5, 0)
  }
}));

export const StudyTabsTable = ({ data, selectedKey, onEdit, onDelete }: StudyTabsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy?: any; sortOrder?: SortOrder }>({});
  const [filterObject, setFilter] = useState<Record<string, string>>({});

  const isPBA = selectedKey === ProfitBasedAnalysisTab;

  const displayableData = useMemo(() => {
    if (isPBA) {
      return ((data as ProfitBasedAnalysis[]) ?? []).map((pba) => {
        const { name, primaryLegalEntity, testedParty, jurisdictionStatuses = [] } = pba;
        return {
          name,
          testedPartyEntity: primaryLegalEntity.code,
          method: t('analysis:pba-tnmm'),
          segmentFunction:
            testedParty === undefined || testedParty === null
              ? ''
              : testedParty.segmentType === undefined
              ? ''
              : SegmentTypeEnum[testedParty.segmentType],
          totalTransactionsValue: Math.floor(Math.random() * (100000 + 1)),
          pbaStatus:
            jurisdictionStatuses.length > 0
              ? jurisdictionStatuses.map((jurisdictionStatus) => {
                  return (
                    <Box key={jurisdictionStatus.jurisdictionId} className={classes.progressContainer}>
                      <JurisdictionStatusLabel
                        jurisdictionIso={jurisdictionStatus.jurisdictionIsoCode}
                        jurisdictionStatusId={jurisdictionStatus.status}
                      />
                    </Box>
                  );
                })
              : t('analysis:no-associated-transactions').toUpperCase(),
          directLink: <StudyTabsDirectLink element={pba} isPBA={isPBA} />,
          menu: <StudyTabsMenu element={pba} isPBA={isPBA} onEdit={onEdit} onDelete={onDelete} />
        };
      }) as Array<Partial<ProfitBasedAnalysis>>;
    }

    return (((data as TransactionBasedAnalysis[]) ?? []).map((tba) => {
      const { name, tbaEvaluationMethod, transactionType, jurisdictionStatuses = [] } = tba;
      return {
        name,
        method: tbaEvaluationMethod.name,
        transactionType: transactionType.name,
        totalTransactionsValue: Math.floor(Math.random() * (100000 + 1)),
        tbaStatus:
          jurisdictionStatuses.length > 0
            ? jurisdictionStatuses.map((jurisdictionStatus) => {
                return (
                  <Box key={jurisdictionStatus.jurisdictionId} className={classes.progressContainer}>
                    <JurisdictionStatusLabel
                      jurisdictionIso={jurisdictionStatus.jurisdictionIsoCode}
                      jurisdictionStatusId={jurisdictionStatus.status}
                    />
                  </Box>
                );
              })
            : t('analysis:no-associated-transactions').toUpperCase(),
        directLink: <StudyTabsDirectLink element={tba} isPBA={isPBA} />,
        menu: <StudyTabsMenu element={tba} isPBA={isPBA} onEdit={onEdit} onDelete={onDelete} />
      };
    }) as unknown) as Array<Partial<TransactionBasedAnalysis>>;
  }, [data, isPBA, t, classes, onEdit, onDelete]);

  const studyTabsColumnsHeaders: StudyTabsColumnsHeadersProps = {
    [ProfitBasedAnalysisTab]: [
      {
        key: 'name',
        header: (
          <SearchAndSort
            field={t('analysis:column-pba-name')}
            onSortClicked={(sortOrder) => {
              setSort({ sortBy: 'name', sortOrder });
            }}
            onSearchChange={(value) => {
              setFilter({ ...filterObject, name: value });
            }}
          />
        ),
        width: '15.63rem'
      },
      { key: 'testedPartyEntity', header: t('analysis:column-tested-party-entity') },
      { key: 'method', header: t('analysis:column-method') },
      { key: 'segmentFunction', header: t('analysis:column-segment-function') },
      { key: 'pbaStatus', header: t('analysis:column-pba-status'), width: '9.375rem' },
      { key: 'directLink', header: '', width: '3.13rem' },
      { key: 'menu', header: '', width: '3.13rem' }
    ],
    [TransactionBasedAnalysisTab]: [
      {
        key: 'name',
        header: (
          <SearchAndSort
            field={t('analysis:column-tba-name')}
            onSortClicked={(sortOrder) => {
              setSort({ sortBy: 'name', sortOrder });
            }}
            onSearchChange={(value) => {
              setFilter({ ...filterObject, name: value });
            }}
          />
        ),
        width: '15.63rem'
      },
      { key: 'method', header: t('analysis:column-method') },
      { key: 'transactionType', header: t('analysis:column-transaction-type') },
      { key: 'tbaStatus', header: t('analysis:column-tba-status'), width: '9.375rem' },
      { key: 'directLink', header: '', width: '3.13rem' },
      { key: 'menu', header: '', width: '3.13rem' }
    ]
  };

  return (
    <Table
      data={sortData(filterData(displayableData, filterObject), sortObject)}
      columns={studyTabsColumnsHeaders[selectedKey]}
      stickyCols={{
        menu: {
          side: 'right',
          position: 0
        }
      }}
      columnSpecificStyles={{
        name: {
          ...body2
        }
      }}
    />
  );
};
