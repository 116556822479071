import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BulkRejectionsTab as Component } from './BulkRejectionsTab';
import { RangeComparables, BulkRejection, PbaJurisdictionPliFormat } from '../../../../models';
import {
  fetchInitialRangeComparablesWithRejections,
  fetchAppliedBulkRejections,
  fetchNonAppliedBulkRejections,
  applyUnappliedBulkRejection,
  fetchJurisdictionPliFormats
} from '../../../../redux/profitBasedAnalyses';
import {
  selectInitialRangeComparablesWithRejections,
  selectAppiedBulkRejections,
  selectNonAppiedBulkRejections,
  selectWorkingContainer,
  selectJurisdictionPliFormat
} from '../../../../selectors';
import { AppDispatch } from '../../../../store';

const Connector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgId = Number(useParams<{ pliAvgId: string }>().pliAvgId);
  const initialComparables: RangeComparables | undefined = useSelector(selectInitialRangeComparablesWithRejections);
  const appliedBulkRejections: BulkRejection[] | null = useSelector(selectAppiedBulkRejections) ?? null;
  const nonAppliedBulkRejections: BulkRejection[] | null = useSelector(selectNonAppiedBulkRejections) ?? null;
  const workingContainer = useSelector(selectWorkingContainer);
  const [applyBulkRejectionsIsLoading, setApplyBulkRejectionsIsLoading] = useState(false);

  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const pliFormat = jurisdictionPliFormat
    ? jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pliId)
    : null;

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId) {
      void dispatch(
        fetchInitialRangeComparablesWithRejections({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
    }
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  useEffect(() => {
    void dispatch(
      fetchAppliedBulkRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  useEffect(() => {
    void dispatch(
      fetchNonAppliedBulkRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  const onApplyBulkRejection = async (isRejected: boolean, rejectionTypeId: number) => {
    setApplyBulkRejectionsIsLoading(true);
    await dispatch(
      applyUnappliedBulkRejection({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId,
        rejectionTypeId,
        isApplying: isRejected,
        containerId: workingContainer?.containerId!
      })
    );

    await dispatch(
      fetchNonAppliedBulkRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );

    await dispatch(
      fetchAppliedBulkRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );

    await dispatch(
      fetchInitialRangeComparablesWithRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );
    setApplyBulkRejectionsIsLoading(false);
  };

  return (
    <Component
      companies={initialComparables?.data ?? []}
      columns={initialComparables?.columns ?? []}
      appliedBulkRejections={appliedBulkRejections ?? []}
      nonAppliedBulkRejections={nonAppliedBulkRejections ?? []}
      applyBulkRejectionsIsLoading={applyBulkRejectionsIsLoading}
      pliFormat={pliFormat}
      pliId={pliId}
      onApplyBulkRejection={onApplyBulkRejection}
    />
  );
};

export default Connector;
