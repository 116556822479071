import { useCallback, useEffect, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { Box, Tab, Tabs, makeStyles } from '@material-ui/core';
import { AnalysisSummary } from './AnalysisSummary';
import { EmptyTab } from './EmptyTab';
import { CenteredProgress } from '../../../components';
import { Entity, MethodEvaluation, ProfitBasedEvaluation, TransactionBasedEvaluation } from '../../../models';
import { getAnalysisMethod } from '../../../utils';
import { DashboardCard } from '../../DashboardCard';
import { TransactionAnalysisSummaryCardProps } from '../TransactionAnalysisSummaryCard.proptype';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.default,
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(1.5)
  }
}));

const getDetails = ({
  analysis,
  source,
  destination,
  methods,
  transactionTypeName,
  t
}: {
  analysis: TransactionBasedEvaluation | ProfitBasedEvaluation;
  source: Entity;
  destination: Entity;
  methods: MethodEvaluation[];
  transactionTypeName: string;
  t: TFunction;
}) => {
  const details = 'tba' in analysis ? analysis.tba : analysis.pba;

  return {
    id: 'tba' in analysis ? analysis.tba.tbaId : analysis.pba.pbaId,
    name: details.name,
    methodName: getAnalysisMethod(analysis, methods)?.methodName ?? '',
    details:
      ('tba' in analysis
        ? t(`transactions:type-${transactionTypeName}`)
        : t(`entities:primary-function-${analysis.pba.testedParty?.primaryFunction?.name ?? ''}`)) ?? '',
    sourceIsoCode: source.domicile.isoCode,
    destinationIsoCode: destination.domicile.isoCode,
    jurisdictionStatuses: details.jurisdictionStatuses,
    // API does not return them yet
    sourceCompleted: false,
    destinationCompleted: false,
    analysisType: 'tba' in analysis ? 'tba' : 'pba'
  };
};

export const TransactionAnalysisSummaryCard = ({
  transactionId,
  transactionTypeName,
  study,
  source,
  destination,
  evaluations
}: TransactionAnalysisSummaryCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [analyses, setAnalyses] = useState<Array<TransactionBasedEvaluation | ProfitBasedEvaluation>>([]);

  const handleNavigate = useCallback(
    (tab) => {
      setTab(tab);
      setAnalyses((tab === 0 ? evaluations?.pbas : evaluations?.tbas) ?? []);
    },
    [evaluations]
  );

  useEffect(() => {
    if (evaluations?.pbas.length) {
      handleNavigate(0);
    } else if (evaluations?.tbas.length) {
      handleNavigate(1);
    }
  }, [evaluations, handleNavigate]);

  return (
    <DashboardCard title={t('transactions:title-analysis-summary')} classes={{ root: classes.card }}>
      {evaluations && source && destination ? (
        <>
          <Tabs
            value={tab}
            onChange={(event, value) => {
              handleNavigate(value);
            }}
          >
            <Tab label={t('label-pba')} />
            <Tab label={t('label-tba')} />
          </Tabs>
          <Box className={classes.container}>
            {analyses.length === 0 ? (
              <EmptyTab isTBA={tab === 1} />
            ) : (
              analyses.map((analysis) => {
                const data = getDetails({
                  analysis,
                  source,
                  destination,
                  methods: evaluations.methods,
                  transactionTypeName,
                  t
                });
                return (
                  <AnalysisSummary key={data.id} studyId={study?.studyId} transactionId={transactionId} {...data} />
                );
              })
            )}
          </Box>
        </>
      ) : (
        <CenteredProgress />
      )}
    </DashboardCard>
  );
};
