import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { MethodEvaluations } from '@xbs/xbs-enums';
import { EntityDiscussion } from './EntityDiscussion';
import { List } from './List';
import {
  AnalysisAndMethod,
  EditedEntity,
  getAnalysisDiscussion,
  getAnalysisEvaluationType,
  getCompletionState,
  mergeAnalysis
} from './utils';
import { CenteredProgress, CompletionLabel } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { getMethodEvaluationName, hasEditAccess } from '../../../utils';
import useDebounce from '../../../utils/debounce';
import { ProgressButton } from '../../ProgressButton';
import { MethodEvaluationProps } from '../MethodEvaluation.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 1.5)
  },
  headerTitle: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    gap: theme.spacing(2)
  },
  content: {
    alignItems: 'stretch',
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    '& > :first-child': {
      flex: 0.7
    },
    '& > :last-child': {
      flex: 1
    }
  },
  editors: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    '& > *': {
      width: '50%'
    }
  }
}));

const americanEvaluationTypes = MethodEvaluations.AsOrderedArray.sort(
  (eval1: { Name: string }, eval2: { Name: string }) => {
    return eval1.Name > eval2.Name ? 1 : -1;
  }
).map(({ Id: value, Name: name }: { Id: number; Name: string }) => ({
  value,
  labelKey: `transactions:method-evaluation-USA-${name}`
}));

const evaluationTypes = MethodEvaluations.AsOrderedArray.sort((eval1: { Name: string }, eval2: { Name: string }) => {
  return eval1.Name > eval2.Name ? 1 : -1;
}).map(({ Id: value, Name: name }: { Id: number; Name: string }) => ({
  value,
  labelKey: `transactions:method-evaluation-${name}`
}));

export const MethodEvaluation = ({
  transactionType,
  sourceJurisdiction,
  destinationJurisdiction,
  evaluations,
  evaluationsCompletion,
  onSave,
  onAutoSave
}: MethodEvaluationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [current, setCurrent] = useState<AnalysisAndMethod | null>(null);
  const [completed, setCompleted] = useState(false);
  const [editedSource, setEditedSource] = useState<EditedEntity | null>(null);
  const [editedDestination, setEditedDestination] = useState<EditedEntity | null>(null);

  useEffect(() => {
    if (current?.analysis) {
      // unfortunately, unused TBAs and PBAs don't have their own storage for discussion points.
      // for the time being, we store discussion points at method level
      setCompleted(current.used ? getCompletionState(current.analysis) : current.method.isCompleted ?? false);
      setEditedSource(
        current.used
          ? {
              discussion: getAnalysisDiscussion(current.analysis, true),
              evaluationType: getAnalysisEvaluationType(current.analysis, true)
            }
          : { discussion: current.method.srcDiscussion ?? '' }
      );
      setEditedDestination(
        current.used
          ? {
              discussion: getAnalysisDiscussion(current.analysis, false),
              evaluationType: getAnalysisEvaluationType(current.analysis, false)
            }
          : { discussion: current.method.destDiscussion ?? '' }
      );
    } else {
      setEditedSource(null);
      setEditedDestination(null);
    }
  }, [current]);

  const handleSubmit = (event: string | boolean) => {
    const isCompleted = typeof event === 'boolean' ? event : completed;
    setCompleted(isCompleted);

    if (current?.analysis && editedSource && editedDestination) {
      if (current.used) {
        onSave(current.method, mergeAnalysis(current.analysis, isCompleted, editedSource, editedDestination));
      } else {
        // unfortunately, unused TBAs and PBAs don't have their own storage for discussion points.
        // for the time being, we store discussion points at method level
        onSave(
          {
            ...current.method,
            isCompleted,
            srcDiscussion: editedSource.discussion,
            destDiscussion: editedDestination.discussion
          },
          current.analysis
        );
      }
    }
  };

  const handleAutoSaveSubmit = (event: string | boolean) => {
    const isCompleted = typeof event === 'boolean' ? event : completed;

    if (current?.analysis && editedSource && editedDestination) {
      if (current.used) {
        onAutoSave(current.method, mergeAnalysis(current.analysis, isCompleted, editedSource, editedDestination));
      } else {
        // unfortunately, unused TBAs and PBAs don't have their own storage for discussion points.
        // for the time being, we store discussion points at method level
        onAutoSave(
          {
            ...current.method,
            isCompleted,
            srcDiscussion: editedSource.discussion,
            destDiscussion: editedDestination.discussion
          },
          current.analysis
        );
      }
    }
  };

  const [handleEditorAutoSave] = useDebounce(() => {
    handleAutoSaveSubmit('autoSave');
  }, 3000);

  useEffect(() => {
    handleEditorAutoSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedSource, editedDestination]);

  return evaluations ? (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {evaluationsCompletion === null ? (
          <CenteredProgress />
        ) : (
          <>
            <Box className={classes.headerTitle}>
              <Typography>{t('transactions:title-method')}</Typography>
              <CompletionLabel completed={evaluationsCompletion} />
            </Box>
            {documentationReviewAccess(<ProgressButton complete={completed} onChange={handleSubmit} />)}
          </>
        )}
      </Box>
      <Box className={classes.content}>
        <List
          transactionType={transactionType}
          sourceJurisdiction={sourceJurisdiction}
          destinationJurisdiction={destinationJurisdiction}
          evaluations={evaluations}
          current={current}
          onSelect={setCurrent}
        />
        <Box className={classes.editors} component="main">
          {current && (
            <>
              <EntityDiscussion
                title={sourceJurisdiction?.name ?? ''}
                methodName={getMethodEvaluationName(
                  sourceJurisdiction?.isoCode ?? '',
                  transactionType,
                  current.method.methodName
                )}
                discussion={editedSource?.discussion ?? ''}
                evaluationType={editedSource?.evaluationType ?? 0}
                evaluationTypes={sourceJurisdiction?.isoCode === 'USA' ? americanEvaluationTypes : evaluationTypes}
                used={current.used}
                disabled={!hasEditAccess() || completed}
                notApplicable={!current.appliesToSource}
                saveChange={handleSubmit}
                onChange={setEditedSource}
              />
              <EntityDiscussion
                title={destinationJurisdiction?.name ?? ''}
                methodName={getMethodEvaluationName(
                  destinationJurisdiction?.isoCode ?? '',
                  transactionType,
                  current.method.methodName
                )}
                discussion={editedDestination?.discussion ?? ''}
                evaluationType={editedDestination?.evaluationType ?? 0}
                evaluationTypes={destinationJurisdiction?.isoCode === 'USA' ? americanEvaluationTypes : evaluationTypes}
                used={current.used}
                disabled={!hasEditAccess() || completed}
                notApplicable={!current.appliesToDestination}
                saveChange={handleSubmit}
                onChange={setEditedDestination}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <CenteredProgress />
  );
};
