import { useEffect, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { CenteredProgress } from '../../components';
import { getAppConfig } from '../../utils';
let LDProviderTest: any;

interface propsChildren {
  children: JSX.Element;
}
const LaunchDarklyFeatureFlagInit = ({ children }: propsChildren) => {
  const { xbsEnvironment, launchDarklyEnv } = getAppConfig();
  const [isProviderReady, setConfigurationLoaded] = useState(false);
  useEffect(() => {
    if (!isProviderReady) {
      (async () => {
        LDProviderTest = await asyncWithLDProvider({
          clientSideID: launchDarklyEnv[xbsEnvironment]
        });
        setConfigurationLoaded(true);
      })();
    }
  }, [isProviderReady, xbsEnvironment, launchDarklyEnv]);
  return isProviderReady ? <LDProviderTest>{children}</LDProviderTest> : <CenteredProgress />;
};

export default LaunchDarklyFeatureFlagInit;
