import { ProductNames } from '@xbs/xbs-enums';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import i18next from 'i18next';
import { RequestObject } from './httpService.types';
import { REFRESH_URL } from '../../constants';
import { Notification } from '../../models';
import { AuthStateInterface, getAppConfig, getAuthInfo, redirectToLogin } from '../../utils';
import { getPlatformModule } from '../platform/platform';

class HTTPService {
  private readonly client;
  private readonly interceptorAuthId: number;
  private errorMessageResolver?: (error: AxiosError) => string | string[];
  private showNotification?: (notification: Notification) => void;

  constructor() {
    this.client = axios.create({
      // configure this with the base url of the app
    });
    this.interceptorAuthId = this.client.interceptors.request.use(this.authorizeRequest.bind(this));
    this.client.interceptors.request.use(this.getProxiedRequest.bind(this));

    this.client.interceptors.response.use((response) => response, this.handleErrorResponse.bind(this));
  }

  setErrorMessageResolver(errorMessageResolver: (error: AxiosError) => string | string[]) {
    this.errorMessageResolver = errorMessageResolver;
  }

  public setShowNotification(showNotification: (notification: Notification) => void) {
    this.showNotification = showNotification;
  }

  public async request<T>({ method, apiUrlKey, relativePath = '', params, data }: RequestObject) {
    this.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => i18next.t(`errors:${message}`));
    });
    const config = getAppConfig();
    const url = `${apiUrlKey && config[apiUrlKey] ? config[apiUrlKey]! : ''}${relativePath}`;
    const headers = {
      xbsproductuuid: ProductNames.ByName.Tpiq.Uuid,
      xbsproductid: ProductNames.ByName.Tpiq.Id
    };

    return this.client.request<T>({ method, url, params, data, headers });
  }

  public async requestWithoutAuthHeader<T>(apiUrl: string, httpMethod?: Method, data?: any, header?: any) {
    const newClient = axios.create({});
    newClient.interceptors.request.use(this.getProxiedRequest.bind(this));

    newClient.interceptors.response.use((response) => response, this.handleErrorResponse.bind(this));

    this.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => i18next.t(`errors:${message}`));
    });
    const url = apiUrl;
    const headers = {
      ...header,
      xbsproductuuid: ProductNames.ByName.Tpiq.Uuid,
      xbsproductid: ProductNames.ByName.Tpiq.Id
    };

    const method = httpMethod ? httpMethod : 'get';

    return newClient.request<T>({ method, data, url, headers });
  }

  public async refreshToken() {
    const authorizationInfo: AuthStateInterface = getAuthInfo();
    const { authUrl } = getAppConfig();
    const { authToken, refreshToken } = authorizationInfo;
    const refreshedAuthResponse = await fetch(`${authUrl}/${REFRESH_URL}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${String(authToken)}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken })
    });

    if (!refreshedAuthResponse.ok) {
      redirectToLogin();
      return authorizationInfo;
    }

    const refreshedAuth = await refreshedAuthResponse.json();
    const {
      data: {
        idToken: {
          jwtToken: newAccessToken,
          payload: { exp: newExpiration }
        },
        refreshToken: { token: newRefreshToken }
      }
    } = refreshedAuth;
    return {
      authToken: newAccessToken,
      refreshToken: newRefreshToken,
      expiration: newExpiration * 1000
    };
  }

  authorizeRequest(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
    const authorizationInfo: AuthStateInterface = getAuthInfo();
    const { authToken } = authorizationInfo;

    if (!authToken) {
      redirectToLogin();
    }

    requestConfig.headers.Authorization = `Bearer ${String(authToken)}`;
    return requestConfig;
  }

  async appendXbsHeaders(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const { IdentityInfo } = await getPlatformModule();
    const session = await IdentityInfo.getIdentitySession();
    const { containerUuid, roleUuid, userUuid, productUuid } = session;

    requestConfig.headers = {
      ...requestConfig.headers,
      xbscontaineruuid: containerUuid,
      xbsroleuuid: roleUuid,
      xbsuseruuid: userUuid,
      xbsproductuuid: productUuid ? productUuid : ProductNames.ByName.Tpiq.Uuid
    };

    return requestConfig;
  }

  private async getProxiedRequest(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const { authUrl, proxyUrl } = getAppConfig();
    requestConfig.url = requestConfig.url ?? '';
    const isHttps: boolean = requestConfig.url.startsWith('https://');
    const isAuthStack: boolean = requestConfig.url.includes(authUrl);
    const isS3Call: boolean = requestConfig.url.includes('s3.amazonaws.com');
    const isFileUploadCall: boolean = requestConfig.url.includes('upload');
    const isSelfCall: boolean = requestConfig.url.includes(window.location.host);
    const shouldProxyRequest: boolean = isHttps && !isAuthStack && !isS3Call && !isFileUploadCall && !isSelfCall;

    if (shouldProxyRequest) {
      const params = new URLSearchParams(requestConfig.params || {}).toString();
      const url = params ? `${requestConfig.url}?${params}` : requestConfig.url;
      const urlWithProxy = `${proxyUrl}/v1/http?redirectUrl=${encodeURIComponent(url)}`;
      requestConfig.url = urlWithProxy;
      requestConfig = await this.appendXbsHeaders(requestConfig);
    }

    return requestConfig;
  }

  private handleErrorResponse(error: AxiosError): void {
    const { response: errorResponse } = error;
    if (errorResponse?.status === 401) {
      redirectToLogin();
      return;
    }

    if (errorResponse?.status === 403 && errorResponse?.data?.type === 'TYPE_EULA_NOT_ACCEPTED') {
      // this._modalService.closeModal();
      // this._router.navigate(['/eula']);
      console.log('you did not accept the EULA yet');
    }

    let message;
    if (this.errorMessageResolver) {
      message = this.errorMessageResolver(error);
    } else {
      message = getNested(errorResponse?.data, 'message', 'body');
      message = message ?? getNested(errorResponse?.data, 'errors', 'message');
      message = message ?? getNested(errorResponse?.data, 'errors', 'global');
    }

    message = message ?? 'Something went wrong'; // TranslationConstants.GLOBAL_SERVER_ERROR;

    if (message) {
      this.showNotification?.({ type: 'error', message });
    }

    // this._notificationsService.notify(error);

    throw error;
  }
}

function getNested(errorResponse: any, level: string, ...rest: string[]): string | null {
  if (errorResponse === undefined) {
    return null;
  }

  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(errorResponse, level)) {
    return errorResponse[level];
  }

  return getNested(errorResponse[level], rest[0], ...rest.slice(1));
}

export default HTTPService;
