import { useEffect } from 'react';
import { getAppConfig } from '../../utils';

export const Rum = () => {
  const { xbsEnvironment, awsRumEnvKeys } = getAppConfig();

  useEffect(() => {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.innerHTML = `
      (function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
        'cwr',
        '${awsRumEnvKeys[xbsEnvironment]}',
        '1.0.0',
        'us-east-1',
        'https://client.rum.us-east-1.amazonaws.com/1.5.x/cwr.js',
        {
          sessionSampleRate: 1,
          guestRoleArn: "arn:aws:iam::485951704680:role/RUM-Monitor-us-east-1-485951704680-2449374264561-Unauth",
          identityPoolId: "us-east-1:1f39afe7-bda8-4f6e-a37e-f69d9584bb1e",
          endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
          telemetries: ["performance","errors","http"],
          allowCookies: true,
          enableXRay: true
        }
      );
    `;
    document.querySelectorAll('head')[0].append(node);
  }, [xbsEnvironment, awsRumEnvKeys]);

  return null;
};
