import { combineReducers } from '@reduxjs/toolkit';
import baseData from './baseData';
import complianceCentral from './complianceCentral';
import conversionRates from './conversionRates';
import entities from './entities';
import { entityFinancialReducer as entityFinancial } from './entityFinancial';
import globalGuides from './globalGuides';
import { reducer as localReports } from './localReports';
import pbas from './profitBasedAnalyses';
import reports from './reports';
import statementOfFacts from './statementOfFacts';
import studies from './studies';
import testedParty from './testedParty';
import tbas from './transactionBasedAnalyses';
import transactionEvaluations from './transactionEvaluations';
import transactions from './transactions';

const mainReducer = combineReducers({
  baseData,
  testedParty,
  conversionRates,
  entities,
  complianceCentral,
  entityFinancial,
  globalGuides,
  localReports,
  pbas,
  tbas,
  reports,
  statementOfFacts,
  studies,
  transactionEvaluations,
  transactions
});

export type RootState = ReturnType<typeof mainReducer>;

export default mainReducer;
