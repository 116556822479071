import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles } from '@material-ui/core';
import LabelInput from '../../../components/LabelInput';
import tokens from '../../../styles/designTokens';
import { AddSmallIcon } from '../../../svgs';
import { createDefaultNameCharacteristic } from '../../../utils/createDefaultCharacteristicName';

const useStyles = makeStyles(() => ({
  newInput: {
    width: '16vw',
    display: 'flex',
    padding: '0.5rem 0',
    marginLeft: '1.5rem'
  },
  addNew: {
    marginLeft: '0.75rem',
    color: `${tokens.product100}`,

    '&:hover': {
      borderRadius: '0.15rem',
      background: `${tokens.neutral90}`
    }
  },
  inputAlign: {
    paddingLeft: '0.75rem',
    width: '100%'
  }
}));

export const FunctionalAnalysisAddNewInput = ({
  areaId,
  createNewCharacteristic,
  functionalAnalysisCharacteristicsArea
}: any) => {
  const [newName, setNewName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  function handleNameSave() {
    if (newName?.length >= 3) {
      createNewCharacteristic(newName, areaId);
    } else {
      createNewCharacteristic(createDefaultNameCharacteristic(functionalAnalysisCharacteristicsArea), areaId);
    }

    setNewName('');
    setIsActive(false);
  }

  return (
    <Box className={classes.newInput}>
      {isActive ? (
        <span className={classes.inputAlign}>
          <LabelInput
            value={newName}
            isActive={isActive}
            placeholder={t('analysis:add-name')}
            maxLength={50}
            onChange={(newVal: string) => {
              setNewName(newVal);
            }}
            onBlur={() => {
              handleNameSave();
            }}
            onSubmit={() => {
              handleNameSave();
            }}
          />
        </span>
      ) : (
        <Button
          className={classes.addNew}
          onClick={() => {
            setIsActive(true);
          }}
        >
          <AddSmallIcon />
          {t(`analysis:add-new`)}
        </Button>
      )}
    </Box>
  );
};
