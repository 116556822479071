import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { CenteredProgress, SearchAndSort, SortOrder } from '../../../components';
import { SortControl } from '../../../components/SortControl';
import { RangeColumns } from '../../../models';
import { filterData, sortData } from '../../../services/filtering';
import { mediumFont } from '../../../styles/fonts';
import { body4 } from '../../../styles/typography';
import { Table } from '../../Table';
import { TableColumnDef, StickyColsDefinition } from '../../Table/Table.proptype';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  scrollTable: {
    width: '100%'
  },
  headingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 1em'
  },
  headingTitle: {
    ...body4,
    fontSize: 'medium',
    margin: 'auto 0'
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '16em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  }
}));

interface CompanyTableProps {
  companies: Array<Record<string, HTMLElement | number>>;
  columns: RangeColumns[];
  chips: any;
  onRowClick?: (event: MouseEvent, index: number, data: any) => void;
}

export const CompanyTable = (props: CompanyTableProps) => {
  const { companies, columns, chips, onRowClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const stickyCols: StickyColsDefinition = {};

  const data = sortData(filterData(companies, filterObject), sortObject);
  const displayColumns = columns
    ? columns.map((clmn) => {
        let displayClmn: TableColumnDef;

        if (clmn.key === 'companyName') {
          stickyCols[clmn.key] = {
            side: 'left',
            position: 0
          };

          displayClmn = {
            key: clmn.key,
            header: (
              <SearchAndSort
                field={t('analysis:search-companies')}
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
                onSearchChange={(value) => {
                  setFilter({ companyName: value });
                }}
              />
            ),
            width: '18em'
          };
        } else if (clmn.key === 'average') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 0
          };

          displayClmn = {
            key: clmn.key,
            header: (
              <SortControl
                field={`${clmn.label} (%)`}
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
              />
            ),
            align: 'right',
            width: '8em'
          };
        } else if (clmn.key === 'rejectionStatus') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 1
          };

          displayClmn = { key: clmn.key, header: clmn.label, width: '3em' };
        } else {
          displayClmn = { key: clmn.key, header: clmn.label, width: '5em' };
        }

        return displayClmn;
      })
    : [];

  return (
    <div className={classes.container}>
      <div className={classes.headingRow}>
        <span className={classes.headingTitle}>{t('analysis:comparable-companies')}</span>
        {chips}
      </div>
      {companies.length > 0 ? (
        <div className={classes.tableWrapper}>
          <Table
            className={classes.scrollTable}
            data={data}
            columns={displayColumns}
            stickyCols={stickyCols}
            onRowClick={onRowClick}
          />
        </div>
      ) : (
        <CenteredProgress />
      )}
    </div>
  );
};
