import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReportVersionStatus, ReportReviewStatuses } from '@xbs/xbs-enums';
import { useLocalReportsPolling } from './hooks/useLocalReportsPolling';
import { ConnectorProps } from './LocalReports.proptype';
import { LocalReportData } from '../../models';
import { fetchComplianceCentralReportsInfo } from '../../redux/complianceCentral';
import { fetchEntities } from '../../redux/entities';
import {
  actions as localReportsActions,
  generateNewReport,
  fetchTemplates,
  fetchReportInstancesForJurisdiction,
  updateReportActiveVersion,
  saveReportStatus,
  publishReport,
  unpublishReport
} from '../../redux/localReports';
import { ReportPayload } from '../../redux/localReports/localReports.proptype';
import {
  selectCountry,
  selectEntitiesList,
  selectLocalFileReports,
  selectUPE,
  selectUPECurrency,
  selectWorkingContainer,
  selectLocalReportsData,
  selectSelectedPrimaryEntities,
  selectSelectedTradingPartners,
  selectSelectedTransactions,
  selectCurrencies,
  selectTemplateList
} from '../../selectors';
import { AppDispatch } from '../../store';
import { LocalReportCreationModal } from '../LocalReportCreationModal';

const Connector = ({ jurisdictionId, component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const localReportsData = useSelector(selectLocalReportsData);
  const selectedPrimaryEntities = useSelector(selectSelectedPrimaryEntities);
  const selectedTradingPartners = useSelector(selectSelectedTradingPartners);
  const selectedTransactions = useSelector(selectSelectedTransactions);
  const mneCurrency = useSelector(selectUPECurrency);
  const currencies = useSelector(selectCurrencies);
  const entities = useSelector(selectEntitiesList);
  const upe = useSelector(selectUPE);
  const localFileReports = useSelector(selectLocalFileReports);
  const container = useSelector(selectWorkingContainer);
  const country = useSelector(selectCountry(jurisdictionId))!;
  const reportTemplates = useSelector(selectTemplateList);
  const [showLocalReportCreationModal, setShowLocalReportCreationModal] = useState(false);
  const { pollAndUpdate } = useLocalReportsPolling();

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (entities && reportTemplates?.length === 0) {
      void dispatch(fetchTemplates(entities[0]?.entityId));
    }
  }, [dispatch, entities, reportTemplates]);

  const quickGenerate = useCallback(
    async (report: LocalReportData) => {
      let defaultTemplate;
      if (!report.templateId && report.primaryEntities.length > 0) {
        const entityId = report.primaryEntities[0].entityId ?? 1;
        const response = unwrapResult(await dispatch(fetchTemplates(entityId)));
        defaultTemplate = response[1]?.templateId;
      }

      const reportPayload: ReportPayload = {
        internalLocalfileReportId: report.internalLocalfileReportId,
        currencyId: report.reportCurrency.currencyId.toString(),
        selectedTransactionIds: report.reportTransactions.map((transaction: any) => transaction.transactionId),
        name: report.name,
        templateId: report.templateId ?? defaultTemplate,
        containerId: container?.containerId!,
        primaryEntityIds: report.primaryEntities.map((entity: any) => entity.entityId),
        tradingPartnerIds: report.tradingPartners.map((entity: any) => entity.entityId),
        mneCurrency: report.reportCurrency.currencyId.toString() === mneCurrency?.currencyId.toString(),
        mneId: upe?.entityId!,
        executiveSummaryIntro: report.executiveSummaryIntro,
        executiveSummaryConclusion: report.executiveSummaryConclusion,
        orgChart: report.orgChart,
        targetLanguage: report.languageIso,
        newReportInstance: true,
        isoCode: country?.isoCode,
        domicileName: country?.name,
        domicileId: country?.countryId,
        taxYear: container?.taxYear!,
        shouldDisplayPageNumber: report.shouldDisplayPageNumber,
        isLimitationWording: report.isLimitationWording,
        isActive: Boolean(report.isActive),
        container: {
          containerId: container?.containerId!,
          name: container?.name!,
          taxYear: container?.taxYear!,
          isService: container?.isService,
          isPlaceholder: container?.isPlaceholder,
          tenantId: container?.tenantId
        }
      };

      const reportMetadata = unwrapResult(await dispatch(generateNewReport(reportPayload)));

      dispatch(
        localReportsActions.replaceLocalFileReportInstanceData({
          previousInstanceId: report.internalLocalfileReportInstanceId,
          instanceData: {
            ...report,
            isUploadedReport: 0,
            status: {
              internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
              name: ReportVersionStatus.ByName.Pending.Name
            },
            createdAt: new Date().toString()
          }
        })
      );

      pollAndUpdate(
        report.name,
        Number(report.internalLocalfileReportId),
        Number(reportMetadata.reportInstanceId),
        Number(report.internalLocalfileReportInstanceId),
        container?.containerId
      );

      void dispatch(
        updateReportActiveVersion({
          reportId: report.internalLocalfileReportId,
          internalLocalfileReportInstanceId: reportMetadata.reportInstanceId
        })
      );
    },
    [container, country, mneCurrency, dispatch, pollAndUpdate, upe]
  );

  const onUpdateReportStatus = async (reportStatus: string, report: LocalReportData) => {
    await dispatch(
      saveReportStatus({
        reportId: report.internalLocalfileReportId,
        reportInstanceId: report.internalLocalfileReportInstanceId,
        reportStatus,
        containerId: container?.containerId
      })
    );
    await (ReportReviewStatuses.ByOldStatus[1].newStatuses.includes(reportStatus)
      ? dispatch(
          publishReport({
            reportId: report.internalLocalfileReportId,
            reportInstanceId: report.internalLocalfileReportInstanceId,
            containerId: container?.containerId
          })
        )
      : dispatch(
          unpublishReport({
            reportId: report.internalLocalfileReportId,
            reportInstanceId: report.internalLocalfileReportInstanceId
          })
        ));

    void dispatch(fetchComplianceCentralReportsInfo(container!.containerId));
  };

  const onCreateLocalReportOpen = () => {
    setShowLocalReportCreationModal(true);
  };

  const onCreateLocalReportClose = async () => {
    await dispatch(localReportsActions.resetData());
    setShowLocalReportCreationModal(false);
  };

  const onCreateLocalReportSubmit = async () => {
    await onCreateLocalReportClose();

    const reportPayload: ReportPayload = {
      currencyId: localReportsData.setup.currency,
      selectedTransactionIds: selectedTransactions.map((trans) => trans.transactionId),
      name: localReportsData.setup.reportTitle,
      templateId: localReportsData.setup.reportTemplate,
      containerId: container?.containerId!,
      primaryEntityIds: selectedPrimaryEntities.map((ent) => ent.entityId),
      tradingPartnerIds: selectedTradingPartners.map((ent) => ent.entityId),
      mneCurrency: localReportsData.setup.currency === mneCurrency?.currencyId.toString(),
      mneId: upe?.entityId!,
      executiveSummaryIntro: localReportsData.summaries.executive,
      executiveSummaryConclusion: localReportsData.summaries.conclusion,
      orgChart: localReportsData.summaries.orgChart,
      targetLanguage: localReportsData.setup.language,
      newReportInstance: false,
      isoCode: country?.isoCode,
      domicileName: country?.name,
      domicileId: country?.countryId,
      taxYear: container?.taxYear!,
      shouldDisplayPageNumber: localReportsData.setup.addPageNumber,
      isLimitationWording: localReportsData.setup.limitWording,
      isActive: true,
      container: {
        containerId: container?.containerId!,
        name: container?.name!,
        taxYear: container?.taxYear!,
        isService: container?.isService,
        isPlaceholder: container?.isPlaceholder,
        tenantId: container?.tenantId
      }
    };

    const reportMetadata = unwrapResult(await dispatch(generateNewReport(reportPayload)));
    const reportId = Number(reportMetadata.reportId);
    const reportInstanceId = Number(reportMetadata.reportInstanceId);

    setNewlyCreatedReportDataInState(reportPayload, reportId, reportInstanceId);
  };

  /**
   * Used to show a new report's tile immediately after it's created
   */
  const setNewlyCreatedReportDataInState = (
    reportPayload: ReportPayload,
    reportId: number,
    reportInstanceId: number
  ) => {
    const newReportData: LocalReportData = {
      internalLocalfileReportId: reportId,
      name: reportPayload.name,
      internalLocalfileReportInstanceId: reportInstanceId,
      version: 1,
      reportStatus: 'draft',
      createdAt: new Date().toString(),
      customerVersionFlag: 0,
      finalVersionFlag: 0,
      reportCurrency: currencies.find((curr) => curr.currencyId === Number(reportPayload.currencyId))!,
      executiveSummaryConclusion: reportPayload.executiveSummaryConclusion,
      orgChart: reportPayload.orgChart,
      executiveSummaryIntro: reportPayload.executiveSummaryIntro,
      languageIso: reportPayload.targetLanguage,
      isUploadedReport: 0,
      isActive: 1,
      binaryPath: '',
      templateId: reportPayload.templateId,
      reportTransactions: selectedTransactions,
      primaryEntities: selectedPrimaryEntities,
      tradingPartners: selectedTradingPartners,
      status: {
        internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
        name: ReportVersionStatus.ByName.Pending.Name
      }
    };

    dispatch(localReportsActions.setLocalFileReport([newReportData, ...(localFileReports ?? [])]));
    pollAndUpdate(newReportData.name, reportId, reportInstanceId, undefined, container?.containerId);
  };

  const fetchDataAndStartPendingPollings = useCallback(async () => {
    void dispatch(localReportsActions.setLocalFileReport(null));

    const instances = unwrapResult(
      await dispatch(
        fetchReportInstancesForJurisdiction({
          jurisdictionId,
          isActive: true,
          containerId: container?.containerId
        })
      )
    );

    instances.forEach((instance) => {
      const status = ReportVersionStatus.ById[instance.status?.internalLocalfileReportInstanceStatusId].Name;

      if (status === 'Converting' || status === 'Pending') {
        pollAndUpdate(
          instance.name,
          instance.internalLocalfileReportId,
          instance.internalLocalfileReportInstanceId,
          instance.internalLocalfileReportInstanceId,
          container?.containerId
        );
      }
    });
  }, [dispatch, jurisdictionId, pollAndUpdate, container?.containerId]);

  useEffect(() => {
    void fetchDataAndStartPendingPollings();
  }, [dispatch, fetchDataAndStartPendingPollings]);

  return (
    <>
      <Component
        localReports={localFileReports}
        country={country}
        fetchDataForReportTiles={fetchDataAndStartPendingPollings}
        quickGenerate={quickGenerate}
        onUpdateReportStatus={onUpdateReportStatus}
        onCreateLocalReportClick={onCreateLocalReportOpen}
      />
      {showLocalReportCreationModal && (
        <LocalReportCreationModal
          title={t('reports:label-create-local-report')}
          jurisdictionId={jurisdictionId}
          jurisdictionName={country?.name}
          onSubmit={onCreateLocalReportSubmit}
          onClose={onCreateLocalReportClose}
        />
      )}
    </>
  );
};

export default Connector;
