import { Box, makeStyles } from '@material-ui/core';
import { LocalReportCreateVersionModalProps } from './LocalReportCreateVersionModal.proptype';
import { LocalReportCreationModal } from '../LocalReportCreationModal';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    height: '100%'
  }
}));

export const LocalReportCreateVersionModal = ({
  title,
  jurisdictionId,
  jurisdictionName,
  onSubmit,
  onClose
}: LocalReportCreateVersionModalProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <LocalReportCreationModal
        title={title}
        jurisdictionId={jurisdictionId}
        jurisdictionName={jurisdictionName}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Box>
  );
};
