import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Language as LanguageIcon } from '@material-ui/icons';
import { sectionsByRole } from './constants';
import { SideMenuProps } from './SideMenu.proptype';
import { selectUPE } from '../../selectors';
import { EntitiesIcon, TransactionsIcon, AnalysisIcon, ReportsIcon, MasterFileIcon } from '../../svgs';
import { getAuthInfo, decodeTokens, addElementToArrayIfTrue, handleRoleRedirect } from '../../utils';

const Connector = ({ component: Component }: { component: ComponentType<SideMenuProps> }) => {
  const { t } = useTranslation();
  const hasUPE = Boolean(useSelector(selectUPE));
  const { pathname } = useLocation();
  const history = useHistory();
  const { authToken } = getAuthInfo();
  const decodedToken = decodeTokens(authToken);
  const userRole = decodedToken?.role;
  const availableSections = userRole && sectionsByRole[userRole] ? sectionsByRole[userRole] : new Set();

  return (
    <Component
      selectedPath={pathname}
      sections={[
        {
          items: [
            ...addElementToArrayIfTrue(
              {
                icon: <EntitiesIcon />,
                text: t('link-entities'),
                path: '/entities',
                key: 'entities'
              },
              availableSections.size === 0 || availableSections.has('entities')
            ),
            ...addElementToArrayIfTrue(
              {
                icon: <TransactionsIcon />,
                text: t('link-transactions'),
                path: '/transactions',
                disabled: !hasUPE,
                key: 'transactions'
              },
              availableSections.size === 0 || availableSections.has('transactions')
            ),
            ...addElementToArrayIfTrue(
              {
                icon: <AnalysisIcon />,
                text: t('link-analysis'),
                path: '/analysis',
                disabled: !hasUPE,
                key: 'analysis'
              },
              availableSections.size === 0 || availableSections.has('analysis')
            ),
            ...addElementToArrayIfTrue(
              {
                icon: <ReportsIcon />,
                text: t('link-reports'),
                path: '/reports',
                disabled: !hasUPE,
                key: 'reports'
              },
              availableSections.size === 0 || availableSections.has('reports')
            )
          ]
        },
        ...addElementToArrayIfTrue(
          {
            title: t('link-separator-master-file'),
            items: [
              {
                icon: <MasterFileIcon />,
                path: '/master-file',
                text: t('link-master-file'),
                key: 'master-file'
              }
            ]
          },
          availableSections.size === 0 || availableSections.has('master-file')
        ),
        ...addElementToArrayIfTrue(
          {
            title: t('link-separator-knowledge'),
            items: [
              {
                icon: <LanguageIcon />,
                path: '/global-guide',
                text: t('link-global-guide'),
                disabled: !hasUPE,
                key: 'global-guide'
              }
            ]
          },
          availableSections.size === 0 || availableSections.has('global-guide')
        )
      ]}
      onNavigate={(path) => {
        // TODO: Remove when master file section is setup
        if (path === '/master-file') {
          handleRoleRedirect('/legacy/master-file');
        } else {
          history.push(path);
        }
      }}
    />
  );
};

export default Connector;
