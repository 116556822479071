import BasisGrotesqueBoldPro from './BasisGrotesquePro-Bold.woff';
import BasisGrotesqueProMedium from './BasisGrotesquePro-Medium.woff';
import BasisGrotesquePro from './BasisGrotesquePro-Regular.woff';

// if we remove the 'as normal' it will cause TypeScript errors in ReportTile.tsx and wherever else this is used.
// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
const fontWeight = 'normal' as 'normal'; // eslint-disable-line @typescript-eslint/prefer-as-const

export const bodyFont = {
  fontFamily: 'Basis Grotesque Pro',
  fontStyle: 'normal',
  fontWeight,
  src: `
    local('Basis Grotesque Pro'),
    url(${BasisGrotesquePro as string})
    format('woff')
  `
};

export const titleFont = {
  fontFamily: 'Basis Grotesque Pro Bold',
  fontStyle: 'normal',
  fontWeight,
  src: `
    local('Basis Grotesque Pro Bold'),
    url(${BasisGrotesqueBoldPro as string})
    format('woff')
  `
};

export const subtitleFont = bodyFont;

export const mediumFont = {
  fontFamily: 'Basis Grotesque Pro Medium',
  fontStyle: 'normal',
  fontWeight,
  src: `
    local('Basis Grotesque Pro Medium'),
    url(${BasisGrotesqueProMedium as string})
    format('woff')
  `
};

export const regularFont = {
  fontFamily: 'Basis Grotesque Pro',
  fontStyle: 'normal',
  fontWeight,
  src: `
    local('Basis Grotesque Pro'),
    url(${BasisGrotesquePro as string})
    format('woff')
  `
};
