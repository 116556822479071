import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Grid, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import {
  defaultReportStage,
  mimeTypePDF,
  fileExtensionPDF,
  UploadReportModalProps,
  UploadReportPayloadProps
} from './UploadReportModal.proptype';
import { LocalReportData } from '../../../models';
import { FinancialDataValueEnteredInEnum } from '../../../models/financial.interface';
import designTokens from '../../../styles/designTokens';
import { UploadIcon } from '../../../svgs';
import { TPModal } from '../../TPModal';

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-start'
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    backgroundColor: designTokens.product15,
    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1.19rem',
    fontFamily: designTokens.mediumFont.fontFamily
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:disabled': {
      backgroundColor: designTokens.product15
    }
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  formWrapper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: theme.spacing(3),
    boxSizing: 'content-box',
    height: '100%',
    width: '90%'
  },
  gridWrapper: {
    marginTop: theme.spacing(1)
  },
  contentTitle: {
    fontFamily: designTokens.mediumFont.fontFamily,
    fontSize: '1rem',
    color: designTokens.core3
  },
  label: {
    color: designTokens.core2,
    marginBottom: '6px',
    fontSize: '0.9rem',
    fontFamily: designTokens.mediumFont.fontFamily,
    lineHeight: 1.8
  },
  input: {
    display: 'none'
  },
  wrapperInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  inputFileName: {
    fontFamily: designTokens.mediumFont.fontFamily,
    fontSize: '0.89rem',
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    padding: theme.spacing(0.7),
    marginLeft: theme.spacing(1),
    borderRadius: '4px'
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  formControl: {
    width: '100%',
    boxSizing: 'content-box'
  }
}));

interface FormProps {
  language: string;
  currency: number;
  file: FileList | null;
}

export const UploadReportModal = ({
  open = false,
  currencyOptions,
  languageOptions,
  executeUploadReport,
  setIsUploadReportModalOpen,
  reportInfo,
  container,
  upeCurrency,
  defaultCurrency,
  isUploadingReport
}: UploadReportModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [formComplete, setFormComplete] = useState(false);

  const handlerOnClose = useCallback(() => {
    setIsUploadReportModalOpen(false);
  }, [setIsUploadReportModalOpen]);

  const formMethods = useForm<FormProps>({
    defaultValues: {
      language: 'en',
      currency: FinancialDataValueEnteredInEnum.MNE,
      file: null
    }
  });

  const { control, getValues, handleSubmit, watch } = formMethods;
  const uploadReport = () => {
    void handleSubmit((formData) => {
      const uploadReportPayload = createUploadReportVersionPayload(formData) as UploadReportPayloadProps;
      if (formData.currency && formData.language && formData.file) {
        executeUploadReport(uploadReportPayload, formData.file[0], reportInfo as LocalReportData);
      }
    })();
  };

  const createUploadReportVersionPayload = (formData: FormProps) => {
    let { currencyId } = upeCurrency;
    if (formData.currency === 2) {
      currencyId = defaultCurrency.currencyId;
    }

    const selectedPrimaryEntityIds = reportInfo.primaryEntities!.map((primaryEntity) => primaryEntity.entityId);
    const selectedTradingPartnerIds = reportInfo.tradingPartners!.map((tradingPartner) => tradingPartner.entityId);

    return {
      reportStage: defaultReportStage,
      currencyId,
      internalLocalfileReportId: reportInfo.internalLocalfileReportId,
      primaryEntityId: selectedPrimaryEntityIds[0],
      tradingPartnerEntityId: selectedTradingPartnerIds[0],
      primaryEntityIds: selectedPrimaryEntityIds,
      tradingPartnerIds: selectedTradingPartnerIds,
      executiveSummaryIntro: reportInfo.executiveSummaryIntro,
      executiveSummaryConclusion: reportInfo.executiveSummaryConclusion,
      orgChart: reportInfo.orgChart,
      languageIso: formData.language,
      containerId: container.containerId,
      isUploadedReport: true,
      selectedTransactionIds: reportInfo.reportTransactions!.map((transaction) => transaction.transactionId),
      mimeType: mimeTypePDF,
      fileExtension: fileExtensionPDF
    };
  };

  const watchFields = watch();
  const fileSelected = getValues('file');

  if (watchFields && !formComplete) {
    const existFile = Boolean(watchFields.file && watchFields.file.length > 0);

    if (existFile && watchFields.language && watchFields.currency) {
      setFormComplete(true);
    }
  }

  return (
    <TPModal
      isOpen={open}
      maxWidth="md"
      containerStyle={classes.contentWrapper}
      title={
        <Box display="inline" className={classes.headerWrapper}>
          <Box className={classes.headerIcon}>
            <UploadIcon />
          </Box>
          <Typography className={classes.headerTitle}>{t('reports:title-modal-upload-report')}</Typography>
        </Box>
      }
      actions={[
        {
          name: t('action-cancel'),
          handler: handlerOnClose
        },
        {
          name: t('reports:action-upload'),
          className: classes.doneButton,
          disabled: !formComplete || isUploadingReport,
          handler: uploadReport
        }
      ]}
      onClose={handlerOnClose}
    >
      <Box className={classes.formWrapper}>
        <Typography className={classes.contentTitle}>{t('reports:title-modal-upload-report-details')}</Typography>
        <Typography>{t('reports:message-modal-upload-report')}</Typography>
        <Box marginTop="2rem">
          <Typography className={classes.label}>
            {t('reports:label-local-report-creation-modal-report-title')}
          </Typography>
          <Typography>{reportInfo.name}</Typography>
        </Box>

        <form>
          <Grid container justify="center" spacing={2} className={classes.gridWrapper}>
            <Grid item xs={6}>
              <Typography className={classes.label}>
                {t('reports:label-local-report-creation-modal-language')}
              </Typography>
              <FormControl size="small" variant="outlined" className={classes.formControl}>
                <Controller
                  data-testid="language"
                  name="language"
                  control={control}
                  render={({ value, onChange }) => (
                    <Select value={value} onChange={onChange}>
                      {languageOptions.map(({ isoCode, name }) => (
                        <MenuItem key={isoCode} value={isoCode}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.label}>
                {t('reports:label-local-report-creation-modal-currency')}
              </Typography>
              <FormControl size="small" variant="outlined" className={classes.formControl}>
                <Controller
                  data-testid="Currency"
                  name="currency"
                  control={control}
                  render={({ value, onChange }) => (
                    <Select value={value} onChange={onChange}>
                      {currencyOptions.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.label}>{t('reports:label-upload-report-file')}</Typography>
              <FormControl size="small" variant="outlined" className={classes.formControl}>
                <Controller
                  data-testid="file"
                  name="file"
                  control={control}
                  render={({ onChange }) => (
                    <>
                      <input
                        accept="application/pdf"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          onChange(e.target.files);
                        }}
                      />
                      <label htmlFor="contained-button-file" className={classes.wrapperInput}>
                        <Button variant="outlined" color="primary" component="span">
                          {t('reports:action-browse-file')}
                        </Button>
                        {fileSelected && fileSelected.length > 0 ? (
                          <span className={classes.inputFileName}>{fileSelected[0].name}</span>
                        ) : (
                          ''
                        )}
                      </label>
                    </>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
    </TPModal>
  );
};
