import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { TransactionMenu } from './TransactionMenu';
import { CompletionLabel, SearchAndSort, SortOrder } from '../../../components';
import { filterData, sortData } from '../../../services/filtering';
import { body2 } from '../../../styles/typography';
import { ArrowMove } from '../../../svgs';
import { currencyValueFormatter, findTransactionDestination, findTransactionSource } from '../../../utils';
import { dateInLocalTimeZone } from '../../../utils/dates';
import { Table } from '../../Table';
import { TransactionsSortBy, TransactionsProps } from '../Transactions.proptype';

export const TransactionsTable = ({
  entities,
  transactions,
  onNavigate,
  onEdit,
  onDelete,
  upeCurrency
}: TransactionsProps) => {
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy?: TransactionsSortBy; sortOrder?: SortOrder }>({});
  const [filterObject, setFilter] = useState<Record<string, string>>({});

  const entityById = useMemo(() => new Map((entities ?? []).map((entity) => [entity.entityId, entity])), [entities]);

  const displaybleTransactions = useMemo(
    () =>
      (transactions ?? []).map((transaction) => {
        const {
          transactionId,
          transactionType,
          identifier,
          transferDate,
          value = 0,
          propertyTransferred,
          pbas = [],
          tbas = []
        } = transaction;

        const hasNoAnalysis = pbas.length === 0 && tbas.length === 0;
        const areAnalysesComplete =
          !hasNoAnalysis &&
          tbas.every(({ sourceCompleted, destinationCompleted }) => sourceCompleted && destinationCompleted) &&
          pbas.every(({ sourceCompleted, destinationCompleted }) => sourceCompleted && destinationCompleted);
        let statusLabel = 'label-in-progress';
        if (hasNoAnalysis) {
          statusLabel = 'transactions:label-no-analysis';
        } else if (areAnalysesComplete) {
          statusLabel = 'label-complete';
        }

        return {
          transactionId,
          source: findTransactionSource(transaction, entityById)?.code ?? '',
          destination: findTransactionDestination(transaction, entityById)?.code ?? '',
          identifier,
          type: transactionType?.name ? t(`transactions:type-${transactionType.name}`) : '',
          date: t(`date-format-short`, { date: new Date(dateInLocalTimeZone(transferDate)) }),
          property: propertyTransferred,
          value: value ? new Intl.NumberFormat('en', currencyValueFormatter(2, 2, upeCurrency)).format(value) : '',
          status: <CompletionLabel hideMark completed={areAnalysesComplete} label={t(statusLabel)} />,
          toDashboard: (
            <IconButton
              onClick={() => {
                onNavigate(`/transactions/${transactionId}/dashboard`);
              }}
            >
              <ArrowMove />
            </IconButton>
          ),
          menu: <TransactionMenu {...{ onNavigate, onEdit, onDelete, transaction }} />
        };
      }),
    [t, entityById, transactions, onNavigate, onEdit, onDelete, upeCurrency]
  );

  return (
    <Table
      data={sortData(filterData(displaybleTransactions, filterObject), sortObject)}
      columns={[
        {
          key: 'source',
          header: (
            <SearchAndSort
              field={t('transactions:column-source')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'source', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, source: value });
              }}
            />
          ),
          width: 180
        },
        {
          key: 'destination',
          header: (
            <SearchAndSort
              field={t('transactions:column-destination')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'destination', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, destination: value });
              }}
            />
          ),
          width: 180
        },
        { key: 'identifier', header: t('transactions:column-id') },
        {
          key: 'type',
          header: (
            <SearchAndSort
              field={t('transactions:column-type')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'type', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, type: value });
              }}
            />
          ),
          width: 250
        },
        { key: 'property', header: t('transactions:label-property-transferred') },
        { key: 'date', header: t('transactions:column-transfer') },
        {
          key: 'value',
          header: `${t('transactions:column-amount')}  (${upeCurrency.isoCode ? upeCurrency.isoCode : 'USD'})`,
          align: 'right'
        },
        { key: 'status', header: t('transactions:column-status'), width: 150 },
        { key: 'toDashboard', header: '', width: 30 },
        { key: 'menu', header: '', width: 30 }
      ]}
      stickyCols={{
        source: {
          side: 'left',
          position: 0
        },
        destination: {
          side: 'left',
          position: 1
        },
        status: {
          side: 'right',
          position: 0
        },
        toDashboard: {
          side: 'right',
          position: 1
        },
        menu: {
          side: 'right',
          position: 2
        }
      }}
      columnSpecificStyles={{
        source: {
          ...body2
        },
        destination: {
          ...body2
        },
        toDashboard: {
          paddingLeft: 0,
          paddingRight: 0
        },
        menu: {
          paddingLeft: 0
        }
      }}
    />
  );
};
