import { useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { GlobalGuideProps } from './GlobalGuide.proptypes';
import { GlobalGuideCard, GlobalGuideSearchTable, WorldMap } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  tableContainer: {
    width: '35%'
  },
  mapContainer: {
    position: 'relative',
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(2)
  }
}));

const Component = ({ globalGuides, selected, geoData, onSelect }: GlobalGuideProps) => {
  const classes = useStyles();
  const modalContainer = useRef<HTMLDivElement>();

  const handleCountryClick = (isoCode: string) => {
    onSelect(globalGuides?.find((country) => country.isoCode === isoCode));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.tableContainer}>
        <GlobalGuideSearchTable data={globalGuides} selectedItem={selected} onClick={onSelect} />
      </Box>
      {/* ref are not allowed on Box, until material-ui@5 https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360 */}
      <Box {...{ ref: modalContainer }} className={classes.mapContainer}>
        <WorldMap data={geoData} onClick={handleCountryClick} />
        <GlobalGuideCard
          container={modalContainer.current!}
          displayed={selected}
          onClose={() => {
            onSelect();
          }}
        />
      </Box>
    </Box>
  );
};

export default Component;
