import React, { ReactNode } from 'react';
import { ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, PopoverOrigin, Theme } from '@material-ui/core';
import tokens from '../../styles/designTokens';

export interface GenericDropDownProps {
  anchorEl: Element | null;
  anchorOrigin?: PopoverOrigin;
  items: Array<GenericDropDownItem | ReactNode>;
  minWidth: string;
  transformOrigin?: PopoverOrigin;
  title?: string;
  onClose: () => void;
  disabled?: boolean;
}

export interface GenericDropDownItem {
  icon?: JSX.Element;
  text: string;
  textCss?: React.CSSProperties;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

interface StyleProps {
  minWidth: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    fontSize: '0.875rem',
    '& .MuiListItemIcon-root': {
      minWidth: 'initial',
      width: '1.2rem',
      marginRight: '0.75rem'
    },
    '& .MuiList-padding': {
      minWidth: (props) => props.minWidth
    },
    '& .MuiButtonBase-root': {
      '&:hover, &.Mui-selected': {
        backgroundColor: tokens.neutral70
      }
    },
    '& .MuiPaper-root': {
      backgroundColor: tokens.white,
      boxShadow: '0 0 11px 0 rgba(0,0,0,0.12)'
    },
    '& .MuiDivider-middle': {
      marginLeft: '1rem',
      marginRight: '1rem',
      marginBottom: '0.6875rem',
      marginTop: '0.6875rem'
    }
  },
  menuItem: {
    height: '2.75rem'
  },
  withoutTopPadding: {
    '& .MuiList-padding': {
      paddingTop: 0
    }
  },
  title: {
    color: tokens.core2,
    fontFamily: tokens.mediumFont.fontFamily,
    fontSize: '0.75rem'
  },
  text: {
    fontSize: '0.875rem'
  }
}));

export const GenericDropDown = ({
  anchorEl,
  anchorOrigin,
  transformOrigin,
  title,
  items,
  minWidth,
  onClose,
  disabled
}: GenericDropDownProps) => {
  const styles = useStyles({ minWidth });

  return (
    <Menu
      keepMounted
      className={styles.root + (title ? ` ${styles.withoutTopPadding}` : '')}
      open={disabled ? false : Boolean(anchorEl)}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'top',
          horizontal: 'left'
        }
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: 'top',
          horizontal: 'left'
        }
      }
      onClose={onClose}
    >
      {title && (
        <MenuItem component="div">
          <ListItemText disableTypography primary={<span className={styles.title}>{title}</span>} />
        </MenuItem>
      )}

      {(items ?? []).map((item) => {
        if (React.isValidElement(item)) {
          return item;
        }

        const itemData = item as GenericDropDownItem;

        return (
          <MenuItem
            key={itemData.text}
            button
            disabled={itemData.disabled}
            className={styles.menuItem}
            component="div"
            selected={Boolean(itemData.selected)}
            onClick={() => {
              itemData.onClick();
            }}
          >
            {itemData.icon && <ListItemIcon>{itemData.icon}</ListItemIcon>}
            <ListItemText
              disableTypography
              primary={
                <div className={styles.text} style={itemData.textCss}>
                  {itemData.text}
                </div>
              }
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
