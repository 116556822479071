import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';
import { CustomIconMenu, ConfirmationModal } from '../../../components';
import { Entity } from '../../../models';
import { TrashCanIcon } from '../../../svgs';
import { hasEditAccess } from '../../../utils';
import { EntityDetailsTab } from '../../EntityDetails';

interface MenuProps {
  entity: Entity;
  isUPE: boolean;
  onNavigate: (path: string) => void;
  onEdit: (entityId: number) => void;
  onDelete: (entityId: number) => void;
}

export const EntitiesMenu = ({ entity, isUPE, onNavigate, onEdit, onDelete }: MenuProps) => {
  const { entityId, name, domicile } = entity;
  const { t } = useTranslation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const items = [
    {
      text: t('entities:title-dashboard'),
      handleOnClick: () => {
        onNavigate(`/entities/${entityId}/dashboard`);
      }
    },
    {
      text: t('title-sof'),
      handleOnClick: () => {
        onNavigate(`/entities/${entityId}/details/${EntityDetailsTab.StatementOfFacts}`);
      }
    },
    {
      text: t('entities:title-income-statement'),
      handleOnClick: () => {
        onNavigate(`/entities/${entityId}/details/${EntityDetailsTab.IncomeStatement}`);
      }
    },
    {
      text: t('entities:title-balance-sheet'),
      handleOnClick: () => {
        onNavigate(`/entities/${entityId}/details/${EntityDetailsTab.BalanceSheet}`);
      },
      dividerAfter: true
    },
    {
      text: t('title-global-guide'),
      handleOnClick: () => {
        onNavigate(`/global-guide/${domicile?.isoCode}`);
      }
    }
  ];

  if (hasEditAccess()) {
    items.push({
      text: t(isUPE ? 'entities:action-edit-upe' : 'entities:action-edit'),
      handleOnClick: () => {
        onEdit(entityId);
      }
    });

    if (!isUPE) {
      items.push({
        text: t('entities:action-delete'),
        handleOnClick: () => {
          setIsConfirmationOpen(true);
        }
      });
    }
  }

  const handleDeletion = (confirmed: boolean) => {
    setIsConfirmationOpen(false);
    if (confirmed) {
      onDelete(entityId);
    }
  };

  return (
    <>
      <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
      {isConfirmationOpen && (
        <ConfirmationModal
          open
          title={t('entities:action-delete')}
          subtitle={t('entities:title-delete', { name })}
          text={t('message-delete')}
          HeaderIcon={TrashCanIcon}
          handleClose={handleDeletion}
        />
      )}
    </>
  );
};
