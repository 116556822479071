import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';
import tokens from '../../../styles/designTokens';
import { body1, body2, captionOverline } from '../../../styles/typography';
import { FunctionalAnalysisCharacteristic } from '../../FunctionalAnalysisTable/FunctionalAnalysisTable.proptype';

const useStyles = makeStyles(() => ({
  primaryCard: {
    background: tokens.product15,
    display: 'flex',
    justifyContent: 'space-between',
    border: `solid 1px ${tokens.product25}`,
    borderRadius: '0.25rem',
    padding: '0.5rem'
  },
  primaryHeader: {
    ...tokens.mediumFont,
    color: tokens.product100
  },
  primarySubheader: {
    ...tokens.regularFont,
    color: tokens.core2
  },
  primaryButton: {
    margin: 'auto 0',
    color: tokens.product100,
    ...tokens.mediumFont,
    backgroundColor: tokens.product25
  },
  selectedSubtitle: {
    ...tokens.mediumFont,
    color: tokens.core2,
    marginTop: '0.5rem'
  },
  secondaryCard: {
    backgroundColor: 'white',
    border: `solid 1px ${tokens.neutral90}`,
    borderRadius: '0.25rem',
    padding: '0.5rem'
  },
  standardizedTextBadge: {
    color: tokens.product100,
    ...captionOverline,
    width: 'fit-content',
    backgroundColor: tokens.product15,
    border: `solid 1px ${tokens.product25}`,
    borderRadius: '0.25rem',
    padding: '0.25rem'
  },
  characterizationTitle: {
    ...body2,
    color: tokens.core1
  },
  defaultText: {
    ...body1,
    color: tokens.core1
  },
  replacementRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  replacementDescription: {
    ...body1,
    color: tokens.core2,
    margin: 'auto 0'
  }
}));

interface StandardizedFunctionalAnalysisWizardProps {
  onApply: () => void;
  onBulkApply: () => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
}

export const StandardizedFunctionalAnalysisWizard = ({
  onApply,
  onBulkApply,
  selectedAnalysis
}: StandardizedFunctionalAnalysisWizardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.primaryCard}>
        <span>
          <div className={classes.primaryHeader}>{t(`analysis:standardized_functional_analysis.bulk_apply_title`)}</div>
          <div className={classes.primarySubheader}>
            {t(`analysis:standardized_functional_analysis.bulk_apply_text`)}
          </div>
        </span>
        <Button
          className={classes.primaryButton}
          onClick={() => {
            onBulkApply();
          }}
        >
          {t(`analysis:action-apply`)}
        </Button>
      </div>
      <span className={classes.selectedSubtitle}>
        {t(`analysis:standardized_functional_analysis.selected_characteristics`)}
      </span>
      <div className={classes.secondaryCard}>
        <div className={classes.standardizedTextBadge}>
          {t(`analysis:standardized_functional_analysis.standardized_text`).toUpperCase()}
        </div>
        {selectedAnalysis ? (
          <>
            <div className={classes.characterizationTitle}>
              {selectedAnalysis.functionalCharacteristicType
                ? t(`analysis:${selectedAnalysis.functionalCharacteristicType.name}`)
                : selectedAnalysis.characteristicName}
            </div>
            <div className={classes.defaultText}>
              {selectedAnalysis.functionalCharacteristicType
                ? selectedAnalysis.functionalCharacteristicType.discussion
                : t(`analysis:standardized_functional_analysis.no-default-discussion`)}
            </div>
            {selectedAnalysis.functionalCharacteristicType && (
              <div className={classes.replacementRow}>
                <span className={classes.replacementDescription}>
                  {t(`analysis:standardized_functional_analysis.characterization_replacement_text`)}
                </span>
                <Button
                  className={classes.primaryButton}
                  onClick={() => {
                    onApply();
                  }}
                >
                  {t(`analysis:action-apply`)}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className={classes.defaultText}>
            {t(`analysis:standardized_functional_analysis.no-characteristic-selected`)}
          </div>
        )}
      </div>
    </>
  );
};
