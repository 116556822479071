import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { RootState } from '..';
import { Study, CustomStudy } from '../../models';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface StudiesState {
  studies: Study[] | null;
  error?: string;
}

const initialState: StudiesState = { studies: null };

export const fetchStudies = createAsyncThunk<Study[], void, { rejectValue: Error }>(
  'studies/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: Study[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'studies',
          params: { sort: 'name', order: 'asc' }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteStudy = createAsyncThunk<number, number, { rejectValue: Error }>(
  'studies/delete',
  async (studyId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `studies/${studyId}`
      });
      return studyId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveStudy = createAsyncThunk<
  CustomStudy,
  { study: Partial<CustomStudy>; t: TFunction },
  { state: RootState; rejectValue: Error }
>('studies/save', async ({ study, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit = typeof study.studyId === 'number';
    const relativePath = isEdit ? `studies/${study.studyId!}` : 'studies';
    const { data } = await httpService.request<{ data: Study }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: study
    });

    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const studiesSlice = createSlice({
  name: 'studies',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(deleteStudy.fulfilled, (state: StudiesState, action: PayloadAction<number>) => {
        const idx = (state.studies ?? []).findIndex(({ studyId }) => studyId === action.payload);
        if (idx >= 0) {
          state.studies!.splice(idx, 1);
        }
      })
      .addCase(fetchStudies.fulfilled, (state: StudiesState, action: PayloadAction<Study[]>) => {
        state.studies = action.payload;
      })
      .addMatcher(
        (action) => action.type.match(/^studies\/.+\/pending$/),
        (state: StudiesState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^studies\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default studiesSlice.reducer;
