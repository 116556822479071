import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format as formatDate } from 'date-fns';
import { RootState } from '..';
import { Entity } from '../../models';
import { selectCountry, selectUPE } from '../../selectors';
import httpService from '../../services/http';
import { getDateAsTimestamp } from '../../utils/dates';
import { getWorkingContainer } from '../baseData';

interface EntitiesState {
  entities: Entity[] | null;
  error?: string;
}

const initialState: EntitiesState = { entities: null };

export const fetchEntities = createAsyncThunk<Entity[], void, { rejectValue: Error }>(
  'entities/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: Entity[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'entities',
          params: { sort: 'code', order: 'asc' }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveEntity = createAsyncThunk<Entity, Partial<Entity>, { state: RootState; rejectValue: Error }>(
  'entities/save',
  async (saved, { getState, rejectWithValue }) => {
    try {
      const { entityId, domicile, taxJurisdiction, parentEntities, primaryFunction, upeCurrency } = saved;
      const UPE = selectUPE(getState());
      // A quirk of the API is that the domicile fields in the entities are missing their default jurisdictions
      const domicileWithDefaultJurisdiction = domicile ? selectCountry(domicile.countryId)(getState()) : domicile;
      const fiscalYearEnd = saved.fiscalYearEnd ? new Date(saved.fiscalYearEnd) : new Date();
      const {
        data: { data }
      } = await httpService.request<{ data: Entity }>({
        method: entityId ? 'patch' : 'post',
        apiUrlKey: 'baseUrl',
        relativePath: entityId ? `entities/${entityId}` : 'entities',
        data: {
          ...saved,
          fiscalYearEnd: formatDate(fiscalYearEnd, 'yyyy-MM-dd'),
          domicile: domicileWithDefaultJurisdiction,
          upe: UPE === undefined || UPE.entityId === entityId
        }
      });

      // restore related data from saved entity because API only returns ids
      if (domicileWithDefaultJurisdiction) {
        data.domicile = domicileWithDefaultJurisdiction;
      }

      if (primaryFunction) {
        data.primaryFunction = primaryFunction;
      }

      if (taxJurisdiction) {
        data.taxJurisdiction = taxJurisdiction;
      }

      if (parentEntities) {
        data.parentEntities = parentEntities;
      }

      if (upeCurrency) {
        data.upeCurrency = upeCurrency;
      }

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteEntity = createAsyncThunk<number, number, { rejectValue: Error }>(
  'entities/delete',
  async (entityId, { rejectWithValue }) => {
    try {
      await httpService.request<{ result: { entityId: number } }>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `entities/${entityId}`
      });
      return entityId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchEntities.fulfilled, (state: EntitiesState, action: PayloadAction<Entity[]>) => {
        state.entities = action.payload.map((entity) => {
          const fiscalYearEnd = getDateAsTimestamp(entity.fiscalYearEnd);
          const prepareByDate = getDateAsTimestamp(entity.prepareByDate);
          return {
            ...entity,
            fiscalYearEnd,
            prepareByDate
          };
        });
      })
      .addCase(deleteEntity.fulfilled, (state: EntitiesState, action: PayloadAction<number>) => {
        const idx = state.entities?.findIndex(({ entityId }) => entityId === action.payload);
        if (idx !== -1 && idx !== undefined) {
          state.entities!.splice(idx, 1);
        }
      })
      .addCase(saveEntity.fulfilled, (state: EntitiesState, action: PayloadAction<Entity>) => {
        if (state.entities === null) {
          state.entities = [action.payload];
        } else {
          const idx = state.entities.findIndex(({ entityId }) => entityId === action.payload.entityId);
          if (idx >= 0) {
            state.entities[idx] = action.payload;
          } else {
            state.entities.push(action.payload);
          }
        }
      })
      .addMatcher(
        (action) => action.type.match(/^entities\/.+\/pending$/),
        (state: EntitiesState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^entities\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default entitiesSlice.reducer;
