import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReportVersionStatus } from '@xbs/xbs-enums';
import { ConnectorProps } from './LocalReportCreateVersionModal.proptype';
import { Entity, Transaction } from '../../models';
import {
  actions as localReportsActions,
  createPrimaryTradingPartnersMap,
  generateNewReport,
  fetchInternalLocalFileReportInstance,
  updateReportActiveVersion
} from '../../redux/localReports';
import { ReportPayload, SetupData, SummariesData } from '../../redux/localReports/localReports.proptype';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCountry,
  selectUPE,
  selectUPECurrency,
  selectWorkingContainer,
  selectTransactionsList
} from '../../selectors';
import {
  selectLanguageList,
  selectLocalReportsData,
  selectPrimaryTradingPartnersMap,
  selectSelectedTradingPartners,
  selectSelectedTransactions,
  selectTemplateList,
  selectSelectedPrimaryEntities
} from '../../selectors/localReports';
import { AppDispatch } from '../../store';
import { useLocalReportsPolling } from '../LocalReports/hooks/useLocalReportsPolling';

const Connector = ({
  component: Component,
  isOpen,
  onClose,
  jurisdictionId,
  jurisdictionName,
  reportInfo
}: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const localReportsData = useSelector(selectLocalReportsData);
  const languages = useSelector(selectLanguageList);
  const templates = useSelector(selectTemplateList);
  const transactions = useSelector(selectTransactionsList);
  const selectedPrimaryEntities = useSelector(selectSelectedPrimaryEntities);
  const selectedPrimaryTradingPartnersMap = useSelector(selectPrimaryTradingPartnersMap);
  const selectedTradingPartners = useSelector(selectSelectedTradingPartners);
  const selectedTransactions = useSelector(selectSelectedTransactions);
  const mneCurrency = useSelector(selectUPECurrency);
  const upe = useSelector(selectUPE);
  const container = useSelector(selectWorkingContainer);
  const country = useSelector(selectCountry(jurisdictionId));
  const { pollAndUpdate } = useLocalReportsPolling();
  const previousTradingPartners = useMemo(() => {
    return reportInfo?.tradingPartners ?? [];
  }, [reportInfo]);
  const previousReportTransactions = useMemo(() => {
    return reportInfo?.reportTransactions ?? [];
  }, [reportInfo]);

  const onCreateLocalReportSubmit = async () => {
    await generateNewLocalReportVersion();
    await dispatch(localReportsActions.resetData());
    onClose();
  };

  const onCreateLocalReportClose = async () => {
    await dispatch(localReportsActions.resetData());
    onClose();
  };

  useEffect(() => {
    const selectedLanguage = languages.find((language) => language.isoCode === reportInfo.languageIso);
    const selectedTemplate = templates.find((template) => template.templateId === reportInfo.templateId);

    const previousSetupData: SetupData = {
      reportTitle: reportInfo.name,
      language: selectedLanguage?.isoCode ?? '',
      currency: String(reportInfo?.reportCurrency.currencyId),
      reportTemplate: selectedTemplate?.templateId ?? '',
      limitWording: Boolean(reportInfo.isLimitationWording),
      addPageNumber: Boolean(reportInfo.shouldDisplayPageNumber),
      disableTitle: true
    };

    const summaryData: SummariesData = {
      executive: reportInfo?.executiveSummaryIntro,
      conclusion: reportInfo?.executiveSummaryConclusion,
      orgChart: reportInfo?.orgChart
    };

    dispatch(localReportsActions.setSetup(previousSetupData));
    dispatch(localReportsActions.setSummaries(summaryData));

    if (!reportInfo.tradingPartners || !reportInfo.reportTransactions) {
      void dispatch(
        fetchInternalLocalFileReportInstance({
          reportId: reportInfo.internalLocalfileReportId,
          instanceId: reportInfo.internalLocalfileReportInstanceId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!transactions) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (transactions && !selectedPrimaryTradingPartnersMap) {
      void dispatch(createPrimaryTradingPartnersMap(jurisdictionId));
    }
  }, [dispatch, jurisdictionId, transactions, selectedPrimaryTradingPartnersMap]);

  useEffect(() => {
    if (selectedPrimaryTradingPartnersMap && selectedPrimaryEntities?.length === 0) {
      dispatch(
        localReportsActions.setSelectedPrimaryEntities(
          reportInfo?.primaryEntities.map((entity: Partial<Entity>) => entity.entityId!)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedPrimaryTradingPartnersMap, selectedPrimaryEntities]);

  useEffect(() => {
    if (
      selectedPrimaryTradingPartnersMap &&
      selectedTradingPartners?.length === 0 &&
      previousTradingPartners?.length !== 0
    ) {
      dispatch(
        localReportsActions.setSelectedTradingPartners(
          previousTradingPartners.map((tradingPartner: Partial<Entity>) => {
            return tradingPartner.entityId!;
          })
        )
      );
    }
  }, [dispatch, selectedPrimaryTradingPartnersMap, selectedTradingPartners, previousTradingPartners]);

  useEffect(() => {
    if (
      selectedPrimaryTradingPartnersMap &&
      selectedTransactions?.length === 0 &&
      previousReportTransactions?.length !== 0
    ) {
      dispatch(
        localReportsActions.setSelectedTransactions(
          previousReportTransactions.map((transaction: Partial<Transaction>) => {
            return transaction.transactionId!;
          })
        )
      );
    }
  }, [dispatch, selectedPrimaryTradingPartnersMap, selectedTransactions, previousReportTransactions]);

  const generateNewLocalReportVersion = async () => {
    const reportPayload: ReportPayload = {
      internalLocalfileReportId: reportInfo.internalLocalfileReportId,
      currencyId: localReportsData.setup.currency,
      selectedTransactionIds: selectedTransactions.map((trans) => trans.transactionId),
      name: localReportsData.setup.reportTitle,
      templateId: localReportsData.setup.reportTemplate,
      containerId: container?.containerId!,
      primaryEntityIds: selectedPrimaryEntities.map((ent) => ent.entityId),
      tradingPartnerIds: selectedTradingPartners.map((ent) => ent.entityId),
      mneCurrency: localReportsData.setup.currency === mneCurrency?.currencyId.toString(),
      mneId: upe?.entityId!,
      executiveSummaryIntro: localReportsData.summaries.executive,
      executiveSummaryConclusion: localReportsData.summaries.conclusion,
      orgChart: localReportsData.summaries.orgChart,
      targetLanguage: localReportsData.setup.language,
      newReportInstance: true,
      isoCode: country?.isoCode,
      domicileName: country?.name,
      domicileId: country?.countryId,
      taxYear: container?.taxYear!,
      shouldDisplayPageNumber: localReportsData.setup.addPageNumber,
      isLimitationWording: localReportsData.setup.limitWording,
      isActive: true,
      container: {
        containerId: container?.containerId!,
        name: container?.name!,
        taxYear: container?.taxYear!,
        isService: container?.isService,
        isPlaceholder: container?.isPlaceholder,
        tenantId: container?.tenantId
      }
    };

    const reportMetadata = unwrapResult(await dispatch(generateNewReport(reportPayload)));
    dispatch(
      localReportsActions.replaceLocalFileReportInstanceData({
        previousInstanceId: reportInfo.internalLocalfileReportInstanceId,
        instanceData: {
          ...reportInfo,
          isUploadedReport: 0,
          status: {
            internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
            name: ReportVersionStatus.ByName.Pending.Name
          },
          createdAt: new Date().toString()
        }
      })
    );

    pollAndUpdate(
      reportInfo.name,
      Number(reportInfo.internalLocalfileReportId),
      Number(reportMetadata.reportInstanceId),
      Number(reportInfo.internalLocalfileReportInstanceId),
      container?.containerId
    );

    void dispatch(
      updateReportActiveVersion({
        reportId: reportInfo.internalLocalfileReportId,
        internalLocalfileReportInstanceId: reportMetadata.reportInstanceId
      })
    );
  };

  return (
    <div>
      {isOpen && (
        <Component
          title={t('reports:label-create-new-report-version')}
          jurisdictionId={jurisdictionId}
          jurisdictionName={jurisdictionName}
          onSubmit={onCreateLocalReportSubmit}
          onClose={onCreateLocalReportClose}
        />
      )}
    </div>
  );
};

export default Connector;
