import { useDispatch, useSelector } from 'react-redux';
import { ConnectorProps } from './TransactionsTab.proptype';
import { actions as localReportsActions } from '../../../redux/localReports';
import { selectUPECurrency } from '../../../selectors';
import { selectSelectedTransactions, selectTransactionsListByTradingPartners } from '../../../selectors/localReports';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactionsListByTradingPartners);
  const selectedTransactions = useSelector(selectSelectedTransactions);
  const upeCurrency = useSelector(selectUPECurrency);

  const onSelectionChange = (selectedIds: number[]) => {
    dispatch(localReportsActions.setSelectedTransactions(selectedIds));
  };

  return (
    <Component
      transactions={transactions}
      upeCurrency={upeCurrency!}
      selectedTransactions={selectedTransactions.map((trans) => trans.transactionId)}
      onSelectionChange={onSelectionChange}
    />
  );
};

export default Connector;
