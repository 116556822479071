import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, useTheme, makeStyles } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { FilingTimingDialog } from './FilingTimingDialog';
import { ReportStatusChip } from './ReportStatusChip';
import { SearchAndSort, SortOrder } from '../../../components';
import { filterData, sortData } from '../../../services/filtering';
import { body2 } from '../../../styles/typography';
import { ArrowMove } from '../../../svgs';
import { hasEditAccess } from '../../../utils';
import { ActionButton } from '../../ActionButton';
import { Table } from '../../Table';
import { TableColumnDef } from '../../Table/Table.proptype';
import { ReportDataProps, ReportsCentralTabs, ReportsSortBy, ReportsTableProps } from '../Reports.proptype';

const { MasterFileReportTab, LocalReportTab } = ReportsCentralTabs;

interface ReportsCentralTabsColumnHeadersProps {
  [LocalReportTab: string]: TableColumnDef[];
  [MasterFileReportTab]: TableColumnDef[];
}

const useStyles = makeStyles((theme) => ({
  date: {
    padding: '0.4rem',
    borderRadius: theme.shape.borderRadius
  },
  tableContainer: {
    overflow: 'auto'
  }
}));

export const ReportsTable = ({
  entityReportData,
  onNavigate,
  masterFileReportsInfo,
  selectedTab
}: ReportsTableProps) => {
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy?: ReportsSortBy; sortOrder?: SortOrder }>({});
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const dueDateRefs = useRef<HTMLSpanElement[]>([]); // To style each dueDate column value by ref and not re-render the whole table
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    dueDateRefs.current = dueDateRefs.current.slice(0, entityReportData.length);
    setSort({ sortBy: 'countryName', sortOrder: 'asc' }); // by default sorted alphabetically from A to Z based on country name
  }, [entityReportData]);

  const reportsInfoTable = useMemo(() => {
    return entityReportData.map((reportData: ReportDataProps, i: number) => {
      const {
        countryName,
        domicileId,
        domicileStatus,
        dueDate,
        filingTiming,
        draft,
        isoCode,
        final,
        hasReports,
        jurisdictionId,
        published,
        review
      } = reportData;

      return {
        statusCircle: (
          <FiberManualRecord
            style={{
              color: domicileStatus ? theme.palette.primary.main : theme.palette.warning.main,
              fontSize: 12
            }}
          />
        ),
        countryName,
        domicileStatus,
        dueDate: (
          <FilingTimingDialog
            filingTimingInfo={filingTiming}
            url={`/global-guide/${isoCode}`}
            onOpen={() => {
              dueDateRefs.current[i].style.backgroundColor = '#f8f8f8';
            }}
            onClose={() => {
              dueDateRefs.current[i].style.backgroundColor = theme.palette.common.white;
            }}
          >
            <span
              ref={(elem) => {
                dueDateRefs.current[i] = elem!;
              }}
              className={classes.date}
            >
              {dueDate}
            </span>
          </FilingTimingDialog>
        ),
        draft: hasReports ? (
          <ReportStatusChip status="draft" type="reportsTable" label={draft.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" />
        ),
        review: hasReports ? (
          <ReportStatusChip status="review" type="reportsTable" label={review.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" />
        ),
        published: hasReports ? (
          <ReportStatusChip status="published" type="reportsTable" label={published.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" />
        ),
        final: hasReports ? (
          <ReportStatusChip status="final" type="reportsTable" label={final.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" />
        ),
        domicileId,
        jurisdictionId,
        toDashboard: (
          <ActionButton
            isReady={hasReports}
            buttonText={hasReports ? t('compliance:manage-reports') : t('compliance:create-reports')}
            action={() => {
              onNavigate(`/reports/${jurisdictionId}`);
            }}
            disabled={hasEditAccess() ? false : !hasReports}
          />
        )
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityReportData]);

  const masterFileReportsInfoTable = useMemo(() => {
    return entityReportData.map((reportData: any) => {
      const {
        countryName,
        domicileId,
        domicileStatus,
        dueDate,
        draft,
        final,
        hasReports,
        isoCode,
        published,
        review
      } = reportData;

      const domicileIdDisplay: string = domicileId ? domicileId.toString() : '';

      return {
        countryName,
        domicileId,
        domicileStatus,
        isoCode,
        dueDate,
        draft: hasReports ? (
          <ReportStatusChip status="draft" type="reportsTable" label={draft.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" label="" />
        ),
        review: hasReports ? (
          <ReportStatusChip status="review" type="reportsTable" label={review.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" label="" />
        ),
        published: hasReports ? (
          <ReportStatusChip status="published" type="reportsTable" label={published.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" label="" />
        ),
        final: hasReports ? (
          <ReportStatusChip status="final" type="reportsTable" label={final.toString()} />
        ) : (
          <ReportStatusChip status="noReports" type="reportsTable" label="" />
        ),
        toDashboard: (
          <IconButton
            onClick={() => {
              onNavigate(`/global-guide/${domicileIdDisplay}`);
            }}
          >
            <ArrowMove />
          </IconButton>
        )
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterFileReportsInfo]);

  const reportCentralTabsColumnHeaders: ReportsCentralTabsColumnHeadersProps = {
    [LocalReportTab]: [
      { key: 'statusCircle', header: '', width: '3.13rem' },
      {
        key: 'countryName',
        header: (
          <SearchAndSort
            field={t('compliance:column-name')}
            onSortClicked={(sortOrder) => {
              setSort({ sortBy: 'countryName', sortOrder });
            }}
            onSearchChange={(value) => {
              setFilter({ ...filterObject, countryName: value });
            }}
          />
        )
      },
      {
        key: 'dueDate',
        header: (
          <SearchAndSort
            field={t('compliance:column-due-date')}
            onSortClicked={(sortOrder) => {
              setSort({ sortBy: 'dueDate', sortOrder });
            }}
            onSearchChange={(value) => {
              setFilter({ ...filterObject, dueDate: value });
            }}
          />
        )
      },
      { key: 'draft', header: t('reports:draft'), align: 'center', width: '5rem' },
      { key: 'review', header: t('reports:review'), align: 'center', width: '5rem' },
      { key: 'published', header: t('reports:published'), align: 'center', width: '5rem' },
      { key: 'final', header: t('reports:final'), align: 'center', width: '5rem' },
      { key: 'toDashboard', header: '', align: 'right', width: '12.5rem' }
    ],
    [MasterFileReportTab]: [
      {
        key: 'countryName',
        header: (
          <SearchAndSort
            field={t('compliance:column-name')}
            onSortClicked={(sortOrder) => {
              setSort({ sortBy: 'countryName', sortOrder });
            }}
            onSearchChange={(value) => {
              setFilter({ ...filterObject, countryName: value });
            }}
          />
        ),
        width: '21.88rem'
      },
      {
        key: 'dueDate',
        header: t('compliance:column-due-date')
      },
      { key: 'draft', header: t('reports:draft'), align: 'center', width: '5rem' },
      { key: 'review', header: t('reports:review'), align: 'center', width: '5rem' },
      { key: 'published', header: t('reports:published'), align: 'center', width: '5rem' },
      { key: 'final', header: t('reports:final'), align: 'center', width: '5rem' },
      {
        key: 'domicileStatus',
        header: t('compliance:column-domicile-status')
      },
      { key: 'toDashboard', header: '', width: '3.13rem' },
      { key: 'menu', header: '', width: '3.13rem' }
    ]
  };

  return (
    <div className={classes.tableContainer}>
      <Table
        data={
          selectedTab === LocalReportTab
            ? sortData(filterData(reportsInfoTable, filterObject), sortObject)
            : sortData(filterData(masterFileReportsInfoTable, filterObject), sortObject)
        }
        columns={reportCentralTabsColumnHeaders[selectedTab]}
        stickyCols={{
          toDashboard: {
            side: 'right',
            position: 0
          }
        }}
        columnSpecificStyles={{
          countryName: {
            ...body2
          }
        }}
      />
    </div>
  );
};
