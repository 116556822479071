import { AppBar, Box, IconButton, Toolbar, Typography, makeStyles, Button } from '@material-ui/core';
import { SearchableContainerSelector } from './SearchableContainerSelector';
import { UserMenu } from './UserMenu';
import Logo from '../../../svgs/LogoSvg';
import { HeaderProps } from '../Header.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark
  },
  toolBar: {
    minHeight: 0,
    padding: theme.spacing(0.5, 2, 0.5, 1)
  },
  logo: {
    height: '1rem',
    width: '1rem',
    fill: theme.palette.common.white
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.white
  },
  selectorContainer: {
    height: '2.688rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1.5rem'
  },
  legacyUiButton: {
    marginRight: theme.spacing(2),
    background: '#6E56DB',
    borderColor: 'white',
    color: 'white'
  }
}));

export const Header = (props: HeaderProps) => {
  const { title, onLogoClick, goToLegacyUI, legacyUiText } = props;
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" elevation={0} color="primary" className={classes.root}>
        <Toolbar className={classes.toolBar}>
          <IconButton onClick={onLogoClick}>
            <Logo className={classes.logo} />
          </IconButton>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Button variant="outlined" className={classes.legacyUiButton} onClick={goToLegacyUI}>
            {legacyUiText}
          </Button>
          <UserMenu {...props} />
        </Toolbar>
      </AppBar>
      <Box className={classes.selectorContainer}>
        <SearchableContainerSelector {...props} />
      </Box>
    </>
  );
};
