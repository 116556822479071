import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';
import { ConfirmationModal, CustomIconMenu } from '../../../components';
import { AssociatedTransactionsPBA } from '../../../models';
import { TrashCanIcon } from '../../../svgs';
import { AssociatedTransactionsEventsProps } from '../PBADashboardTPDSummary.proptype';

interface AssociatedTransactionsMenuProps extends AssociatedTransactionsEventsProps {
  element: AssociatedTransactionsPBA;
}

export const AssociatedTransactionsMenu = ({ element, onDelete }: AssociatedTransactionsMenuProps) => {
  const { value, transactionId } = element;
  const elementId = transactionId;
  const { t } = useTranslation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const items = [
    {
      text: t(`transactions:title-remove`),
      handleOnClick: () => {
        setIsConfirmationOpen(true);
      }
    }
  ]; // todo: do we wish to restrict removal of transactions from a tested party PBA?

  const handleDeletion = (confirmed: boolean) => {
    setIsConfirmationOpen(false);
    if (confirmed && elementId) {
      onDelete!(elementId);
    }
  };

  return (
    <>
      <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
      {isConfirmationOpen && (
        <ConfirmationModal
          open
          title={t(`transactions:title-remove`)}
          subtitle={t('transactions:remove-transaction-subtitle', { value })}
          text=""
          HeaderIcon={TrashCanIcon}
          handleClose={handleDeletion}
        />
      )}
    </>
  );
};
