import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography, makeStyles, Theme } from '@material-ui/core';
import { RerunCompSearchServiceProviderProps } from './RerunCompSearchModal.proptype';
import { SearchBox } from '../../components';
import tokens from '../../styles/designTokens';
import { body4 } from '../../styles/typography';

const useStyles = makeStyles((theme: Theme) => ({
  serviceProviderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  majorClassificationContainer: {
    width: '100%',
    maxWidth: '31.25rem'
  },
  majorClassificationCard: {
    height: '9.375rem',
    marginRight: '1.25rem',
    marginTop: '1.25rem',
    backgroundColor: tokens.white
  },
  leftMostMajorClassificationCard: {
    marginRight: '0rem'
  },
  majorClassificationLabel: {
    paddingRight: '0.625rem',
    ...body4,
    color: tokens.core3
  },
  majorClassificationSearchInput: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white
    },
    '& > .MuiTextField-root .MuiAutocomplete-inputRoot': {
      color: theme.palette.common.white,
      background: '#6E56DB',
      '& > .MuiAutocomplete-endAdornment .MuiIconButton-root': {
        color: theme.palette.common.white
      }
    }
  },
  majorClassificationInputContainer: {
    padding: '0rem 1rem 1rem 1rem'
  },
  validationErrors: {
    marginLeft: '1.25rem'
  }
}));

const RerunCompSearchServiceProvider = ({
  formMethods,
  defaultValues,
  selectedModalJurisdictionInfo,
  handleRecalculateProjectCounts
}: RerunCompSearchServiceProviderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, setValue, watch, getValues, errors } = formMethods;
  const jurisdictionInfo = selectedModalJurisdictionInfo?.pbaJurisdictionInfo;
  let majorClassificationOptions: string[] = [];

  if (jurisdictionInfo?.majorClassification1) majorClassificationOptions.push(jurisdictionInfo?.majorClassification1);
  if (jurisdictionInfo?.majorClassification2) majorClassificationOptions.push(jurisdictionInfo?.majorClassification2);
  if (jurisdictionInfo?.majorClassification3) majorClassificationOptions.push(jurisdictionInfo?.majorClassification3);

  majorClassificationOptions = majorClassificationOptions.filter((classificationOption) => {
    return (
      classificationOption !== watch('majorClassification1') &&
      classificationOption !== watch('majorClassification2') &&
      classificationOption !== watch('majorClassification3')
    );
  });

  const setMajorClassification1 = (majorClassification: string | null) => {
    if (majorClassification) {
      setValue('majorClassification1', majorClassification);
    }
  };

  const setMajorClassification2 = (majorClassification: string | null) => {
    if (majorClassification) {
      setValue('majorClassification2', majorClassification);
    }
  };

  const setMajorClassification3 = (majorClassification: string | null) => {
    if (majorClassification) {
      setValue('majorClassification3', majorClassification);
    }
  };

  const hasAtLeastOneMajorClassificationValidation = () => {
    const majorClassification1Value = getValues('majorClassification1');
    const majorClassification2Value = getValues('majorClassification2');
    const majorClassification3Value = getValues('majorClassification3');

    return (
      Boolean(majorClassification1Value) || Boolean(majorClassification2Value) || Boolean(majorClassification3Value)
    );
  };

  const ErrorMessage = ({ error, message, className }: { error: boolean; message: string; className: string }) =>
    error ? (
      <Typography className={className} color="error" variant="caption">
        {message}
      </Typography>
    ) : null;

  return (
    <Box className={classes.serviceProviderContainer}>
      <Box className={classes.majorClassificationContainer}>
        <Card className={classes.majorClassificationCard}>
          <CardContent>
            <Box display="flex">
              <Typography className={classes.majorClassificationLabel}>
                {t('analysis:jurisdiction-comp-search-info-major-classification-1')}
              </Typography>
            </Box>
          </CardContent>
          <Box className={classes.majorClassificationInputContainer}>
            <Controller
              name="majorClassification1"
              render={({ onChange, value }) => (
                <SearchBox
                  className={classes.majorClassificationSearchInput}
                  disableClearable={false}
                  noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                  placeholder={t('analysis:rerun-comp-search-modal.search_classifications')}
                  error={Boolean((errors as any).majorClassification1)}
                  value={value}
                  options={majorClassificationOptions}
                  getOptionLabel={(majorClasifictaionOption: string) => {
                    return majorClasifictaionOption ? t(`analysis:${majorClasifictaionOption}`) : '';
                  }}
                  onChange={(event, data) => {
                    onChange(data);
                    setMajorClassification1(data);
                    handleRecalculateProjectCounts();
                  }}
                />
              )}
              control={control}
              defaultValue={defaultValues.majorClassification1}
              rules={{ validate: hasAtLeastOneMajorClassificationValidation }}
              error={Boolean((errors as any).majorClassification1)}
            />
          </Box>
          <ErrorMessage
            error={Boolean((errors as any).majorClassification1)}
            message={t('errors:error_message.at_least_one_major_classification_required')}
            className={classes.validationErrors}
          />
        </Card>
      </Box>
      {jurisdictionInfo?.majorClassification2 ? (
        <Box className={classes.majorClassificationContainer}>
          <Card className={classes.majorClassificationCard}>
            <CardContent>
              <Box display="flex">
                <Typography className={classes.majorClassificationLabel}>
                  {t('analysis:jurisdiction-comp-search-info-major-classification-2')}
                </Typography>
              </Box>
            </CardContent>
            <Box className={classes.majorClassificationInputContainer}>
              <Controller
                name="majorClassification2"
                render={({ onChange, value }) => (
                  <SearchBox
                    className={classes.majorClassificationSearchInput}
                    disableClearable={false}
                    noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                    placeholder={t('analysis:rerun-comp-search-modal.search_classifications')}
                    error={Boolean((errors as any).majorClassification2)}
                    value={value}
                    options={majorClassificationOptions}
                    getOptionLabel={(majorClasifictaionOption: string) => {
                      return majorClasifictaionOption ? t(`analysis:${majorClasifictaionOption}`) : '';
                    }}
                    onChange={(_, data) => {
                      onChange(data);
                      setMajorClassification2(data);
                      handleRecalculateProjectCounts();
                    }}
                  />
                )}
                control={control}
                defaultValue={defaultValues.majorClassification2}
                rules={{ validate: hasAtLeastOneMajorClassificationValidation }}
                error={Boolean((errors as any).majorClassification2)}
              />
            </Box>
            <ErrorMessage
              error={Boolean((errors as any).majorClassification2)}
              message={t('errors:error_message.at_least_one_major_classification_required')}
              className={classes.validationErrors}
            />
          </Card>
        </Box>
      ) : null}
      {jurisdictionInfo?.majorClassification3 ? (
        <Box className={classes.majorClassificationContainer}>
          <Card className={`${classes.majorClassificationCard} ${classes.leftMostMajorClassificationCard}`}>
            <CardContent>
              <Box display="flex">
                <Typography className={classes.majorClassificationLabel}>
                  {t('analysis:jurisdiction-comp-search-info-major-classification-3')}
                </Typography>
              </Box>
            </CardContent>
            <Box className={classes.majorClassificationInputContainer}>
              <Controller
                name="majorClassification3"
                render={({ onChange, value }) => (
                  <SearchBox
                    className={classes.majorClassificationSearchInput}
                    disableClearable={false}
                    noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                    placeholder={t('analysis:rerun-comp-search-modal.search_classifications')}
                    error={Boolean((errors as any).majorClassification3)}
                    value={value}
                    options={majorClassificationOptions}
                    getOptionLabel={(majorClasifictaionOption: string) => {
                      return majorClasifictaionOption ? t(`analysis:${majorClasifictaionOption}`) : '';
                    }}
                    onChange={(_, data) => {
                      onChange(data);
                      setMajorClassification3(data);
                      handleRecalculateProjectCounts();
                    }}
                  />
                )}
                control={control}
                defaultValue={defaultValues.majorClassification3}
                rules={{ validate: hasAtLeastOneMajorClassificationValidation }}
                error={Boolean((errors as any).majorClassification3)}
              />
            </Box>
            <ErrorMessage
              error={Boolean((errors as any).majorClassification3)}
              message={t('errors:error_message.at_least_one_major_classification_required')}
              className={classes.validationErrors}
            />
          </Card>
        </Box>
      ) : null}
    </Box>
  );
};

export default RerunCompSearchServiceProvider;
