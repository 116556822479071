export interface TestedPartyDistributorProps {
  toggleDistributor: (isValueAddedDistributor: boolean) => void;
  displayedSicIndusties: IndustrialCodeValue[];
  currentSelectedSic: IndustrialCodeValue | null;
  isValueAddedDistributor: boolean;
  onSelecteSICIndustry: (event: any, selectedSICIndustry: IndustrialCodeValue) => void;
}

export interface Distributor {
  characterizationId: number;
  description: string;
  characterizationFunctionsPerformed: characterizationFunctionsPerformed[];
  typeOfPrimaryFunctionOption: { typeOfPrimaryFunctionOptionId: number; name: string };
  codeType: { codeTypeId: number; name: string };
  industrialCodeValue: IndustrialCodeValue;
}

export interface characterizationFunctionsPerformed {
  state: boolean;
  functionPerformed: { functionPerformedId: number; name: string; group: string };
}

export interface IndustrialCodeValue {
  industrialCodeValueId: number;
  name: string;
  value: number;
}

export interface sicType {
  typeOfPrimaryFunctionOptionId: number;
  name: string;
  codeTypes: codeType[];
}

export interface codeType {
  codeTypeId: number;
  name: string;
  subtypes: subType[];
}

export interface subType {
  industrialCodeValueId: number;
  name: string;
  value: number;
}

export enum CharacterizationTypeEnum {
  FunctionsPerformed = 1,
  TypeOfOption,
  Description
}

export enum TypeOfPrimaryFunctionOptionEnum {
  Retail = 1,
  Wholesaler,
  ManufacturerOption,
  ServiceProviderOption,
  IntegratedOption
}

export enum CodeType {
  SIC = 3,
  NAICS
}
