import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography, Chip, Theme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ReportVersionStatus } from '@xbs/xbs-enums';
import { format } from 'date-fns';
import { ActionsDropDown, StatusDropDown } from '.';
import { StatusButton } from './StatusButton';
import { Circle } from '../../../components/Circle';
import { CustomTooltip } from '../../../components/CustomTooltip';
import reportsOnlyAccess from '../../../components/HigherOrderComponents/ReportsOnlyAccess';
import { SummaryTile } from '../../../components/SummaryTile';
import appColors from '../../../styles/appColors';
import tokens from '../../../styles/designTokens';
import { body1, body2, body4, captionOverline } from '../../../styles/typography';
import { DownloadIcon, NewVersionIcon } from '../../../svgs';
import { hasReportsOnlyAccess, canDownloadReport } from '../../../utils';
import { ReportStatus, ReportTileProps } from '../LocalReports.proptype';

interface StyleProps {
  tileState: ReportTileState;
  reportStatus: ReportStatus;
}

export type ReportTileState = 'isGenerating' | 'isUploading' | 'generationFailed' | 'uploadFailed' | 'complete';

const getTileState = (isUploaded: boolean, generationStatus: number): ReportTileState => {
  const status = ReportVersionStatus.ById[generationStatus].Name;

  if (isUploaded) {
    if (status === 'Pending' || status === 'Converting') {
      return 'isUploading';
    }

    if (status === 'Failed') {
      return 'uploadFailed';
    }
  } else {
    if (status === 'Pending' || status === 'Converting') {
      return 'isGenerating';
    }

    if (status === 'Failed') {
      return 'generationFailed';
    }
  }

  if (status === 'Uploaded' || status === 'Generated') {
    return 'complete';
  }

  throw new Error(`Unrecognized report generation status: ${status as string}`);
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    width: '100%'
  },
  versionInfo: {
    letterSpacing: '0.6px',
    marginLeft: '1rem'
  },
  statusChip: {
    color: (props) => (props.tileState === 'isGenerating' ? '#c5c5c9' : undefined)
  },
  circle: {
    paddingRight: '1.468rem'
  },
  iconsContainer: {
    display: 'flex',
    marginLeft: '1.56rem',
    marginRight: '1.68rem',
    width: '6.75rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      cursor: 'pointer'
    }
  },
  body4,
  displayFlex: {
    display: 'flex'
  },
  partnersName: {
    ...body2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block'
  },
  partnersNameSingle: {
    ...body2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'inline-block'
  },
  partnersNumbers: {
    ...body1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'inline-block'
  },
  partnersChip: {
    ...captionOverline,
    color: tokens.core1,
    background: appColors.reports.draft.fill,
    marginLeft: '1rem',
    minWidth: '1.5626rem',
    height: '1.2rem',
    '& .MuiChip-label': {
      padding: '0'
    }
  },
  language: {
    ...captionOverline,
    lineHeight: '1.25rem',
    textTransform: 'uppercase'
  },
  version: {
    color: appColors.reports.final.fill,
    marginRight: '0.6rem'
  },
  '@keyframes myEffect': {
    '0%': {
      color: 'rgba(60, 42, 142, 1.0)'
    },
    '50%': {
      color: 'rgba(60, 42, 142, 0.3)'
    },
    '100%': {
      color: 'rgba(60, 42, 142, 1.0)'
    }
  },
  generationStatusChip: {
    ...captionOverline,
    height: '2.75rem',
    textTransform: 'uppercase',
    width: '10rem',
    '&.processing': {
      color: appColors.reports.published.text,
      background: appColors.reports.published.fill,
      animation: `$myEffect 3000ms infinite ease-in-out`
    },
    '&.failed': {
      color: tokens.error100,
      background: tokens.error15
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  iconWrapper: {
    display: 'flex'
  },
  noClick: {
    color: '#c5c5c9',
    cursor: 'default',
    pointerEvents: 'none'
  }
}));

export const ReportTile = ({
  status,
  name,
  primaryEntities,
  tradingPartners,
  transactions,
  version,
  language,
  date,
  generationStatus,
  isUploaded,
  startDeleteReport,
  onDownload,
  onManageVersions,
  onNewReportVersion,
  quickGenerate,
  openReportModal,
  onUpdateReportStatus
}: ReportTileProps) => {
  const tileState = getTileState(isUploaded, generationStatus);
  const [reportStatus, setReportStatus] = useState<ReportStatus>(status);

  const classes = useStyles({ tileState, reportStatus });
  const { t } = useTranslation();
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null);

  const colorMap = {
    draft: {
      ...appColors.reports.draft
    },
    review: {
      ...appColors.reports.review
    },
    published: {
      ...appColors.reports.published
    },
    final: {
      ...appColors.reports.final,
      text: appColors.reports.final.fill
    }
  };

  const displayFlex = () => {
    if (primaryEntities.length > 1) {
      return (
        <div className={classes.displayFlex}>
          <Typography className={classes.partnersName}>{primaryEntities[0].code}</Typography>
          <Chip className={classes.partnersChip} label={`+${primaryEntities.length - 1}`} />
        </div>
      );
    }

    return (
      <div className={classes.displayFlex}>
        <Typography className={classes.partnersNameSingle}>{primaryEntities[0].code}</Typography>
      </div>
    );
  };

  const formattedDate = () => {
    const options = 'dd MMM yyyy hh:mm:ssaa';
    return format(new Date(date), options).toUpperCase();
  };

  const generationStatusChip = () => {
    let label = '';
    let statusClass = '';

    if (tileState === 'isGenerating') {
      label = t('reports:generating');
      statusClass = 'processing';
    } else if (tileState === 'isUploading') {
      label = t('reports:uploading');
      statusClass = 'processing';
    } else if (tileState === 'generationFailed') {
      label = t('reports:generation-failed');
      statusClass = 'failed';
    } else if (tileState === 'uploadFailed') {
      label = t('reports:upload-failed');
      statusClass = 'failed';
    }

    return <Chip className={classes.generationStatusChip + ' ' + statusClass} label={label} />;
  };

  const versionInfo = () => {
    return (
      <>
        <div className={classes.displayFlex}>
          <Typography className={`${classes.version} ${classes.language}`}>{`${t('reports:version')}: ${String(
            version
          )}`}</Typography>
          <Typography className={classes.language + ' lightGreyTone'}>{language}</Typography>
        </div>
        <Typography className={classes.language + ' lightGreyTone'}>{formattedDate()}</Typography>
      </>
    );
  };

  const handleActionsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const handleStatusOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleStatusChange = (newStatus: ReportStatus) => {
    // TODO: Just to suppress linter complaints. Should be removed when real implementation is set
    setStatusAnchorEl(null);
    setReportStatus(newStatus);
    onUpdateReportStatus(newStatus);
  };

  const isProcessing = tileState === 'isGenerating' || tileState === 'isUploading';
  const isFailed = tileState === 'generationFailed' || tileState === 'uploadFailed';

  return (
    <Box className={classes.root}>
      <SummaryTile
        data={[
          <div key="circle" className={classes.circle}>
            <Circle color={colorMap[status].fill} borderColor={colorMap[status].border} />
          </div>,
          <div key="reportName" className={classes.reportInfo}>
            <Typography className={classes.body4}>{name}</Typography>
          </div>,
          <div key="partnersInfo" className={classes.partnersInfo}>
            {displayFlex()}
            <div className={classes.displayFlex}>
              <Typography className={classes.partnersNumbers + ' lightGreyTone'}>
                {tradingPartners.length} {t('reports:label-trading-partners')}&emsp;{transactions.length}&nbsp;
                {t('reports:label-transactions')}
              </Typography>
            </div>
          </div>,

          <div key="versionInfo" className={classes.versionInfo}>
            {isProcessing || isFailed ? generationStatusChip() : versionInfo()}
          </div>,

          <div key="status" className={classes.statusChip}>
            <StatusButton status={reportStatus} isGenerating={isProcessing || isFailed} onClick={handleStatusOpen} />
            <StatusDropDown
              anchorEl={statusAnchorEl}
              value={reportStatus}
              disabled={hasReportsOnlyAccess()}
              onChange={handleStatusChange}
              onClose={handleStatusClose}
            />
          </div>,
          <div key="icons" className={classes.iconsContainer}>
            {reportsOnlyAccess(
              <Box className={classes.iconWrapper + (isProcessing ? ' ' + classes.noClick : '')}>
                <CustomTooltip title={t('reports:label-quick-generate')!}>
                  <NewVersionIcon data-testid="quickGenerateBtn" onClick={quickGenerate} />
                </CustomTooltip>
              </Box>
            )}
            {canDownloadReport(reportStatus) && (
              <Box className={classes.iconWrapper + (isProcessing || isFailed ? ' ' + classes.noClick : '')}>
                <CustomTooltip title={t('reports:action-download')!}>
                  <DownloadIcon onClick={onDownload} />
                </CustomTooltip>
              </Box>
            )}
            <Box
              className={classes.iconWrapper + (isProcessing ? ' ' + classes.noClick : '')}
              onClick={handleActionsOpen}
            >
              <MoreHorizIcon />
            </Box>
            <ActionsDropDown
              anchorEl={actionsAnchorEl}
              isFailedReport={isFailed}
              status={reportStatus}
              startDeleteReport={startDeleteReport}
              openReportModal={openReportModal}
              onClose={handleActionsClose}
              onNewReportVersion={onNewReportVersion}
              onDownload={onDownload}
              onManageVersions={onManageVersions}
            />
          </div>
        ]}
        altWidths={[{ index: 0, width: '5em' }]}
      />
    </Box>
  );
};
