export const roundValue = (value: number, exp: number) => {
  if (typeof exp === 'undefined' || Number(exp) === 0) {
    return Math.round(value);
  }

  value = Number(value);
  exp = Number(exp);
  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return Number.NaN;
  }

  const convertedIntegerValue = Math.round(Number(`${value}e${-exp}`));
  const backToFloatValue = Number(`${convertedIntegerValue}e${exp}`);

  return backToFloatValue;
};
