import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, List, makeStyles } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReportVersionStatus } from '@xbs/xbs-enums';
import { DeleteReportConfirmation } from './DeleteReportConfirmation';
import { ReportTile } from './ReportTile';
import { LocalReportData } from '../../../models';
import { FinancialDataValueEnteredInEnum } from '../../../models/financial.interface';
import {
  actions as localReportsActions,
  deleteReport,
  downloadReportInstance,
  fetchLanguages,
  getSignedUrlForUploadedReport,
  fetchReportInstancesForJurisdiction,
  updateReportActiveVersion,
  uploadReportToSignedUrl
} from '../../../redux/localReports';
import { selectCountries, selectLanguageList, selectUPECurrency, selectWorkingContainer } from '../../../selectors';
import { sortData } from '../../../services/filtering';
import { AppDispatch } from '../../../store';
import { LocalReportCreateNewVersion } from '../../LocalReportCreateVersionModal';
import { UploadReportModal } from '../../LocalReportsActions/UploadReportModal/UploadReportModal';
import { UploadReportPayloadProps } from '../../LocalReportsActions/UploadReportModal/UploadReportModal.proptype';
import { getReportName } from '../../Reports/ReportsUtilities';
import { useLocalReportsPolling } from '../hooks/useLocalReportsPolling';
import { ReportStatus } from '../LocalReports.proptype';

interface LocalReportsListProps {
  reportsData: LocalReportData[];
  onManageVersions: (version: number) => void;
  quickGenerate: (report: LocalReportData) => void;
  onUpdateReportStatus: (status: string, report: LocalReportData) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
    padding: '2.25rem 2.5625rem 2.25rem 2.1875rem',
    width: '100%',
    '& .MuiListItem-root': {
      '&:first-child': {
        padding: 0
      },
      paddingBottom: 0,
      paddingTop: '0.75rem'
    }
  }
}));

export const LocalReportsList = ({
  reportsData,
  onManageVersions,
  quickGenerate,
  onUpdateReportStatus
}: LocalReportsListProps) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { pollAndUpdate } = useLocalReportsPolling();
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
  const [isUploadReportModalOpen, setIsUploadReportModalOpen] = useState(false);
  const [isCreateReportVersionModalOpen, setIsCreateReportVersionModalOpen] = useState(false);
  const [isUploadingReport, setIsUploadingReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState<
    LocalReportData | { name: string; internalLocalfileReportId: number }
  >({ name: '', internalLocalfileReportId: -1 });
  const container = useSelector(selectWorkingContainer);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const countries = useSelector(selectCountries);
  const jurisdictionLanguages = useSelector(selectLanguageList);
  const upeCurrency = useSelector(selectUPECurrency);
  const currentContainer = useSelector(selectWorkingContainer);
  const defaultCurrency = countries.find((country) => country.countryId === jurisdictionId)?.defaultCurrency;
  const jurisdictionName = countries.find((country) => country.countryId === jurisdictionId)?.name;

  const currencyOptions = [
    { id: FinancialDataValueEnteredInEnum.MNE, name: FinancialDataValueEnteredInEnum[1] },
    { id: FinancialDataValueEnteredInEnum.Entity, name: FinancialDataValueEnteredInEnum[2] }
  ];

  useEffect(() => {
    void dispatch(fetchLanguages({ jurisdictionId, taxYear: container?.taxYear! }));
  }, [container?.taxYear, dispatch, jurisdictionId]);

  const startDeleteReport = (report: LocalReportData) => {
    setIsDeleteReportModalOpen(true);
    setSelectedReport(report);
  };

  const openReportModal = (report: LocalReportData) => {
    setIsUploadReportModalOpen(true);
    setSelectedReport(report);
  };

  const onNewReportVersion = (report: LocalReportData) => {
    setIsCreateReportVersionModalOpen(true);
    setSelectedReport(report);
  };

  const executeDeleteReport = async () => {
    await dispatch(deleteReport({ reportId: selectedReport.internalLocalfileReportId }));
    await dispatch(
      fetchReportInstancesForJurisdiction({
        jurisdictionId,
        isActive: true,
        containerId: currentContainer?.containerId
      })
    );
    setSelectedReport({ name: '', internalLocalfileReportId: -1 });
  };

  const executeUploadReport = async (
    uploadReportPayload: UploadReportPayloadProps,
    reportFile: File,
    report: LocalReportData
  ) => {
    setIsUploadingReport(true);
    const uploadedReportInfo = unwrapResult(await dispatch(getSignedUrlForUploadedReport(uploadReportPayload)));
    const internalLocalfileReportInstanceId = uploadedReportInfo?.data?.internalLocalfileReportInstanceId;
    const awsSignedUrlPath = uploadedReportInfo?.data?.url;
    void dispatch(uploadReportToSignedUrl({ awsSignedUrlPath, reportFile }));

    dispatch(
      localReportsActions.replaceLocalFileReportInstanceData({
        previousInstanceId: report.internalLocalfileReportInstanceId,
        instanceData: {
          ...report,
          isUploadedReport: 1,
          status: {
            internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
            name: ReportVersionStatus.ByName.Pending.Name
          },
          createdAt: new Date().toString()
        }
      })
    );

    pollAndUpdate(
      report.name,
      Number(report.internalLocalfileReportId),
      Number(internalLocalfileReportInstanceId),
      Number(report.internalLocalfileReportInstanceId),
      container?.containerId
    );

    void dispatch(
      updateReportActiveVersion({
        reportId: report.internalLocalfileReportId,
        internalLocalfileReportInstanceId
      })
    );
    setIsUploadingReport(false);
    setIsUploadReportModalOpen(false);
  };

  const onDownload = useCallback(
    (reportToDownload: LocalReportData) => {
      if (reportToDownload && container) {
        const payload = {
          filePath: reportToDownload.binaryPath,
          name: getReportName({
            reportName: reportToDownload.name,
            reportTemplateId: reportToDownload.templateId,
            version: reportToDownload.version
          }),
          containerId: container.containerId,
          container
        };

        void dispatch(downloadReportInstance(payload));
      }
    },
    [container, dispatch]
  );

  const rows = useMemo(() => {
    const sortLocalReports = sortData(reportsData, { sortBy: 'createdAt', sortOrder: 'desc' });
    return sortLocalReports.map((report) => {
      return (
        <ReportTile
          key={report.internalLocalfileReportInstanceId}
          status={report.reportStatus ?? 'draft'}
          name={report.name}
          primaryEntities={report.primaryEntities}
          tradingPartners={report.tradingPartners}
          transactions={report.reportTransactions}
          version={report.version}
          language={t(`languageIsoInfo:${report.languageIso}`)}
          date={report.createdAt}
          generationStatus={report.status?.internalLocalfileReportInstanceStatusId}
          isUploaded={Boolean(report.isUploadedReport)}
          quickGenerate={() => {
            quickGenerate(report);
          }}
          startDeleteReport={() => {
            startDeleteReport(report);
          }}
          openReportModal={() => {
            openReportModal(report);
          }}
          onDownload={() => {
            onDownload(report);
          }}
          onManageVersions={() => {
            onManageVersions(report.internalLocalfileReportId);
          }}
          onNewReportVersion={() => {
            onNewReportVersion(report);
          }}
          onUpdateReportStatus={(newReportStatus: ReportStatus) => {
            onUpdateReportStatus(newReportStatus, report);
          }}
        />
      );
    });
  }, [reportsData, t, quickGenerate, onDownload, onManageVersions, onUpdateReportStatus]);

  const languageList = useMemo(() => {
    return jurisdictionLanguages
      .map((lang) => ({
        ...lang,
        name: t(`languageIsoInfo:${lang.isoCode}`)
      }))
      .sort((lang1, lang2) => {
        return lang1.name > lang2.name ? 1 : -1;
      });
  }, [jurisdictionLanguages, t]);

  return (
    <Box className={classes.container}>
      <List disablePadding>{rows}</List>
      <DeleteReportConfirmation
        open={isDeleteReportModalOpen}
        nameReport={selectedReport.name}
        setIsConfirmationOpen={setIsDeleteReportModalOpen}
        executeDeleteReport={executeDeleteReport}
      />
      <UploadReportModal
        open={isUploadReportModalOpen}
        currencyOptions={currencyOptions}
        languageOptions={languageList}
        executeUploadReport={executeUploadReport}
        setIsUploadReportModalOpen={setIsUploadReportModalOpen}
        reportInfo={selectedReport}
        container={container!}
        upeCurrency={upeCurrency!}
        defaultCurrency={defaultCurrency!}
        isUploadingReport={isUploadingReport}
      />
      {isCreateReportVersionModalOpen ? (
        <LocalReportCreateNewVersion
          reportInfo={selectedReport as LocalReportData}
          jurisdictionId={jurisdictionId}
          jurisdictionName={jurisdictionName ?? ''}
          isOpen={isCreateReportVersionModalOpen}
          onClose={() => {
            setIsCreateReportVersionModalOpen(false);
          }}
        />
      ) : null}
    </Box>
  );
};
