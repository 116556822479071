import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { tpiqRoutes } from './constants';
import { Layout } from '../../components';
import { getAuthInfo, decodeTokens, handleRoleRedirect } from '../../utils';
import { Header } from '../Header';
import { SideMenu } from '../SideMenu';

const Router = () => {
  const { authToken } = getAuthInfo();
  const decodedToken = decodeTokens(authToken);
  const userRole = decodedToken?.role;
  const roleFilteredRoutes = tpiqRoutes.filter((route) => {
    const shouldIncludeRoute = userRole ? route.roles.includes(userRole) : true;
    return shouldIncludeRoute;
  });
  const routesToDisplay = roleFilteredRoutes.length === 0 ? tpiqRoutes : roleFilteredRoutes;

  return (
    <BrowserRouter basename="/newexperience">
      <Layout
        header={<Header />}
        aside={<SideMenu />}
        main={
          <Switch>
            {routesToDisplay.map(({ path, component }) => (
              <Route key={path} path={path} component={component} />
            ))}
            <Route
              path="/admin"
              component={() => {
                handleRoleRedirect(`${window.location.origin}/legacy/admin`);
                return null;
              }}
            />
            <Redirect to={routesToDisplay[0].path} />
          </Switch>
        }
      />
    </BrowserRouter>
  );
};

export default Router;
