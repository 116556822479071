import { Container, Entity } from '.';
import { PrimaryFunction } from './primaryFunction.interface';

export interface JurisdictionStatusCountry {
  countryId: number;
  isoCode: string;
  name: string;
}

export interface JurisdictionStatus {
  status: number;
  country: JurisdictionStatusCountry;
}

export enum TransactionValueEnteredInEnum {
  MNE = 1,
  Source = 2,
  Destination = 3
}

// value are type ids, while keys are type names
export enum TransactionTypeEnum {
  TangibleGoods = 1,
  IntangibleGoods,
  Services,
  Loans
}

export interface Transaction {
  transactionId: number;
  transactionType: TransactionType;
  transferDateDay: number;
  transferDateMonth: number;
  transferDateYear: number;
  transferDate: number;
  units: number;
  value: number;
  valueEnteredIn: TransactionValueEnteredInEnum;
  container: Container;
  description: string;
  identifier: string;
  legalEntityTransactions: LegalEntityTransaction[];
  partyRole: PartyRole;
  propertyTransferred: string;
  pbas: Array<{ pbaId: number; sourceCompleted: boolean; destinationCompleted: boolean }>;
  tbas: Array<{ tbaId: number; sourceCompleted: boolean; destinationCompleted: boolean }>;
  study?: TransactionStudy;
}

export interface TransactionType {
  transactionTypeId: number;
  name: string;
}

export interface LegalEntityTransaction {
  details: string;
  entity: Entity;
  completed: boolean;
  isSource: boolean;
}

export interface PartyRole {
  name: string;
  partyRoleId: number;
}

export interface TransactionResponse {
  transactions: Transaction[];
}

export interface TransactionEvaluations {
  methods: MethodEvaluation[];
  pbas: ProfitBasedEvaluation[];
  tbas: TransactionBasedEvaluation[];
}

export interface MethodEvaluation {
  methodName: string;
  evaluationMethodId: number;
  isCompleted: boolean;
  // unfortunately, unused TBAs and PBAs don't have their own storage for discussion points.
  // for the time being, we store discussion points at method level
  srcDiscussion: string | null;
  destDiscussion: string | null;
  srcUsed?: boolean;
  destUsed?: boolean;
  notAppliedEvaluationMethodId?: number | null;
}

interface MethodEvaluationAnalysis {
  srcDiscussion: string;
  destDiscussion: string;
  isCompleted: boolean;
  sourceEvaluationType?: number;
  destinationEvaluationType?: number;
}

export interface TransactionBasedMethodEvaluation extends MethodEvaluationAnalysis {
  tbaMethodEvaluationId?: number;
}

export interface ProfitBasedMethodEvaluation extends MethodEvaluationAnalysis {
  pbaMethodEvaluationId?: number;
}

export interface TransactionBasedEvaluation {
  tba: {
    tbaId: number;
    name: string;
    exclude: boolean;
    description: string;
    tbaEvaluationMethod: { tbaEvaluationMethodId: number };
    jurisdictionStatuses?: JurisdictionStatus[];
  };
  methodEvaluation: TransactionBasedMethodEvaluation | null;
}

export interface ProfitBasedEvaluation {
  pba: {
    pbaId: number;
    name: string;
    exclude: boolean;
    description: string;
    method: number;
    jurisdictionStatuses?: JurisdictionStatus[];
    testedParty?: {
      testedPartyId: number;
      name: string;
      justification: string;
      primaryFunction: PrimaryFunction;
      segmentType?: number;
    };
  };
  methodEvaluations: ProfitBasedMethodEvaluation[] | null;
}

export interface TransactionServiceEvaluation {
  serviceTransactionEvaluationId: number;
  destinationEvaluation: string | null;
  sourceEvaluation: string | null;
  notes: string | null;
  isSourceComplete: boolean;
  isDestinationComplete: boolean;
}

export interface AssociatedTransactionsPBA {
  transactionId: number;
  legalEntityTransactions: LegalEntityTransaction[];
  identifier: number | string;
  transactionType: TransactionType;
  propertyTransferred: string;
  transferDate: number;
  value: number;
}

export interface TransactionStudy {
  studyId: number;
  name: string;
  description: string;
}
