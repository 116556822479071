import { useMemo, useState } from 'react';
import { FinancialTypes } from '@xbs/xbs-enums';
import { FinancialTableConnectorProps, TableBodyDataProps } from './FinancialTable.proptype';
import { FinancialConversion, FinancialData } from '../../models';
import { hasEditAccess } from '../../utils/hasAccess';

const Connector = ({
  component: Component,
  tableData,
  financialConversions,
  financialReadOnlyFieldIds,
  financialFieldIds,
  columnYears,
  completionByYear,
  hasFooter,
  title,
  headerInfo,
  currencyOptions,
  namespace,
  isFieldValueValid,
  isColumnValid,
  calculateFieldValues,
  saveData,
  onSetCompletion
}: FinancialTableConnectorProps) => {
  const [tableBodyData, setData] = useState<TableBodyDataProps[]>([]);
  const [isNotDefaultUPECurrency, setIsNotDefaultUPECurrency] = useState<boolean>(false);

  const getCustomTableData = (tableData: FinancialData[], financialValues: number[], years: number[]) => {
    const financialData: TableBodyDataProps[] = [];
    financialValues.forEach((financial) => {
      const financialDataObj: TableBodyDataProps = {};
      const financialType = FinancialTypes.ById[financial].Name;
      financialDataObj.name = financialType;
      financialDataObj.id = FinancialTypes.ById[financial].Id;
      years.forEach((year) => {
        const financialDataByType = tableData.find(
          (data) => data.taxYear === year && data.financialType.name === financialType
        );
        financialDataObj[year] = {
          value: financialDataByType?.value ?? '',
          data: financialDataByType ?? null
        };
      });
      financialData.push(financialDataObj);
    });
    return financialData;
  };

  useMemo(() => {
    const modifyData = (
      tableData: FinancialData[] | null,
      columnYears: number[],
      financialFieldIds: number[],
      financialReadOnlyFieldIds: number[]
    ) => {
      let tableBodyData: TableBodyDataProps[] = [];
      if (tableData && columnYears.length > 0) {
        tableBodyData = getCustomTableData(tableData, financialFieldIds, columnYears);
        tableBodyData.forEach((data) => {
          columnYears.forEach((year) => {
            if (financialReadOnlyFieldIds.includes(data.id)) {
              data[year].isAllowed = false;
            }

            if (completionByYear?.[year]) {
              data[year].isAllowed = false;
            }
          });
        });
      }

      return tableBodyData;
    };

    if (tableData) {
      const modifiedData = modifyData(tableData, columnYears, financialFieldIds, financialReadOnlyFieldIds);
      setData(modifiedData);
    }
  }, [columnYears, financialFieldIds, financialReadOnlyFieldIds, tableData, completionByYear]);

  const validateRequiredCells = () => {
    let validationFailed = false;
    const tempTableData = [...tableBodyData];

    columnYears.forEach((year: number) => {
      if (!isColumnValid(tempTableData, String(year))) {
        tempTableData.forEach((data) => {
          if (!isFieldValueValid(data, String(year))) {
            data[year].showRedBorder = true;
            validationFailed = true;
          }
        });
      }
    });
    setData(tempTableData);

    return !validationFailed;
  };

  const handleChangeCurrency = (index: number) => {
    const isNotUpe = Boolean(index);
    setIsNotDefaultUPECurrency(isNotUpe);
    const tempTableData = [...tableBodyData];
    if (financialConversions) {
      tempTableData.forEach((cell) => {
        columnYears.forEach((year) => {
          const conversionValueForYear = financialConversions.find((conversionYear) => conversionYear.taxYear === year);
          if (conversionValueForYear) {
            let convertedValue = conversionValueForYear.conversionValue;
            if (index === 0) {
              convertedValue = 1 / conversionValueForYear.conversionValue;
            }

            if (cell[year].value) {
              cell[year].value = Number((cell[year].value / convertedValue).toFixed(2));
            }
          } else {
            cell[year].notAvailable = isNotUpe;
            cell[year].showRedBorder = false;
          }
        });
      });
    }

    return tempTableData;
  };

  const onCurrencyChange = (index: number) => {
    const updateTableData = handleChangeCurrency(index);
    setData(updateTableData);
  };

  const handleUpdate = (index: any, year: any, changedValue: number | string): void => {
    const tempTableData = [...tableBodyData];
    tempTableData[index][year].value = changedValue;

    tempTableData[index][year].showRedBorder = false;

    if (isColumnValid(tempTableData, String(year))) {
      tempTableData.forEach((data) => {
        data[year].showRedBorder = false;
      });
    }

    const updatedTableData = calculateFieldValues(tempTableData, year);

    setData(updatedTableData);
  };

  const onSaveData = () => {
    const canSave = validateRequiredCells();
    let conversionValueForYear: FinancialConversion | null;
    if (canSave) {
      if (isNotDefaultUPECurrency) {
        const tableBodyDataToSave: TableBodyDataProps[] = [];
        tableBodyData.forEach((row) => {
          const obj: any = {};
          columnYears.forEach((year) => {
            if (financialConversions) {
              conversionValueForYear =
                financialConversions.find((conversionYear) => conversionYear.taxYear === year) ?? null;
            }

            if (conversionValueForYear) {
              obj[year] = {
                value: row[year].value
                  ? Number(row[year].value / (1 / (conversionValueForYear?.conversionValue ?? 0))).toFixed(2)
                  : row[year].value,
                data: row[year].data
              };
              obj.name = row.name;
              obj.id = row.id;
            }
          });
          tableBodyDataToSave.push(obj);
        });
        saveData(tableBodyDataToSave);
      } else {
        saveData(tableBodyData);
      }
    }

    return canSave;
  };

  const handleSetCompletion = (taxYear: number, isCompleted: boolean) => {
    if (onSaveData()) {
      onSetCompletion(taxYear, isCompleted);
    }
  };

  return (
    <Component
      hasFooter={hasEditAccess() && hasFooter}
      tableData={tableBodyData}
      columnYears={columnYears}
      saveData={saveData}
      title={title}
      headerInfo={headerInfo}
      namespace={namespace}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      handleUpdate={handleUpdate}
      completionByYear={completionByYear}
      onSaveData={onSaveData}
      onCurrencyChange={onCurrencyChange}
      onSetCompletion={handleSetCompletion}
    />
  );
};

Connector.defaultProps = {
  isFieldValueValid: () => true,
  isColumnValid: () => true,
  financialReadOnlyFieldIds: [],
  headerInfo: [],
  calculateFieldValues: (tempTableData: TableBodyDataProps[]) => tempTableData
};

export default Connector;
