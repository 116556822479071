import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, makeStyles } from '@material-ui/core';
import { Block as BlockIcon, VisibilityOutlined as EyeIcon } from '@material-ui/icons';
import { BulkRejectionsProps } from './BulkRejections.proptype';
import { AppliedBulkRejections } from './components/AppliedBulkRejections';
import { SplitView } from '../../../../components/SplitView';
import designTokens from '../../../../styles/designTokens';
import { mediumFont } from '../../../../styles/fonts';
import { body2, captionOverline } from '../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../utils/formatPliDisplayValue';
import { CompanyTable } from '../CompanyTable';

const useStyles = makeStyles(() => ({
  boxSection: {
    height: '100%'
  },
  chipsWrapper: {
    display: 'flex'
  },
  rejectedChip: {
    ...captionOverline,
    border: '0.063rem solid',
    height: '2.6rem',
    width: '19.688rem',
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    letterSpacing: '0.038rem',
    padding: '0 1rem',
    boxSizing: 'border-box',
    '& .MuiChip-icon': {
      width: '1.125rem',
      color: designTokens.product100
    }
  },
  primaryChip: {
    ...captionOverline,
    border: '0.063rem solid',
    height: '2.6rem',
    width: '6rem',
    marginLeft: '0.5rem',
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  chipSecondary: {
    ...captionOverline,
    marginLeft: '0.5rem',
    width: '6rem',
    height: '2.6rem',
    backgroundColor: designTokens.neutral70,
    color: designTokens.core1,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  },
  iconWrapper: {
    marginRight: '0.25rem',
    '& svg': {
      width: '0.75rem',
      height: '0.75rem'
    }
  },
  blockedCompany: {
    '& .MuiTableCell-root': {
      color: designTokens.core2,
      backgroundColor: designTokens.neutral70
    },
    '& .MuiTableCell-body:first-child': {
      color: designTokens.core2,
      backgroundColor: designTokens.neutral70
    }
  },
  filterCompany: {
    '& .MuiTableCell-root': {
      color: designTokens.core1,
      backgroundColor: designTokens.purpleLight3
    },
    '& .MuiTableCell-body:first-child': {
      color: designTokens.core1,
      backgroundColor: designTokens.purpleLight3,
      '& svg': {
        color: designTokens.purpleLight2
      }
    }
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  },
  captionOverline,
  body2
}));

export const BulkRejectionsTab = ({
  appliedBulkRejections,
  nonAppliedBulkRejections,
  companies,
  columns,
  onApplyBulkRejection,
  applyBulkRejectionsIsLoading,
  pliFormat,
  pliId
}: BulkRejectionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filteringBulkRejection, setFilteringBulkRejection] = useState<string>();
  const bulkRejectedComparables = (companies ?? []).filter((comparable: any) => comparable.isBulkRejected === 1);

  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const displaybleComparables: Array<Record<string, HTMLElement | number>> = useMemo(() => {
    const resultCompanies = filteringBulkRejection
      ? (companies ?? []).filter((comparable: any) => comparable.rejectionType === filteringBulkRejection)
      : companies ?? [];
    return resultCompanies.map((comparable: any) => {
      const displayableComp: any = {};
      Object.keys(comparable).forEach((key) => {
        if (typeof comparable[key] === 'number' && key !== 'sourceId') {
          displayableComp[key] = formatPliDisplayValue(formatType, roundingValue, comparable[key]);
        } else if (typeof comparable[key] === 'string') {
          if (comparable.isBulkRejected === 1) {
            displayableComp[key] = (
              <span className={classes.truncate} title={comparable[key]} data-value={comparable[key]}>
                <span className={classes.iconWrapper}>
                  <BlockIcon />
                </span>
                <span>{comparable[key]}</span>
              </span>
            );
            displayableComp.className = filteringBulkRejection ? classes.filterCompany : classes.blockedCompany;
          } else {
            displayableComp[key] = (
              <span className={classes.truncate} title={comparable[key]}>
                {comparable[key]}
              </span>
            );
          }
        }
      });
      return displayableComp;
    });
  }, [classes, companies, filteringBulkRejection, formatType, roundingValue]);

  return (
    <SplitView
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            companies={displaybleComparables}
            columns={columns}
            chips={
              <div className={classes.chipsWrapper}>
                {filteringBulkRejection ? (
                  <Chip
                    size="medium"
                    icon={<EyeIcon />}
                    label={t(`analysis:${t(`analysis:${filteringBulkRejection}`)}`).toUpperCase()}
                    title={t(`analysis:${t(`analysis:${filteringBulkRejection}`)}`)}
                    className={classes.rejectedChip}
                  />
                ) : (
                  <>
                    <Chip
                      size="medium"
                      label={
                        <>
                          <div className={classes.captionOverline}>
                            {t('analysis:comparable-range-total').toUpperCase()}
                          </div>
                          <div className={classes.body2}>{displaybleComparables.length}</div>
                        </>
                      }
                      className={classes.primaryChip}
                    />
                    <Chip
                      className={classes.chipSecondary}
                      size="medium"
                      label={
                        <>
                          <div className={classes.captionOverline}>
                            {t('analysis:comparable-range-rejected').toUpperCase()}
                          </div>
                          <div className={classes.body2}>{bulkRejectedComparables.length}</div>
                        </>
                      }
                    />
                    <Chip
                      size="medium"
                      className={classes.chipSecondary}
                      label={
                        <>
                          <div className={classes.captionOverline}>
                            {t('analysis:comparable-range-remaining').toUpperCase()}
                          </div>
                          <div className={classes.body2}>
                            {displaybleComparables.length - bulkRejectedComparables.length}
                          </div>
                        </>
                      }
                    />
                  </>
                )}
              </div>
            }
          />
        </div>
      }
      right={
        <AppliedBulkRejections
          appliedBulkRejections={appliedBulkRejections}
          nonAppliedBulkRejections={nonAppliedBulkRejections}
          applyBulkRejectionsIsLoading={applyBulkRejectionsIsLoading}
          onApplyFilterBulkRejection={(rejectedType) => {
            setFilteringBulkRejection(rejectedType);
          }}
          onApplyBulkRejection={onApplyBulkRejection}
        />
      }
      className={classes.boxSection}
    />
  );
};
