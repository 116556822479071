import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectorProps } from './LocalReportVersionsModal.prototypes';
import { ConfirmationModal } from '../../components';
import {
  fetchAllInternalLocalFileReportVersions,
  actions as localReportsActions,
  updateReportActiveVersion,
  deleteReportVersion,
  downloadReportInstance,
  deleteReport
} from '../../redux/localReports';
import { selectReportVersions, selectWorkingContainer } from '../../selectors';
import { TrashCanIcon } from '../../svgs';

const Connector = ({ component: Component, reportId, reportName, onClose }: ConnectorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reportVersions = useSelector(selectReportVersions);
  const container = useSelector(selectWorkingContainer);
  const [showDeleteReportVersionModal, setShowDeleteReportVersionModal] = useState(false);
  const [versionIdToDelete, setVersionIdToDelete] = useState(-1);
  const [requireReloadOnExit, setRequireReloadOnExit] = useState(false);

  useEffect(() => {
    dispatch(localReportsActions.resetReportVersionsData());
    dispatch(fetchAllInternalLocalFileReportVersions(reportId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActiveVersion = (versionId: number) => {
    const reportToUpdate = reportVersions?.find((version) => version.version === versionId);
    if (reportToUpdate) {
      const { internalLocalfileReportInstanceId } = reportToUpdate;
      dispatch(
        updateReportActiveVersion({
          reportId,
          internalLocalfileReportInstanceId
        })
      );
      setRequireReloadOnExit(true);
    }
  };

  const downloadReportVersion = (versionId: number) => {
    const reportToDownload = reportVersions?.find((version) => version.version === versionId);
    if (reportToDownload && container) {
      const payload = {
        filePath: reportToDownload.binaryPath,
        name: reportName,
        containerId: container.containerId,
        container
      };

      dispatch(downloadReportInstance(payload));
    }
  };

  const startDeleteVersion = (versionId: number) => {
    if (reportVersions && reportVersions.length > 1) {
      const versionToDelete = reportVersions?.find((version) => version.version === versionId);
      if (versionToDelete?.isActive) {
        let versionToActivate: any;
        reportVersions.forEach((version) => {
          if (
            version.version !== versionToDelete.version &&
            (versionToActivate === undefined || version.createdAt > versionToActivate.createdAt)
          ) {
            versionToActivate = version;
          }
        });

        if (versionToActivate) {
          dispatch(
            updateReportActiveVersion({
              reportId,
              internalLocalfileReportInstanceId: versionToActivate.internalLocalfileReportInstanceId
            })
          );
          setRequireReloadOnExit(true);
        }
      }

      executeDelete(versionId);
    } else {
      setVersionIdToDelete(versionId);
      setShowDeleteReportVersionModal(true);
    }
  };

  const confirmDeleteLastVersion = (confirmed: boolean) => {
    setShowDeleteReportVersionModal(false);
    if (confirmed && versionIdToDelete >= 0) {
      const versionToDelete = reportVersions?.find((version) => version.version === versionIdToDelete);
      if (versionToDelete) {
        dispatch(deleteReport({ reportId }));
        onClose(true);
      }

      setVersionIdToDelete(-1);
    }
  };

  const executeDelete = (versionId: number) => {
    const versionToDelete = reportVersions?.find((version) => version.version === versionId);
    if (versionToDelete) {
      setRequireReloadOnExit(true);
      const { internalLocalfileReportInstanceId } = versionToDelete;
      dispatch(deleteReportVersion({ reportId, internalLocalfileReportInstanceId }));
    }
  };

  const resetAndClose = () => {
    onClose(requireReloadOnExit);
    setRequireReloadOnExit(false);
  };

  return (
    <>
      {reportVersions && (
        <Component
          reportVersions={reportVersions}
          reportName={reportName}
          updateActiveVersion={updateActiveVersion}
          downloadReportVersion={downloadReportVersion}
          startDeleteVersion={startDeleteVersion}
          onClose={resetAndClose}
        />
      )}

      {showDeleteReportVersionModal && (
        <ConfirmationModal
          open
          title={t('reports:title-modal-delete-report')}
          subtitle={t('analysis:title-delete', { name: reportName })}
          text={t('reports:message-delete')}
          HeaderIcon={TrashCanIcon}
          handleClose={confirmDeleteLastVersion}
        />
      )}
    </>
  );
};

export default Connector;
