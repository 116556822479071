import { Container } from '.';
import { Entity } from './entities.interface';

export enum SegmentTypeEnum {
  'Entire Entity' = 1,
  'Product Line',
  'Product Line Group',
  'Division',
  'Sub-Division',
  'Group',
  'Sub-Group',
  'Other'
}

export interface ProfitBasedAnalysis {
  pbaId: number;
  name: string;
  exclude: boolean;
  isSourceComplete: boolean;
  isDestinationComplete: boolean;
  description: string | null;
  method: number;
  testedParty?: TestedParty;
  primaryLegalEntity: Entity;
  jurisdictionStatuses?: PbaJurisdictionStatus[];
}
export interface newProfitBasedAnalysis {
  name: string;
  description: string;
  study: { studyId?: number };
  primaryLegalEntity: { entityId?: number };
  method: number;
}
export interface TabsHeaderProfitBasedAnalysis {
  tabId: number;
  name: string;
  disabled: boolean;
}

export interface TestedParty {
  testedPartyId: number;
  name: string;
  segmentType: SegmentTypeEnum;
  justification: string;
  primaryFunctionId?: number;
  otherPrimaryFunction?: string;
}

export interface PbaJurisdiction {
  compSearchId: number;
  industryCodeType: string;
  industryCodeValue: number;
  jurisdictionId: number;
  locationCode: string;
  pbaId: number;
  primaryFunctionId: number;
  searchCreated: string;
  pbaJurisdictionInfo: any;
  jurisdictionPli: any;
  jurisdictionPliFormat: any;
}

export interface PbaJurisdictionPayload {
  data: any;
  jurisdictionId: number;
}

export interface PbaJurisdictionStatus {
  jurisdictionId: number;
  jurisdictionIsoCode: string;
  status: number;
}

export interface JurisdictionDescription {
  codeRanges: string;
  compSearchId: number;
  domicileName: string;
  /* eslint-disable camelcase */
  domicile_id: number;
  fionaExclusions: number;
  industryCodeType: string;
  initialCount: number;
  jurisdictionId: number;
  jurisdictionName: string;
  location: string;
  locationTypeId: number;
  primaryFunctionId: number;
  publicPrivate: string;
  runOnDate: string;
  taxYear: string;
  majorClassifications: any[];
  maxIndustrialCodeRange: number;
  minIndustrialCodeRange: number;
  majorClassification1: string;
  majorClassification2: string;
  majorClassification3: string;
}

export interface JurisdictionOverride extends JurisdictionDescription {
  isCompPoolAllowed: boolean;
  locationId: number;
  locationTypeId: number;
  locations: any;
  majorClassifications: any;
  maxIndustrialCodeRange: number;
  minIndustrialCodeRange: number;
  numCompsInPool: number;
  primaryFunctionId: number;
  runOnDate: string;
}

export interface PbaJurisdictionPliFormat {
  compSearchId: number;
  displayFormat: number;
  jurisdictionId: number;
  pliTypeId: number;
  roundingValue: number;
}

export interface BulkRejection {
  inRangeStatus: string;
  numRejected: number;
  numRemaining: number;
  rejectionReason: string;
  rejectionType: string;
  rejectionTypeId: number;
  startingCount: number;
}

export interface PbaJurisdictionPliFormatPayload {
  data: PbaJurisdictionPliFormat[];
}

export interface RangeColumns {
  key: string;
  label: string | number;
}

export interface RangeComparables {
  data: RangeCompany[];
  columns: RangeColumns[];
}

export interface RangeParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
}

export interface BulkRejectionsParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
}

export interface ApplyUnappliedBulkRejectionParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
  rejectionTypeId: number;
  isApplying: boolean;
  containerId: number;
}

export interface ApplyUnappliedBulkRejectionResponse {
  rejectionTypeId: number;
  isApplying: boolean;
}

export interface CompSearchExecutionContext {
  jurisdictions: {
    [key: string]: {
      executionMode: number;
      jurisdictionId: number;
      compPoolOverrides?: {
        locationId: number;
        locationTypeId: number;
        industrialCodeTypeId?: number;
        minIndustrialCodeRange?: number;
        maxIndustrialCodeRange?: number;
        majorClassificationIds?: number[];
        runOnDate?: Date;
      };
    };
  };
}
export interface CompSearchPayload {
  containerId: number | undefined;
  container: Container | undefined;
  pbaId: number;
  jurisdictionId?: number;
  executionContext?: CompSearchExecutionContext;
}

export interface CompSearchRunBothPayload {
  pbaId: number;
  compSearchPayload: CompSearchPayload;
}

export interface ProjectCompPoolPayload {
  jurisdictionId: number;
  container: Container | undefined;
  executionContext?: CompSearchExecutionContext;
}

export interface CompSearchStatus {
  compSearchState: number;
  doesTestedPartyExist: number;
  doesTestedPartyHavePli: number;
  hasIndustrialCodeSicValue: number;
  hasTransactionsAttachedToPba: number;
  isTestedPartyCharacterized: number;
  pbaId: number;
}

export interface Classification {
  majorClassificationTypeId: number;
  majorClassificationName: string;
  minorClassifications: Array<{
    minorClassificationTypeId: number;
    minorClassificationName: string;
  }>;
}

export interface RangeCompany {
  [key: string]: number | string | boolean | null | undefined;
  sourceId: number;
  companyName: string;
  average: number | null;
  isShifted: boolean;
}

export interface RangeResults {
  data: Array<Record<string, string | number | undefined | null>>;
  columns: RangeColumns[];
  requestInfo: {
    compSearchId: number;
    pliTypeId: number;
  };
}

export interface RangeDiscussion {
  rangeDiscussion: string;
  jurisdiction: string;
}

export interface RangeDiscussionParams extends RangeParams {
  container: any;
  containerId: number;
  rangeDiscussion: string;
}

export interface FineTuningFinancialInfo {
  advertisingExpenses: number;
  cash: number;
  currencyCode: string;
  cashEquivalents: number;
  costOfGoodsSold: number;
  grossProfit: number;
  interestExpense: number;
  netInventory: number;
  netPayables: number;
  netReceivables: number;
  netSales: number;
  operatingAssets: number;
  operatingExpenses: number;
  operatingIncome: number;
  propertyPlantEquipment: number;
  rndExpense: number;
  taxYear: number;
  totalAssets: number;
  operatingProfit: number;
  netPpe: number;
}

export interface FineTuningPLIInfoData {
  pliAverageTypeId: string;
  pliTypeId: string;
  pliValue: number;
  shiftedTaxYear: number;
  sourceId: number;
  taxYear: number;
}

export interface FineTuningPLIInfo {
  average: FineTuningPLIInfoData[];
  yearly: FineTuningPLIInfoData[];
}
