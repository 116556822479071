import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CompSearchAnimationProps, csRunningStates } from './CompSearchAnimation.proptype';
import tokens from '../../styles/designTokens';
import { mediumFont } from '../../styles/fonts';

const useStyles = makeStyles((theme) => ({
  boxLoading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 999,
    opacity: 0.95,
    inset: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(36, 14, 58, 1) 100%)'
  },
  spinner: {
    width: theme.spacing(32),
    height: theme.spacing(32),
    position: 'relative'
  },
  centerTextContainer: {
    top: '90%',
    left: '-5%',
    position: 'absolute',
    height: '4rem',
    width: '30rem',
    background: tokens.black,
    opacity: 0.95
  },
  centerResultsContainer: {
    padding: theme.spacing(3),
    position: 'relative',
    height: '26.2rem',
    width: '40.625rem',
    background: tokens.black,
    opacity: 0.95
  },
  resultsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between'
  },
  compSearchText: {
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(1.5),
    display: 'inline-block',
    color: tokens.purple,
    fontFamily: mediumFont.fontFamily
  },
  compSearchFailedText: {
    fontSize: '1.5rem',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: mediumFont.fontFamily,
    color: theme.palette.common.white
  },
  errorIcon: {
    color: theme.palette.common.white,
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  compSearchFailedDescription: {
    fontSize: '1rem',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    fontFamily: mediumFont.fontFamily
  },
  resultsCompsearch: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-1),
    color: tokens.purpleLight1,
    fontFamily: mediumFont.fontFamily
  },
  resultsText: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    fontFamily: mediumFont.fontFamily
  },
  resultsNumber: {
    fontSize: '2rem',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-1),
    display: 'inline-block',
    color: theme.palette.common.white,
    fontFamily: mediumFont.fontFamily
  },
  continueButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  firstSearchResultsContainer: {
    display: 'inline-block',
    width: '17.5rem',
    height: ' 8.5rem',
    backgroundColor: 'rgba(60,42,142,0.58)',
    borderRadius: theme.spacing(0.5),
    border: '1px solid',
    borderColor: tokens.purpleLight2,
    padding: theme.spacing(1)
  },
  secondSearchResultsContainer: {
    display: 'inline-block',
    width: '17.5rem',
    height: '8.5rem',
    backgroundColor: 'rgba(255,255,255,0.05)',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1)
  },
  dynamicText: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
    color: theme.palette.common.white,
    fontFamily: mediumFont.fontFamily,
    textTransform: 'uppercase',
    animation: '$dynamicText 1s linear infinite'
  },
  '@keyframes spinnerBefore': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0
    },
    '100%': {
      transform: 'scale(1, 1)',
      opacity: 0.8
    }
  },
  '@keyframes spinnerAfter': {
    '0%': {
      transform: 'scale(1)',
      opacity: 0.8
    },
    '100%': {
      transform: 'scale(2)',
      opacity: 0
    }
  },
  spinnerBox1: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-4rem 0 0 -4rem',
    '&::before': {
      content: '""',
      width: '95%',
      height: '95%',
      display: 'block',
      border: `9px solid ${tokens.pink}`,
      position: 'absolute',
      top: '2.5%',
      left: '2.5%',
      borderRadius: '50%',
      boxSizing: 'border-box',
      animation: '$spinnerBefore 1s linear infinite',
      transform: 'scale(0)',
      opacity: 0
    },
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      display: 'block',
      border: `18px solid ${tokens.pink}`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      boxSizing: 'border-box',
      animation: '$spinnerBefore 1s linear infinite',
      transform: 'scale(0)',
      opacity: 0
    }
  },
  spinnerBox2: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-4rem 0 0 -4rem',
    '&::before': {
      content: '""',
      width: '95%',
      height: '95%',
      display: 'block',
      border: `9px solid ${tokens.pink}`,
      position: 'absolute',
      top: '2.5%',
      left: '2.5%',
      borderRadius: '50%',
      boxSizing: 'border-box',
      animation: '$spinnerAfter 1s linear infinite',
      animationDeplay: '1s',
      transform: 'scale(1)',
      opacity: 0.5
    },
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      display: 'block',
      border: `18px solid ${tokens.pink}`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      boxSizing: 'border-box',
      animation: '$spinnerAfter 1s linear infinite',
      animationDeplay: '1s',
      transform: 'scale(1)',
      opacity: 0.5
    }
  },
  '@keyframes dynamicText': {
    '0%': {
      opacity: 0
    },
    '25%': {
      opacity: 0.5
    },
    '50%': {
      opacity: 1
    },
    '75%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 0
    }
  }
}));

export const CompSearchAnimation = ({
  currentCSStatus,
  setCurrentCSStatus,
  pbaJurisdictionInfo,
  navigateToAnalysis
}: CompSearchAnimationProps) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const { t } = useTranslation();
  const classes = useStyles();

  let comparablesFound = 0;
  let comparablesSearched = 0;

  if (pbaJurisdictionInfo) {
    comparablesFound = pbaJurisdictionInfo.numCompsInPool;
    comparablesSearched = pbaJurisdictionInfo.initialCount;
  }

  const continueAnalysis = () => {
    setCurrentCSStatus(csRunningStates.done);
    navigateToAnalysis();
  };

  useEffect(() => {
    if (currentTextIndex < 2) {
      setTimeout(() => {
        setCurrentTextIndex(currentTextIndex + 1);
      }, 1000);
    }
  }, [currentTextIndex]);

  return (
    <Box className={classes.boxLoading}>
      {currentCSStatus === csRunningStates.running ? (
        <Box className={classes.spinner}>
          <Box className={classes.spinnerBox1} />
          <Box className={classes.spinnerBox2} />
          <Box className={classes.centerTextContainer}>
            <Typography className={classes.compSearchText}>{t('analysis:title-section-comparable-search')}</Typography>
            <Typography className={classes.dynamicText}>
              {t(`analysis:comp-search-dynamic-text-${currentTextIndex}`)}
            </Typography>
          </Box>
        </Box>
      ) : currentCSStatus === csRunningStates.failed ? (
        <Box className={classes.boxLoading}>
          <Box className={classes.centerResultsContainer}>
            <Box>
              <ErrorOutlineIcon className={classes.errorIcon} />
              <Typography className={classes.compSearchFailedText}>{t('analysis:comp-search-failed')}</Typography>
              <Typography className={classes.compSearchFailedDescription}>
                {t('analysis:comp-search-failed-description')}
              </Typography>
            </Box>
            <Button variant="contained" color="primary" className={classes.continueButton} onClick={continueAnalysis}>
              {t('analysis:comp-search-return-to-analysis')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className={classes.boxLoading}>
          <Box className={classes.centerResultsContainer}>
            <Box>
              <Typography className={classes.resultsCompsearch}>
                {t('analysis:title-section-comparable-search')}
              </Typography>
              <Typography className={classes.resultsText}>{t('analysis:run-comp-search-results-text')}</Typography>
            </Box>
            <Box className={classes.resultsContainer}>
              <Box className={classes.firstSearchResultsContainer}>
                <Typography className={classes.resultsText}>
                  {t('analysis:run-comp-search-comparables-found')}
                </Typography>
                <Typography className={classes.resultsNumber}>
                  {new Intl.NumberFormat().format(comparablesFound)}
                </Typography>
              </Box>
              <Box className={classes.secondSearchResultsContainer}>
                <Typography className={classes.resultsText}>
                  {t('analysis:run-comp-search-companies-searched')}
                </Typography>
                <Typography className={classes.resultsNumber}>
                  {new Intl.NumberFormat().format(comparablesSearched)}
                </Typography>
              </Box>
            </Box>
            <Button variant="contained" color="primary" className={classes.continueButton} onClick={continueAnalysis}>
              {t('analysis:run-comp-search-continue-analysis')}
            </Button>
          </Box>
        </Box>
      )}
      ;
    </Box>
  );
};
