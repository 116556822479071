import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompSearchState } from '@xbs/xbs-enums';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { RootState } from '..';
import {
  AreaIds,
  FunctionalAnalysisCharacteristic
} from '../../app/FunctionalAnalysisTable/FunctionalAnalysisTable.proptype';
import { SheetFinancialData } from '../../app/PBADashboardFinancials/PBADashboardBalanceSheet/PBADashboardBalanceSheet.proptype';
import {
  PBASummarySubmitData,
  TestedPartyProps
} from '../../app/PBADashboardTPDSummary/PBADashboardTPDSummary.proptype';
import { PliValues, PliAverages } from '../../app/ProfitLevelIndicator/ProfitLevelIndicator.proptype';
import {
  FineTuneParams,
  FineTuningCompanies,
  FineTuningCompanyParams,
  FineTuningCompDetails,
  Rejections,
  RejectionStatusPayload
} from '../../app/TuneCompSearch/components/FineTuning/FineTuning.proptype';
import {
  ProfitBasedAnalysis,
  newProfitBasedAnalysis,
  TestedParty,
  ApplyUnappliedBulkRejectionParams,
  ApplyUnappliedBulkRejectionResponse,
  BulkRejectionsParams,
  Container,
  PbaJurisdiction,
  JurisdictionDescription,
  PbaJurisdictionPliFormat,
  PbaJurisdictionPliFormatPayload,
  BulkRejection,
  RangeParams,
  PbaJurisdictionPayload,
  CompSearchStatus,
  CompSearchRunBothPayload,
  RangeResults,
  RangeDiscussion,
  RangeDiscussionParams,
  RangeComparables,
  PbaJurisdictionStatus,
  FineTuningPLIInfo,
  FineTuningFinancialInfo,
  ProjectCompPoolPayload
} from '../../models';
import { FinancialData, FinancialDataObjectTypeEnum } from '../../models/financial.interface';
import httpService from '../../services/http';
import { roundPliValues } from '../../utils/roundPliValues';
import { getWorkingContainer } from '../baseData';

interface PBAsState {
  currentPBA?: ProfitBasedAnalysis;
  currentTestedParty?: TestedPartyProps;
  pbas: ProfitBasedAnalysis[] | null;
  balancesheetFinancials: FinancialData[] | null;
  pbaPli?: {
    pliValues: PliValues[];
    pliAverages: PliAverages[];
  };
  pbaJurisdiction: PbaJurisdiction[] | null;
  initialRangeComparablesWithRejections?: RangeComparables;
  appliedBulkRejections?: BulkRejection[] | null;
  nonAppliedBulkRejections?: BulkRejection[] | null;
  initialRangeComparables?: RangeComparables;
  initialRangeResults?: RangeResults;
  finalRangeComparables?: RangeComparables;
  finalRangeResults?: RangeResults;
  finalRangeDiscussion?: RangeDiscussion;
  error?: string;
  csStatus?: number;
  fineTuningCompanies?: any;
  rejectionReasons?: Rejections[] | undefined;
  selectedFineTuningCompany?: FineTuningCompDetails;
  financialInfoCompany?: FineTuningFinancialInfo[];
  pliInfoCompany?: FineTuningPLIInfo;
  additionalFinancialInfo?: string;
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
}

const initialState: PBAsState = {
  pbas: null,
  balancesheetFinancials: null,
  pbaJurisdiction: null,
  appliedBulkRejections: null,
  nonAppliedBulkRejections: null,
  additionalFinancialInfo: '',
  functionalAnalysisCharacteristics: {
    Functions: [],
    Risks: [],
    Assets: []
  }
};

interface PliData {
  pliAverages: PliAverages[];
  pliValues: PliValues[];
}

const pbaFunctionalAnalysisTypeId = 3;

export const fetchTestedPartyFinancialsInfo = createAsyncThunk<string, number, { rejectValue: Error }>(
  'testedParty/financials-info/fetch',
  async (testedPartyId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: string }>({
          method: 'get',
          apiUrlKey: 'tpCoreApiUrl',
          relativePath: `/v1/tested-party/${testedPartyId}/financials-info`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateTestedPartyFinancialsInfo = createAsyncThunk<
  any,
  {
    container: Container;
    financialsInfo: string;
    testedPartyId: number;
  },
  { rejectValue: Error }
>('testedParty/financials-info/post', async ({ container, financialsInfo, testedPartyId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/tested-party/${testedPartyId}/financials-info`,
        data: { container, financialsInfo }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchPBAs = createAsyncThunk<ProfitBasedAnalysis[], number, { rejectValue: Error }>(
  'pbas/fetch',
  async (studyId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: ProfitBasedAnalysis[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/study/${studyId}`,
          params: { sort: 'name', order: 'asc' }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFunctionalAnalysisCharacteristics = createAsyncThunk<
  FunctionalAnalysisCharacteristic[],
  { functionalAnalysisId: number; areaId: number },
  { rejectValue: Error }
>(`functional-analysis/area/type/fetch`, async ({ functionalAnalysisId, areaId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: FunctionalAnalysisCharacteristic[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/${functionalAnalysisId}/area/${areaId}/type/${pbaFunctionalAnalysisTypeId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const createFunctionalAnalysisCharacteristic = createAsyncThunk<
  FunctionalAnalysisCharacteristic,
  {
    functionalAnalysisId: number;
    areaId: number;
    characteristic: { characteristicName: string; container: Container | undefined };
  },
  { rejectValue: Error }
>(
  'functional-analysis/area/type/post',
  async ({ functionalAnalysisId, areaId, characteristic }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: FunctionalAnalysisCharacteristic }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `functional-analysis/${functionalAnalysisId}/characteristic/area/${areaId}/type/${pbaFunctionalAnalysisTypeId}`,
          data: characteristic
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateFunctionalAnalysisCharacteristic = createAsyncThunk<
  number,
  {
    characteristicId: number;
    characteristic: Partial<FunctionalAnalysisCharacteristic>;
  },
  { rejectValue: Error }
>(
  'functional-analysis/characteristic/type/patch',
  async ({ characteristicId, characteristic }, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/characteristic/${characteristicId}/type/${pbaFunctionalAnalysisTypeId}`,
        data: characteristic
      });
      return characteristicId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteFunctionalAnalysisCharacteristic = createAsyncThunk<number, number, { rejectValue: Error }>(
  'functional-analysis/characteristic/type/delete',
  async (characteristicId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/characteristic/${characteristicId}/type/${pbaFunctionalAnalysisTypeId}`
      });
      return characteristicId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchTestedPartyFinancials = createAsyncThunk<
  FinancialData[],
  { testedPartyId: number; financialTypeId: number },
  { rejectValue: Error }
>('testedParty/financial/balancesheet/fetch', async ({ testedPartyId, financialTypeId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: FinancialData[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `financial-data/${testedPartyId}/area/${financialTypeId}/type/${FinancialDataObjectTypeEnum.TESTED_PARTY}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});
export const fetchPBAPli = createAsyncThunk<PliData, number, { rejectValue: Error }>(
  'pbas/tested-party/pli',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: PliData }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/pli`,
          params: { sort: 'name', order: 'asc' }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCurrentTestedParty = createAsyncThunk<TestedPartyProps, number, { rejectValue: Error }>(
  'current-tested-party/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: TestedPartyProps }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deletePBA = createAsyncThunk<number, number, { rejectValue: Error }>(
  'pbas/delete',
  async (pbaId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `pbas/${pbaId}`
      });
      return pbaId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deletePBAAssociatedTransaction = createAsyncThunk<
  number,
  { pbaId: number; transactionId: number },
  { rejectValue: Error }
>('pbasAssociatedTransaction/delete', async ({ pbaId, transactionId }, { rejectWithValue }) => {
  try {
    await httpService.request<number>({
      method: 'delete',
      apiUrlKey: 'baseUrl',
      relativePath: `pbas/${pbaId}/transactions/${transactionId}`
    });
    return transactionId;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const savePBA = createAsyncThunk<
  ProfitBasedAnalysis,
  { pba: Partial<ProfitBasedAnalysis> | newProfitBasedAnalysis; t: TFunction },
  { state: RootState; rejectValue: Error }
>('pbas/save', async ({ pba, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit = typeof (pba as Partial<ProfitBasedAnalysis>).pbaId === 'number';
    const relativePath = isEdit ? `pbas/${(pba as Partial<ProfitBasedAnalysis>).pbaId!}` : 'pbas';
    const { data } = await httpService.request<{ data: ProfitBasedAnalysis }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: pba
    });

    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updatePbaJurisdictionStatus = createAsyncThunk<
  number,
  { pbaId: number; jurisdictionStatus: PbaJurisdictionStatus },
  { rejectValue: Error }
>('pbas/jurisdiction-status/update', async ({ pbaId, jurisdictionStatus }, { rejectWithValue }) => {
  try {
    await httpService.request({
      method: 'patch',
      apiUrlKey: 'baseUrl',
      relativePath: `pbas/${pbaId}/jurisdiction-status`,
      data: jurisdictionStatus
    });
    return pbaId;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const applyUnappliedBulkRejection = createAsyncThunk<
  ApplyUnappliedBulkRejectionResponse,
  ApplyUnappliedBulkRejectionParams,
  { rejectValue: Error }
>('applied-bulk-rejections/applyUnapply', async (data: ApplyUnappliedBulkRejectionParams, { rejectWithValue }) => {
  try {
    const endPath = data.isApplying ? 'apply' : 'unapply';
    const relativePath = `/v1/compsearch/${data.compSearchId}/jurisdiction/${data.jurisdictionId}/pli/${data.pliId}/pli-avg/${data.pliAvgId}/bulk-rejections/${data.rejectionTypeId}/${endPath}`;
    await httpService.request<{ data: any }>({
      method: 'put',
      apiUrlKey: 'compSearchApiUrl',
      relativePath,
      data
    });

    return {
      rejectionTypeId: data.rejectionTypeId,
      isApplying: data.isApplying
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchAppliedBulkRejections = createAsyncThunk<
  BulkRejection[],
  BulkRejectionsParams,
  { rejectValue: Error }
>('applied-bulk-rejections/fetch', async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: BulkRejection[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/bulk-rejections/applied`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchNonAppliedBulkRejections = createAsyncThunk<
  BulkRejection[],
  BulkRejectionsParams,
  { rejectValue: Error }
>(
  'non-applied-bulk-rejections/fetch',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: BulkRejection[] }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/bulk-rejections/non-applied`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchPbaJurisdictions = createAsyncThunk<PbaJurisdictionPayload, number, { rejectValue: Error }>(
  'pba-jurisdictions/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: any }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/pba/${pbaId}/jurisdictions`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchInitialRangeComparables = createAsyncThunk<RangeComparables, RangeParams, { rejectValue: Error }>(
  'range-comparables/initial',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeComparables }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/initial`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchInitialRangeComparablesWithRejections = createAsyncThunk<
  RangeComparables,
  RangeParams,
  { rejectValue: Error }
>(
  'range-comparables/initial-rejection',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeComparables }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/initial-rejection`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchInitialRangeResults = createAsyncThunk<RangeResults, RangeParams, { rejectValue: Error }>(
  'range-results/initial',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeResults }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-results/initial`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalRangeComparables = createAsyncThunk<RangeComparables, RangeParams, { rejectValue: Error }>(
  'range-comparables/final/fetch',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeComparables }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/final`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalRangeResults = createAsyncThunk<RangeResults, RangeParams, { rejectValue: Error }>(
  'range-results/final',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeResults }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-results/final`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalRangeDiscussion = createAsyncThunk<RangeDiscussion, RangeParams, { rejectValue: Error }>(
  'range-discussion/fetch',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeDiscussion }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-discussion`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveFinalRangeDiscussion = createAsyncThunk<any, RangeDiscussionParams, { rejectValue: Error }>(
  'range-discussion/save',
  async (
    { compSearchId, jurisdictionId, pliId, pliAvgId, container, containerId, rangeDiscussion },
    { rejectWithValue }
  ) => {
    try {
      return (
        await httpService.request<{ data: RangeDiscussion }>({
          method: 'put',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-discussion`,
          data: { container, containerId, rangeDiscussion }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchJurisdictionDescription = createAsyncThunk<
  JurisdictionDescription,
  { compSearchId: number; jurisdictionId: number },
  { rejectValue: Error }
>('compSearch/jurisdiction-description/fetch', async ({ compSearchId, jurisdictionId }, { rejectWithValue }) => {
  try {
    const fetchDescription = httpService.request<{ data: any }>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/description`
    });
    const fetchRuleOverride = httpService.request<{ data: any }>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/rule-override`
    });
    const promises = [fetchDescription, fetchRuleOverride];
    const response = await Promise.all(promises);
    const compPoolDescription = response[0].data.data.data.compPoolDescription.data;
    const jurisdictionRuleOverrideInfo = response[1].data.data;
    const searchResults = { ...compPoolDescription, ...jurisdictionRuleOverrideInfo };
    return searchResults;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchJurisdictionPlis = createAsyncThunk<
  PbaJurisdictionPayload,
  { compSearchId: number; jurisdictionId: number },
  { rejectValue: Error }
>('compSearch/jurisdiction-pli/fetch', async ({ compSearchId, jurisdictionId }, { rejectWithValue }) => {
  try {
    return {
      data: (
        await httpService.request<{ data: PbaJurisdictionPayload }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/plis`
        })
      ).data.data.data.evaluationInstances,
      jurisdictionId
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchJurisdictionPliFormats = createAsyncThunk<
  { data: PbaJurisdictionPliFormat[] },
  { compSearchId: number; jurisdictionId: number },
  { rejectValue: Error }
>('compSearch/jurisdiction-pli-formats/fetch', async ({ compSearchId, jurisdictionId }, { rejectWithValue }) => {
  try {
    return {
      data: (
        await httpService.request<PbaJurisdictionPliFormatPayload>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/get-jurisdiction-pli-formats`
        })
      ).data.data
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

interface UpdatePliStatusParams {
  compSearchId: number;
  jurisdictionId: number;
  pliTypeId: number;
  pliAverageTypeId: number;
  pliStatus: number;
  currentContainer: Container | undefined;
}

export const updatePliStatus = createAsyncThunk<void, UpdatePliStatusParams, { rejectValue: Error }>(
  'compSearch/pli-status/update',
  async (data: UpdatePliStatusParams, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/compsearch/${data.compSearchId}/jurisdiction/${data.jurisdictionId}/pli/${data.pliTypeId}/pli-avg/${data.pliAverageTypeId}/pli-status`;
      await httpService.request<{ data: any }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const currentPBA = createAction('currentPBA', (pba: ProfitBasedAnalysis) => {
  return { payload: pba };
});

interface EditTestedPartyParams {
  pbaId: number;
  testedParty: PBASummarySubmitData;
}

export const editTestedParty = createAsyncThunk<void, EditTestedPartyParams, { rejectValue: Error }>(
  'pbas/editTestedParty',
  async ({ pbaId, testedParty }, { rejectWithValue }) => {
    try {
      const relativePath = `pbas/${pbaId}/tested-party`;
      await httpService.request<{ data: TestedParty }>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath,
        data: {
          pba: { pbaId },
          ...testedParty
        }
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const createTestedParty = createAsyncThunk<void, EditTestedPartyParams, { rejectValue: Error }>(
  'pbas/createTestedParty',
  async ({ pbaId, testedParty }, { rejectWithValue }) => {
    try {
      const relativePath = `pbas/${pbaId}/tested-party`;
      await httpService.request<{ data: TestedParty }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath,
        data: testedParty
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const runCompSearch = createAsyncThunk<CompSearchStatus, CompSearchRunBothPayload, { rejectValue: Error }>(
  'pbas/compSearch/run-compSearch/post',
  async ({ pbaId, compSearchPayload }, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/pba/${pbaId}/run-compsearch`;
      const { data } = await httpService.request<{ data: CompSearchStatus }>({
        method: 'post',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: compSearchPayload
      });

      return data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCompSearchStatus = createAsyncThunk<CompSearchStatus, number, { rejectValue: Error }>(
  'pbas/compSearch-status/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: CompSearchStatus }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/pba/${pbaId}/compsearch/status`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

interface ProjectedCompoolCountResponse {
  isCompPoolAllowed: boolean;
  numCompsInPool: number;
  jurisdictionId: number;
}
interface ProjectedCompoolCount {
  isCompPoolAllowed: boolean;
  numCompsInPool: number;
}

interface ProjectedComPoolCountReduxPayload {
  compSearchId: number;
  jurisdictionId: number;
  projectedCompPoolPayload: ProjectCompPoolPayload;
}

export const projectCompPoolCount = createAsyncThunk<
  ProjectedCompoolCountResponse,
  ProjectedComPoolCountReduxPayload,
  { rejectValue: Error }
>(
  'pbas/compSearch/project-comp-pool-count/put',
  async ({ compSearchId, jurisdictionId, projectedCompPoolPayload }, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/comp-pool-count/project`;
      const { data } = await httpService.request<{ data: ProjectedCompoolCount }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: projectedCompPoolPayload
      });

      return {
        jurisdictionId,
        ...data.data
      };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateTestedPartyFinancial = createAsyncThunk<
  SheetFinancialData[],
  // TODO: we need to add container to payload too, and iterate every year when save
  {
    testedPartyId: number;
    financialTypeId: number;
    payload: { financialData: SheetFinancialData[]; valueEnteredIn: number; container: Container };
  },
  { rejectValue: Error }
>(
  'testedParty/financial/balancesheet/post',
  async ({ testedPartyId, financialTypeId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await httpService.request<{ data: SheetFinancialData[] }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `financial-data/${testedPartyId}/area/${financialTypeId}/type/${FinancialDataObjectTypeEnum.TESTED_PARTY}`,
        data: payload
      });

      return data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFineTuningCompanies = createAsyncThunk<
  FineTuningCompanies,
  { params: FineTuneParams },
  { rejectValue: Error }
>('pba/fineTuning/signedUrl/get', async ({ params }, { rejectWithValue }) => {
  try {
    const data = await httpService.request<any>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}/comp-pool?sort=pliValue:asc`
    });

    const signedUrl = data.data.data.url;
    const response = await httpService.requestWithoutAuthHeader<FineTuningCompanies>(signedUrl);
    return response.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchRejectionReasons = createAsyncThunk<Rejections[], { params: FineTuneParams }, { rejectValue: Error }>(
  'pba/fineTuning/rejectionReasons/get',
  async ({ params }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: Rejections[] }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}/rejection-reasons`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFineTuningCompany = createAsyncThunk<
  FineTuningCompDetails[],
  { params: FineTuningCompanyParams },
  { rejectValue: Error }
>('pba/fineTuning/companyDetails/get', async ({ params }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: FineTuningCompDetails[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}/evaluate-tax-payer?companyIds=${params.companyId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateRejectionReason = createAsyncThunk<any, RejectionStatusPayload, { rejectValue: Error }>(
  'pba/fineTuning/rejectionReasons/update',
  async (params: RejectionStatusPayload, { rejectWithValue }) => {
    try {
      await httpService.request<{ data: any }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/${String(params.compSearchId)}/jurisdiction/${String(
          params.jurisdictionId
        )}/pli/${String(params.pliTypeId)}/update-fine-tuning-status`,
        data: params.data
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFineTuningFinancialInformationCompany = createAsyncThunk<
  FineTuningFinancialInfo[],
  { params: FineTuningCompanyParams },
  { rejectValue: Error }
>('pba/fineTuning/financialInfo/get', async ({ params }, { rejectWithValue }) => {
  try {
    const queryPath = `companyIds=${params.companyId}`;
    const relativePath = `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/financial-info?${queryPath}`;
    return (
      await httpService.request<{ data: FineTuningFinancialInfo[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchFineTuningPLICompany = createAsyncThunk<
  FineTuningPLIInfo,
  { params: FineTuningCompanyParams },
  { rejectValue: Error }
>('pba/fineTuning/PLI/get', async ({ params }, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/source/${params.companyId}/comp-pool-plis`;
    return (
      await httpService.request<{ data: FineTuningPLIInfo }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const pbasSlice = createSlice({
  name: 'pbas',
  initialState,
  reducers: {
    clearPbaData: (state) => {
      state.pbas = null;
      state.balancesheetFinancials = null;
      state.pbaJurisdiction = null;
      state.appliedBulkRejections = null;
      state.nonAppliedBulkRejections = null;
      state.additionalFinancialInfo = '';
      state.functionalAnalysisCharacteristics = {
        Functions: [],
        Risks: [],
        Assets: []
      };
    },
    doneCSRunning: (state) => {
      state.csStatus = CompSearchState.ByName.NotReady.Id;
    },
    removeCurrentPbaJurisdictions: (state) => {
      state.pbaJurisdiction = [];
    },
    updateRejectionRowStatus(state, action: PayloadAction<any>) {
      const row = state.fineTuningCompanies.data.find((comp: any) => {
        return Number(comp.sourceId) === Number(action.payload.sourceId);
      });
      row.compStatus = action.payload.compStatus === true ? 'global.accepted' : 'global.rejected';
    },
    clearSelectedCompDetails: (state) => {
      state.selectedFineTuningCompany = undefined;
    },
    clearCompSearch: (state) => {
      state.initialRangeComparables = undefined;
      state.initialRangeResults = undefined;
      state.appliedBulkRejections = undefined;
      state.nonAppliedBulkRejections = undefined;
      state.initialRangeComparablesWithRejections = undefined;
      state.fineTuningCompanies = undefined;
      state.selectedFineTuningCompany = undefined;
      state.finalRangeComparables = undefined;
      state.finalRangeResults = undefined;
      state.finalRangeDiscussion = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchPBAPli.fulfilled, (state: PBAsState, action: PayloadAction<PliData>) => {
        const { pliValues } = action.payload;
        const { pliAverages } = action.payload;
        state.pbaPli = { pbaPliValues: [], pliAverages: [] } as any;
        const roundedPliValues = roundPliValues(pliValues);
        const roundedPliAverages = roundPliValues(pliAverages);
        state.pbaPli!.pliValues = roundedPliValues;
        state.pbaPli!.pliAverages = roundedPliAverages;
      })
      .addCase(deletePBA.fulfilled, (state: PBAsState, action: PayloadAction<number>) => {
        const idx = (state.pbas ?? []).findIndex(({ pbaId }) => pbaId === action.payload);
        if (idx >= 0) {
          state.pbas!.splice(idx, 1);
        }
      })
      .addCase(fetchAppliedBulkRejections.fulfilled, (state: PBAsState, action: PayloadAction<BulkRejection[]>) => {
        state.appliedBulkRejections = action.payload;
      })
      .addCase(fetchNonAppliedBulkRejections.fulfilled, (state: PBAsState, action: PayloadAction<BulkRejection[]>) => {
        state.nonAppliedBulkRejections = action.payload;
      })
      .addCase(fetchPBAs.fulfilled, (state: PBAsState, action: PayloadAction<ProfitBasedAnalysis[]>) => {
        state.pbas = action.payload;
        state.currentTestedParty = undefined;
      })
      .addCase(fetchCurrentTestedParty.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.currentTestedParty = action.payload;
      })
      .addCase(currentPBA, (state: PBAsState, action: PayloadAction<ProfitBasedAnalysis>) => {
        state.currentPBA = action.payload;
      })
      .addCase(fetchTestedPartyFinancials.fulfilled, (state: PBAsState, action: PayloadAction<FinancialData[]>) => {
        state.balancesheetFinancials = action.payload;
      })
      .addCase(runCompSearch.fulfilled, (state: PBAsState, action: PayloadAction<CompSearchStatus>) => {
        state.csStatus = action.payload.compSearchState;
      })
      .addCase(fetchCompSearchStatus.fulfilled, (state: PBAsState, action: PayloadAction<CompSearchStatus>) => {
        state.csStatus = action.payload.compSearchState;
      })
      .addCase(fetchPbaJurisdictions.fulfilled, (state: PBAsState, action: PayloadAction<PbaJurisdictionPayload>) => {
        const jurisdictions: string[] = Object.keys(action.payload.data);
        const pbaJurisdictions = action.payload.data;
        state.pbaJurisdiction = jurisdictions.map((jurisdiction: string) => {
          return pbaJurisdictions[jurisdiction].data[0];
        });
      })
      .addCase(
        fetchJurisdictionDescription.fulfilled,
        (state: PBAsState, action: PayloadAction<JurisdictionDescription>) => {
          const jurisdictionInfo = action.payload;
          state.pbaJurisdiction?.forEach((jurisdiction) => {
            const { jurisdictionId } = jurisdictionInfo;
            jurisdiction.pbaJurisdictionInfo =
              jurisdiction.jurisdictionId === jurisdictionId ? jurisdictionInfo : jurisdiction.pbaJurisdictionInfo;
          });
        }
      )
      .addCase(fetchJurisdictionPlis.fulfilled, (state: PBAsState, action: PayloadAction<PbaJurisdictionPayload>) => {
        const jurisdictionPli = action.payload.data;
        state.pbaJurisdiction?.forEach((jurisdiction) => {
          jurisdiction.jurisdictionPli =
            jurisdiction.jurisdictionId === action.payload.jurisdictionId ? jurisdictionPli : null;
        });
      })
      .addCase(
        fetchJurisdictionPliFormats.fulfilled,
        (state: PBAsState, action: PayloadAction<{ data: PbaJurisdictionPliFormat[] }>) => {
          const jurisdictionPliFormat = action.payload.data;
          state.pbaJurisdiction?.forEach((pbaJurisdiction) => {
            const pliJurisdiction = jurisdictionPliFormat.filter(
              (jurisdiction: PbaJurisdictionPliFormat) =>
                jurisdiction.jurisdictionId === pbaJurisdiction.jurisdictionId &&
                jurisdiction.compSearchId === pbaJurisdiction.compSearchId
            );
            pbaJurisdiction.jurisdictionPliFormat = pliJurisdiction ?? null;
          });
        }
      )
      .addCase(
        fetchInitialRangeComparablesWithRejections.fulfilled,
        (state: PBAsState, action: PayloadAction<RangeComparables>) => {
          state.initialRangeComparablesWithRejections = action.payload;
        }
      )
      .addCase(fetchInitialRangeComparables.fulfilled, (state: PBAsState, action: PayloadAction<RangeComparables>) => {
        state.initialRangeComparables = action.payload;
      })
      .addCase(fetchInitialRangeResults.fulfilled, (state: PBAsState, action: PayloadAction<RangeResults>) => {
        state.initialRangeResults = action.payload;
      })
      .addCase(fetchFineTuningCompanies.fulfilled, (state: PBAsState, action: PayloadAction<FineTuningCompanies>) => {
        state.fineTuningCompanies = action.payload;
      })
      .addCase(fetchRejectionReasons.fulfilled, (state: PBAsState, action: PayloadAction<Rejections[]>) => {
        state.rejectionReasons = action.payload;
      })
      .addCase(fetchFineTuningCompany.fulfilled, (state: PBAsState, action: PayloadAction<FineTuningCompDetails[]>) => {
        state.selectedFineTuningCompany = action.payload[0];
      })
      .addCase(fetchFinalRangeComparables.fulfilled, (state: PBAsState, action: PayloadAction<RangeComparables>) => {
        state.finalRangeComparables = action.payload;
      })
      .addCase(fetchFinalRangeResults.fulfilled, (state: PBAsState, action: PayloadAction<RangeResults>) => {
        state.finalRangeResults = action.payload;
      })
      .addCase(fetchFinalRangeDiscussion.fulfilled, (state: PBAsState, action: PayloadAction<RangeDiscussion>) => {
        state.finalRangeDiscussion = action.payload;
      })
      .addCase(fetchFunctionalAnalysisCharacteristics.fulfilled, (state: PBAsState, action) => {
        const { areaId } = action.meta.arg;
        if (areaId === AreaIds.AreaIdFunctions) {
          state.functionalAnalysisCharacteristics!.Functions = action.payload;
        }

        if (areaId === AreaIds.AreaIdRisks) {
          state.functionalAnalysisCharacteristics!.Risks = action.payload;
        }

        if (areaId === AreaIds.AreaIdAssets) {
          state.functionalAnalysisCharacteristics!.Assets = action.payload;
        }
      })
      .addCase(saveFinalRangeDiscussion.fulfilled, (state: PBAsState) => state)
      .addCase(
        fetchFineTuningFinancialInformationCompany.fulfilled,
        (state: PBAsState, action: PayloadAction<FineTuningFinancialInfo[]>) => {
          state.financialInfoCompany = action.payload;
        }
      )
      .addCase(fetchFineTuningPLICompany.fulfilled, (state: PBAsState, action: PayloadAction<FineTuningPLIInfo>) => {
        state.pliInfoCompany = action.payload;
      })
      .addCase(fetchTestedPartyFinancialsInfo.fulfilled, (state: PBAsState, action: PayloadAction<string>) => {
        state.additionalFinancialInfo = typeof action.payload === 'string' ? action.payload : '';
      })
      .addMatcher(
        (action) => action.type.match(/^pbas\/.+\/pending$/),
        (state: PBAsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^pbas\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

interface UpdatePbaTransactionParams {
  transactionId: number;
  pbaId: number;
}
export const updatePbaTransactions = createAsyncThunk<void, UpdatePbaTransactionParams, { rejectValue: Error }>(
  'pbas/createTestedParty',
  async ({ pbaId, transactionId }, { rejectWithValue }) => {
    try {
      const relativePath = `pbas/${pbaId}/transactions`;
      await httpService.request<{ data: TestedParty }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath,
        data: { transactionId }
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const {
  clearPbaData,
  doneCSRunning,
  removeCurrentPbaJurisdictions,
  updateRejectionRowStatus,
  clearSelectedCompDetails,
  clearCompSearch
} = pbasSlice.actions;

export default pbasSlice.reducer;
