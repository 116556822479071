import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Radio, TableCell, TableRow } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { format } from 'date-fns';
import { ActionsDropDown } from './ActionsDropDown';
import { LocalReportVersionsRowProps } from './LocalReportVersionsModal.prototypes';
import reportsOnlyAccess from '../../components/HigherOrderComponents/ReportsOnlyAccess';
import appColors from '../../styles/appColors';
import tokens from '../../styles/designTokens';
import { body1, body2, buttonText1, captionOverline } from '../../styles/typography';
import { DownloadIcon } from '../../svgs';
import { canDownloadReport, hasReportsOnlyAccess } from '../../utils/hasAccess';
import { ReportStatusChip } from '../Reports/components/ReportStatusChip';

const useStyles = makeStyles(() => ({
  versionCell: {
    ...body2
  },
  statusChip: {
    ...captionOverline
  },
  detailsCell: {
    ...buttonText1
  },
  createdAtCell: {
    ...body1
  },
  activeIcon: {
    cursor: 'pointer',
    fill: appColors.reports.active.text
  },
  inActiveIcon: {
    cursor: 'pointer',
    fill: appColors.reports.draft.text
  },
  active: {
    color: appColors.reports.active.text
  },
  inActive: {
    color: appColors.reports.draft.text
  },
  activeRow: {
    backgroundColor: tokens.product15
  },
  icons: {
    width: '2%',
    marginLeft: '2%',
    cursor: 'pointer'
  }
}));

export const LocalReportVersionsRow = ({
  version,
  updateActiveVersion,
  startDeleteVersion,
  downloadReportVersion
}: LocalReportVersionsRowProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

  const handleActionsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const deleteVersion = (versionId: number) => {
    startDeleteVersion(versionId);
  };

  const source = version.isUploadedReport ? ` - ${t('reports:action-upload-version')}` : '';
  const details = `${t(`languageIsoInfo:${version.languageIso}`)} - ${version.reportCurrency.isoCode}${source}`;

  return (
    <TableRow key={version.version.toString()} component="div" className={version.isActive ? classes.activeRow : ''}>
      <TableCell
        key={`${version.version.toString()}-version`}
        component="div"
        className={`${classes.versionCell} ${version.isActive ? classes.active : classes.inActive}`}
        scope="dataRow"
      >
        V{version.version.toString()}
      </TableCell>
      <TableCell
        key={`${version.version.toString()}-status`}
        component="div"
        className={classes.statusChip}
        scope="dataRow"
      >
        <ReportStatusChip
          status={version.reportStatus}
          type="reportVersionsTable"
          label={t(`reports:${String(version.reportStatus)}`)}
        />
      </TableCell>
      <TableCell
        key={`${version.version.toString()}-details`}
        component="div"
        className={`${classes.detailsCell} ${version.isActive ? classes.active : classes.inActive}`}
        scope="dataRow"
      >
        {details}
      </TableCell>
      <TableCell
        key={`${version.version.toString()}-createdAt`}
        component="div"
        className={`${classes.createdAtCell} ${version.isActive ? classes.active : classes.inActive}`}
        scope="dataRow"
      >
        {format(new Date(version.createdAt), `d MMM yyyy hh:mm aaaaa'm'`)}
      </TableCell>
      <TableCell
        key={`${version.version.toString()}-active`}
        component="div"
        className={classes.versionCell}
        scope="dataRow"
      >
        <Radio
          checked={Boolean(version.isActive)}
          data-testid={`radio-${version.version}`}
          disabled={hasReportsOnlyAccess()}
          onClick={() => {
            updateActiveVersion(version.version);
          }}
        />
      </TableCell>
      <TableCell
        key={`${version.version.toString()}-download`}
        component="div"
        className={version.isActive ? classes.active : classes.inActive}
        scope="dataRow"
      >
        {canDownloadReport(version.reportStatus) && (
          <DownloadIcon
            className={version.isActive ? classes.activeIcon : classes.inActiveIcon}
            data-testid={`download-${version.version}`}
            onClick={() => {
              downloadReportVersion(version.version);
            }}
          />
        )}
      </TableCell>
      <TableCell key={`${version.version.toString()}-moreMenu`} component="div" scope="dataRow">
        {reportsOnlyAccess(
          <div className={classes.icons}>
            <Box onClick={handleActionsOpen}>
              <MoreHorizIcon />
            </Box>
            <ActionsDropDown
              anchorEl={actionsAnchorEl}
              startDeleteVersion={() => {
                deleteVersion(version.version);
              }}
              onClose={handleActionsClose}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
