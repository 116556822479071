import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles, Button, Box } from '@material-ui/core';
import clsx from 'clsx';
import { PbaAnalysisIcon } from '../../../svgs';
import { AnalysisHeaderProps } from '../AnalysisHeader.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    minHeight: '100%',
    '& > :nth-child(2)': {
      '& .Mui-selected': {
        color: theme.palette.primary.dark
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  contentHeader: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '3.4rem',
    padding: theme.spacing(0, 2)
  },
  contentHeaderBorder: {
    backgroundColor: theme.palette.background.default,
    marginTop: 0,
    marginBottom: 0,
    fontSize: '1rem'
  },
  headerTexts: {
    gap: '2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  contentHeaderColor: {
    color: theme.palette.secondary.dark,
    fontWeight: 500
  },
  contentHeaderPrimary: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 600,
    gap: theme.spacing(0.5)
  },
  PbaTitle: {
    color: theme.palette.primary.dark
  },
  pbaAnalysisIcon: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '3.75rem',
    padding: '0.375rem'
  },
  PbaName: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    padding: '0.375rem',
    borderRadius: '0.25rem'
  }
}));

export const AnalysisHeader = ({ pba, workingContainer, onHandleClose }: AnalysisHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onHandleActionClose = useCallback(() => {
    onHandleClose();
  }, [onHandleClose]);

  return (
    <Box>
      <Box className={classes.contentHeader}>
        <Box className={classes.headerTexts}>
          <Typography key={1} variant="h2" className={clsx(classes.contentHeaderBorder, classes.contentHeaderPrimary)}>
            <PbaAnalysisIcon className={classes.pbaAnalysisIcon} />
            <Box component="span" className={classes.PbaTitle}>
              Profit Based Analysis
            </Box>
          </Typography>
          <Typography key={2} variant="h2" className={clsx(classes.contentHeaderBorder, classes.PbaName)}>
            <Box component="span">{pba?.name}</Box>
          </Typography>
          <Typography key={3} variant="h2" className={clsx(classes.contentHeaderBorder, classes.contentHeaderColor)}>
            <Box component="span">Tax Year {workingContainer?.taxYear}</Box>
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" color="primary" onClick={onHandleActionClose}>
            {t('analysis:action-close-analysis')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
