import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';
import { CustomIconMenu, ConfirmationModal } from '../../../components';
import { Transaction } from '../../../models';
import { TrashCanIcon } from '../../../svgs';
import { hasEditAccess } from '../../../utils';
import { TransactionDetailsTab } from '../../TransactionDetails';
import { TransactionsEventsProps } from '../Transactions.proptype';

interface MenuProps extends Omit<TransactionsEventsProps, 'onAddSingle' | 'onImport'> {
  transaction: Transaction;
}

export const TransactionMenu = ({ transaction, onNavigate, onEdit, onDelete }: MenuProps) => {
  const { transactionId, transactionType, identifier, pbas, tbas } = transaction;
  const { t } = useTranslation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const items: any = [
    {
      text: t('transactions:title-dashboard'),
      handleOnClick: () => {
        onNavigate(`/transactions/${transactionId}/dashboard`);
      }
    },
    {
      text: t('title-sof'),
      handleOnClick: () => {
        onNavigate(`/transactions/${transactionId}/details/${TransactionDetailsTab.StatementOfFacts}`);
      }
    }
  ];

  if (hasEditAccess()) {
    items.push(
      {
        text: t('transactions:action-edit'),
        handleOnClick: () => {
          onEdit(transactionId);
        },
        dividerBefore: true
      },
      {
        text: t('transactions:action-delete'),
        handleOnClick: () => {
          setIsConfirmationOpen(true);
        }
      }
    );
  }

  const handleDeletion = (confirmed: boolean) => {
    setIsConfirmationOpen(false);
    if (confirmed) {
      onDelete(transactionId);
    }
  };

  if (transactionType?.name === 'Services') {
    items.splice(2, 0, {
      text: t('transactions:title-evaluation'),
      handleOnClick: () => {
        onNavigate(`/transactions/${transactionId}/details/${TransactionDetailsTab.TransactionEvaluation}`);
      }
    });
  }

  if (tbas?.length > 0 || pbas?.length > 0) {
    items.splice(2, 0, {
      text: t('transactions:title-method'),
      handleOnClick: () => {
        onNavigate(`/transactions/${transactionId}/details/${TransactionDetailsTab.MethodEvaluation}`);
      }
    });
  }

  return (
    <>
      <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
      {isConfirmationOpen && (
        <ConfirmationModal
          open
          title={t('transactions:action-delete')}
          subtitle={t('transactions:title-delete', { name: identifier })}
          text={t('message-delete')}
          HeaderIcon={TrashCanIcon}
          handleClose={handleDeletion}
        />
      )}
    </>
  );
};
