import { Button, makeStyles } from '@material-ui/core';
import { ArrowMove } from '../../svgs';

export interface ActionButtonProps {
  buttonText: string;
  isReady: boolean;
  action: () => void;
  id?: string;
  disabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  isReady: {
    color: theme.palette.primary.dark,
    background: theme.palette.action.selected,
    minWidth: '10rem',
    '&:hover': {
      background: theme.palette.action.selected
    },
    svg: {
      color: theme.palette.primary.dark
    }
  },
  notReady: {
    background: theme.palette.secondary.light,
    '&:hover': {
      background: theme.palette.secondary.light
    },
    minWidth: '10rem'
  },
  svg: {
    color: theme.palette.primary.light
  }
}));

export const ActionButton = ({ buttonText, isReady, action, id, disabled }: ActionButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      id={id}
      className={isReady ? classes.isReady : classes.notReady}
      endIcon={<ArrowMove />}
      disabled={disabled}
      onClick={() => {
        action();
      }}
    >
      {buttonText}
    </Button>
  );
};
