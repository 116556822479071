import { Dialog, makeStyles } from '@material-ui/core';
import { LocalReportCreationModalProps } from './LocalReportCreationModal.proptypes';
import { EntitiesTab } from '../LocalReportCreationTabs/EntitiesTab';
import { SetupTab } from '../LocalReportCreationTabs/SetupTab';
import { SummariesTab } from '../LocalReportCreationTabs/SummariesTab';
import { TransactionsTab } from '../LocalReportCreationTabs/TransactionsTab';
import { LocalReportCreationTabsContainer } from '../LocalReportCreationTabsContainer/LocalReportCreationTabsContainer';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%'
    }
  }
}));

export const LocalReportCreationModal = ({
  title,
  jurisdictionId,
  jurisdictionName,
  stageCompleteness,
  onClose,
  onSubmit
}: LocalReportCreationModalProps) => {
  const styles = useStyles();

  const tabs = [
    {
      key: 'Setup',
      label: 'Setup',
      component: <SetupTab jurisdictionId={jurisdictionId} />
    },
    {
      key: 'Entities',
      label: 'Entities',
      component: <EntitiesTab jurisdictionId={jurisdictionId} />
    },
    {
      key: 'Transactions',
      label: 'Transactions',
      component: <TransactionsTab />
    },
    {
      key: 'Summary',
      label: 'Summary',
      component: <SummariesTab />
    }
  ];

  return (
    <Dialog fullWidth open className={styles.root} maxWidth="lg" onClose={onClose}>
      <LocalReportCreationTabsContainer
        title={title}
        jurisdictionName={jurisdictionName}
        stageCompleteness={stageCompleteness}
        tabs={tabs}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};
