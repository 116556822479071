import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Box, Tabs, Tab } from '@material-ui/core';
import { BalanceSheetTab } from './BalanceSheetTab';
import { IncomeStatementTab } from './IncomeStatementTab';
import { OwnershipTab } from './OwnershipTab';
import { PLITab } from './PLITab';
import { SummaryTab } from './SummaryTab';
import { CenteredProgress } from '../../../../../components';
import tokens from '../../../../../styles/designTokens';
import { body4, captionOverline } from '../../../../../styles/typography';
import { ComparableInformationProps } from '../FineTuning.proptype';

const useStyles = makeStyles(() => ({
  tapWrapper: {
    height: '100%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  titleTab: {
    ...body4
  },
  stateWrapperRejected: {
    ...captionOverline,
    color: tokens.white,
    textTransform: 'uppercase',
    backgroundColor: tokens.core1,
    padding: '0.5rem',
    borderRadius: '4px',
    margin: '0 1rem'
  },
  stateWrapperAccepted: {
    ...captionOverline,
    color: tokens.white,
    textTransform: 'uppercase',
    backgroundColor: tokens.purpleLight2,
    padding: '0.5rem',
    borderRadius: '4px',
    margin: '0 1rem'
  },
  tabsWrapper: {
    marginTop: '1rem',
    '& .MuiTab-root': {
      padding: '0 0.5rem'
    }
  },
  tabsContent: {
    paddingTop: '1rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
}));

export const ComparableInformation = (props: ComparableInformationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const subPages = useMemo(
    () => [
      {
        key: 'summary',
        label: 'Summary',
        component: SummaryTab
      },
      {
        key: 'income_statement',
        label: 'Income Statement',
        component: IncomeStatementTab
      },
      {
        key: 'balance_sheet',
        label: 'Balance Sheet',
        component: BalanceSheetTab
      },
      {
        key: 'pli',
        label: 'PLI',
        component: PLITab
      },
      {
        key: 'ownership',
        label: 'Ownership',
        component: OwnershipTab
      }
    ],
    []
  );

  const [currentCompany, setCurrectCompany] = useState(props.company);
  const [currentPage, setCurrentPage] = useState(subPages[0]);

  useEffect(() => {
    if (props.company !== currentCompany || props.company?.isRejected !== currentCompany?.isRejected) {
      setCurrectCompany(props.company);
      setCurrentPage(subPages[0]);
    }
  }, [props.company, props.company?.isRejected, subPages, currentPage, currentCompany]);

  const handlerOnSelectPage = (subPage: any) => {
    setCurrentPage(subPage);
  };

  const ComponentPage = currentPage.component as FunctionComponent<ComparableInformationProps>;

  return (
    <Box className={classes.tapWrapper}>
      {props.company && (
        <>
          <Box className={classes.headerTitle}>
            <Typography className={classes.titleTab}>{props.company.companyName}</Typography>
            {props.company.isRejected === 1 && (
              <span className={classes.stateWrapperRejected}>{t('analysis:status.company-rejected')}</span>
            )}
            {props.company.isRejected === 0 && (
              <span className={classes.stateWrapperAccepted}>{t('analysis:status.company-accepted')}</span>
            )}
          </Box>
          <Tabs className={classes.tabsWrapper} value={currentPage.key} indicatorColor="primary" textColor="primary">
            {subPages.map((subpage) => (
              <Tab
                key={subpage.key}
                value={subpage.key}
                label={subpage.label}
                onClick={() => {
                  handlerOnSelectPage(subpage);
                }}
              />
            ))}
          </Tabs>
          <Box className={classes.tabsContent}>
            <ComponentPage {...props} />
          </Box>
        </>
      )}
      {!props.company && <CenteredProgress />}
    </Box>
  );
};
