import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { RootState } from '..';
import { TransactionBasedAnalysis } from '../../models';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface TBAsState {
  tbas: TransactionBasedAnalysis[] | null;
  error?: string;
}

const initialState: TBAsState = { tbas: null };

export const fetchTBAs = createAsyncThunk<TransactionBasedAnalysis[], number, { rejectValue: Error }>(
  'tbas/fetch',
  async (studyId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { tbas: TransactionBasedAnalysis[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `tbas/study/${studyId}`,
          params: { sort: 'name', order: 'asc' }
        })
      ).data.result.tbas;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteTBA = createAsyncThunk<number, number, { rejectValue: Error }>(
  'tbas/delete',
  async (tbaId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}`
      });
      return tbaId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveTBA = createAsyncThunk<
  TransactionBasedAnalysis,
  { tba: Partial<TransactionBasedAnalysis>; t: TFunction },
  { state: RootState; rejectValue: Error }
>('tbas/save', async ({ tba, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit = typeof tba.tbaId === 'number';
    const relativePath = isEdit ? `tbas/${tba.tbaId!}` : 'tbas';
    const { data } = await httpService.request<{ data: TransactionBasedAnalysis }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: tba
    });

    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const tbasSlice = createSlice({
  name: 'tbas',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(deleteTBA.fulfilled, (state: TBAsState, action: PayloadAction<number>) => {
        const idx = (state.tbas ?? []).findIndex(({ tbaId }) => tbaId === action.payload);
        if (idx >= 0) {
          state.tbas!.splice(idx, 1);
        }
      })
      .addCase(fetchTBAs.fulfilled, (state: TBAsState, action: PayloadAction<TransactionBasedAnalysis[]>) => {
        state.tbas = action.payload;
      })
      .addMatcher(
        (action) => action.type.match(/^tbas\/.+\/pending$/),
        (state: TBAsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^tbas\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default tbasSlice.reducer;
