import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { currentPBA, fetchPBAs } from '../../redux/profitBasedAnalyses';
import { fetchCurrentMajorClassifications, fetchMajorClassifications } from '../../redux/testedParty';
import { selectPBA, selectPBAsList } from '../../selectors';

interface ConnectorProps {
  component: any;
  // ToDo: Change this to Component type
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const pbas = useSelector(selectPBAsList);
  const pba = useSelector(selectPBA(pbaId));

  useEffect(() => {
    if (pbas === null) {
      dispatch(fetchPBAs);
    } else {
      dispatch(fetchCurrentMajorClassifications(pbaId));
    }
  }, [dispatch, pbas, pbaId]);

  useEffect(() => {
    dispatch(fetchMajorClassifications());
    dispatch(currentPBA);
  }, [dispatch, pba]);

  return <Component />;
};

export default Connector;
