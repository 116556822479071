import { ComponentType, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BackgroundInfoType } from '../../models';
import {
  copyStatementOfFacts,
  fetchStatementOfFacts,
  resetStatementOfFacts,
  saveStatementOfFacts
} from '../../redux/statementOfFacts';
import { fetchTransactions } from '../../redux/transactions';
import { selectTransactionsList, selectTransaction, selectStatementOfFacts, selectCountries } from '../../selectors';
import { fetchStatementOfFactsCopySources } from '../../utils/statementOfFacts';
import { StatementOfFactsOverviewProps } from '../StatementOfFactsOverview';

const Connector = ({ component: Component }: { component: ComponentType<StatementOfFactsOverviewProps> }) => {
  const dispatch = useDispatch();
  const transactionId = Number(useParams<{ transactionId: string }>().transactionId);
  const transactions = useSelector(selectTransactionsList);
  const transaction = useSelector(selectTransaction(transactionId));
  const statementOfFacts = useSelector(
    selectStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId })
  );
  const countries = useSelector(selectCountries);

  useEffect(() => {
    if (!transactions) {
      dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (!statementOfFacts) {
      dispatch(fetchStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId }));
    }
  }, [dispatch, statementOfFacts, transactionId]);

  const fetchCopySources = useCallback(
    async (jurisdiction: string) =>
      fetchStatementOfFactsCopySources({
        type: BackgroundInfoType.Transaction,
        id: transactionId,
        jurisdiction,
        countries
      }),
    [transactionId, countries]
  );

  return (
    <Component
      name={transaction?.identifier ?? ''}
      namespace="transactions"
      statementOfFacts={statementOfFacts}
      fetchCopySources={fetchCopySources}
      onAddSubtopic={(args) => {
        dispatch(saveStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId, ...args }));
      }}
      onCopySubtopics={(args) => {
        dispatch(copyStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId, ...args }));
      }}
      onResetSubtopics={({ jurisdiction }) => {
        dispatch(resetStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId, jurisdiction }));
      }}
      onSubmit={(args) => {
        dispatch(saveStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId, ...args }));
      }}
    />
  );
};

export default Connector;
