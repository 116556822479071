import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReportsSplashImage } from '../../../svgs';
import { LocalReportsSplashScreenProps } from '../LocalReports.proptype';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    margin: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  cardContentContainer: {
    width: '50%',
    padding: theme.spacing(4)
  },
  cardContentTitle: {
    marginBottom: '0px',
    color: theme.palette.text.primary
  },
  cardContentSubTitle: {
    marginTop: '0px',
    color: theme.palette.text.secondary,
    fontSize: '1.16rem',
    marginBottom: theme.spacing(2)
  },
  cardContentText: {
    fontSize: '1rem',
    lineHeight: '22px'
  }
}));

export const LocalReportsSplashScreen = (props: LocalReportsSplashScreenProps) => {
  const { title } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer}>
      <ReportsSplashImage />
      <Box className={classes.cardContentContainer}>
        <Typography variant="h2" className={classes.cardContentTitle}>
          {title}
        </Typography>
        <Typography variant="h2" className={classes.cardContentSubTitle}>
          {t('reports:title-local-reports-splashscreen')}
        </Typography>
        <Typography className={classes.cardContentText}>{t('reports:message-local-reports-splashscreen')}</Typography>
      </Box>
    </Box>
  );
};
