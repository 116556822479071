import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch, Theme, makeStyles } from '@material-ui/core';

export interface ProgressButtonProps {
  complete: boolean;
  onChange?: (complete: boolean) => void;
}

const useStyles = makeStyles<Theme, ProgressButtonProps>((theme) => ({
  labelRoot: {
    backgroundColor: ({ complete }) => (complete ? theme.palette.primary.light : theme.palette.secondary.light),
    borderRadius: 20,
    color: ({ complete }) => (complete ? theme.palette.primary.main : theme.palette.text.primary),
    margin: 0,
    padding: theme.spacing(0.5, 1.5, 0.5, 1),
    textTransform: 'uppercase'
  },
  label: {
    fontSize: '0.9em'
  },
  switchRoot: {
    display: 'flex',
    height: 12,
    marginRight: '1em',
    padding: 0,
    width: 28
  },
  switchBase: {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        opacity: 1
      }
    }
  },
  track: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: 1
  },
  thumb: {
    color: theme.palette.common.white,
    boxShadow: 'none',
    height: 8,
    width: 8
  }
}));

export const ProgressButton = ({ complete, onChange }: ProgressButtonProps) => {
  const { label, labelRoot, switchRoot, ...switchClasses } = useStyles({ complete });
  const { t } = useTranslation();
  return (
    <FormControlLabel
      classes={{ root: labelRoot, label }}
      control={
        <Switch
          classes={{ root: switchRoot, ...switchClasses }}
          checked={complete}
          color={complete ? 'primary' : 'secondary'}
          onChange={(event, checked) => {
            onChange?.(checked);
          }}
        />
      }
      label={t(complete ? 'label-complete' : 'label-in-progress')}
    />
  );
};
