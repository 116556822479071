import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MethodEvaluation,
  NormalizedStateSlice,
  ProfitBasedEvaluation,
  TransactionBasedEvaluation,
  TransactionEvaluations
} from '../../models';
import httpService from '../../services/http';
import { isAnalysisUsed } from '../../utils';
import { getWorkingContainer } from '../baseData';

interface TransactionEvaluationsState extends NormalizedStateSlice<TransactionEvaluations> {
  error?: string;
}

const initialState: TransactionEvaluationsState = { byId: null, allIds: [] };

export const fetchTransactionEvaluations = createAsyncThunk<
  TransactionEvaluations,
  { transactionId: number },
  { rejectValue: Error }
>('transactionEvaluations/fetch', async ({ transactionId }, { rejectWithValue }) => {
  try {
    const [methodData, pbaData, tbaData] = await Promise.all([
      httpService.request<{ data: MethodEvaluation[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions/${transactionId}/methods/all-methods`
      }),
      httpService.request<{ data: ProfitBasedEvaluation[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions/${transactionId}/pbas/method-evaluations`
      }),
      httpService.request<{ data: TransactionBasedEvaluation[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions/${transactionId}/tbas/method-evaluations`
      })
    ]);

    return {
      methods: methodData.data.data,
      pbas: pbaData.data.data,
      tbas: tbaData.data.data
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const saveMethodEvaluation = createAsyncThunk<
  { method: MethodEvaluation; analysis: TransactionBasedEvaluation | ProfitBasedEvaluation },
  { transactionId: number; method: MethodEvaluation; analysis: TransactionBasedEvaluation | ProfitBasedEvaluation },
  { rejectValue: Error }
>('transactionEvaluations/save', async ({ transactionId, method, analysis }, { rejectWithValue }) => {
  try {
    const evaluation = 'tba' in analysis ? analysis.methodEvaluation! : analysis.methodEvaluations![0]!;
    const used = isAnalysisUsed(analysis, method);
    // unfortunately, unused TBAs and PBAs don't have their own storage for discussion points.
    // for the time being, we store discussion points at method level
    const url = used
      ? `${'tba' in analysis ? 'tbas' : 'pbas'}/${
          'tba' in analysis ? analysis.tba.tbaId : analysis.pba.pbaId
        }/method-evaluation`
      : 'methods/not-applied/0';
    const data = used
      ? {
          isCompleted: evaluation.isCompleted ?? false,
          srcDiscussion: evaluation.srcDiscussion ?? '',
          destDiscussion: evaluation.destDiscussion ?? '',
          sourceEvaluationType: evaluation.sourceEvaluationType ?? 0,
          destinationEvaluationType: evaluation.destinationEvaluationType ?? 0
        }
      : {
          isCompleted: method.isCompleted ?? false,
          evaluationMethodId: method.evaluationMethodId,
          srcDiscussion: method.srcDiscussion ?? '',
          destDiscussion: method.destDiscussion ?? ''
        };

    await httpService.request({
      method: 'put',
      apiUrlKey: 'baseUrl',
      relativePath: `transactions/${transactionId}/${url}`,
      data
    });

    return { analysis, method };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const saveMethodEvaluationEditorText = createAsyncThunk<
  { method: MethodEvaluation; analysis: TransactionBasedEvaluation | ProfitBasedEvaluation },
  { transactionId: number; method: MethodEvaluation; analysis: TransactionBasedEvaluation | ProfitBasedEvaluation },
  { rejectValue: Error }
>(
  'transactionEvaluations/saveMethodEvaluationEditorText/patch',
  async ({ transactionId, method, analysis }, { rejectWithValue }) => {
    try {
      const evaluation = 'tba' in analysis ? analysis.methodEvaluation! : analysis.methodEvaluations![0]!;
      const used = isAnalysisUsed(analysis, method);
      // unfortunately, unused TBAs and PBAs don't have their own storage for discussion points.
      // for the time being, we store discussion points at method level
      const url = used
        ? `${'tba' in analysis ? 'tbas' : 'pbas'}/${
            'tba' in analysis ? analysis.tba.tbaId : analysis.pba.pbaId
          }/method-evaluation`
        : 'methods/not-applied/0';
      const data = used
        ? {
            isCompleted: evaluation.isCompleted ?? false,
            srcDiscussion: evaluation.srcDiscussion ?? '',
            destDiscussion: evaluation.destDiscussion ?? '',
            sourceEvaluationType: evaluation.sourceEvaluationType ?? 0,
            destinationEvaluationType: evaluation.destinationEvaluationType ?? 0
          }
        : {
            isCompleted: method.isCompleted ?? false,
            evaluationMethodId: method.evaluationMethodId,
            srcDiscussion: method.srcDiscussion ?? '',
            destDiscussion: method.destDiscussion ?? ''
          };

      await httpService.request({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions/${transactionId}/${url}`,
        data
      });

      return { analysis, method };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

const transactionEvaluationsSlice = createSlice({
  name: 'transactionEvaluations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchTransactionEvaluations.fulfilled, (state, action) => {
        const { transactionId } = action.meta.arg;
        if (!state.byId) {
          state.byId = {};
        }

        state.byId[transactionId] = action.payload;
        if (!state.allIds.includes(transactionId)) {
          state.allIds.push(transactionId);
        }
      })
      .addCase(saveMethodEvaluation.fulfilled, (state, action) => {
        const {
          transactionId,
          method: { evaluationMethodId }
        } = action.meta.arg;
        const { method: newMethod, analysis: newAnalysis } = action.payload;
        const tbaId = 'tba' in newAnalysis ? newAnalysis.tba.tbaId : null;
        const pbaId = 'pba' in newAnalysis ? newAnalysis.pba.pbaId : null;

        if (!state.byId) {
          state.byId = {};
        }

        if (!state.allIds.includes(transactionId)) {
          state.allIds.push(transactionId);
        }

        state.byId[transactionId] = {
          methods: state.byId[transactionId].methods.map((method) =>
            method.evaluationMethodId === evaluationMethodId ? newMethod : method
          ),
          tbas: state.byId[transactionId].tbas.map((analysis) =>
            analysis.tba.tbaId === tbaId ? (newAnalysis as TransactionBasedEvaluation) : analysis
          ),
          pbas: state.byId[transactionId].pbas.map((analysis) =>
            analysis.pba.pbaId === pbaId ? (newAnalysis as ProfitBasedEvaluation) : analysis
          )
        };
      })
      .addMatcher(
        (action) => action.type.match(/^transactionEvaluations\/.+\/pending$/),
        (state: TransactionEvaluationsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^transactionEvaluations\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default transactionEvaluationsSlice.reducer;
