import { useTranslation } from 'react-i18next';
import { Circle } from '../../../components/Circle';
import { GenericDropDown, GenericDropDownItem } from '../../../components/GenericDropDown';
import appColors from '../../../styles/appColors';
import { captionOverline } from '../../../styles/typography';
import { ReportStatus } from '../LocalReports.proptype';

export interface StatusDropDownProps {
  anchorEl: Element | null;
  value: ReportStatus;
  onChange: (value: ReportStatus) => void;
  onClose: () => void;
  disabled?: boolean;
}

const fontStyle = {
  ...captionOverline,
  letterSpacing: '0.0375rem'
};

export const StatusDropDown = ({ anchorEl, value, onChange, onClose, disabled }: StatusDropDownProps) => {
  const { t } = useTranslation();

  const handleChange = (value: ReportStatus) => {
    onChange(value);
    onClose();
  };

  const items: GenericDropDownItem[] = [
    {
      text: t('reports:draft').toUpperCase(),
      textCss: { ...fontStyle, color: appColors.reports.draft.border },
      icon: <Circle color={appColors.reports.draft.fill} borderColor={appColors.reports.draft.border} />,
      onClick: () => {
        handleChange('draft');
      },
      selected: value === 'draft'
    },
    {
      text: t('reports:review').toUpperCase(),
      textCss: { ...fontStyle, color: appColors.reports.review.text },
      icon: <Circle color={appColors.reports.review.fill} borderColor={appColors.reports.review.border} />,
      onClick: () => {
        handleChange('review');
      },
      selected: value === 'review'
    },
    {
      text: t('reports:published').toUpperCase(),
      textCss: { ...fontStyle, color: appColors.reports.published.text },
      icon: <Circle color={appColors.reports.published.fill} borderColor={appColors.reports.published.border} />,
      onClick: () => {
        handleChange('published');
      },
      selected: value === 'published'
    },
    {
      text: t('reports:final').toUpperCase(),
      textCss: { ...fontStyle, color: appColors.reports.final.fill },
      icon: <Circle color={appColors.reports.final.fill} />,
      onClick: () => {
        handleChange('final');
      },
      selected: value === 'final'
    }
  ];

  return (
    <GenericDropDown
      title={t('reports:label-report-status').toUpperCase()}
      items={items}
      anchorEl={anchorEl}
      minWidth="12.31rem"
      disabled={disabled}
      onClose={onClose}
    />
  );
};
