import { useCallback, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { LocalReportsHeader } from './LocalReportsHeader';
import { LocalReportsList } from './LocalReportsList';
import { LocalReportsSplashScreen } from './LocalReportsSplashScreen';
import { CenteredProgress } from '../../../components';
import tokens from '../../../styles/designTokens';
import { LocalReportVersionsModal } from '../../LocalReportVersionsModal';
import { LocalReportsProps } from '../LocalReports.proptype';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  reportsContainer: {
    backgroundColor: tokens.neutral70,
    display: 'flex',
    height: '100%',
    overflowY: 'auto'
  }
}));

export const LocalReports = ({
  country,
  localReports,
  onCreateLocalReportClick,
  fetchDataForReportTiles,
  quickGenerate,
  onUpdateReportStatus
}: LocalReportsProps) => {
  const classes = useStyles();
  const [reportVersionsModalIsOpen, setReportVersionsModalIsOpen] = useState(false);
  const [reportId, setReportId] = useState(1);
  const [selectedReportName, setSelectedReportName] = useState<string>('');

  const onManageVersions = useCallback(
    (reportId: number) => {
      setReportId(reportId);
      setReportVersionsModalIsOpen(true);
      const selectedReport = localReports?.find((report) => report.internalLocalfileReportId === reportId);
      setSelectedReportName(selectedReport?.name ?? '');
    },
    [localReports]
  );

  const closeLocalReportVersionsModal = (resetRequired: boolean) => {
    setReportVersionsModalIsOpen(false);
    if (resetRequired) {
      fetchDataForReportTiles();
    }
  };

  const renderContent = () => {
    if (!localReports) {
      return <CenteredProgress />;
    }

    if (localReports.length > 0) {
      return (
        <LocalReportsList
          reportsData={localReports}
          quickGenerate={quickGenerate}
          onManageVersions={onManageVersions}
          onUpdateReportStatus={onUpdateReportStatus}
        />
      );
    }

    return <LocalReportsSplashScreen title={country.name} />;
  };

  return (
    <Box className={classes.container}>
      <LocalReportsHeader title={country.name} onCreateLocalReport={onCreateLocalReportClick} />
      <Box className={classes.reportsContainer}>{renderContent()}</Box>
      {reportVersionsModalIsOpen && (
        <LocalReportVersionsModal
          reportId={reportId}
          reportName={selectedReportName}
          onClose={closeLocalReportVersionsModal}
        />
      )}
    </Box>
  );
};
