import { getCompletionState } from '../app/MethodEvaluation/components/utils';
import { Entity, Transaction } from '../models';
import { RootState } from '../redux';
import { findTransactionSource, findTransactionDestination, isMethodApplicable, isAnalysisUsed } from '../utils';

export const selectTransactionEvaluations = ({ transactionId }: { transactionId: number }) => ({
  transactionEvaluations
}: RootState) => transactionEvaluations.byId?.[transactionId];

export const selectEvaluationsCompletion = ({
  transactionId,
  transaction,
  entities
}: {
  transactionId: number;
  transaction: Transaction | null;
  entities: Entity[] | null;
}) => ({ transactionEvaluations }: RootState) => {
  const evaluations = transactionEvaluations.byId?.[transactionId];
  let allCompleted: boolean | null = null;

  if (evaluations && transaction && entities) {
    const transactionType = transaction?.transactionType.transactionTypeId ?? 0;
    const entityById = new Map((entities ?? []).map((entity) => [entity.entityId, entity]));
    const source = findTransactionSource(transaction, entityById);
    const destination = findTransactionDestination(transaction, entityById);

    allCompleted = !evaluations.methods.some((method) => {
      const appliesToSource = isMethodApplicable(method.methodName, transactionType, source!.domicile.isoCode);
      const appliesToDestination = isMethodApplicable(
        method.methodName,
        transactionType,
        destination!.domicile.isoCode
      );
      const disabled = !appliesToSource && !appliesToDestination;

      return [...evaluations.pbas, ...evaluations.tbas].some((analysis) => {
        const used = isAnalysisUsed(analysis, method);
        const checked = used ? getCompletionState(analysis) : method.isCompleted ?? false;

        return !checked && !disabled;
      });
    });
  }

  return allCompleted;
};
