import { TextField, makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  popupIndicatorOpen: {
    transform: 'none'
  }
}));

export interface SearchBoxProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput' | 'popupIcon'> {
  error?: boolean;
}

const SearchBox = <T,>({ error, ...props }: SearchBoxProps<T>) => (
  <Autocomplete
    fullWidth
    disableClearable
    classes={useStyles()}
    {...props}
    popupIcon={!props.disabled && <SearchIcon />}
    renderInput={(params) => (
      <TextField placeholder={props.placeholder ?? ''} {...params} error={error} variant="outlined" />
    )}
  />
);

export { SearchBox };
