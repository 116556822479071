import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, makeStyles } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { SearchBox, TPModal } from '../../../components';
import { CopyBackgroundInfoSource, CORE_KEY } from '../../../models';
import { TranslationNamespace } from '../StatementOfFactsOverview.proptype';

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    fill: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    verticalAlign: 'bottom'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 6)
  },
  infoContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > *': {
      flexGrow: 1
    }
  },
  subtitle: {
    color: theme.palette.text.secondary
  }
}));

interface CopyModalProps {
  isOpen: boolean;
  namespace: TranslationNamespace;
  name: string;
  jurisdiction: string;
  options: CopyBackgroundInfoSource[];
  onClose: () => void;
  onSubmit: (args: { source: CopyBackgroundInfoSource }) => void;
}

export const CopyModal = ({ isOpen, namespace, name, jurisdiction, options, onSubmit, onClose }: CopyModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit: submitWrapper, control, errors } = useForm();

  return (
    <TPModal
      isOpen={isOpen}
      title={
        <>
          <FileCopyOutlined fontSize="large" className={classes.icon} />
          {t(`${namespace}:title-copy-sof`)}
        </>
      }
      actions={[
        { name: t('action-cancel'), handler: onClose },
        { name: t('action-load'), variant: 'contained', color: 'primary', handler: submitWrapper(onSubmit) }
      ]}
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box>
          <Typography variant="caption" component="div">
            {t('message-copy-from')}
          </Typography>
          <Typography className={classes.subtitle}>{t('message-use-existing-sof')}</Typography>
        </Box>
        <Box className={classes.infoContainer}>
          <Box>
            <Typography variant="caption" component="div">
              {t(`${namespace}:label-copy-destination`)}
            </Typography>
            <TextField disabled fullWidth margin="dense" variant="outlined" value={name} />
          </Box>
          <Box>
            <Typography variant="caption" component="div">
              {t(`${namespace}:action-copy`)}
            </Typography>
            <Controller
              name="source"
              render={({ onChange, value }) => (
                <Box marginTop={0.7} marginBottom={0.2}>
                  <SearchBox
                    placeholder={t(`${namespace}:placeholder-select-existing`)}
                    error={Boolean(errors.source)}
                    value={value === '' ? null : value}
                    options={options}
                    getOptionLabel={(option) => option?.identifier ?? ''}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                  />
                </Box>
              )}
              defaultValue=""
              control={control}
              rules={{ required: true }}
            />
            {errors.source && (
              <Typography color="error" variant="caption" component="div">
                {t('invalid-entry-required-sof-source')}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="caption" component="div">
            {t('label-sof-section')}
          </Typography>
          <TextField
            disabled
            style={{ width: '50%' }}
            margin="dense"
            variant="outlined"
            value={jurisdiction === CORE_KEY ? t('label-core') : jurisdiction}
          />
        </Box>
      </Box>
    </TPModal>
  );
};
