import { ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../models';

export enum StudyDashboardTab {
  ProfitBasedAnalysisTab = 'profit-based-analysis',
  TransactionBasedAnalysisTab = 'transaction-based-analysis',
  ProfitBasedAnalysisDashboard = 'pba-dashboard'
}
export interface StudyDashboardEventsProps {
  onEdit?: (elementId: number) => void;
  onDelete?: (elementId: number) => void;
  onAddSingle?: () => void;
}
export interface StudyDashboardProps extends StudyDashboardEventsProps {
  studyId: number;
  tab: StudyDashboardTab;
}
export interface StudyTabsTableProps extends StudyDashboardEventsProps {
  data?: ProfitBasedAnalysis[] | TransactionBasedAnalysis[] | null;
  selectedKey: string;
}
export interface DisplayablePBAsData {
  name: string;
  testedPartyEntity: string;
  method: string;
}
