import * as fonts from './fonts';

const tokens = {
  // https://crossbordersolutions.invisionapp.com/share/QW1024KA5PTX#/447962393_Colour
  core1: '#464d59',
  core2: '#677283',
  core3: '#363636',
  error15: '#ffe8ec',
  error100: '#b00020',
  neutral100: '#8e949f',
  neutral90: '#e2e3e6',
  neutral80: '#efeff2',
  neutral70: '#f5f5f7',
  neutral60: '#faf9fa',
  product100: '#3c2a8e',
  progress20: '#faeab7',
  product90: '#4a36a7',
  product25: '#dcdbfe',
  product15: '#efefff',
  supportColorA20: '#c9e7f1',
  supportColorA15: '#e5f4f9',
  supportColorA100: '#0376a0',
  themeAp100: '#3C2A8E',
  black: '#000000',
  blackAlpha20: '#00000033',
  white: '#ffffff',
  purple: '#bd6dff',
  purpleLight1: '#8d74ff',
  purpleLight2: '#6e56db',
  purpleLight3: '#fbfbff',
  pink: '#c940e0',
  spacing: 12,
  ...fonts,
  baseFontSize: 14
};

export default tokens;
