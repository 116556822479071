import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { ToggleButton } from '@xbs/xbs-common-ui';
import clsx from 'clsx';
import { FinancialTableFooterProps } from '../FinancialTable.proptype';

const useStyles = makeStyles((theme) => ({
  tFooter: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderBottom: '0'
    },
    '& > div': {
      padding: 0
    }
  }
}));

export const FinancialTableFooter = ({ columnYears, onSetCompletion, completionByYear }: FinancialTableFooterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableRow component="div" className="tr">
      <TableCell component="div" className={clsx(classes.tFooter, 'td')} scope="row" />
      {columnYears.map((columnYear) => {
        return (
          <TableCell key={`${columnYear}footer`} component="div" className={clsx(classes.tFooter, 'td')} scope="row">
            {Number.isNaN(columnYear) ? null : (
              <ToggleButton
                leftCaption={t(completionByYear[columnYear] ? 'label-complete' : 'label-incomplete')}
                hasBackground={false}
                hasBorder={false}
                checked={completionByYear[columnYear]}
                handleChange={(_, checked) => {
                  onSetCompletion(columnYear, checked);
                }}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
