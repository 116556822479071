import { ComponentType } from 'react';

export interface FunctionalAnalysisTableProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId: number
  ) => Promise<void>;
  deleteFunctionalAnalysisCharacteristic: (characteristicId: number, areaId: number) => void;
  createNewCharacteristic: (characteristicName: string, areaId: number) => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  setSelectedAnalysis: (item: FunctionalAnalysisCharacteristic) => void;
  expandedSections: string;
  setExpandedSections: (item: string) => void;
  setCurrentSection: (item: number) => void;
  currentSection: number;
}

export interface FunctionalAnalysisProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId?: number
  ) => Promise<void>;
  setSelectedAnalysis: (item: FunctionalAnalysisCharacteristic) => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  setCurrentSection: (item: number) => void;
  currentSection: number;
}

export interface ConnectorProps extends FunctionalAnalysisProps {
  component: ComponentType<FunctionalAnalysisTableProps>;
}

export interface FunctionalCharacteristicType {
  functionalCharacteristicTypeId: number;
  sortOrder: number;
  name: string;
  discussion: string;
}

export interface FunctionalAnalysisCharacteristic {
  characteristicName: string;
  discussion: string;
  otherParticipantContribution: boolean;
  pbaFunctionalAnalysisDataId: number;
  sortOrder: number;
  testedPartyContribution: boolean;
  discussionOnly?: boolean;
  functionalCharacteristicType?: FunctionalCharacteristicType | null;
}

export interface FunctionalAnalysisListItemProps {
  item: FunctionalAnalysisCharacteristic;
  setSelectedAnalysis: (item: FunctionalAnalysisCharacteristic) => void;
  setCurrentSection: (areaId: number) => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId: number
  ) => Promise<void>;
  areaId: number;
  deleteFunctionalAnalysisCharacteristic: (characteristicId: number, areaId: number) => void;
  functionalAnalysisCharacteristics: FunctionalAnalysisCharacteristic[];
}

export interface FunctionalAnalysisDiscussionEditorProps {
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  currentSection: number;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId: number
  ) => Promise<void>;
}

export const functionalAnalysisHeaders = ['Functions', 'Risks', 'Assets'];

export enum AreaIds {
  AreaIdFunctions = 1,
  AreaIdRisks,
  AreaIdAssets
}

export const InitialExpanedSections =
  String(AreaIds.AreaIdFunctions - 1) + String(AreaIds.AreaIdRisks - 1) + String(AreaIds.AreaIdAssets - 1);
