import { useTranslation } from 'react-i18next';
import { makeStyles, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { LocalReportVersionsTableProps, ReportVersion } from './LocalReportVersionsModal.prototypes';
import { LocalReportVersionsRow } from './LocalReportVersionsRow';
import { body1 } from '../../styles/typography';

const useStyles = makeStyles(() => ({
  container: {
    height: '90%'
  },
  headerCell: {
    ...body1
  }
}));

export const LocalReportVersionsTable = ({
  reportVersions,
  updateActiveVersion,
  startDeleteVersion,
  downloadReportVersion
}: LocalReportVersionsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader component="div" className={classes.headerCell}>
        <TableHead component="div">
          <TableRow component="div" className="tr">
            <TableCell key="Version" component="div" className={classes.headerCell} scope="row">
              {t('reports:version')}
            </TableCell>
            <TableCell key="Status" component="div" className={classes.headerCell} scope="row">
              {t('reports:status')}
            </TableCell>
            <TableCell key="Details" component="div" className={classes.headerCell} scope="row">
              {t('reports:details')}
            </TableCell>
            <TableCell key="DateTime" component="div" className={classes.headerCell} scope="row">
              {t('reports:dateTime')}
            </TableCell>
            <TableCell key="Active" component="div" className={classes.headerCell} scope="row">
              {t('reports:active')}
            </TableCell>
            <TableCell key="Download" component="div" className={classes.headerCell} scope="row">
              &nbsp;
            </TableCell>
            <TableCell key="More" component="div" className={classes.headerCell} scope="row">
              &nbsp;
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportVersions.map((version: ReportVersion) => (
            <LocalReportVersionsRow
              key={`version-${String(version.version)}`}
              version={version}
              startDeleteVersion={startDeleteVersion}
              updateActiveVersion={updateActiveVersion}
              downloadReportVersion={downloadReportVersion}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
