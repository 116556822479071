import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { AddEntitiesButton, EntitiesSplashScreen, EntitiesTable, OrgChart } from '.';
import { CenteredProgress, ContentLayout } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { OrgChartIcon, TableIcon } from '../../../svgs';
import { EntitiesProps } from '../Entities.proptype';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    width: '100%',
    whiteSpace: 'nowrap'
  }
}));

export const Entities = (props: EntitiesProps) => {
  const { entities, onOpenCurrencyConversion } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isList, setIsList] = useState(true);

  return (
    <Box height={1}>
      {entities === null ? (
        <CenteredProgress />
      ) : entities?.length ? (
        <ContentLayout
          title={t('entities:title')}
          actions={
            <Box className={classes.buttonContainer}>
              {documentationReviewAccess(<AddEntitiesButton {...props} />)}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onOpenCurrencyConversion();
                }}
              >
                &yen;$&pound;
              </Button>
              <ToggleButtonGroup
                exclusive
                size="small"
                value={isList ? 'list' : 'chart'}
                onChange={(_: any, value: any) => {
                  setIsList(value === 'list');
                }}
              >
                <ToggleButton value="list">
                  <TableIcon />
                </ToggleButton>
                <ToggleButton value="chart">
                  <OrgChartIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }
          main={isList ? <EntitiesTable {...props} /> : <OrgChart {...props} />}
        />
      ) : (
        <EntitiesSplashScreen {...props} />
      )}
    </Box>
  );
};
