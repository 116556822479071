import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import { Editor } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { hasEditAccess } from '../../../utils';
import useDebounce from '../../../utils/debounce';
import { ProgressButton } from '../../ProgressButton';
import { TransactionDescriptionProps } from '../TransactionEvaluation.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex'
  },
  buttonContainer: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    gap: theme.spacing(0.5)
  },
  description: {
    flexGrow: 1,
    marginRight: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: '16px'
  }
}));

export const TransactionDescription = ({
  title,
  content,
  completed,
  onOpenNote,
  onCompletionChange,
  onContentChange,
  onSaveChange,
  onAutoSave
}: TransactionDescriptionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [handleEditorAutoSave] = useDebounce(() => {
    onAutoSave();
  }, 3000);

  useEffect(() => {
    handleEditorAutoSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.container}>
        <Typography className={classes.description}>{t('transactions:message-evaluate-transaction')}</Typography>
        <Box className={classes.buttonContainer}>
          {documentationReviewAccess(<ProgressButton complete={completed} onChange={onCompletionChange} />)}
          <IconButton aria-label={t('action-add-note')} onClick={onOpenNote}>
            <PostAdd />
          </IconButton>
        </Box>
      </Box>
      <Editor
        theme="TransferPricing"
        value={content ?? ''}
        disabled={!hasEditAccess() || completed}
        onEditorChange={(content) => {
          onContentChange(content);
        }}
        onBlur={onSaveChange}
      />
    </Box>
  );
};
