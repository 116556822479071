import { Box, Paper, makeStyles } from '@material-ui/core';
import { FunctionalAnalysisHeader } from './components/FunctionalAnalysisHeader';
import tokens from '../../styles/designTokens';
import { FunctionalAnalysisTable } from '../FunctionalAnalysisTable';
import { FunctionalAnalysisProps } from '../FunctionalAnalysisTable/FunctionalAnalysisTable.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    background: tokens.neutral80
  },
  rowContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: theme.spacing(2)
  },
  paper: {
    height: '100%',
    backgroundColor: theme.palette.background.default
  }
}));

export const FunctionalAnalysis = ({
  functionalAnalysisCharacteristics,
  editFunctionalAnalysisCharacteristic,
  selectedAnalysis,
  setSelectedAnalysis,
  currentSection,
  setCurrentSection
}: FunctionalAnalysisProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.rowContainer}>
        <Paper className={classes.paper}>
          <FunctionalAnalysisHeader
            functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
            editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
            selectedAnalysis={selectedAnalysis}
            currentSection={currentSection}
          />
          <FunctionalAnalysisTable
            functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
            editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
            selectedAnalysis={selectedAnalysis}
            setSelectedAnalysis={setSelectedAnalysis}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
          />
        </Paper>
      </Box>
    </Box>
  );
};
