import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ConnectorProps } from './CurrencyConversionModal.proptype';
import { FinancialConversion } from '../../models';
import { fetchConversionRates, saveConversionRates } from '../../redux/conversionRates';
import {
  selectConversionRates,
  selectCountries,
  selectCurrencies,
  selectEntitiesList,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { enrichWithCountriesAndCurrencies } from '../../utils';

const Connector = ({ component: Component, isOpen, onClose }: ConnectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const rates = useSelector(selectConversionRates);
  const entities = useSelector(selectEntitiesList);
  const currencies = useSelector(selectCurrencies);
  const countries = useSelector(selectCountries);
  const workingContainer = useSelector(selectWorkingContainer);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    void dispatch(fetchConversionRates());
  }, [dispatch]);

  const entitiesWithCurrency = useMemo(
    () =>
      !entities || entities.length === 0 || currencies.length === 0
        ? []
        : enrichWithCountriesAndCurrencies(entities, countries, currencies),
    [entities, countries, currencies]
  );

  const years = useMemo(
    () => (workingContainer ? Array.from({ length: 6 }, (_, i) => workingContainer.taxYear - i) : []),
    [workingContainer]
  );

  const handleSave = async (newRates: FinancialConversion[]) => {
    setIsSaving(true);
    try {
      unwrapResult(await dispatch(saveConversionRates({ newRates, t })));
      onClose();
    } catch {
      await dispatch(fetchConversionRates());
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Component
      isOpen={isOpen}
      isLoading={isSaving || rates === null}
      rates={rates ?? []}
      entities={entitiesWithCurrency}
      years={years}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default Connector;
