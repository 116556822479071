import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FinancialTypes, FinancialAreas } from '@xbs/xbs-enums';
import { EntityDetailsIncomeStatementProps } from './EntityDetailsIncomeStatement.proptype';
import { FinancialData, FinancialCompletionStatus } from '../../../models';
import { FinancialDataValueEnteredInEnum, FinancialDataObjectTypeEnum } from '../../../models/financial.interface';
import { fetchConversionRates } from '../../../redux/conversionRates';
import {
  getEntityFinancialData,
  updateEntityFinancialData,
  getEntityFinancialCompletionStatus,
  updateEntityFinancialCompletionStatus,
  getEntityAdditionalFinancialInfo
} from '../../../redux/entityFinancial/entityFinancial.actions';
import {
  selectConversionRatesByEntity,
  selectEntityCurrency,
  selectUPECurrency,
  selectWorkingContainer,
  entityFinancialSelector,
  entityFinancialCompletionStatusSelector,
  selectEntityAdditionalFinancialInfo
} from '../../../selectors';
import { TableBodyDataProps } from '../../FinancialTable/FinancialTable.proptype';

const financialInfo = {
  financialAreaId: FinancialAreas.ByName.IncomeStatement.Id,
  financialDataObjectTypeId: FinancialDataObjectTypeEnum.ENTITY,
  valueEnteredIn: FinancialDataValueEnteredInEnum.MNE
};

interface ConnectorProps {
  component: ComponentType<EntityDetailsIncomeStatementProps>;
}

const generateCompletionByYear = (
  taxYear: number,
  numberOfYears: number,
  financialAreaId: number,
  financialCompletionStatus: FinancialCompletionStatus[]
) => {
  const completionByYear: Record<number, boolean> = {};
  for (let index = 0; index < numberOfYears; index++) {
    const currentYear = taxYear - index;
    const yearStatus = financialCompletionStatus.find(
      (status) => status.taxYear === currentYear && status.financialAreaId === financialAreaId
    );
    completionByYear[currentYear] = Boolean(yearStatus?.isCompleted);
  }

  return completionByYear;
};

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balanceSheetData: FinancialData[] | null = useSelector(entityFinancialSelector);
  const workingContainer = useSelector(selectWorkingContainer);
  const { entityId: entityIdAsString }: { entityId: string } = useParams();
  const entityId = Number(entityIdAsString);
  const financialConversions = useSelector(selectConversionRatesByEntity(entityId));
  const additionalFinancialInfo = useSelector(selectEntityAdditionalFinancialInfo);

  const upeCurrency = useSelector(selectUPECurrency);
  const entityCurrency = useSelector(selectEntityCurrency(entityId));
  // valueEnteredIn: 1 always mean upeCurrency, so this option must come first.
  const currencyOptions = [upeCurrency!];
  if (entityCurrency && entityCurrency.currencyId !== upeCurrency?.currencyId) {
    currencyOptions.push(entityCurrency);
  }

  const currentTaxYear = workingContainer?.taxYear ?? 0;
  const columnYears = [currentTaxYear, currentTaxYear - 1, currentTaxYear - 2, currentTaxYear - 3, currentTaxYear - 4];

  const financialCompletionStatus = useSelector(entityFinancialCompletionStatusSelector);

  const completionByYear = generateCompletionByYear(
    currentTaxYear,
    columnYears.length,
    financialInfo.financialAreaId,
    financialCompletionStatus
  );
  const financialTableTitle = t(`analysis:income-statement`);
  const requiredFields = new Set([
    FinancialTypes.ByName.CostOfGoodsSold.Id,
    FinancialTypes.ByName.OperatingExpenses.Id,
    FinancialTypes.ByName.TotalNetSales.Id
  ]);

  useEffect(() => {
    if (!financialConversions) {
      dispatch(fetchConversionRates());
    }
  }, [dispatch, financialConversions]);

  useEffect(() => {
    dispatch(getEntityAdditionalFinancialInfo(entityId));
    dispatch(getEntityFinancialData({ ...financialInfo, entityId }));
    dispatch(getEntityFinancialCompletionStatus(entityId));
  }, [dispatch, entityId]);

  const hasFooter = true;

  const onSaveData = (data: any) => {
    const formatedData: FinancialData[] = [];
    data.forEach((row: any) => {
      columnYears.forEach((year) => {
        if (row[year]) {
          const payload: any = {
            taxYear: year,
            value: Number.isNaN(Number.parseInt(row[year].value, 10)) ? null : Number.parseInt(row[year].value, 10),
            financialType: {
              financialTypeId: FinancialTypes.ById[row.id].Id,
              name: FinancialTypes.ById[row.id].Name
            }
          };

          if (row[year].data) {
            payload.entityFinancialDataId = row[year].data.entityFinancialDataId;
          }

          if (payload.value || payload.value === 0) {
            formatedData.push(payload);
          }

          if (payload.value === null && payload.entityFinancialDataId) {
            formatedData.push(payload);
          }
        }
      });
    });

    dispatch(
      updateEntityFinancialData({
        ...financialInfo,
        valueEnteredIn: 1,
        entityId,
        financialData: formatedData
      })
    );
  };

  const handleOnSetCompletion = (taxYear: number, isCompleted: boolean) => {
    dispatch(
      updateEntityFinancialCompletionStatus({
        entityId,
        taxYear,
        financialAreaId: financialInfo.financialAreaId,
        isCompleted
      })
    );
  };

  const isFieldValueValid = (data: TableBodyDataProps, year: string) => {
    let isValid = true;

    if (
      requiredFields.has(data.id) &&
      !data[year].notAvailable &&
      (data[year].value === '' || data[year].value === 0)
    ) {
      isValid = false;
    }

    return isValid;
  };

  const isColumnValid = (columnData: TableBodyDataProps[], year: string) => {
    let atLeastOneRequiredFieldIsEmpty = false;
    let allFieldsEmpty = true;

    columnData.forEach((data) => {
      if (requiredFields.has(data.id)) {
        if (!data[year].notAvailable && (data[year].value === '' || data[year].value === 0)) {
          atLeastOneRequiredFieldIsEmpty = true;
        } else {
          allFieldsEmpty = false;
        }
      }
    });
    const columnIsValid = allFieldsEmpty ? true : !atLeastOneRequiredFieldIsEmpty;
    return columnIsValid;
  };

  const calculateFieldValues = (tableData: TableBodyDataProps[], year: string) => {
    const grossProfitCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.GrossProfit.Id);
    const totalNetSalesCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.TotalNetSales.Id);
    const costCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.CostOfGoodsSold.Id);
    const operatingExpensesCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.OperatingExpenses.Id);
    const operatingProfit: any = tableData.find((data) => data.id === FinancialTypes.ByName.OperatingProfit.Id);

    if (totalNetSalesCell[year].value === '' && costCell[year].value === '') {
      grossProfitCell[year].value = '';
    } else {
      grossProfitCell[year].value = Number(totalNetSalesCell[year].value) - Number(costCell[year].value);
    }

    if (grossProfitCell[year].value === '' && operatingExpensesCell[year].value === '') {
      operatingProfit[year].value = '';
    } else {
      operatingProfit[year].value = Number(grossProfitCell[year].value) - Number(operatingExpensesCell[year].value);
    }

    return tableData;
  };

  return (
    <Component
      title={financialTableTitle}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      hasFooter={hasFooter}
      tableData={balanceSheetData}
      financialFieldIds={FinancialTypes.ByType.IncomeStatement}
      financialReadOnlyFieldIds={[FinancialTypes.ByName.GrossProfit.Id, FinancialTypes.ByName.OperatingProfit.Id]}
      isFieldValueValid={isFieldValueValid}
      isColumnValid={isColumnValid}
      calculateFieldValues={calculateFieldValues}
      columnYears={columnYears}
      completionByYear={completionByYear}
      saveData={onSaveData}
      additionalFinancialInfo={additionalFinancialInfo}
      entityId={entityId}
      workingContainer={workingContainer}
      onSetCompletion={handleOnSetCompletion}
    />
  );
};

export default Connector;
