import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FinalTab as Component } from './components/FinalTab';
import { PbaJurisdictionPliFormat } from '../../../../models';
import {
  fetchFinalRangeComparables,
  fetchFinalRangeDiscussion,
  fetchFinalRangeResults,
  fetchJurisdictionPliFormats,
  saveFinalRangeDiscussion
} from '../../../../redux/profitBasedAnalyses/index';
import { selectJurisdictionPliFormat, selectWorkingContainer } from '../../../../selectors';
import { AppDispatch } from '../../../../store';

const Connector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const workingContainer = useSelector(selectWorkingContainer);
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgId = Number(useParams<{ pliAvgId: string }>().pliAvgId);

  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const pliFormat = jurisdictionPliFormat
    ? jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pliId)
    : null;

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId) {
      void dispatch(
        fetchFinalRangeComparables({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
      void dispatch(
        fetchFinalRangeResults({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
      void dispatch(
        fetchFinalRangeDiscussion({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
    }
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  const saveOnRangeDiscussion = useCallback(
    (rangeDiscussion: string) => {
      if (workingContainer) {
        const saveRangeDiscussion = {
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId,
          container: workingContainer,
          containerId: workingContainer.containerId,
          rangeDiscussion
        };
        void dispatch(saveFinalRangeDiscussion(saveRangeDiscussion));
      }
    },
    [compSearchId, dispatch, jurisdictionId, pliAvgId, pliId, workingContainer]
  );

  return <Component pliFormat={pliFormat} pliId={pliId} handleOnRangeDiscussion={saveOnRangeDiscussion} />;
};

export default Connector;
