import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Chip, makeStyles } from '@material-ui/core';
import { InitialRange } from './InitialRange';
import { SplitView } from '../../../../../components/SplitView';
import { RangeComparables } from '../../../../../models';
import { selectInitialRangeComparables } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { mediumFont } from '../../../../../styles/fonts';
import { captionOverline, body2 } from '../../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { CompanyTable } from '../../CompanyTable';
import { InitialRangeProps } from '../InitialRange.proptype';

const useStyles = makeStyles(() => ({
  primaryChip: {
    ...captionOverline,
    border: '0.063rem solid',
    textAlign: 'right',
    height: '2.6rem',
    width: '6rem',
    marginLeft: '0.5rem',
    backgroundColor: tokens.product15,
    color: tokens.product100,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem'
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  },
  captionOverline,
  body2
}));

export const InitialTab = ({ pliFormat, pliId }: InitialRangeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const initialComparables: RangeComparables | undefined = useSelector(selectInitialRangeComparables);

  const displayableComparables: Array<Record<string, HTMLElement | number>> = useMemo(() => {
    return (initialComparables?.data ?? []).map((comparable: any) => {
      const displayableComp: any = {};
      Object.keys(comparable).forEach((key) => {
        if (typeof comparable[key] === 'number' && key !== 'sourceId') {
          displayableComp[key] = formatPliDisplayValue(formatType, roundingValue, comparable[key]);
        } else if (typeof comparable[key] === 'string') {
          displayableComp[key] = (
            <span title={comparable[key]} className={classes.truncate}>
              {comparable[key]}
            </span>
          );
        }
      });
      return displayableComp;
    });
  }, [initialComparables?.data, formatType, roundingValue, classes.truncate]);

  return (
    <SplitView
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            companies={displayableComparables}
            columns={initialComparables?.columns ?? []}
            chips={
              displayableComparables.length > 0 && (
                <Chip
                  size="medium"
                  label={
                    <>
                      <div className={classes.captionOverline}>
                        {t('analysis:comparable-range-total').toUpperCase()}
                      </div>
                      <div className={classes.body2}>{displayableComparables.length}</div>
                    </>
                  }
                  className={classes.primaryChip}
                />
              )
            }
          />
        </div>
      }
      right={<InitialRange pliFormat={pliFormat} pliId={pliId} />}
    />
  );
};
