import { Dispatch } from 'redux';
import {
  SET_ENTITYFINANCIAL_DATA,
  SET_ENTITYFINANCIAL_REQUEST_STATUS,
  GetEntityFinancialDataType,
  UpdateEntityFinancialDataType,
  SET_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_ENTITY_ADDITIONAL_FINANCIAL_INFO
} from './entityFinancial.types';
import { FinancialData, FinancialCompletionStatus, Container } from '../../models';
import httpService from '../../services/http';

const getEntityFinancialDataSuccess = (data: FinancialData[]) => ({
  type: SET_ENTITYFINANCIAL_DATA,
  payload: data
});

const getEntityAdditionalFinancialInfoSuccess = (data: string) => ({
  type: SET_ENTITY_ADDITIONAL_FINANCIAL_INFO,
  payload: typeof data === 'string' ? data : ''
});

const setEntityFinancialRequestStatus = (status: string, error: unknown = null) => ({
  type: SET_ENTITYFINANCIAL_REQUEST_STATUS,
  payload: {
    status,
    error
  }
});

export const getEntityFinancialCompletionStatusSuccess = (data: FinancialCompletionStatus[]) => ({
  type: SET_ENTITY_FINANCIAL_COMPLETION_STATUS,
  payload: data
});

export const setEntityFinancialCompletionRequestStatus = (status: string, error: unknown = null) => ({
  type: SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  payload: {
    status,
    error
  }
});

export const updateEntityFinancialCompletionStatusSuccess = (data: FinancialCompletionStatus) => ({
  type: SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS,
  payload: data
});

export const setUpdateEntityFinancialCompletionRequestStatus = (status: string, error: unknown = null) => ({
  type: SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  payload: {
    status,
    error
  }
});

export const getEntityFinancialData = ({
  entityId,
  financialAreaId,
  financialDataObjectTypeId
}: GetEntityFinancialDataType) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending'));

      const {
        data: { data }
      } = await httpService.request<{ data: FinancialData[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `financial-data/${entityId}/area/${financialAreaId}/type/${financialDataObjectTypeId}`
      });

      dispatch(getEntityFinancialDataSuccess(data));
      dispatch(setEntityFinancialRequestStatus('success'));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', error));
    }
  };
};

export const getEntityAdditionalFinancialInfo = (entityId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending'));

      const {
        data: { data }
      } = await httpService.request<{ data: string }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/legal-entity/${entityId}/financials-info`
      });

      dispatch(getEntityAdditionalFinancialInfoSuccess(data));
      dispatch(setEntityFinancialRequestStatus('success'));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', error));
    }
  };
};

export const updateEntityFinancialsInfo = ({
  container,
  financialsInfo,
  entityId
}: {
  container: Container;
  financialsInfo: string;
  entityId: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending'));

      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/legal-entity/${entityId}/financials-info`,
        data: { container, financialsInfo }
      });

      dispatch(setEntityFinancialRequestStatus('success'));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', error));
    }
  };
};

export const updateEntityFinancialData = ({
  entityId,
  financialAreaId,
  financialDataObjectTypeId,
  financialData,
  valueEnteredIn
}: UpdateEntityFinancialDataType) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending'));

      const dataByTaxYear: Record<number, FinancialData[]> = {};

      // Separating data by tax year because financialData post method API requires for data to have same tax year.
      for (const item of financialData) {
        if (typeof item.value !== 'number' && item.value !== null) {
          continue;
        }

        if (!dataByTaxYear[item.taxYear]) {
          dataByTaxYear[item.taxYear] = [];
        }

        dataByTaxYear[item.taxYear].push(item);
      }

      const promises = Object.values(dataByTaxYear).map(async (item) => {
        return httpService.request<{ data: FinancialData[] }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `financial-data/${entityId}/area/${financialAreaId}/type/${financialDataObjectTypeId}`,
          data: {
            valueEnteredIn,
            financialData: item
          }
        });
      });

      await Promise.all(promises);
      dispatch(setEntityFinancialRequestStatus('success'));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', error));
    }
  };
};

export const getEntityFinancialCompletionStatus = (entityId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialCompletionRequestStatus('pending'));
      const {
        data: { data }
      } = await httpService.request<{ data: FinancialCompletionStatus[] }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/entity-financials/completion-status/${entityId}`
      });
      dispatch(getEntityFinancialCompletionStatusSuccess(data));
      dispatch(setEntityFinancialCompletionRequestStatus('success'));
    } catch (error: unknown) {
      dispatch(setEntityFinancialCompletionRequestStatus('failure', error));
    }
  };
};

export const updateEntityFinancialCompletionStatus = (financialStatus: FinancialCompletionStatus) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateEntityFinancialCompletionRequestStatus('pending'));

      await httpService.request<{ data: FinancialCompletionStatus }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/entity-financials/completion-status`,
        data: {
          financialCompletionStatus: financialStatus
        }
      });

      dispatch(updateEntityFinancialCompletionStatusSuccess(financialStatus));
      dispatch(setUpdateEntityFinancialCompletionRequestStatus('success'));
    } catch (error: unknown) {
      dispatch(setUpdateEntityFinancialCompletionRequestStatus('failure', error));
    }
  };
};
