import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { hasEditAccess, hasReportsOnlyAccess } from '../../../utils';
import { ContentHeader } from '../../ContentHeader';
import { LocalReportsHeaderProps } from '../LocalReports.proptype';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: '3.8rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    width: '100%',
    whiteSpace: 'nowrap',
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

export const LocalReportsHeader = (props: LocalReportsHeaderProps) => {
  const { title, onCreateLocalReport } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const onHandlerCreateLocalReport = useCallback(() => {
    onCreateLocalReport();
  }, [onCreateLocalReport]);
  return (
    <Box className={classes.headerContainer}>
      <ContentHeader
        primaryEntity={title}
        title={t('reports:title-local-reports')}
        link="/reports"
        icon={<ArrowBack />}
      />
      {hasEditAccess() && !hasReportsOnlyAccess() && (
        <Button variant="contained" color="primary" onClick={onHandlerCreateLocalReport}>
          {t('reports:label-create-local-report')}
        </Button>
      )}
    </Box>
  );
};
