import { ProductNames } from '@xbs/xbs-enums';
import decodeJWT from 'jwt-decode';
import { getAppConfig } from './configuration';

export interface JWTToken {
  email: string;
  userId: string;
  role: string;
}

export interface AuthStateInterface {
  authToken: string;
  expiration: number;
  refreshToken: string;
  lastRefreshDate: number;
}

export const redirectToLogin = () => {
  const { loginStackRelativeUrl } = getAppConfig();
  if (!loginStackRelativeUrl) {
    return;
  }

  window.localStorage.clear();

  const productUuid = ProductNames.ByName.Tpiq.Uuid;
  window.localStorage.setItem('productUuid', productUuid);

  window.location.replace(`${window.location.origin}/${loginStackRelativeUrl}`);
};

export const isUserAuthenticated = () => {
  const auth = getAuthInfo();

  return (
    Boolean(auth.authToken) &&
    Boolean(auth.expiration) &&
    auth.expiration > Date.now() &&
    auth.lastRefreshDate === new Date().getDate()
  );
};

export const getAuthInfo = (): AuthStateInterface => ({
  authToken: localStorage.getItem('idToken') ?? '',
  refreshToken: localStorage.getItem('refreshToken') ?? '',
  expiration: localStorage.getItem('expires') ? Number(localStorage.getItem('expires')) * 1000 : Date.now() - 1000,
  lastRefreshDate: localStorage.getItem('lastRefreshDate')
    ? Number(localStorage.getItem('lastRefreshDate'))
    : new Date().getDate()
});

export const setAuthTokens = ({
  authToken = '',
  refreshToken = '',
  expiration
}: Partial<AuthStateInterface>): AuthStateInterface => {
  localStorage.setItem('idToken', String(authToken));
  localStorage.setItem('refreshToken', String(refreshToken));
  localStorage.setItem('expires', expiration ? `${expiration / 1000}` : '');
  localStorage.setItem('lastRefreshDate', String(new Date().getDate()));

  return getAuthInfo();
};

export const decodeTokens = (token: string) => {
  try {
    const jwt = decodeJWT(token);

    if (!jwt) {
      return null;
    }

    return {
      email: (jwt as JWTToken).email,
      userId: (jwt as JWTToken).userId,
      role: (jwt as JWTToken).role
    };
  } catch {
    return null;
  }
};
