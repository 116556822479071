import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Typography, makeStyles } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { CustomIconMenu } from '../../../components';
import LabelInput from '../../../components/LabelInput';
import tokens from '../../../styles/designTokens';
import { createDefaultNameCharacteristic } from '../../../utils/createDefaultCharacteristicName';
import { FunctionalAnalysisListItemProps } from '../FunctionalAnalysisTable.proptype';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(4.5),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3)
  },
  itemName: {
    width: '15vw'
  },
  itemText: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    maxWidth: '15vw',
    padding: '0.5rem 0.75rem',

    '&:hover': {
      background: tokens.neutral90,
      borderRadius: '0.15rem'
    }
  },
  itemOptions: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between'
  }
}));

export const FunctionalAnalysisListItem = ({
  item,
  selectedAnalysis,
  setSelectedAnalysis,
  editFunctionalAnalysisCharacteristic,
  areaId,
  deleteFunctionalAnalysisCharacteristic,
  setCurrentSection,
  functionalAnalysisCharacteristics
}: FunctionalAnalysisListItemProps) => {
  const [newName, setNewName] = useState('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();

  function handleEditCharacteristicName() {
    if (newName.length < 3) {
      void editFunctionalAnalysisCharacteristic(
        {
          characteristicName: createDefaultNameCharacteristic(functionalAnalysisCharacteristics),
          otherParticipantContribution: item.otherParticipantContribution,
          testedPartyContribution: item.testedPartyContribution,
          pbaFunctionalAnalysisDataId: item.pbaFunctionalAnalysisDataId
        },
        areaId
      );

      setNewName('');
    } else if (newName.length <= 50) {
      void editFunctionalAnalysisCharacteristic(
        {
          characteristicName: newName,
          otherParticipantContribution: item.otherParticipantContribution,
          testedPartyContribution: item.testedPartyContribution,
          pbaFunctionalAnalysisDataId: item.pbaFunctionalAnalysisDataId
        },
        areaId
      );
    }

    setIsActive(false);
  }

  return (
    <Box
      key={item.pbaFunctionalAnalysisDataId}
      className={classes.listItem}
      style={{
        backgroundColor:
          item.pbaFunctionalAnalysisDataId === selectedAnalysis?.pbaFunctionalAnalysisDataId ? tokens.product15 : ''
      }}
      onClick={() => {
        setSelectedAnalysis(item);
        setCurrentSection(areaId);
      }}
    >
      <Box className={classes.itemName}>
        {isActive ? (
          <span>
            <LabelInput
              value={newName}
              isActive={isActive}
              maxLength={50}
              onChange={(newVal: string) => {
                setNewName(newVal);
              }}
              onBlur={() => {
                handleEditCharacteristicName();
              }}
              onSubmit={() => {
                handleEditCharacteristicName();
              }}
            />
          </span>
        ) : (
          <Typography
            className={classes.itemText}
            title={
              item.characteristicName.includes('functional_characteristic_type')
                ? t(`analysis:${item.characteristicName}`)
                : item.characteristicName
            }
            onClick={() => {
              setNewName(t(`analysis:${item.characteristicName}`));
              setIsActive(true);
            }}
          >
            {newName
              ? newName
              : item.characteristicName.includes('functional_characteristic_type')
              ? t(`analysis:${item.characteristicName}`)
              : item.characteristicName}
          </Typography>
        )}
      </Box>
      <Box className={classes.itemOptions}>
        <Checkbox
          inputProps={{ 'aria-label': 'primary checkbox' }}
          color="primary"
          checked={item.testedPartyContribution}
          onChange={() => {
            void editFunctionalAnalysisCharacteristic(
              {
                otherParticipantContribution: item.otherParticipantContribution,
                testedPartyContribution: !item.testedPartyContribution,
                pbaFunctionalAnalysisDataId: item.pbaFunctionalAnalysisDataId
              },
              areaId
            );
          }}
        />
        <Checkbox
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
          checked={item.otherParticipantContribution}
          onChange={() => {
            void editFunctionalAnalysisCharacteristic(
              {
                otherParticipantContribution: !item.otherParticipantContribution,
                testedPartyContribution: item.testedPartyContribution,
                pbaFunctionalAnalysisDataId: item.pbaFunctionalAnalysisDataId
              },
              areaId
            );
          }}
        />
        <CustomIconMenu
          Icon={MoreHoriz}
          menuItems={[
            {
              text: t('analysis:functional_characteristic.delete_characteristic'),
              handleOnClick: () => {
                deleteFunctionalAnalysisCharacteristic(item.pbaFunctionalAnalysisDataId, areaId);
              }
            }
          ]}
        />
      </Box>
    </Box>
  );
};
