import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  LinearProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
  Select,
  MenuItem
} from '@material-ui/core';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { getMenuItems, formatTableData, dataFromTableData } from './utils';
import { SearchAndSort, SortOrder } from '..';
import { GlobalGuide } from '../../models';
import { filterData, sortData } from '../../services/filtering';
import { TableCellSort } from '../TableCellSort';

export interface GlobalGuideSearchTableProps {
  data: GlobalGuide[] | null;
  selectedItem?: GlobalGuide;
  onClick: (data?: GlobalGuide) => void;
}

export interface MakeStylesProps {
  longestOptionLength: number;
}

const useStyles = makeStyles<Theme, MakeStylesProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 0),
    height: '100%',
    background: theme.palette.background.default
  },
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 2, 0)
  },
  menuWrapper: {
    padding: theme.spacing(1, 2)
  },
  table: {
    flexGrow: 1,
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '& .MuiTableCell-root': {
      padding: theme.spacing(1.5, 2),
      cursor: 'pointer'
    }
  },
  select: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderStyle: 'none'
      },
      '&.Mui-focused fieldset': {
        borderStyle: 'none'
      }
    },
    '& .MuiSelect-root': {
      minWidth: theme.spacing(16)
    }
  },
  transactionSelect: {
    width: (props) => `${props.longestOptionLength}ch`, // Using ch to adjust the width based of the number of characters
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderStyle: 'none'
      }
    }
  }
}));

export const GlobalGuideSearchTable = ({ data, selectedItem, onClick }: GlobalGuideSearchTableProps) => {
  const { t } = useTranslation();

  const [reviewType, setReviewType] = useState<string>(t('globalguide:wtp-review-type-audit-risk'));
  const [longestReviewTypeOption, setLongestReviewTypeOption] = useState<string>(''); // Keeps the longest option
  const formattedTableData = formatTableData(data, reviewType);

  const [filterObject, setFilter] = useState({});
  const [sortObject, setSort] = useState({});

  const menuItems = getMenuItems(data, t);

  const getLongestMenuItem = (menuItems: Array<{ text: string; value: string }>): string => {
    let length = 0;
    let longestOption = '';
    menuItems.forEach((menuItem) => {
      if (menuItem.value.length > length) {
        length = menuItem.value.length;
        longestOption = menuItem.value;
      }
    });
    return longestOption;
  };

  useEffect(() => {
    if (menuItems) {
      const longestOption = getLongestMenuItem(menuItems);
      setLongestReviewTypeOption(longestOption);
    }
  }, [menuItems]);

  const classes = useStyles({ longestOptionLength: longestReviewTypeOption?.length });

  const searchHandler = (filterBy: string, filterString: string) => {
    setFilter({ ...filterObject, [filterBy]: filterString });
  };

  const sortHandler = (sortBy: string, sortOrder: string) => {
    setSort({ sortBy, sortOrder });
  };

  const countryKey = 'country';
  const localeCountry = t(`globalguide:column-${countryKey}`);

  const filteredAndSortedGlobalGuideData = sortData(
    filterData(formattedTableData, filterObject),
    sortObject as { sortBy: string; sortOrder: SortOrder }
  );

  const handleMenuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewType(event.target?.value);
  };

  const selectClasses = clsx({
    [classes.transactionSelect]: true
  });

  return (
    <Paper className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {t('globalguide:title')}
      </Typography>
      {Array.isArray(data) ? (
        <>
          <Box className={classes.menuWrapper}>
            <Select
              disableUnderline
              className={selectClasses}
              variant="outlined"
              value={reviewType}
              onChange={(e: any) => {
                handleMenuChange(e);
              }}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.text} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TableContainer className={classes.table} component={Box}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 260 }}>
                    <SearchAndSort
                      field={localeCountry}
                      onSearchChange={(filterBy: string) => {
                        searchHandler(countryKey, filterBy);
                      }}
                      onSortClicked={(sortOrder: 'asc' | 'desc') => {
                        sortHandler(countryKey, sortOrder);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TableCellSort
                      onSortClicked={(sortOrder: 'asc' | 'desc') => {
                        sortHandler('value', sortOrder);
                      }}
                    >
                      {t('globalguide:column-value')}
                    </TableCellSort>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedGlobalGuideData.map((row) => (
                  <TableRow
                    key={row.id}
                    role="row"
                    tabIndex={-1}
                    selected={selectedItem?.countryId === row.id}
                    onClick={() => {
                      onClick(dataFromTableData(data, row));
                    }}
                  >
                    <TableCell>{row[countryKey]}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <LinearProgress />
      )}
    </Paper>
  );
};
