import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import designTokens from '../../styles/designTokens';

const widthDivider = 11;
const paddingPage = 18;
const sizeMenuInPercentage = Number(window.innerWidth) < 1280 ? 25 : 16.666667;
const sizeMenuInPixels = (Number(window.innerWidth) * sizeMenuInPercentage) / 100;
const minWidthPercentage = 35;

interface SplitViewProps {
  left: React.ReactElement;
  right: React.ReactElement;
  className?: string;
}

const useStyles = makeStyles(() => ({
  dividerHitbox: {
    cursor: 'col-resize',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.25rem',
    '&:hover': {
      '& > div': {
        backgroundColor: designTokens.purpleLight2
      }
    }
  },
  divider: {
    width: '0.188rem',
    height: '100%',
    backgroundColor: designTokens.neutral70
  },
  splitView: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  pane: {
    padding: '1em',
    borderRadius: '0.25em',
    backgroundColor: '#ffffff',
    height: '100%'
  }
}));

export const SplitView = ({ left, right, className }: SplitViewProps) => {
  const [dragging, setDragging] = useState(false);
  const classes = useStyles();

  const splitPaneRef = useRef<HTMLDivElement>(null);
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const rightPaneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onResize();
  }, [splitPaneRef, leftPaneRef, rightPaneRef]);

  const onMouseDown = () => {
    setDragging(true);
  };

  const onTouchStart = () => {
    setDragging(true);
  };

  const onMove = (clientX: number) => {
    const xPositionInComponent = clientX - sizeMenuInPixels;

    if (dragging && splitPaneRef.current && leftPaneRef.current && rightPaneRef.current) {
      const maxWidth = splitPaneRef.current.clientWidth;
      const minWidth = (maxWidth * minWidthPercentage) / 100;

      if (xPositionInComponent > minWidth && xPositionInComponent < maxWidth - minWidth) {
        leftPaneRef.current.style.width = `${xPositionInComponent - widthDivider / 2 - paddingPage}px`;
        rightPaneRef.current.style.width = `${maxWidth - xPositionInComponent + paddingPage}px`;
      }
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    onMove(e.clientX);
  };

  const onTouchMove = (e: TouchEvent) => {
    onMove(e.touches[0].clientX);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  const onResize = () => {
    if (splitPaneRef?.current && leftPaneRef?.current && rightPaneRef?.current) {
      const maxWidth = splitPaneRef.current.clientWidth;

      const widthValueWithDivider = (maxWidth - widthDivider) / 2;
      leftPaneRef.current.style.width = `${widthValueWithDivider}px`;
      rightPaneRef.current.style.width = `${widthValueWithDivider}px`;
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('mouseup', onMouseUp);
    window.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <div ref={splitPaneRef} className={`${classes.splitView} ${className ?? ''}`}>
      <div ref={leftPaneRef} className={classes.pane}>
        {left}
      </div>
      <div
        className={classes.dividerHitbox}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
      >
        <div className={classes.divider} />
      </div>
      <div ref={rightPaneRef} className={classes.pane}>
        {right}
      </div>
    </div>
  );
};
