import { useTranslation } from 'react-i18next';
import { Chip, makeStyles } from '@material-ui/core';
import appColors from '../../../styles/appColors';

const useStyles = makeStyles(() => ({
  draft: {
    color: appColors.reports.draft.text,
    background: appColors.reports.draft.fill,
    borderColor: appColors.reports.draft.text,
    borderRadius: '.25rem',
    minWidth: '3rem',
    height: '24px'
  },
  review: {
    color: appColors.reports.review.text,
    background: appColors.reports.review.fill,
    borderColor: appColors.reports.review.text,
    borderRadius: '.25rem',
    minWidth: '3rem',
    height: '24px'
  },
  published: {
    color: appColors.reports.published.text,
    background: appColors.reports.published.fill,
    borderColor: appColors.reports.published.text,
    borderRadius: '.25rem',
    minWidth: '3rem',
    height: '24px'
  },
  final: {
    color: appColors.reports.final.text,
    background: appColors.reports.final.fill,
    borderColor: appColors.reports.final.text,
    borderRadius: '.25rem',
    minWidth: '3rem',
    height: '24px'
  },
  noReports: {
    display: 'none', // DUO-839 no showing chips when reports are not generated
    background: appColors.reports.noReports.fill,
    borderRadius: '.25rem',
    minWidth: '3rem',
    height: '24px'
  },
  reportsTable: {
    minWidth: '3rem',
    height: '24px'
  },
  reportVersionsTable: {
    border: '1px solid',
    borderRadius: '.25rem',
    minWidth: '3rem',
    height: '24px'
  }
}));

interface StatusChipProps {
  status: 'draft' | 'review' | 'published' | 'final' | 'noReports';
  type: 'reportsTable' | 'reportVersionsTable';
  label?: string;
}

export const ReportStatusChip = ({ status, type, label }: StatusChipProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <Chip className={`${classes[status]} ${classes[type]}`} label={label ? label : t(`reports:${status}`)} />;
};
