import { PbaDashboardFinancialProps } from '../../FinancialTable/FinancialTable.proptype';
import { FinancialTable } from '../../FinancialTable/index';

export const PBADashboardBalanceSheet = ({
  hasFooter,
  title,
  tableData,
  financialFieldIds,
  columnYears,
  currencyOptions,
  headerInfo,
  financialConversions,
  saveData: onSaveData
}: PbaDashboardFinancialProps) => {
  return (
    <FinancialTable
      title={title}
      headerInfo={headerInfo}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      hasFooter={hasFooter}
      tableData={tableData}
      financialFieldIds={financialFieldIds}
      columnYears={columnYears}
      saveData={onSaveData}
      namespace="pba-balance-sheet"
    />
  );
};
