import { useState, MouseEvent } from 'react';
import { Button, ButtonProps, Menu, MenuItem, MenuItemProps } from '@material-ui/core';

export interface DropDownButtonProps extends ButtonProps {
  items?: MenuItemProps[];
  onItemClick?: () => void;
}

export const DropDownButton = (props: DropDownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (props.onItemClick) {
      props.onItemClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onItemClick, ...buttonProps } = props;

  return (
    <>
      <Button aria-haspopup="true" onClick={handleOpen} {...buttonProps}>
        {props.children}
      </Button>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        {(props.items ?? []).map((itemProps, i) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            {...itemProps}
            button
            onClick={(event) => {
              handleClose();
              itemProps.onClick?.(event);
            }}
          >
            {itemProps.children}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
