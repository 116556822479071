import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { UserMenuProps } from '../Header.proptype';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0
  },
  avatar: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.action.active,
    width: 34,
    height: 34
  }
}));

export const UserMenu = ({ onSignOut }: UserMenuProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  return (
    <>
      <IconButton
        className={classes.button}
        onClick={({ currentTarget }) => {
          setAnchorEl(currentTarget);
        }}
      >
        <Avatar className={classes.avatar} />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onSignOut();
          }}
        >
          {t('action-signout')}
        </MenuItem>
      </Menu>
    </>
  );
};
