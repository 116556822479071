import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { RootState } from '..';
import { Transaction, TransactionType, PartyRole, TransactionServiceEvaluation } from '../../models';
import httpService from '../../services/http';
import { findTransactionDestinationRaw, findTransactionSourceRaw } from '../../utils';
import { dateToTimestamp, normalizeMonth, timestampToDate } from '../../utils/dates';
import { getWorkingContainer } from '../baseData';

interface TransactionsState {
  transactions: Transaction[] | null;
  transactionSelected: Transaction | null;
  transactionTypes: TransactionType[] | null;
  serviceEvaluationByTransactionId: Record<number, TransactionServiceEvaluation>;
  partyRoles: PartyRole[] | null;
  error?: string;
}

const initialState: TransactionsState = {
  transactions: null,
  transactionSelected: null,
  transactionTypes: null,
  partyRoles: null,
  serviceEvaluationByTransactionId: {}
};

export const saveTransaction = createAsyncThunk<
  Transaction,
  { transaction: Partial<Transaction>; t: TFunction },
  { state: RootState; rejectValue: Error }
>('transactions/save', async ({ transaction, t }, { rejectWithValue }) => {
  const date = timestampToDate(transaction.transferDate!);
  transaction.transferDateYear = date.getFullYear();
  transaction.transferDateMonth = normalizeMonth(date);
  transaction.transferDateDay = date.getDate();
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit = typeof transaction.transactionId === 'number';
    const { data } = await httpService.request<{ result: Transaction }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath: isEdit ? `transactions/${transaction.transactionId!}` : 'transactions',
      data: transaction
    });

    return data.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchTransactions = createAsyncThunk<Transaction[], void, { rejectValue: Error }>(
  'transactions/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transactions: Transaction[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'transactions',
          params: { sort: 'identifier', order: 'asc' }
        })
      ).data.result.transactions.map((transaction) => {
        const transferDate = dateToTimestamp(
          transaction.transferDateYear,
          transaction.transferDateMonth - 1,
          transaction.transferDateDay
        );
        return {
          ...transaction,
          transferDate,
          legalEntityTransactions: (transaction.legalEntityTransactions ?? []).map((pair) => ({
            ...pair,
            // unfortunately, the API returns an inn (0, 1) instead of a boolean.
            // please remove when the API will support boolean.
            completed: Boolean(pair.completed as unknown)
          }))
        };
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchPartyRoles = createAsyncThunk<PartyRole[], void, { rejectValue: Error }>(
  'transactions/party-roles/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: PartyRole[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'party-roles'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchTransactionTypes = createAsyncThunk<TransactionType[], void, { rejectValue: Error }>(
  'transactions/types/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transactionTypes: TransactionType[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `transaction-types`
        })
      ).data?.result?.transactionTypes;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteTransaction = createAsyncThunk<number, number, { rejectValue: Error }>(
  'transactions/delete',
  async (transactionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transactionId: number } }>({
          method: 'delete',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions/${transactionId}`
        })
      ).data.result.transactionId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchServiceEvaluation = createAsyncThunk<TransactionServiceEvaluation, number, { rejectValue: Error }>(
  'transactions/fetchServiceEvaluation',
  async (transactionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: TransactionServiceEvaluation }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions/${transactionId}/service-evaluation`
        })
      ).data.result;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

interface SaveServiceEvaluationParams {
  transactionId: number;
  evaluation: TransactionServiceEvaluation;
}

export const saveServiceEvaluation = createAsyncThunk<void, SaveServiceEvaluationParams, { rejectValue: Error }>(
  'transactions/saveServiceEvaluation/put',
  async ({ transactionId, evaluation }, { rejectWithValue }) => {
    try {
      await httpService.request<{ result: TransactionServiceEvaluation }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions/${transactionId}/service-evaluation`,
        data: {
          transaction: { transactionId },
          ...evaluation
        }
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveServiceEvaluationEditorText = createAsyncThunk<
  void,
  SaveServiceEvaluationParams,
  { rejectValue: Error }
>('transactions/saveServiceEvaluationEditorText/patch', async ({ transactionId, evaluation }, { rejectWithValue }) => {
  try {
    await httpService.request<{ result: TransactionServiceEvaluation }>({
      method: 'patch',
      apiUrlKey: 'baseUrl',
      relativePath: `transactions/${transactionId}/service-evaluation`,
      data: {
        transaction: { transactionId },
        ...evaluation
      }
    });
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchTransactionById = createAsyncThunk<Transaction, number, { rejectValue: Error }>(
  'transactions/by-id/fetch',
  async (transactionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transaction: Transaction } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions/${transactionId}`
        })
      ).data.result.transaction;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchTransactions.fulfilled, (state: TransactionsState, action: PayloadAction<Transaction[]>) => {
        state.transactions = action.payload;
      })
      .addCase(fetchPartyRoles.fulfilled, (state: TransactionsState, action: PayloadAction<PartyRole[]>) => {
        state.partyRoles = action.payload;
      })
      .addCase(
        fetchTransactionTypes.fulfilled,
        (state: TransactionsState, action: PayloadAction<TransactionType[]>) => {
          state.transactionTypes = action.payload;
        }
      )
      .addCase(deleteTransaction.fulfilled, (state: TransactionsState, action: PayloadAction<number>) => {
        const idx = (state.transactions ?? []).findIndex(({ transactionId }) => transactionId === action.payload);
        if (idx >= 0) {
          state.transactions!.splice(idx, 1);
        }
      })
      .addCase(fetchServiceEvaluation.fulfilled, (state: TransactionsState, action) => {
        state.serviceEvaluationByTransactionId[action.meta.arg] = action.payload;
      })
      .addCase(saveServiceEvaluation.fulfilled, (state: TransactionsState, action) => {
        const { transactionId, evaluation } = action.meta.arg;
        state.serviceEvaluationByTransactionId[transactionId] = evaluation;
        if (state.transactions) {
          const transaction = state.transactions.find((transaction) => transactionId === transaction.transactionId);
          if (transaction) {
            const source = findTransactionSourceRaw(transaction);
            if (source) {
              source.completed = evaluation.isSourceComplete;
            }

            const destination = findTransactionDestinationRaw(transaction);
            if (destination) {
              destination.completed = evaluation.isDestinationComplete;
            }
          }
        }
      })
      .addCase(fetchTransactionById.fulfilled, (state: TransactionsState, action) => {
        state.transactionSelected = action.payload;
      })
      .addMatcher(
        (action) => action.type.match(/^transactions\/.+\/pending$/),
        (state: TransactionsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^transactions\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default transactionsSlice.reducer;
