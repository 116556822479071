import { createSelector } from 'reselect';
import { selectCountries, selectCurrencies } from './baseData';
import { RootState } from '../redux';
import { findCountryCurrency, findEntityCountry } from '../utils';

export const selectEntitiesList = (state: RootState) => state.entities.entities;

export const selectEntityName = (entityId: number) =>
  createSelector(selectEntity(entityId), (entity) => entity?.name ?? '');

export const selectEntity = (entityId: number | null | undefined) =>
  createSelector(
    selectEntitiesList,
    (entities) => (entities ?? []).find((entity) => entity.entityId === entityId) ?? null
  );

export const selectEntityCurrency = (entityId: number | null | undefined) =>
  createSelector(selectEntity(entityId), selectCountries, selectCurrencies, (entity, countries, currencies) =>
    findCountryCurrency(findEntityCountry(entity, countries), currencies)
  );
