import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, FormControl, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { ComparableInformation } from './ComparableInformation';
import { CenteredProgress, DropDownButton, TPModal } from '../../../../../components';
import { SplitView } from '../../../../../components/SplitView';
import tokens from '../../../../../styles/designTokens';
import { mediumFont } from '../../../../../styles/fonts';
import { body2, body4, body1, captionOverline, title2, titlelarge } from '../../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { CompanyTable } from '../../CompanyTable';
import { FineTuningCompaniesProps } from '../FineTuning.proptype';

export interface RejectionProps {
  rejectionType: string;
  validOtherRejectionReason: boolean;
}

const useStyles = makeStyles<Theme, RejectionProps>((theme) => ({
  boxSection: {
    height: '100%'
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  },
  headingTitle: {
    ...body4,
    fontSize: 'medium',
    margin: 'auto 0'
  },
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  checkCircle: {
    color: '#6E56DB'
  },
  cancelCircle: {
    backgroundColor: '#FFFFFF'
  },
  dropDown: {
    paddingLeft: '0.5rem',
    ...body1
  },
  chip: {
    textAlign: 'right',
    width: '6.063rem',
    height: '2.75rem',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    marginRight: '1rem',
    ...captionOverline
  },
  chipLabel: {
    marginTop: '0.5rem',
    paddingRight: '0.5rem',
    textAlign: 'right',
    ...captionOverline,
    textTransform: 'uppercase'
  },
  chipValue: {
    paddingRight: '0.5rem',
    textAlign: 'right',
    ...captionOverline,
    textTransform: 'uppercase',
    marginBottom: '0.5rem'
  },
  totalChip: {
    border: '0.063rem solid',
    backgroundColor: tokens.product15,
    color: tokens.product100,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  acceptedChip: {
    backgroundColor: tokens.neutral70,
    color: tokens.core1,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  totalLabel: {
    color: '#3C2A8E'
  },
  acceptedLabel: {
    color: '#464D59'
  },
  totalValue: {
    color: '#3C2A8E'
  },
  acceptedValue: {
    color: '#464D59'
  },
  doneButton: {
    backgroundColor: ({ rejectionType, validOtherRejectionReason }) =>
      (rejectionType === `rejection_type.other` && !validOtherRejectionReason) || rejectionType === ''
        ? ''
        : theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  rejectionModalContainer: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    width: '100%'
  },
  rejectionsSelectList: {
    width: '100%',
    marginTop: '1rem'
  },
  chipsWrapper: {
    display: 'flex'
  },
  primaryChip: {
    ...captionOverline,
    border: '0.063rem solid',
    height: '2.6rem',
    width: '6rem',
    marginLeft: '0.5rem',
    backgroundColor: tokens.product15,
    color: tokens.product100,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  chipSecondary: {
    ...captionOverline,
    marginLeft: '0.5rem',
    width: '6rem',
    height: '2.6rem',
    backgroundColor: tokens.neutral70,
    color: tokens.core1,
    justfyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  rowSelected: {
    '& .MuiTableCell-root': {
      backgroundColor: tokens.product15
    },
    '& td.MuiTableCell-body:first-child': {
      color: tokens.product100,
      borderLeft: `0.188rem solid ${tokens.purpleLight2}`
    }
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  }
}));

export const FineTuning = (companiesData: FineTuningCompaniesProps) => {
  const { t } = useTranslation();

  const [rejectionType, setRejectionType] = useState<string>('');
  const [openRejectionModal, setOpenRejectionModal] = useState<boolean>(false);
  const [otherRejectionReason, setOtherRejectionReason] = useState('');

  const [validOtherRejectionReason, setValidOtherRejectionReason] = useState(true);
  const classes = useStyles({ rejectionType, validOtherRejectionReason });

  const {
    columns,
    data,
    rejectionReasons,
    updateRejectionStatus,
    fineTuningStatusCounts,
    currentSelectedCompany,
    onClickRow,
    financialInformation,
    pliInfo,
    pliFormat,
    pliId
  } = companiesData;

  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  useEffect(() => {
    setRejectionType(currentSelectedCompany?.rejectionName);
    if (currentSelectedCompany?.rejectionName === 'rejection_type.other') {
      setOtherRejectionReason(currentSelectedCompany?.otherRejection);
    }
  }, [currentSelectedCompany]);

  const displayableData = useMemo(() => {
    return (data ?? []).map((row: any) => {
      const comparableStatusRow = (
        <DropDownButton
          variant="text"
          className={classes.statusDropDown}
          items={[
            {
              children: (
                <>
                  <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
                  <Typography className={`${classes.dropDown}`}>{t(`analysis:comparable-range-accepted`)}</Typography>
                </>
              ),
              onClick: () => {
                updateRejectionStatus(true, [
                  { key: 'isAcceptedAndReviewed', value: 0 },
                  { key: 'isRejected', value: 0 }
                ]);
              }
            },
            {
              children: (
                <>
                  <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                  <Typography className={classes.dropDown}>{t(`analysis:comparable-range-rejected`)}</Typography>
                </>
              ),
              onClick: () => {
                setOpenRejectionModal(true);
              }
            }
          ]}
        >
          {(row?.compStatus === 'global.accepted' || row?.compStatus === 'global.accepted_and_reviewed') && (
            <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
          )}
          {row?.compStatus === 'global.rejected' && (
            <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
          )}
        </DropDownButton>
      );

      const obj: any = {};
      columns.forEach(({ key }: any) => {
        if (typeof row[key] === 'number' && key !== 'sourceId') {
          obj[key] = formatPliDisplayValue(formatType, roundingValue, row[key]);
        } else if (typeof row[key] === 'string') {
          obj[key] = (
            <span title={row[key]} style={{ ...body2 }} className={classes.truncate}>
              {row[key]}
            </span>
          );
        }
      });

      obj.className =
        currentSelectedCompany?.sourceId.toString() === row.sourceId.toString() ? classes.rowSelected : '';

      return {
        ...obj,
        rejectionStatus: comparableStatusRow
      };
    });
  }, [data, classes, t, columns, currentSelectedCompany?.sourceId, updateRejectionStatus, formatType, roundingValue]);

  const displayColumns = columns
    ? columns.map((clmn: any) => {
        let displayClmn;
        if (clmn.key === 'compStatus') {
          displayClmn = {
            key: 'rejectionStatus',
            header: 'Status',
            width: '10em'
          };
        } else {
          return clmn;
        }

        return displayClmn;
      })
    : [];
  const closeRejectionStatusModal = () => {
    setOpenRejectionModal(false);
    setRejectionType('');
  };

  const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
    error ? (
      <Typography color="error" variant="caption">
        {message}
      </Typography>
    ) : null;

  return columns.length > 0 && data.length > 0 ? (
    <SplitView
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            companies={displayableData}
            columns={displayColumns}
            chips={
              <div className={classes.chipsWrapper}>
                <Chip
                  size="medium"
                  label={
                    <>
                      <div className={classes.captionOverline}>
                        {t('analysis:comparable-range-total').toUpperCase()}
                      </div>
                      <div className={classes.body2}>{fineTuningStatusCounts?.total}</div>
                    </>
                  }
                  className={classes.primaryChip}
                />
                <Chip
                  className={classes.chipSecondary}
                  size="medium"
                  label={
                    <>
                      <div className={classes.captionOverline}>
                        {t('analysis:comparable-range-accepted').toUpperCase()}
                      </div>
                      <div className={classes.body2}>{fineTuningStatusCounts?.accepted}</div>
                    </>
                  }
                />
                <Chip
                  size="medium"
                  className={classes.chipSecondary}
                  label={
                    <>
                      <div className={classes.captionOverline}>
                        {t('analysis:comparable-range-rejected').toUpperCase()}
                      </div>
                      <div className={classes.body2}>{fineTuningStatusCounts?.rejected}</div>
                    </>
                  }
                />
              </div>
            }
            onRowClick={(event: MouseEvent, index: number, selectedData: any) => {
              const company = (data ?? []).find(
                (company) => company.companyName === selectedData.companyName.props.title
              );
              if (company) {
                onClickRow(Number(company.sourceId));
              }
            }}
          />
          {openRejectionModal && (
            <TPModal
              isOpen
              maxWidth="sm"
              title={
                <div style={{ padding: '2rem 0 0 2rem' }}>
                  <Typography style={{ ...title2 }}>{t(`analysis:comparable-rejection`)}</Typography>
                  <Typography style={{ ...titlelarge }}>{currentSelectedCompany?.companyName ?? ''}</Typography>
                </div>
              }
              actions={[
                {
                  name: t('action-cancel'),
                  handler: () => {
                    closeRejectionStatusModal();
                  }
                },
                {
                  name: t('action-done'),
                  className: classes.doneButton,
                  disabled:
                    (rejectionType === `rejection_type.other` && !validOtherRejectionReason) || rejectionType === '',
                  handler: async () => {
                    const updateRejectionReasonStatus: boolean =
                      rejectionType !== `rejection_type.other` ||
                      (otherRejectionReason.length > 0 && otherRejectionReason.length < 100);
                    setValidOtherRejectionReason(updateRejectionReasonStatus);
                    if (updateRejectionReasonStatus) {
                      updateRejectionStatus(
                        false,
                        [
                          { key: 'isAcceptedAndReviewed', value: 0 },
                          { key: 'isRejected', value: 1 }
                        ],
                        rejectionType,
                        otherRejectionReason
                      );
                      closeRejectionStatusModal();
                      setOtherRejectionReason('');
                    }
                  }
                }
              ]}
              onClose={() => {
                closeRejectionStatusModal();
              }}
            >
              <div>
                <FormControl size="small" variant="outlined">
                  <div className={classes.rejectionModalContainer}>
                    <Typography style={{ ...title2 }}>{t(`analysis:comparable-rejection-reason`)}</Typography>
                    <Select
                      className={classes.rejectionsSelectList}
                      value={rejectionType ?? ''}
                      onChange={($event) => {
                        setRejectionType(`${String($event.target.value)}`);
                      }}
                    >
                      {(rejectionReasons ?? []).map(({ rejectionTypeId, rejectionName }: any) => (
                        <MenuItem key={rejectionTypeId} value={rejectionName}>
                          {t(`analysis:${String(rejectionName)}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {rejectionType === `rejection_type.other` && (
                      <div style={{ paddingTop: '2rem' }}>
                        <TextField
                          fullWidth
                          size="medium"
                          placeholder="Add rejection reason here"
                          variant="outlined"
                          error={rejectionType === `rejection_type.other` && !validOtherRejectionReason}
                          defaultValue=""
                          value={otherRejectionReason}
                          onChange={({ target: { value } }) => {
                            setOtherRejectionReason(value);
                            setValidOtherRejectionReason(true);
                          }}
                        />
                        <ErrorMessage
                          error={Boolean(rejectionType === `rejection_type.other` && !validOtherRejectionReason)}
                          message="You must enter a rejection reason and it must be within 100 characters."
                        />
                      </div>
                    )}
                  </div>
                </FormControl>
              </div>
            </TPModal>
          )}
        </div>
      }
      right={
        <ComparableInformation
          company={currentSelectedCompany}
          financialInfo={financialInformation}
          pliInfo={pliInfo}
          pliFormat={pliFormat}
          pliId={pliId}
        />
      }
      className={classes.boxSection}
    />
  ) : (
    <CenteredProgress />
  );
};
