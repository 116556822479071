import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import tokens from '../../../styles/designTokens';
import { body3, title2 } from '../../../styles/typography';
import { FunctionalAnalysisSplashImage } from '../../../svgs';

const useStyles = makeStyles((theme) => ({
  splashImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: tokens.neutral70,
    width: '30vw',
    height: '35vh'
  },
  defineTPTitle: {
    margin: theme.spacing(2, 0)
  }
}));

export const FunctionalAnalysisSplashImageHolder = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box margin="1rem">
      <Box className={classes.splashImageContainer}>
        <FunctionalAnalysisSplashImage />
      </Box>
      <Typography className={classes.defineTPTitle} variant="h3" style={{ ...title2 }}>
        {t(`analysis:functional_characteristic.define_party_characteristics`)}
      </Typography>
      <Typography variant="h2" style={{ ...body3, color: tokens.core2 }}>
        {t(`analysis:functional_characteristic.define_party_characteristics_description`)}
      </Typography>
    </Box>
  );
};
