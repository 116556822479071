import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReportsCircleProps } from '../Reports.proptype';

const useStyles = makeStyles(() => ({
  counterItemContainer: {
    margin: '10px',
    display: 'inline-block',
    width: '67px',
    height: '67px',
    '& .counterItem': {
      border: '2px solid',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      '& .number': {
        fontSize: '28px',
        flex: '1',
        textAlign: 'center'
      }
    },
    '& .labelContainer': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '12px',
      textAlign: 'center'
    },
    '& .firstLabelContainer': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '12px',
      textAlign: 'center',
      marginLeft: '-12px'
    }
  },
  svgContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    '& .counterItem': {
      borderWidth: '0px',
      bottom: '0px',
      left: '0px',
      position: 'absolute'
    }
  },
  progressRing: {
    margin: ' -2px'
  },
  progressRingCircle: {
    transition: '0.35s stroke-dashoffset',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%'
  }
}));

export const ReportsCircle = ({ percent, metric, type, color }: ReportsCircleProps) => {
  const classes = useStyles();
  const radius = 33;
  const circumference = radius * 2 * Math.PI;
  let offset = circumference - Number(percent) * circumference;
  if (Number.isNaN(offset)) {
    offset = 0;
  }

  return (
    <Box className={classes.counterItemContainer}>
      <Box className={classes.svgContainer} color={color}>
        <Box className="counterItem">
          <Typography className="number">{metric}</Typography>
        </Box>
        <svg className={classes.progressRing} width="68" height="68">
          <circle
            className={classes.progressRingCircle}
            stroke={color}
            strokeWidth="2"
            fill="transparent"
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={offset}
            r={radius}
            cx="34"
            cy="34"
          />
          <circle
            style={{ opacity: '0.5' }}
            stroke={color}
            strokeWidth="1"
            fill="transparent"
            strokeDasharray={`${circumference} ${circumference}`}
            r={radius}
            cx="34"
            cy="34"
          />
        </svg>
      </Box>
      <Box className={type === 'Jurisdictions' ? 'firstLabelContainer' : 'labelContainer'} color={color}>
        <Typography role="circleText">{type}</Typography>
      </Box>
    </Box>
  );
};
