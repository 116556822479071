import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Connector from './connector';
import { selectWorkingContainer } from '../../selectors';
import { getAppConfig, handleRoleRedirect } from '../../utils';
import { FunctionalAnalysis } from '../FunctionalAnalysis';
import { JurisdictionAnalysisInfo } from '../JurisdictionAnalysisInfo';
import RouteOptions from '../Router/RouteOptions';
import { SearchChecklist } from '../SearchChecklist';
import { TestedPartyCharacterization } from '../TestedPartyCharacterization';
import { TestedPartyDetails as TestedPartyDetailsComponent } from '../TestedPartyDetails';
import { TuneCompSearch } from '../TuneCompSearch';

const ChildRoutes = () => {
  const flags = useFlags();
  const { xbsEnvironmentShort } = getAppConfig();
  const { path } = useRouteMatch();
  const match: any = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const studyId = match.params.studyId ?? null;
  const pbaId = match.params.pbaId ?? null;
  const matchJurisdictionPath: any = useRouteMatch(
    '/analysis/:studyId/pba-dashboard/:pbaId/jurisdiction/:jurisdictionId'
  );
  const jurisdictionId: number = matchJurisdictionPath?.params?.jurisdictionId ?? null;
  const jurisdictionPath: string = matchJurisdictionPath?.path ?? null;
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;

  return (
    <Switch>
      <Route key="tested-party" path={`${path}/tested-party-details`}>
        <Connector component={TestedPartyDetailsComponent} />
      </Route>
      <Route key="tested-party-characterization" path={`${path}/tested-party-characterization`}>
        <Connector component={TestedPartyCharacterization} />
      </Route>
      <Route key="search-checklist" path={`${path}/search-checklist`}>
        <Connector component={SearchChecklist} />
      </Route>
      <Route key="tune-comp-search" path={`${jurisdictionPath}/comp-search/:compSearchId/pli/:pliId/pli-avg/:pliAvgId`}>
        <Connector component={TuneCompSearch} />
      </Route>
      <Route key="functional-analysis" path={`${path}/functional-analysis`}>
        {flags.functionalAnalysisNavigationFlag &&
        (flags.functionalAnalysisNavigationFlag.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
          flags.functionalAnalysisNavigationFlag.includes(`${String(xbsEnvironmentShort)}-*`)) ? (
          <Connector component={FunctionalAnalysis} />
        ) : (
          <Connector
            component={() => {
              handleRoleRedirect(`/legacy/local-files/study/${String(studyId)}/pba/${String(pbaId)};activeTab=5`);
            }}
          />
        )}
      </Route>
      <Route
        key={`jurisdiction-analysis-info-${jurisdictionId}`}
        path={`${jurisdictionPath}/jurisdiction-analysis-info`}
      >
        <Connector component={JurisdictionAnalysisInfo} />
      </Route>
      <Redirect to={`${path}/tested-party-details`} />
    </Switch>
  );
};

export const ProfitBasedAnalysisDashboard = () => {
  return (
    <RouteOptions
      RootRoute={<Connector component={TestedPartyDetailsComponent} />}
      ChildRoutes={[{ path: '/:pbaId', component: <ChildRoutes /> }]}
    />
  );
};
