import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectorProps } from './SummariesTab.proptypes';
import { actions as localReportsActions } from '../../../redux/localReports';
import { SummariesData } from '../../../redux/localReports/localReports.proptype';
import { selectSummariesData } from '../../../selectors/localReports';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const { executive, conclusion, orgChart } = useSelector(selectSummariesData);

  const onHandlerComplete = useCallback(
    (info: SummariesData) => {
      dispatch(localReportsActions.setSummaries(info));
    },
    [dispatch]
  );

  return (
    <Component
      defaultExecutiveValue={executive}
      defaultConclusionValue={conclusion}
      defaultOrgChartValue={orgChart}
      onComplete={onHandlerComplete}
    />
  );
};

export default Connector;
