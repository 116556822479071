import { Controller, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography, makeStyles, Theme } from '@material-ui/core';
import { RerunCompSearchDistributorProps } from './RerunCompSearchModal.proptype';
import { SearchBox } from '../../components';
import tokens from '../../styles/designTokens';
import { body4, body3 } from '../../styles/typography';
import { subType } from '../TestedPartyDistributor/TestedPartyDistributor.proptype';

const useStyles = makeStyles((theme: Theme) => ({
  distributorContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sicCodeInputContainer: {
    padding: '0rem 1rem 1rem 1rem'
  },
  minSicContentCard: {
    height: '9.375rem',
    marginRight: '1.25rem',
    marginTop: '1.25rem',
    backgroundColor: tokens.white
  },
  maxSicContentCard: {
    height: '9.375rem',
    marginTop: '1.25rem',
    backgroundColor: tokens.white
  },
  minMaxLabel: {
    paddingRight: '0.625rem',
    ...body4,
    color: tokens.core3
  },
  sicCodeRangeLabel: {
    ...body3,
    color: tokens.core2
  },
  sicCodeSearchInput: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white
    },
    '& > .MuiTextField-root .MuiAutocomplete-inputRoot': {
      color: theme.palette.common.white,
      background: '#6E56DB',
      '& > .MuiAutocomplete-endAdornment .MuiIconButton-root': {
        color: theme.palette.common.white
      }
    }
  },
  validationErrors: {
    marginLeft: '1.25rem'
  }
}));

const RerunCompSearchDistributor = ({
  formMethods,
  defaultValues,
  displayedSicIndustries,
  handleRecalculateProjectCounts
}: RerunCompSearchDistributorProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, setValue, getValues, errors } = formMethods;

  const setMinSicCode = (minSicCode: subType | null) => {
    if (minSicCode) {
      setValue('minSicCode', minSicCode);
    }
  };

  const setMaxSicCode = (maxSicCode: subType | null) => {
    if (maxSicCode) {
      setValue('maxSicCode', maxSicCode);
    }
  };

  const ErrorMessage = ({ error, className }: { error: FieldError; className: string }) =>
    error ? (
      <Typography className={className} color="error" variant="caption">
        {error.message}
      </Typography>
    ) : null;

  const minCodeIsSmallerThanMaxCodeValidation = () => {
    const minSicCode = getValues('minSicCode');
    const maxSicCode = getValues('maxSicCode');

    if (!maxSicCode) {
      return t('errors:error_message.sic_code_max_is_required');
    }

    if (!minSicCode) {
      return t('errors:error_message.sic_code_min_is_required');
    }

    if (minSicCode.value > maxSicCode.value) {
      return t('errors:error_message.max_sic_must_be_greater_than_min_sic');
    }

    return true;
  };

  return (
    <Box className={classes.distributorContainer}>
      <Box width="100%">
        <Card className={classes.minSicContentCard}>
          <CardContent>
            <Box display="flex">
              <Typography className={classes.minMaxLabel}>{t('common:title-minimum')}</Typography>
              <Typography className={classes.sicCodeRangeLabel}>{t('analysis:title-sic-code-range')}</Typography>
            </Box>
          </CardContent>
          <Box className={classes.sicCodeInputContainer}>
            <Controller
              name="minSicCode"
              render={({ onChange, value }) => (
                <SearchBox
                  className={classes.sicCodeSearchInput}
                  disableClearable={false}
                  noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                  placeholder={t('analysis:rerun-comp-search-modal.search_industry_sic')}
                  error={Boolean((errors as any).minSicCode)}
                  value={value}
                  options={displayedSicIndustries}
                  getOptionLabel={(displayedSicIndustry: subType) => {
                    return displayedSicIndustry
                      ? t(`${displayedSicIndustry.value} - ${t(`analysis:${displayedSicIndustry.name}`)}`)
                      : '';
                  }}
                  onChange={(_, data) => {
                    onChange(data);
                    setMinSicCode(data);
                    handleRecalculateProjectCounts();
                  }}
                />
              )}
              control={control}
              defaultValue={defaultValues.minSicCode}
              rules={{ validate: minCodeIsSmallerThanMaxCodeValidation }}
              error={Boolean((errors as any).minSicCode)}
            />
          </Box>
          <ErrorMessage error={(errors as any).minSicCode} className={classes.validationErrors} />
        </Card>
      </Box>
      <Box width="100%">
        <Card className={classes.maxSicContentCard}>
          <CardContent>
            <Box display="flex">
              <Typography className={classes.minMaxLabel}>{t('common:title-maximum')}</Typography>
              <Typography className={classes.sicCodeRangeLabel}>{t('analysis:title-sic-code-range')}</Typography>
            </Box>
          </CardContent>
          <Box className={classes.sicCodeInputContainer}>
            <Controller
              name="maxSicCode"
              render={({ onChange, value }) => (
                <SearchBox
                  className={classes.sicCodeSearchInput}
                  disableClearable={false}
                  noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                  placeholder={t('analysis:rerun-comp-search-modal.search_industry_sic')}
                  error={Boolean((errors as any).maxSicCode)}
                  value={value}
                  options={displayedSicIndustries}
                  getOptionLabel={(displayedSicIndustry: subType) => {
                    return displayedSicIndustry
                      ? t(`${displayedSicIndustry.value} - ${t(`analysis:${displayedSicIndustry.name}`)}`)
                      : '';
                  }}
                  onChange={(_, data) => {
                    onChange(data);
                    setMaxSicCode(data);
                    handleRecalculateProjectCounts();
                  }}
                />
              )}
              control={control}
              defaultValue={defaultValues.maxSicCode}
              rules={{
                validate: minCodeIsSmallerThanMaxCodeValidation
              }}
              error={Boolean((errors as any).maxSicCode)}
            />
          </Box>
          <ErrorMessage error={(errors as any).maxSicCode} className={classes.validationErrors} />
        </Card>
      </Box>
    </Box>
  );
};

export default RerunCompSearchDistributor;
