import { useTranslation } from 'react-i18next';
import {
  Box,
  makeStyles,
  Typography,
  Card,
  CardContent,
  List,
  ListItemText,
  ListItem,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { FancyDropdown, SearchBox } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { buttonText1, buttonText2 } from '../../../styles/typography';
import { hasEditAccess } from '../../../utils';
import { ClassificationCardProps, MajorClassification } from '../TestedPartyServiceProvider.proptype';

const useStyles = makeStyles((theme) => ({
  minorItem: {
    ...buttonText2,
    margin: 'auto',
    width: '90%',
    marginTop: '1rem',
    paddingTop: '0.188rem',
    paddingBottom: '0.188rem',
    minHeight: '2.25rem',
    borderRadius: '0.25rem',
    background: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  minorItemText: {
    whiteSpace: 'normal'
  },
  minorItemListText: {
    ...buttonText1
  },
  white: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white
    }
  },
  minorItemIconButton: {
    padding: 0,
    margin: 0,
    '& .MuiListItemText-root': {
      margin: 0
    },
    '& .MuiSvgIcon-root': {
      height: '1.125rem',
      width: '1.125rem'
    }
  },
  majorCard: {
    minWidth: '16rem',
    maxWidth: '26.375rem',
    flex: '1 1 20rem',
    minHeight: '43.75rem',
    margin: '1rem',
    boxShadow: 'none'
  },
  closeIcon: {
    color: theme.palette.common.white
  },
  minorsList: {
    height: 'auto'
  }
}));

export const ClassificationCard = ({
  cardNumber,
  selectedMajorClassification,
  allMajorClassifications,
  selectedMajorClassifications,
  onSelectMajorClassification,
  dropDownMinorClassifications,
  removeMinorClassification,
  onSelectMinorClassification,
  majorClassificationIndex
}: ClassificationCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const majorClassificationDropDown = allMajorClassifications
    ? allMajorClassifications.filter((majorClassification) => {
        let shouldKeep = true;
        selectedMajorClassifications.forEach((selectedMajorClassification) => {
          if (
            selectedMajorClassification &&
            selectedMajorClassification.majorServiceClassificationId ===
              majorClassification.majorServiceClassificationId
          ) {
            shouldKeep = false;
          }
        });
        return shouldKeep;
      })
    : [];

  return Boolean(selectedMajorClassification) || hasEditAccess() ? (
    <Card className={classes.majorCard}>
      <CardContent>
        <Typography>
          {t('analysis:major_service_classification.major_classifications')} {majorClassificationIndex}
        </Typography>
      </CardContent>
      <Box width="90%" margin="auto">
        <FancyDropdown
          disableClearable={false}
          getOptionLabel={(marjorClassification: { name: string }) => {
            return t(`analysis:${marjorClassification.name}`);
          }}
          value={selectedMajorClassification}
          noOptionsText={t('analysis:major-classification-label-no-match')}
          options={majorClassificationDropDown}
          placeholder={t('analysis:placeholder.add_major')}
          disabled={!hasEditAccess()}
          onChange={(event: any, value: MajorClassification | null) => {
            void onSelectMajorClassification(cardNumber, selectedMajorClassification!, value!);
          }}
        />
      </Box>
      {selectedMajorClassification && dropDownMinorClassifications && hasEditAccess() ? (
        <Box width="90%" paddingTop="1rem" margin="auto">
          <SearchBox
            key={Math.random()}
            disableClearable={false}
            getOptionLabel={(minorClassification) => {
              return t(`analysis:${minorClassification.name}`);
            }}
            renderOption={(option) => (
              <Typography className={classes.minorItemListText}>{t(`analysis:${option.name}`)}</Typography>
            )}
            className={classes.minorItemListText}
            options={dropDownMinorClassifications}
            noOptionsText={
              <Typography className={classes.minorItemListText}>
                {t('analysis:minor-classification-label-no-match')}
              </Typography>
            }
            placeholder={t('analysis:placeholder.search_minors')}
            onChange={onSelectMinorClassification}
          />
        </Box>
      ) : null}
      <List className={classes.minorsList}>
        {selectedMajorClassification?.minorServiceClassifications.map((selectedMinorClassification) => (
          <ListItem key={selectedMinorClassification.minorServiceClassificationId} className={classes.minorItem}>
            <ListItemText
              primary={t(`analysis:${selectedMinorClassification.name}`)}
              className={classes.minorItemText}
            />
            {documentationReviewAccess(
              <IconButton
                className={classes.minorItemIconButton}
                onClick={() => {
                  removeMinorClassification(selectedMinorClassification);
                }}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            )}
          </ListItem>
        ))}
      </List>
    </Card>
  ) : null;
};
