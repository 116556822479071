import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FineTuning as Component } from './components/FineTuningTab';
import { FineTuneParams, FineTuningCompDetails, FineTuningCounts, Rejections } from './FineTuning.proptype';
import { PbaJurisdictionPliFormat } from '../../../../models';
import {
  fetchFineTuningCompanies,
  fetchFineTuningCompany,
  fetchInitialRangeComparables,
  fetchRejectionReasons,
  updateRejectionReason,
  updateRejectionRowStatus,
  clearSelectedCompDetails,
  fetchFineTuningFinancialInformationCompany,
  fetchFineTuningPLICompany,
  fetchJurisdictionPliFormats
} from '../../../../redux/profitBasedAnalyses';
import {
  fineTuningCounts,
  selectCurrenctFineTuningCompany,
  selectFineTuningCompaniesWithYearlyAvgs,
  selectInitialRangeComparables,
  selectJurisdictionPliFormat,
  selectRejectionReasons,
  selectWorkingContainer,
  selectFinancialInfoCompany,
  selectPliInfoCompany
} from '../../../../selectors';

const Connector = () => {
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliTypeId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgTypeId = Number(useParams<{ pliAvgId: string }>().pliAvgId);
  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const pliFormat = jurisdictionPliFormat
    ? jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pliTypeId)
    : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  const rejectionReasons: Rejections[] | undefined = useSelector(selectRejectionReasons);
  const currentSelectedCompany: FineTuningCompDetails | undefined = useSelector(selectCurrenctFineTuningCompany);
  const fineTuningStatusCounts: FineTuningCounts = useSelector(fineTuningCounts);
  const container = useSelector(selectWorkingContainer);
  const yearly = useSelector(selectInitialRangeComparables);
  const fineTuningCompsYearly = useSelector(selectFineTuningCompaniesWithYearlyAvgs);

  const financialInfo = useSelector(selectFinancialInfoCompany);
  const pliInfo = useSelector(selectPliInfoCompany);

  const [isFistTime, setIsFirstTime] = useState(true);

  const params: FineTuneParams = useMemo(() => {
    return { compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId };
  }, [compSearchId, jurisdictionId, pliAvgTypeId, pliTypeId]);

  useEffect(() => {
    setIsFirstTime(true);
    dispatch(fetchFineTuningCompanies({ params: { compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId } }));
  }, [dispatch, compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId]);

  useEffect(() => {
    if (!yearly) {
      dispatch(
        fetchInitialRangeComparables({
          compSearchId,
          jurisdictionId,
          pliId: pliTypeId,
          pliAvgId: pliAvgTypeId
        })
      );
    }

    if (!rejectionReasons) {
      dispatch(fetchRejectionReasons({ params }));
    }
  });

  const getFineTuningCompanyDetails = useCallback(
    (companyId: number, forceUpdate = false) => {
      if (companyId === currentSelectedCompany?.sourceId && !forceUpdate) return;
      dispatch(clearSelectedCompDetails());
      dispatch(fetchFineTuningCompany({ params: { ...params, companyId } }));
      dispatch(fetchFineTuningFinancialInformationCompany({ params: { ...params, companyId } }));
      dispatch(fetchFineTuningPLICompany({ params: { ...params, companyId } }));
    },
    [dispatch, params, currentSelectedCompany]
  );

  useEffect(() => {
    if (!currentSelectedCompany && fineTuningCompsYearly.data.length > 0 && isFistTime) {
      setIsFirstTime(false);
      getFineTuningCompanyDetails(fineTuningCompsYearly.data[0].sourceId);
    }
  }, [currentSelectedCompany, fineTuningCompsYearly, isFistTime, getFineTuningCompanyDetails]);

  const isUpdateRejectionStatusForceUpdate = (
    fineTuning: any,
    currentSelectCompany: FineTuningCompDetails | undefined,
    rejectionType: string | undefined
  ) => {
    if (fineTuning.isRejected === 0 && currentSelectCompany?.isRejected === 0) return false;
    if (fineTuning.otherRejection !== currentSelectCompany?.otherRejection) return true; // If other rejection changes, needs to be updated
    return (
      currentSelectedCompany?.isRejected !==
        fineTuning.isRejected /* The rejection type selected is different at the current one */ ||
      currentSelectedCompany?.rejectionName !==
        rejectionType /* The rejection type name is different at the current one (only when company is rejected again) */
    );
  };

  const updateRejectionStatus = async (
    isAccepted: boolean,
    statuses: any,
    rejectionType?: string,
    otherRejectionReason?: string
  ) => {
    const baseUrl = `compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}`;
    const fineTuningUpdate: any = { ...currentSelectedCompany, baseUrl, compSearchId: params.compSearchId };

    (statuses ?? []).forEach((status: { key: string; value: string }) => {
      fineTuningUpdate[status.key] = status.value;
    });

    if (rejectionType) {
      const rejectionObject: any = (rejectionReasons ?? []).find((rejection: any) => {
        return rejection.rejectionName === rejectionType;
      });
      if (rejectionObject.rejectionName === 'rejection_type.other') {
        fineTuningUpdate.otherRejection = otherRejectionReason;
      }

      Object.assign(fineTuningUpdate, rejectionObject);
    }

    if (isUpdateRejectionStatusForceUpdate(fineTuningUpdate, currentSelectedCompany, rejectionType)) {
      const data = {
        container,
        containerId: container?.containerId,
        ...fineTuningUpdate,
        pliTypeId: params.pliTypeId,
        pliAverageTypeId: params.pliAvgTypeId,
        sourceIds: [fineTuningUpdate.sourceId]
      };
      dispatch(updateRejectionReason({ ...params, data }));
      dispatch(updateRejectionRowStatus({ sourceId: fineTuningUpdate.sourceId, compStatus: isAccepted }));
    }

    getFineTuningCompanyDetails(
      fineTuningUpdate.sourceId,
      isUpdateRejectionStatusForceUpdate(
        fineTuningUpdate,
        currentSelectedCompany,
        rejectionType
      ) /* forcing right panel update */
    );
  };

  const fineTuningRejections: Rejections[] = (rejectionReasons ?? []).filter(
    (rejection: any) => rejection.rejectionGroupName === 'rejection_group_type.fine_tuning'
  );

  return (
    <Component
      {...fineTuningCompsYearly}
      fineTuningStatusCounts={fineTuningStatusCounts}
      rejectionReasons={fineTuningRejections}
      updateRejectionStatus={updateRejectionStatus}
      currentSelectedCompany={currentSelectedCompany}
      financialInformation={financialInfo}
      pliInfo={pliInfo}
      pliFormat={pliFormat}
      pliId={pliTypeId}
      onClickRow={getFineTuningCompanyDetails}
    />
  );
};

export default Connector;
