import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { SummariesProps } from './SummariesTab.proptypes';
import { Editor } from '../../../components';
import tokens from '../../../styles/designTokens';

const useStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: theme.spacing(3)
  },
  sectionWrapper: {
    marginBottom: theme.spacing(5),
    '&:last-child': {
      marginBottom: theme.spacing(0)
    }
  },
  editorWrapper: {
    marginTop: theme.spacing(2)
  },
  title: {
    fontFamily: tokens.mediumFont.fontFamily,
    fontSize: '1rem'
  },
  description: {
    fontSize: '1rem',
    color: tokens.core2
  }
}));

export const SummariesTab = ({
  defaultExecutiveValue,
  defaultConclusionValue,
  defaultOrgChartValue,
  onComplete
}: SummariesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [executiveValue, setExecutiveValue] = useState(defaultExecutiveValue);
  const [conclusionValue, setConclusionValue] = useState(defaultConclusionValue);
  const [orgChartValue, setOrgChartValue] = useState(defaultOrgChartValue);

  const onChangeExecutiveValue = useCallback((value: string) => {
    setExecutiveValue(value);
  }, []);

  const onChangeConclusionValue = useCallback((value: string) => {
    setConclusionValue(value);
  }, []);

  const onChangeOrgChartValue = useCallback((value: string) => {
    setOrgChartValue(value);
  }, []);

  useEffect(() => {
    onComplete({
      executive: executiveValue,
      conclusion: conclusionValue,
      orgChart: orgChartValue
    });
  }, [onComplete, executiveValue, conclusionValue, orgChartValue]);

  return (
    <Box className={classes.formWrapper}>
      <Box className={classes.sectionWrapper}>
        <Box className={classes.title}>{t('reports:title-local-report-creation-modal-summary-executive')}</Box>
        <Box className={classes.description}>{t('reports:label-local-report-creation-modal-summary-executive')}</Box>
        <Box className={classes.editorWrapper}>
          <Editor
            theme="TransferPricing"
            value={executiveValue}
            placeholder={t('placeholder-add-response-here')}
            onEditorChange={(value = '') => {
              onChangeExecutiveValue(value);
            }}
          />
        </Box>
      </Box>
      <Box className={classes.sectionWrapper}>
        <Box className={classes.title}>{t('reports:title-local-report-creation-modal-summary-conclusion')}</Box>
        <Box className={classes.description}>{t('reports:label-local-report-creation-modal-summary-conclusion')}</Box>
        <Box className={classes.editorWrapper}>
          <Editor
            theme="TransferPricing"
            value={conclusionValue}
            placeholder={t('placeholder-add-response-here')}
            onEditorChange={(value = '') => {
              onChangeConclusionValue(value);
            }}
          />
        </Box>
      </Box>
      <Box className={classes.sectionWrapper}>
        <Box className={classes.title}>
          {t('reports:title-local-report-creation-modal-organizational-chart-summary')}
        </Box>
        <Box className={classes.description}>
          {t('reports:label-local-report-creation-modal-organizational-chart-summary')}
        </Box>
        <Box className={classes.editorWrapper}>
          <Editor
            theme="TransferPricing"
            value={orgChartValue}
            placeholder={t('placeholder-add-response-here')}
            onEditorChange={(value = '') => {
              onChangeOrgChartValue(value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
