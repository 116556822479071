import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDropDown, GenericDropDownItem } from '../../components/GenericDropDown';

export interface ActionsDropDownProps {
  anchorEl: Element | null;
  onClose: () => void;
  startDeleteVersion: () => void;
}

export const ActionsDropDown = ({ anchorEl, onClose, startDeleteVersion }: ActionsDropDownProps) => {
  const { t } = useTranslation();

  const items: Array<GenericDropDownItem | ReactNode> = [
    {
      text: t('reports:action-delete-version'),
      onClick: () => {
        startDeleteVersion();
        onClose();
      }
    }
  ];

  return (
    <GenericDropDown
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      items={items}
      anchorEl={anchorEl}
      minWidth="12.313rem"
      onClose={onClose}
    />
  );
};
