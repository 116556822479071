import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { CenteredProgress } from '../../../../../components';
import { RangeResults } from '../../../../../models';
import { selectInitialRange } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { body3, body2 } from '../../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { Table } from '../../../../Table';
import { StickyColsDefinition } from '../../../../Table/Table.proptype';
import { InitialRangeProps } from '../InitialRange.proptype';

const rangeStatus = 'Range Status';
const testedParty = 'Tested Party';
const testedPartyPli = 'Tested Party PLI';

const useStyles = makeStyles(() => ({
  wrapperComponent: {
    height: '100%'
  },
  tableWrapper: {
    overflow: 'auto',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body:first-child': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-head.left.MuiTableCell-stickyHeader': {
      textAlign: 'left'
    }
  },
  scrollTable: {
    width: '100%',
    overflow: 'auto',
    '& tr:last-child td': {
      color: tokens.product100,
      backgroundColor: tokens.product15,
      '& .MuiTableCell-root': {
        color: tokens.product100,
        backgroundColor: tokens.product15
      }
    },
    '& tr:last-child td:first-child': {
      backgroundColor: tokens.product15
    }
  },
  headingRow: {
    ...body3,
    display: 'flex',
    margin: '1.75rem 0'
  },
  rowLabel: {
    ...body2,
    textAlign: 'left'
  }
}));

export const InitialRange = ({ pliFormat, pliId }: InitialRangeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialRangeResults: RangeResults | undefined = useSelector(selectInitialRange);

  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const displayResults: Array<Record<string, HTMLElement | string | number>> = (initialRangeResults?.data ?? [])
    .filter((result: any) => {
      return !result.rowLabel.includes(rangeStatus);
    })
    .map((result: any) => {
      const displayableResults: any = {};
      Object.keys(result).forEach((key) => {
        if (typeof result[key] === 'number') {
          displayableResults[key] = formatPliDisplayValue(formatType, roundingValue, result[key]);
        } else if (typeof result[key] === 'string') {
          const rowLabel = result[key] === testedParty ? testedPartyPli : result[key];
          displayableResults[key] = <div className={classes.rowLabel}>{rowLabel}</div>;
        }
      });
      return displayableResults;
    });

  const stickyCols: StickyColsDefinition = {};
  const displayColumns = (initialRangeResults?.columns ?? []).map((column) => {
    if (column.key === 'average') {
      stickyCols[column.key] = {
        side: 'right',
        position: 0
      };

      return { key: column.key, header: `${column.label} (%)`, width: '8.75rem' };
    }

    if (column.key === 'rowLabel') {
      stickyCols[column.key] = {
        side: 'left',
        position: 0
      };

      return {
        key: column.key,
        header: <span className={classes.rowLabel}>{column.label}</span>,
        width: '8.75rem'
      };
    }

    return { key: column.key, header: column.label, width: '7rem' };
  });

  return (
    <div className={classes.wrapperComponent}>
      <div className={classes.headingRow}>{t('analysis:initial-range')}</div>
      {initialRangeResults && initialRangeResults.columns?.length > 0 ? (
        <div className={classes.tableWrapper}>
          <Table
            className={classes.scrollTable}
            data={displayResults}
            columns={displayColumns}
            stickyCols={stickyCols}
          />
        </div>
      ) : (
        <CenteredProgress />
      )}
    </div>
  );
};
