import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { Theme, makeStyles, Typography, Box, TextField } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  TransactionAddEditModalProps,
  TransactionAddEditModalInputs,
  TransactionCurrencyDropdownValue
} from './TransactionAddEditModal.proptype';
import { Editor, SearchBox, TPModal, TPModalActions } from '../../components';
import { Entity, PartyRole, Transaction, TransactionType } from '../../models';
import { TransactionValueEnteredInEnum } from '../../models/transactions.interface';
import {
  findCountryCurrency,
  findTransactionDestination,
  findTransactionSource,
  findEntityCountry,
  convertValueToLocalValue,
  convertLocalValueToValue,
  getLegalEntityCurrencyConversionByTaxYear
} from '../../utils';
import { dateInLocalTimeZone } from '../../utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    boxSizing: 'content-box',
    fill: theme.palette.primary.contrastText,
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(0.5, 5.5),
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  errorWrapper: {
    padding: theme.spacing(0.5, 7.5)
  },
  formItem: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(0.5, 2)
  },
  formItemShrinked: {
    flexShrink: 1.5
  }
}));

const mapTransactionToForm = ({
  transaction,
  sourceEntity,
  destinationEntity,
  currencyTypes
}: {
  transaction: Transaction | null;
  sourceEntity: Entity | null;
  destinationEntity: Entity | null;
  currencyTypes: TransactionCurrencyDropdownValue[];
}): Partial<TransactionAddEditModalInputs> => {
  const {
    identifier = '',
    transactionType = null,
    description = '',
    propertyTransferred = '',
    value,
    units,
    partyRole = null,
    valueEnteredIn,
    transferDate
  } = transaction ?? {};

  return {
    identifier,
    transactionType,
    description,
    propertyTransferred,
    sourceEntity,
    destinationEntity,
    transferDate: transferDate ? new Date(dateInLocalTimeZone(transferDate)) : undefined,
    inputCurrency: currencyTypes.find((c) => c.value === valueEnteredIn) ?? null,
    partyRole,
    value,
    units
  };
};

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const TransactionAddEditModal = ({
  onClose,
  onSubmit,
  entities = [],
  transaction,
  partyRoles = [],
  transactionTypes = [],
  countries,
  container,
  currencies,
  conversionRates,
  upeCurrency
}: TransactionAddEditModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const entityById = useMemo(() => new Map((entities ?? []).map((entity) => [entity.entityId, entity])), [entities]);

  const [currencyTypes, setCurrencyTypes] = useState<TransactionCurrencyDropdownValue[]>([]);
  const [sourceEntity, setSourceEntity] = useState<Entity | null>(
    transaction ? findTransactionSource(transaction, entityById) ?? null : null
  );
  const [destinationEntity, setDestinationEntity] = useState<Entity | null>(
    transaction ? findTransactionDestination(transaction, entityById) ?? null : null
  );

  const [localValue, setLocalValue] = useState(0);
  const [currencyConversionError, setCurrencyConversionError] = useState(false);

  const getCurrency = useCallback(
    (valueEnteredIn: TransactionValueEnteredInEnum) => {
      if (valueEnteredIn === TransactionValueEnteredInEnum.Source) {
        return findCountryCurrency(findEntityCountry(sourceEntity, countries), currencies)!;
      }

      if (valueEnteredIn === TransactionValueEnteredInEnum.Destination) {
        return findCountryCurrency(findEntityCountry(destinationEntity, countries), currencies)!;
      }

      return upeCurrency;
    },
    [sourceEntity, destinationEntity, upeCurrency, countries, currencies]
  );

  const defaultValues = mapTransactionToForm({
    transaction,
    sourceEntity,
    destinationEntity,
    currencyTypes
  });

  const { handleSubmit, control, errors, watch, setValue, reset } = useForm<TransactionAddEditModalInputs>({
    defaultValues
  });

  const inputCurrencyWatcher = watch('inputCurrency');
  const transferDateWatcher = watch('transferDate');
  const sourceEntityWatcher = watch('sourceEntity');
  const destinationEntityWatcher = watch('destinationEntity');
  const valueWatcher = watch('value');

  const shouldRenderLocalValue = Boolean(
    inputCurrencyWatcher && inputCurrencyWatcher.currency.isoCode !== upeCurrency?.isoCode
  );

  useEffect(() => {
    // `react-hook-form` has some issues when adding default value and component is calling `useEffect`
    // To this is necessary to avoid input currency not showing the correct value when editing transaction
    if (transaction !== null && inputCurrencyWatcher === null && defaultValues.inputCurrency !== null) {
      setValue('inputCurrency', defaultValues.inputCurrency);
    }
  }, [defaultValues.inputCurrency, setValue, transaction, inputCurrencyWatcher]);

  useEffect(() => {
    if (valueWatcher !== null && inputCurrencyWatcher !== null && sourceEntity && destinationEntity) {
      const inputValue = Number(valueWatcher);
      if (inputCurrencyWatcher.value === TransactionValueEnteredInEnum.MNE) {
        setLocalValue(inputValue);
      } else {
        const sourceOrDestinationEntity = (inputCurrencyWatcher.value === TransactionValueEnteredInEnum.Source
          ? sourceEntity
          : destinationEntity)!;

        setLocalValue(
          convertValueToLocalValue(inputValue, container.taxYear, conversionRates, sourceOrDestinationEntity)
        );
      }
    }
  }, [
    valueWatcher,
    inputCurrencyWatcher,
    sourceEntity,
    destinationEntity,
    conversionRates,
    container,
    localValue,
    transaction
  ]);

  useEffect(() => {
    // Changing the currency types if source or entity destination changes
    if (sourceEntity && destinationEntity) {
      const mneCurrency: TransactionCurrencyDropdownValue = {
        value: TransactionValueEnteredInEnum.MNE,
        currency: getCurrency(TransactionValueEnteredInEnum.MNE)
      };

      setCurrencyTypes(
        [
          mneCurrency,
          ...[
            {
              value: TransactionValueEnteredInEnum.Source,
              currency: getCurrency(TransactionValueEnteredInEnum.Source)
            },
            {
              value: TransactionValueEnteredInEnum.Destination,
              currency: getCurrency(TransactionValueEnteredInEnum.Destination)
            }
          ].filter(({ currency }) => currency.isoCode !== mneCurrency.currency.isoCode)
        ].sort((c) => c.value)!
      );
    }
  }, [sourceEntity, destinationEntity, getCurrency]);

  useEffect(() => {
    if (sourceEntity && inputCurrencyWatcher && destinationEntity) {
      const sourceOrDestinationEntity = (inputCurrencyWatcher.value === TransactionValueEnteredInEnum.Source
        ? sourceEntity
        : destinationEntity)!;
      const isACurrencyConversionError =
        getLegalEntityCurrencyConversionByTaxYear(sourceOrDestinationEntity, container.taxYear, conversionRates) ===
          undefined && inputCurrencyWatcher.currency.isoCode !== upeCurrency.isoCode;

      if (isACurrencyConversionError) {
        setValue('value', 0);
        setValue('localValue', 0);
      }

      setCurrencyConversionError(isACurrencyConversionError);
    }
  }, [
    sourceEntity,
    destinationEntity,
    container,
    setCurrencyConversionError,
    upeCurrency,
    conversionRates,
    inputCurrencyWatcher,
    setValue
  ]);

  return (
    <TPModal
      isOpen
      maxWidth="md"
      title={
        <span className={classes.modalTitleWrapper}>
          <AddIcon className={classes.headerIcon} />
          <Typography variant="h6">
            {t(`transactions:${transaction ? 'title-edit-transaction' : 'title-add-transaction'}`)}
          </Typography>
        </span>
      }
      actions={
        [
          { name: t('action-cancel'), handler: onClose },
          transaction
            ? null
            : {
                name: t('action-save-and-new'),
                id: 'button-newui-add-transactions-modal-save-and-new',
                handler: handleSubmit(async (data) => {
                  try {
                    await onSubmit({ ...data, value: localValue });
                    setSourceEntity(null);
                    setDestinationEntity(null);
                    reset(
                      mapTransactionToForm({
                        transaction: null,
                        sourceEntity: null,
                        destinationEntity: null,
                        currencyTypes
                      })
                    );
                  } catch {}
                })
              },
          {
            name: t('action-save'),
            id: 'button-newui-add-transactions-modal-save',
            variant: 'contained',
            color: 'primary',
            handler: handleSubmit(async (data) => {
              try {
                await onSubmit({ ...data, value: localValue });
                onClose();
              } catch {}
            })
          }
        ].filter(Boolean) as TPModalActions[]
      }
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:column-id')}</Typography>
          <Controller
            name="identifier"
            render={({ onChange, value }) => (
              <TextField
                id="identifier"
                name="identifier"
                margin="dense"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
            defaultValue={defaultValues.identifier}
            rules={{
              required: true,
              validate: (identifier: string) => identifier.trim().length > 0 && identifier.trim().length <= 30
            }}
            error={Boolean(errors.identifier)}
          />
          <ErrorMessage error={Boolean(errors.identifier)} message={t('transactions:invalid-entry-required-id')} />
        </Box>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:column-type')}</Typography>
          <Controller
            name="transactionType"
            render={({ onChange, value }) => (
              <SearchBox
                id="field-newui-add-edit-transaction-input"
                error={Boolean(errors.transactionType)}
                value={value || defaultValues.transactionType}
                options={transactionTypes}
                getOptionSelected={(option, value) => option.transactionTypeId === value.transactionTypeId}
                getOptionLabel={(type: TransactionType) => t(`transactions:type-${type.name}`)}
                onChange={(_, value) => {
                  onChange(value);
                }}
              />
            )}
            defaultValue={defaultValues.transactionType ?? undefined}
            control={control}
            rules={{ required: true }}
            error={Boolean(errors.transactionType)}
          />
          <ErrorMessage
            error={Boolean(errors.transactionType)}
            message={t('transactions:invalid-entry-required-type')}
          />
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:column-source')}</Typography>
          <Controller
            name="sourceEntity"
            render={({ onChange, value }) =>
              sourceEntity && transaction ? (
                <TextField disabled id="sourceEntity" margin="dense" variant="outlined" value={value.code} />
              ) : (
                <SearchBox
                  error={Boolean(errors.sourceEntity)}
                  value={value}
                  options={entities.filter((source) => destinationEntity?.entityId !== source.entityId)}
                  getOptionSelected={(option, value) => option.code === value.code}
                  getOptionLabel={(source) => source.code}
                  onChange={(_, item) => {
                    setSourceEntity(item);
                    setValue('inputCurrency', null);
                    onChange(item);
                  }}
                />
              )
            }
            control={control}
            rules={{ required: true }}
            defaultValue={defaultValues.sourceEntity}
            error={Boolean(errors.sourceEntity)}
          />
          <ErrorMessage
            error={Boolean(errors.sourceEntity)}
            message={t('transactions:invalid-entry-required-source')}
          />
        </Box>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:column-destination')}</Typography>
          <Controller
            name="destinationEntity"
            render={({ onChange, value }) =>
              destinationEntity && transaction ? (
                <TextField disabled id="destinationEntity" margin="dense" variant="outlined" value={value.code} />
              ) : (
                <SearchBox
                  error={Boolean(errors.destinationEntity)}
                  value={destinationEntity ?? value}
                  options={entities.filter((source) => sourceEntity?.entityId !== source.entityId)}
                  getOptionSelected={(option, value) => option.code === value.code}
                  getOptionLabel={(dest: { code: string }) => dest.code}
                  onChange={(_, value) => {
                    onChange(value);
                    setDestinationEntity(value as Entity);
                    setValue('inputCurrency', null);
                  }}
                />
              )
            }
            control={control}
            rules={{ required: true }}
            defaultValue={defaultValues.destinationEntity}
            error={Boolean(errors.destinationEntity)}
          />
          <ErrorMessage
            error={Boolean(errors.destinationEntity)}
            message={t('transactions:invalid-entry-required-destination')}
          />
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:label-property-transferred')}</Typography>
          <Controller
            name="propertyTransferred"
            render={({ onChange, value }) => (
              <TextField
                id="propertyTransferred"
                name="propertyTransferred"
                margin="dense"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
            rules={{ required: true }}
            defaultValue={defaultValues.propertyTransferred}
            error={Boolean(errors.propertyTransferred)}
          />
          <ErrorMessage
            error={Boolean(errors.propertyTransferred)}
            message={t('transactions:invalid-entry-required-property-transferred')}
          />
        </Box>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:column-transfer')}</Typography>
          <Controller
            name="transferDate"
            render={({ onChange, value }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  id="transferDate"
                  format={t('transactions:date-MUI-format')}
                  placeholder={t('transactions:placeholder-date')}
                  value={value || (defaultValues.transferDate ?? null)}
                  maxDate={new Date(container.taxYear + 1, 11, 31)}
                  minDate={new Date(container.taxYear - 1, 0, 1)}
                  onChange={onChange}
                />
              </MuiPickersUtilsProvider>
            )}
            control={control}
            rules={{ required: true }}
            defaultValue={defaultValues.transferDate ?? null}
            error={Boolean(errors.transferDate)}
          />
          <ErrorMessage
            error={Boolean(errors.transferDate)}
            message={t('transactions:invalid-entry-required-transfer')}
          />
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:label-units')}</Typography>
          <Controller
            name="units"
            render={({ onChange, value }) => (
              <TextField
                id="units"
                name="units"
                margin="dense"
                variant="outlined"
                type="number"
                value={value ?? ''}
                onChange={onChange}
              />
            )}
            control={control}
            defaultValue={defaultValues.units ?? ''}
            error={Boolean(errors.units)}
          />
        </Box>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:label-input-currency')}</Typography>
          <Controller
            name="inputCurrency"
            render={({ onChange, value }) => (
              <SearchBox
                error={Boolean(errors.inputCurrency) || currencyConversionError}
                disabled={
                  transferDateWatcher === null || sourceEntityWatcher === null || destinationEntityWatcher === null
                }
                options={currencyTypes}
                getOptionSelected={(option, value) => option.value === value.value}
                getOptionLabel={(inputCurrency: TransactionCurrencyDropdownValue) =>
                  t(`transactions:option-input-currency-${inputCurrency.value}`, inputCurrency.currency)
                }
                value={value || defaultValues.inputCurrency}
                onChange={(_, value) => {
                  onChange(value);
                }}
              />
            )}
            control={control}
            defaultValue={defaultValues.inputCurrency}
            rules={{
              required: true
            }}
            error={Boolean(errors.inputCurrency) || currencyConversionError}
          />
          <ErrorMessage
            error={Boolean(errors.inputCurrency)}
            message={t('transactions:invalid-entry-required-input-currency')}
          />
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={`${classes.formItem} ${shouldRenderLocalValue ? classes.formItemShrinked : ''}`}>
          <Typography>{t('transactions:label-value', { currency: upeCurrency.isoCode })}</Typography>
          <Controller
            name="value"
            render={({ onChange, value }) => (
              <TextField
                id="value"
                name="value"
                disabled={currencyConversionError || inputCurrencyWatcher === null || shouldRenderLocalValue}
                margin="dense"
                variant="outlined"
                type="number"
                value={value ?? ''}
                onChange={onChange}
              />
            )}
            control={control}
            rules={{ required: true, validate: (input) => input > 0 }}
            defaultValue={defaultValues.value ?? ''}
            error={Boolean(errors.value)}
          />
          <ErrorMessage error={Boolean(errors.value)} message={t('transactions:invalid-entry-required-value')} />
        </Box>
        {shouldRenderLocalValue && (
          <Box className={`${classes.formItem} ${classes.formItemShrinked}`}>
            <Typography>
              {t('transactions:label-value', {
                currency: inputCurrencyWatcher?.currency.isoCode
              })}
            </Typography>
            <TextField
              name="localValue"
              margin="dense"
              variant="outlined"
              type="number"
              value={localValue}
              disabled={currencyConversionError}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const sourceOrDestinationEntity = (inputCurrencyWatcher!.value === TransactionValueEnteredInEnum.Source
                  ? sourceEntity
                  : destinationEntity)!;

                setValue(
                  'value',
                  convertLocalValueToValue(
                    Number(event.target.value),
                    container.taxYear,
                    conversionRates,
                    sourceOrDestinationEntity
                  )
                );
              }}
            />
          </Box>
        )}
        <Box className={classes.formItem}>
          <Typography>{t('transactions:label-party-roles')}</Typography>
          <Controller
            name="partyRole"
            render={({ onChange, value }) => (
              <SearchBox
                error={Boolean(errors.partyRole)}
                value={value || defaultValues.partyRole}
                options={partyRoles}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(partyRole: PartyRole) => t(`transactions:party-role-${partyRole.name}`)}
                onChange={(_, value) => {
                  onChange(value);
                }}
              />
            )}
            control={control}
            defaultValue={defaultValues.partyRole}
            rules={{ required: true }}
            error={Boolean(errors.partyRole)}
          />
          <ErrorMessage
            error={Boolean(errors.partyRole)}
            message={t('transactions:invalid-entry-required-party-role')}
          />
        </Box>
      </Box>

      <Box className={`${classes.container} ${classes.errorWrapper}`}>
        <ErrorMessage error={currencyConversionError} message={t('errors:error_message.currency_conversion_rate')} />
      </Box>

      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography>{t('transactions:label-description')}</Typography>
          <Controller
            name="description"
            render={({ onChange, value }) => (
              <Editor
                error={Boolean(errors.description)}
                theme="TransferPricing"
                id="add-edit-transaction"
                init={{ height: '250' }}
                value={value}
                onEditorChange={(content) => {
                  onChange(content);
                }}
              />
            )}
            control={control}
            rules={{ required: true }}
            defaultValue={defaultValues.description}
            error={Boolean(errors.description)}
          />
          <ErrorMessage
            error={Boolean(errors.description)}
            message={t('transactions:invalid-entry-required-description')}
          />
        </Box>
      </Box>
    </TPModal>
  );
};
