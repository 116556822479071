import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ConfirmationModal } from '../../../components';
import { DeleteReportConfirmationProps } from '../LocalReports.proptype';

export const DeleteReportConfirmation = ({
  open = false,
  nameReport,
  executeDeleteReport,
  setIsConfirmationOpen
}: DeleteReportConfirmationProps) => {
  const { t } = useTranslation();

  const onHandleDeletion = useCallback(
    (confirmed: boolean) => {
      setIsConfirmationOpen(false);
      if (confirmed) {
        executeDeleteReport();
      }
    },
    [executeDeleteReport, setIsConfirmationOpen]
  );

  return (
    <ConfirmationModal
      open={open}
      title={t('reports:title-modal-delete-report')}
      subtitle=""
      text={
        ((
          <>
            <Box fontWeight="bold">{nameReport}</Box>
            <Box fontSize="0.95rem">{t('reports:message-modal-delete-report-part-1')}</Box>
            <Box fontSize="0.95rem">{t('reports:message-modal-delete-report-part-2')}</Box>
          </>
        ) as unknown) as string
      }
      HeaderIcon={DeleteIcon}
      handleClose={onHandleDeletion}
    />
  );
};
