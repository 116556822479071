import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { AssociatedTransactions } from './AssociatedTransactions';
import { TestedPartyDetailsForm } from './TestedPartyDetailsForm';
import { Transaction } from '../../../models';
import { ProfitBasedAnalysisAddModalTable } from '../../ProfitBasedAnalysisAddModal';
import { SelectedTransaction } from '../../ProfitBasedAnalysisAddModal/ProfitBasedAnalysisAddModal.proptype';
import { TPModal } from '../../TPModal';
import { PBADashboardSummaryProps } from '../PBADashboardTPDSummary.proptype';

const useStyles = makeStyles((theme) => ({
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  titleClass: {
    display: 'inline-flex',
    float: 'left'
  },
  dropdownClass: {
    float: 'right'
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  headerIcon: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const PBADashboardTPDSummary = ({
  pba,
  currentTestedParty,
  onSubmit,
  onTransactionsUpdateSubmit,
  primaryFunctionOptions,
  transactionsPBA,
  dropdownEntities,
  testedPartyTransactions,
  entities,
  onDeleteAssociateTransaction,
  upeCurrency
}: PBADashboardSummaryProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false);

  const [selectedTransactions, setSelectedTransactions] = useState<SelectedTransaction[]>([]);
  const [showError, setShowError] = useState(false);

  const [associatedTransactions, setAssociatedTransactions] = useState<Transaction[]>(testedPartyTransactions);

  useEffect(() => {
    if (testedPartyTransactions) {
      setAssociatedTransactions(testedPartyTransactions);
    }
  }, [testedPartyTransactions]);

  const onAddTransactions = () => {
    setAddTransactionModalOpen(true);
  };

  const onClose = () => {
    closeAndReset();
  };

  const handleClose = () => {
    closeAndReset();
  };

  const closeAndReset = () => {
    setSelectedTransactions([]);
    setShowError(false);
    setAssociatedTransactions(testedPartyTransactions);
    setAddTransactionModalOpen(false);
  };

  const onUpdateTransactions = (entityCode: string) => {
    const transactions: Transaction[] = [];
    testedPartyTransactions.forEach((transaction: Transaction) => {
      const entities = transaction.legalEntityTransactions;
      if (entities[0].entity.code === entityCode || entities[1].entity.code === entityCode) {
        transactions.push(transaction);
      }
    });
    setAssociatedTransactions(transactions);
  };

  const defaultValues = {
    name: '',
    code: ''
  };

  const formMethods = useForm<any>({
    defaultValues
  });

  const { control } = formMethods;

  return (
    <>
      <TestedPartyDetailsForm
        currentTestedParty={currentTestedParty}
        pba={pba}
        primaryFunctionOptions={primaryFunctionOptions}
        onSubmit={onSubmit}
      />
      <AssociatedTransactions
        pba={pba}
        transactionsPBA={transactionsPBA}
        entities={entities}
        upeCurrency={upeCurrency}
        onAddTransactions={onAddTransactions}
        onDelete={onDeleteAssociateTransaction}
      />
      {addTransactionModalOpen && (
        <TPModal
          isOpen
          maxWidth="md"
          title={
            <>
              <span className={classes.titleClass}>
                <AddIcon className={classes.headerIcon} />
                <Typography variant="h6">{t('analysis:title-add-associated-transactions')}</Typography>
              </span>
              <span className={classes.dropdownClass}>
                <FormControl size="small" variant="outlined">
                  <Controller
                    render={({ value, onChange }) => (
                      <Select
                        displayEmpty
                        value={value}
                        onChange={(event: any) => {
                          onChange(event);
                          setSelectedTransactions([]);
                          onUpdateTransactions(event.target.value);
                        }}
                      >
                        <MenuItem value="">
                          <div className={classes.placeHolder}>{t('analysis:title-filter-trading-partner')}</div>
                        </MenuItem>
                        {dropdownEntities.map(({ name, code }) => (
                          <MenuItem key={name} value={name}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    name="tradingPartnerEntity"
                    control={control}
                    defaultValue={defaultValues.name}
                    rules={{ required: true }}
                  />
                </FormControl>
              </span>
            </>
          }
          actions={[
            {
              name: t('action-cancel'),
              handler: () => {
                onClose();
              }
            },
            {
              name: t('action-done'),
              className: classes.doneButton,
              handler: async () => {
                if (selectedTransactions.length > 0) {
                  setShowError(false);
                  await onTransactionsUpdateSubmit!(selectedTransactions, pba.pbaId);
                  setSelectedTransactions([]);
                  handleClose();
                } else {
                  setShowError(true);
                }
              }
            }
          ]}
          onClose={handleClose}
        >
          <Grid>
            <ProfitBasedAnalysisAddModalTable
              entities={entities}
              selectedTransactions={selectedTransactions}
              transactions={associatedTransactions}
              upeCurrency={upeCurrency}
              onChange={(transactions: any[]) => {
                setSelectedTransactions(transactions);
                setShowError(false);
              }}
            />
            <ErrorMessage error={showError} message={t('analysis:invalid-add-associated-transactions')} />
          </Grid>
        </TPModal>
      )}
    </>
  );
};
