import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContentText, TextField, makeStyles } from '@material-ui/core';
import { CenteredProgress } from '../../../components';
import { body1 } from '../../../styles/typography';
import { ImportModalProps } from '../ImportModal.proptype';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    padding: theme.spacing(4, 2.5, 4, 7.75)
  },
  input: {
    display: 'none'
  },
  uploadDialogContentText: {
    paddingBottom: theme.spacing(5)
  },
  fileInput: {
    width: '50%',
    '& .MuiInputLabel-formControl': {
      ...body1,
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%'
    }
  }
}));

interface UploadProps extends Pick<ImportModalProps, 'namespace' | 'loading'> {
  file?: File | null;
  onSelectFile: (file?: File | null) => void;
}

export const Upload = ({ namespace, file, loading, onSelectFile }: UploadProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.modalWrapper}>
      <DialogContentText>{t(`${namespace}:message-upload-modal-file`)}</DialogContentText>
      <DialogContentText className={classes.uploadDialogContentText}>
        {t(`${namespace}:message-upload-modal-file-description`)}
      </DialogContentText>
      {loading ? (
        <CenteredProgress />
      ) : (
        <>
          <DialogContentText>{t(`${namespace}:message-upload-modal-file-select`)}</DialogContentText>
          <Box className={classes.fileInput}>
            <TextField
              key={file?.name}
              disabled
              size="small"
              label={file ? file.name : t('placeholder-choose-file')}
              variant="outlined"
            />
          </Box>
          <Box marginTop={2}>
            {/* Unfortunately, this is a known issue in material-ui package.
        So button can't be a button.
        More details in https://stackoverflow.com/questions/40589302/how-to-enable-file-upload-on-reacts-material-ui-simple-input#answer-54043619 */}
            <Button variant="outlined" component="label">
              {t('action-choose-file')}
              <input
                accept=".xls,.xlsx,.xlsm"
                className={classes.input}
                data-testid="upload-file"
                type="file"
                onChange={(event) => {
                  onSelectFile(event.target.files?.item(0));
                }}
              />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
