import { useTranslation } from 'react-i18next';
import { Box, Typography, fade, makeStyles } from '@material-ui/core';
import { CenteredProgress } from '../../components';
import { Currency, Entity, Transaction } from '../../models';
import { dateInLocalTimeZone } from '../../utils/dates';
import { DashboardCard } from '../DashboardCard';

const useStyles = makeStyles((theme) => ({
  characteristics: {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    gap: theme.spacing(4),
    '& .MuiTypography-caption': {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap'
    }
  },
  diagram: {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    '& .MuiTypography-caption': {
      color: theme.palette.text.secondary
    }
  },
  entity: {
    alignItems: 'center',
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    textAlign: 'center',
    '&::before': {
      aspectRatio: '1',
      backgroundColor: theme.palette.primary.main,
      border: `10px solid ${theme.palette.primary.light}`,
      borderRadius: '100%',
      content: '""',
      display: 'inline-block',
      margin: theme.spacing(5, 0, 1),
      width: '75%'
    }
  },
  value: {
    aspectRatio: '1',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.secondary.main, 0.9),
    borderRadius: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, '15%'),
    padding: theme.spacing(1),
    position: 'relative',
    zIndex: 1
  },
  arrow: {
    position: 'absolute',
    top: '48%',
    width: '100%',
    zIndex: 0
  },
  arrowBar: {
    stroke: theme.palette.primary.dark,
    strokeWidth: 1
  },
  arrowMarker: {
    fill: theme.palette.primary.dark
  }
}));

export interface TransactionSummaryCardProps {
  transaction?: Transaction;
  source?: Entity;
  destination?: Entity;
  currency?: Currency;
}

export const TransactionSummaryCard = ({ transaction, currency, source, destination }: TransactionSummaryCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const arrowSize = 10;

  return (
    <DashboardCard title={t('transactions:title-summary')}>
      {transaction ? (
        <>
          <Box className={classes.characteristics}>
            <Box>
              <Typography variant="caption">{t('transactions:column-type')}</Typography>
              <Box>
                {transaction.transactionType?.name ? t(`transactions:type-${transaction.transactionType.name}`) : ''}
              </Box>
            </Box>
            <Box>
              <Typography variant="caption">{t('transactions:column-transfer')}</Typography>
              <Box>
                {t(`date-format-short`, {
                  date: new Date(dateInLocalTimeZone(transaction.transferDate))
                })}
              </Box>
            </Box>
          </Box>
          <Box className={classes.diagram}>
            <Box className={classes.entity}>
              <Typography variant="body1">{source?.code}</Typography>
              <Typography variant="caption">{source?.domicile?.name}</Typography>
            </Box>
            <Box flex={0.5} position="relative">
              <Box className={classes.value}>
                <Typography variant="caption">
                  {t('transactions:label-value', { currency: currency?.isoCode })}
                </Typography>
                <Typography variant="body1">
                  {t('{{value, currency}}', { value: transaction?.value ?? 0, currency: currency?.isoCode })}
                </Typography>
              </Box>
              <svg className={classes.arrow} viewBox={`0 0 200 ${arrowSize}`}>
                <defs>
                  <marker
                    id="arrow"
                    markerWidth={arrowSize}
                    markerHeight={arrowSize}
                    viewBox={`0 0 ${arrowSize} ${arrowSize}`}
                    refX={arrowSize}
                    refY={arrowSize / 2}
                    orient="auto"
                  >
                    <polygon
                      points={`0 0, ${arrowSize} ${arrowSize / 2}, 0 ${arrowSize}`}
                      className={classes.arrowMarker}
                    />
                  </marker>
                </defs>
                <line
                  y1={arrowSize / 2}
                  x2={200}
                  y2={arrowSize / 2}
                  className={classes.arrowBar}
                  markerEnd="url(#arrow)"
                />
              </svg>
            </Box>
            <Box className={classes.entity}>
              <Typography variant="body1">{destination?.code}</Typography>
              <Typography variant="caption">{destination?.domicile?.name}</Typography>
            </Box>
          </Box>
        </>
      ) : (
        <CenteredProgress />
      )}
    </DashboardCard>
  );
};
