import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Theme, makeStyles, Typography, Box, TextField } from '@material-ui/core';
import { CreateOutlined as CreateOutlinedIcon } from '@material-ui/icons';
import { StudyTabsEditModalProps, StudyTabsElementInputs } from './StudyTabsEditModal.proptype';
import { TPModal } from '../../components';
import { ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    boxSizing: 'content-box',
    fill: theme.palette.primary.contrastText,
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(0.5, 5.5),
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  formItem: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(0.5, 0)
  },
  label: {
    padding: theme.spacing(0.5)
  }
}));

const mapElementToForm = ({
  element
}: {
  element: ProfitBasedAnalysis | TransactionBasedAnalysis | null;
}): StudyTabsElementInputs => {
  const { name = '' } = element ?? {};

  return {
    name
  };
};

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const StudyTabsEditModal = ({ onClose, onSubmit, element, isPBA }: StudyTabsEditModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultValues = mapElementToForm({ element });

  const labelType = isPBA ? 'pba' : 'tba';

  const formMethods = useForm<StudyTabsElementInputs>({
    defaultValues
  });
  const { handleSubmit, control, errors } = formMethods;

  return (
    <TPModal
      isOpen
      maxWidth="sm"
      title={
        <span className={classes.modalTitleWrapper}>
          <CreateOutlinedIcon className={classes.headerIcon} />
          <Typography variant="h6">{t(`analysis:title-edit-${labelType}`)}</Typography>
        </span>
      }
      actions={
        [
          { name: t('action-cancel'), handler: onClose },
          {
            name: t('action-done'),
            variant: 'contained',
            color: 'primary',
            handler: handleSubmit(async (data: ProfitBasedAnalysis | TransactionBasedAnalysis) => {
              try {
                onSubmit(data);
                onClose();
              } catch {}
            })
          }
        ].filter(Boolean) as any
      }
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography className={classes.label}>{t(`analysis:column-${labelType}-name`)}</Typography>
          <Controller
            name="name"
            render={({ onChange, value }) => (
              <TextField
                id="name"
                name="name"
                margin="dense"
                variant="outlined"
                value={value}
                error={Boolean(errors.name)}
                onChange={onChange}
              />
            )}
            control={control}
            defaultValue={defaultValues.name}
            rules={{ required: true }}
            error={Boolean(errors.name)}
          />
          <ErrorMessage
            error={Boolean(errors.name)}
            message={t(`analysis:invalid-entry-required-element-name-${labelType}`)}
          />
        </Box>
      </Box>
    </TPModal>
  );
};
