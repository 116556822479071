import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, makeStyles } from '@material-ui/core';
import { FineTuningFinancialInfo } from '../../../../../models';
import tokens from '../../../../../styles/designTokens';
import { body2, captionOverline } from '../../../../../styles/typography';
import { Table } from '../../../../Table';
import { StickyColsDefinition, TableColumnDef } from '../../../../Table/Table.proptype';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    overflow: 'auto',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-head.left.MuiTableCell-stickyHeader': {
      textAlign: 'left'
    }
  },
  scrollTable: {
    width: '100%',
    overflow: 'auto',
    '& td': {
      borderLeft: `0.063rem solid ${tokens.neutral90}`
    },
    '& td:nth-child(2)': {
      borderLeft: 0
    },
    '& .MuiTableCell-head': {
      '&:first-child': {
        paddingLeft: 0
      }
    },
    '& .MuiTableCell-body:first-child': {
      backgroundColor: tokens.neutral60,
      borderLeft: 0
    }
  },
  relevantRow: {
    '& .MuiTableCell-body:first-child': {
      ...body2,
      backgroundColor: tokens.neutral70
    },
    '& .MuiTableCell-root': {
      ...body2,
      backgroundColor: tokens.neutral70,
      borderTop: '0.063rem solid ' + tokens.core2,
      '& .MuiTableCell-root': {
        borderTop: 0
      }
    }
  },
  chipWrapper: {
    ...captionOverline,
    color: tokens.product100,
    borderRadius: '0.688rem',
    backgroundColor: tokens.product15
  }
}));

interface FinancialTableProps {
  keysFinancial: string[];
  financialInfo?: FineTuningFinancialInfo[];
}

interface RowFinancialInfo {
  [key: string]: string | number;
  type: string;
  order: number;
}

export const FinancialTable = ({ keysFinancial, financialInfo }: FinancialTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currency = useMemo(() => {
    if (financialInfo && financialInfo.length > 1) {
      return financialInfo[0].currencyCode;
    }

    return '';
  }, [financialInfo]);

  const financialInfoWithOperatingProfit = useCallback(() => {
    return (financialInfo ?? []).map((info: FineTuningFinancialInfo) => {
      return {
        ...info,
        operatingProfit: Number(info.grossProfit) - Number(info.operatingExpenses),
        netPpe: info.netSales
      };
    });
  }, [financialInfo]);

  const data = useMemo(() => {
    const rows: RowFinancialInfo[] = [];
    const info: FineTuningFinancialInfo[] = financialInfoWithOperatingProfit();
    info.forEach((financeInfo: FineTuningFinancialInfo) => {
      Object.keys(financeInfo).forEach((key) => {
        if (key !== 'taxYear' && keysFinancial.includes(key)) {
          const existRowIndex = rows.findIndex((row: RowFinancialInfo) => row.type === key);
          const displayableResult: RowFinancialInfo = rows[existRowIndex] ?? {};
          displayableResult.type = key;
          displayableResult.order = keysFinancial.indexOf(key) + 1;
          displayableResult[financeInfo.taxYear] = financeInfo[key as keyof FineTuningFinancialInfo];
          if (existRowIndex > -1) {
            rows[existRowIndex] = displayableResult;
          } else {
            rows.push(displayableResult);
          }
        }
      });
    });

    rows.sort((firstRow: RowFinancialInfo, secondRow: RowFinancialInfo) => firstRow.order - secondRow.order);

    return rows;
  }, [financialInfoWithOperatingProfit, keysFinancial]);

  const columnsDefinition = useMemo(() => {
    const stickyCols: StickyColsDefinition = {};
    const displayColumns: TableColumnDef[] = [];

    if (data) {
      Object.keys(data[0] ?? {}).forEach((key) => {
        if (key !== 'order' && key !== 'type') {
          displayColumns.push({ key, header: <span>{key}</span>, width: '1rem' });
        }

        if (key === 'type') {
          stickyCols[key] = { side: 'left', position: 0 };
          displayColumns.push({
            key,
            header: currency && <Chip className={classes.chipWrapper} size="small" label={currency} />,
            width: '2rem'
          });
        }
      });
    }

    return {
      displayColumns,
      stickyCols
    };
  }, [classes.chipWrapper, currency, data]);

  const dataFin: Array<Record<string, HTMLElement | number>> = data.map((financial) => {
    const displayableComp: any = {};
    Object.keys(financial).forEach((key) => {
      if (financial.type === 'grossProfit' || financial.type === 'operatingProfit') {
        displayableComp.className = classes.relevantRow;
      }

      if (typeof financial[key] === 'number' && key !== 'order') {
        const valueRow = Number(financial[key]);
        displayableComp[key] = new Intl.NumberFormat('en', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(valueRow);
      } else if (typeof financial[key] === 'string') {
        displayableComp[key] = <>{t(`analysis:pba-financial-financial_type.${String(financial[key])}`)}</>;
      } else {
        displayableComp[key] = financial[key];
      }
    });

    return displayableComp;
  });

  return (
    <div className={classes.tableWrapper}>
      <Table
        className={classes.scrollTable}
        data={dataFin}
        columns={columnsDefinition.displayColumns}
        stickyCols={columnsDefinition.stickyCols}
      />
    </div>
  );
};
