import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MajorClassification } from '../../app/TestedPartyCharacterization/TestedPartyCharacterization.proptype';
import { Distributor, sicType } from '../../app/TestedPartyDistributor';
import httpService from '../../services/http';

const initialState: TesetedPartyState = {
  majorClassifications: [],
  currentMajorClassification: [],
  currentDistributor: {},
  sicTypes: []
} as any;

interface TesetedPartyState {
  majorClassifications: MajorClassification[];
  currentMajorClassification: MajorClassification[];
  currentDistributor: Distributor;
  sicTypes: sicType[];
}

interface ValueAddedDistributorPayload {
  characterizationType: number;
  characterizationFunctionsPerformed: ToggleState[];
}

interface ToggleState {
  state: boolean;
  functionPerformed: { functionPerformedId: number };
}

interface SICOptionPayload {
  characterizationType: number;
  codeType: { codeTypeId: number };
  typeOfPrimaryFunctionOption: { typeOfPrimaryFunctionOptionId: number };
  industrialCodeValue: { industrialCodeValueId: number; name: string; value: number };
}

export const fetchMajorClassifications = createAsyncThunk<MajorClassification[], void, { rejectValue: Error }>(
  'testedParty/major-service-classifications/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: MajorClassification[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'major-service-classifications'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCurrentMajorClassifications = createAsyncThunk<MajorClassification[], number, { rejectValue: Error }>(
  'testedParty/current-major-service-classifications/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: MajorClassification[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/major-service-classifications`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const addMajorClassifications = createAsyncThunk<
  MajorClassification[],
  { pbaId: number; majorClassificationId: number },
  { rejectValue: Error }
>(
  'testedParty/current-major-service-classifications/add',
  async ({ pbaId, majorClassificationId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: MajorClassification[] }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/major-service-classifications/${majorClassificationId}`,
          data: {}
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const addMinorClassifications = createAsyncThunk<
  MajorClassification[],
  { pbaId: number; minorClassificationId: number },
  { rejectValue: Error }
>(
  'testedParty/current-minor-service-classifications/add',
  async ({ pbaId, minorClassificationId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: MajorClassification[] }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/minor-service-classifications/${minorClassificationId}`,
          data: {}
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteMajorClassifications = createAsyncThunk<
  MajorClassification[],
  { pbaId: number; majorClassificationId: number },
  { rejectValue: Error }
>(
  'testedParty/current-major-service-classifications/delete',
  async ({ pbaId, majorClassificationId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: MajorClassification[] }>({
          method: 'delete',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/major-service-classifications/${majorClassificationId}`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteMinorClassifications = createAsyncThunk<
  MajorClassification[],
  { pbaId: number; minorClassificationId: number },
  { rejectValue: Error }
>(
  'testedParty/current-minor-service-classifications/delete',
  async ({ pbaId, minorClassificationId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: MajorClassification[] }>({
          method: 'delete',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/minor-service-classifications/${minorClassificationId}`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCurrentDistributor = createAsyncThunk<Distributor, number, { rejectValue: Error }>(
  'testedParty/current-distributor/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: Distributor }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/characterization`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchSICTypeOptions = createAsyncThunk<sicType[], number, { rejectValue: Error }>(
  'testedParty/sic-type-options/fetch',
  async (typeOption, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: sicType[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/characterization/type-of-options/${typeOption}`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateSICOption = createAsyncThunk<
  Distributor,
  { pbaId: number; payload: SICOptionPayload },
  { rejectValue: Error }
>('testedParty/sic-industry-option/patch', async ({ pbaId, payload }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: Distributor }>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `pbas/${pbaId}/characterization/option-type`,
        data: payload
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const saveSICOption = createAsyncThunk<
  Distributor,
  { pbaId: number; payload: SICOptionPayload },
  { rejectValue: Error }
>('testedParty/sic-industry-option/patch', async ({ pbaId, payload }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: Distributor }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `pbas/${pbaId}/characterization/option-type`,
        data: payload
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const toggleValueAddedDistributor = createAsyncThunk<
  Distributor,
  { pbaId: number; payload: ValueAddedDistributorPayload },
  { rejectValue: Error }
>('testedParty/toggle-value-added-distributor/patch', async ({ pbaId, payload }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: Distributor }>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `pbas/${pbaId}/characterization/functions-performed`,
        data: payload
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const createValueAddedDistributor = createAsyncThunk<
  Distributor,
  { pbaId: number; payload: ValueAddedDistributorPayload },
  { rejectValue: Error }
>('testedParty/toggle-value-added-distributor/add', async ({ pbaId, payload }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: Distributor }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `pbas/${pbaId}/characterization/functions-performed`,
        data: payload
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const testedPartySlice = createSlice({
  name: 'testedParty',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchMajorClassifications.fulfilled,
        (state: TesetedPartyState, action: PayloadAction<MajorClassification[]>) => {
          state.majorClassifications = action.payload;
        }
      )
      .addCase(
        fetchCurrentMajorClassifications.fulfilled,
        (state: TesetedPartyState, action: PayloadAction<MajorClassification[]>) => {
          state.currentMajorClassification = action.payload;
        }
      )
      .addCase(fetchCurrentDistributor.fulfilled, (state: TesetedPartyState, action: PayloadAction<Distributor>) => {
        state.currentDistributor = action.payload;
      })
      .addCase(fetchSICTypeOptions.fulfilled, (state: TesetedPartyState, action: PayloadAction<sicType[]>) => {
        state.sicTypes = action.payload;
      });
  }
});

export default testedPartySlice.reducer;
