import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { JurisdictionStatusLabel } from '../../../components';
import { JurisdictionStatus } from '../../../models';

interface AnalysisSummaryProps {
  id: number;
  name: string;
  methodName: string;
  details: string;
  jurisdictionStatuses?: JurisdictionStatus[];
  studyId?: number;
  transactionId: number;
  analysisType: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1)
  },
  country: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(0.5),
    paddingRight: theme.spacing(1)
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    gap: theme.spacing(1)
  }
}));

export const AnalysisSummary = ({
  id,
  name,
  methodName,
  details,
  jurisdictionStatuses,
  studyId,
  transactionId,
  analysisType
}: AnalysisSummaryProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const onLocationHandle = useCallback(() => {
    if (studyId) {
      if (analysisType === 'pba') {
        history.push(
          `/analysis/${studyId}/pba-dashboard/${id}/tested-party-details?referredTransaction=${transactionId}`
        );
      } else {
        window.location.replace(`${window.location.origin}/legacy/local-files/study/${studyId}/tba/${id}`);
      }
    }
  }, [history, analysisType, studyId, id, transactionId]);

  return (
    <Box className={classes.root} role="listitem">
      <Box flexGrow={1}>
        <Typography variant="subtitle1">{t('label-name-and-code', { name, isoCode: methodName })}</Typography>
        <Typography variant="subtitle2">{details}</Typography>
      </Box>
      <Box className={classes.progressContainer}>
        {jurisdictionStatuses && jurisdictionStatuses.length > 0
          ? jurisdictionStatuses.map((jurisdictionStatus) => {
              return (
                <JurisdictionStatusLabel
                  key={jurisdictionStatus.country.countryId}
                  jurisdictionIso={jurisdictionStatus.country.isoCode}
                  jurisdictionStatusId={jurisdictionStatus.status}
                />
              );
            })
          : null}
      </Box>
      {studyId && (
        <IconButton onClick={onLocationHandle}>
          <Launch />
        </IconButton>
      )}
    </Box>
  );
};
