import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Typography, Divider, makeStyles, Theme } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import RerunCompSearchDistributor from './RerunCompSearchDistributor';
import {
  RerunCompSearchModalProps,
  RerunCompsearchModalInputs,
  RerunCompsearchLocation
} from './RerunCompSearchModal.proptype';
import RerunCompSearchServiceProvider from './RerunCompSearchServiceProvider';
import { SearchBox } from '../../components';
import tokens from '../../styles/designTokens';
import { title2, body2, body4, captionOverline, buttonText2 } from '../../styles/typography';
import { MajorClassification } from '../TestedPartyServiceProvider/TestedPartyServiceProvider.proptype';
import { TPModal } from '../TPModal';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  modalTitle: {
    color: tokens.core3,
    ...title2
  },
  modalJurisdictionName: {
    color: tokens.core2,
    marginLeft: '0.625rem',
    ...title2
  },
  headerIcon: {
    background: tokens.product15,
    color: tokens.themeAp100,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  topHalfBodyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1.563rem'
  },
  topHalfLeftContainer: {
    display: 'flex'
  },
  topHalfRightContainer: {
    display: 'flex'
  },
  contentWrapper: {
    backgroundColor: tokens.neutral70,
    height: '43.75rem'
  },
  initialResultsLabel: {
    marginBottom: '0.938rem',
    color: tokens.core2,
    ...body2
  },
  initialResultsDisplay: {
    backgroundColor: tokens.product25,
    color: tokens.product100,
    textTransform: 'uppercase',
    ...captionOverline,
    borderRadius: '0.25rem',
    textAlign: 'center',
    padding: '0.625rem'
  },
  chooseDateLabel: {
    ...body4,
    marginBottom: '0.938rem',
    color: tokens.core3
  },
  chooseDatePicker: {
    backgroundColor: tokens.white,
    margin: 'auto'
  },
  countrySearchInput: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white
    },
    '& > .MuiTextField-root .MuiAutocomplete-inputRoot': {
      color: theme.palette.common.white,
      background: '#6E56DB',
      '& > .MuiAutocomplete-endAdornment .MuiIconButton-root': {
        color: theme.palette.common.white
      }
    }
  },
  countrySearchContainer: {
    paddingRight: '0.625rem'
  },
  countrySearchLabel: {
    ...body4,
    marginBottom: '0.938rem',
    color: tokens.core3
  },
  runWithFionaButton: {
    ...buttonText2,
    border: `thin solid ${tokens.product100}`,
    color: tokens.product100,
    marginRight: '65%'
  },
  runWithOverridesButton: {
    ...buttonText2,
    backgroundColor: tokens.product100,
    color: tokens.white
  },
  cancelButton: {
    ...buttonText2,
    color: tokens.product100
  },
  validationErrors: {}
}));

export const RerunCompSearchModal = ({
  onClose,
  onSubmit,
  recalculateProjectedCounts,
  selectedModalJurisdictionInfo,
  displayedSicIndustries,
  compPoolCountToDisplay
}: RerunCompSearchModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const locations: RerunCompsearchLocation[] = selectedModalJurisdictionInfo?.pbaJurisdictionInfo?.locations || [];
  const selectedLocation = locations.find(
    (location) => location.locationId === selectedModalJurisdictionInfo?.pbaJurisdictionInfo?.locationId
  );
  const isServiceProvider = PrimaryFunctions.ByType.ServiceProvider.includes(
    selectedModalJurisdictionInfo?.primaryFunctionId
  );
  const isDistributor = PrimaryFunctions.ByType.Distributor.includes(selectedModalJurisdictionInfo?.primaryFunctionId);
  const minSicCode = isDistributor
    ? displayedSicIndustries.find(
        (sic) => sic.value === selectedModalJurisdictionInfo?.pbaJurisdictionInfo?.minIndustrialCodeRange
      )
    : null;
  const maxSicCode = isDistributor
    ? displayedSicIndustries.find(
        (sic) => sic.value === selectedModalJurisdictionInfo?.pbaJurisdictionInfo?.maxIndustrialCodeRange
      )
    : null;
  const majorClassifications =
    selectedModalJurisdictionInfo?.pbaJurisdictionInfo?.majorClassifications?.map(
      (majorClassification: MajorClassification) => majorClassification.name
    ) || [];
  const runOnDate = selectedModalJurisdictionInfo?.pbaJurisdictionInfo?.runOnDate;
  const defaultValues = {
    dataDate: runOnDate ? new Date(runOnDate) : null,
    location: selectedLocation ?? null,
    minSicCode: minSicCode ?? null,
    maxSicCode: maxSicCode ?? null,
    majorClassification1: majorClassifications[0] ?? null,
    majorClassification2: majorClassifications[1] ?? null,
    majorClassification3: majorClassifications[2] ?? null
  };
  const formMethods = useForm<RerunCompsearchModalInputs>({
    defaultValues: defaultValues as RerunCompsearchModalInputs
  });
  const { handleSubmit, setValue, control, errors } = formMethods;
  const setLocation = (location: RerunCompsearchLocation | null) => {
    if (location) {
      setValue('location', location);
    }
  };

  const ErrorMessage = ({ error, message, className }: { error: boolean; message: string; className: string }) =>
    error ? (
      <Typography className={className} color="error" variant="caption">
        {message}
      </Typography>
    ) : null;

  const handleRecalculateProjectCounts = handleSubmit((data) => {
    if (selectedModalJurisdictionInfo?.jurisdictionId) {
      recalculateProjectedCounts(
        data,
        selectedModalJurisdictionInfo?.jurisdictionId,
        selectedModalJurisdictionInfo?.compSearchId
      );
    }
  });

  return (
    <TPModal
      isOpen
      containerStyle={classes.contentWrapper}
      title={
        <span className={classes.modalTitleWrapper}>
          <Autorenew className={classes.headerIcon} />
          <Typography className={classes.modalTitle}>{t('analysis:title-modal-rerun-comp-search')}</Typography>
          <Typography className={classes.modalJurisdictionName}>
            {selectedModalJurisdictionInfo?.pbaJurisdictionInfo
              ? selectedModalJurisdictionInfo.pbaJurisdictionInfo.jurisdictionName
              : ''}
          </Typography>
        </span>
      }
      actions={[
        {
          name: t('analysis:button.run-with-fiona'),
          id: 'button-rerun-comp-search-modal-run-with-fiona',
          className: classes.runWithFionaButton,
          handler: handleSubmit((data) => {
            if (selectedModalJurisdictionInfo?.jurisdictionId) {
              onSubmit(data, selectedModalJurisdictionInfo?.jurisdictionId, false);
              onClose();
            }
          })
        },
        {
          name: t('action-cancel'),
          id: 'button-rerun-comp-search-modal-cancel',
          className: classes.cancelButton,
          handler: () => {
            onClose();
          }
        },
        {
          name: t('analysis:button.run-with-overrides'),
          id: 'button-rerun-comp-search-modal-run-with-overrides',
          className: classes.runWithOverridesButton,
          handler: handleSubmit((data) => {
            if (selectedModalJurisdictionInfo?.jurisdictionId) {
              onSubmit(data, selectedModalJurisdictionInfo?.jurisdictionId, true);
              onClose();
            }
          })
        }
      ]}
      onClose={onClose}
    >
      <form>
        <Box className={classes.topHalfBodyContainer}>
          <Box className={classes.topHalfLeftContainer}>
            <Box className={classes.countrySearchContainer}>
              <Typography className={classes.countrySearchLabel}>{t('analysis:title-select-country')}</Typography>
              <Box width="20.313rem">
                <Controller
                  name="location"
                  render={({ onChange, value }) => (
                    <SearchBox
                      className={classes.countrySearchInput}
                      disableClearable={false}
                      placeholder={t('analysis:placeholder.search_country_or_region')}
                      noOptionsText={t('analysis:country-or-region-label-no-match')}
                      error={Boolean(errors.location)}
                      value={value}
                      options={locations}
                      getOptionLabel={(option: RerunCompsearchLocation) => option.name}
                      getOptionSelected={(option: RerunCompsearchLocation, value: RerunCompsearchLocation) =>
                        option.locationId === value.locationId
                      }
                      renderOption={(option) => <Typography>{option.name}</Typography>}
                      onChange={(_, data) => {
                        onChange(data);
                        setLocation(data);
                        void handleRecalculateProjectCounts();
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.location}
                  rules={{ required: true }}
                  error={Boolean(errors.location)}
                />
              </Box>
              <ErrorMessage
                error={Boolean((errors as any).location)}
                message={t('errors:error_message.country_or_region_is_required')}
                className={classes.validationErrors}
              />
            </Box>
            <Box>
              <Typography className={classes.chooseDateLabel}>{t('analysis:title-choose-data-date')}</Typography>
              <Controller
                name="dataDate"
                render={({ onChange, value }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      size="small"
                      variant="inline"
                      inputVariant="outlined"
                      className={classes.chooseDatePicker}
                      margin="normal"
                      id="rerun-comp-search-modal-date-picker-dialog"
                      format="dd/MM/yyyy"
                      value={value || (defaultValues.dataDate ?? null)}
                      onChange={(date) => {
                        onChange(date);
                        void handleRecalculateProjectCounts();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues.dataDate ?? null}
                error={Boolean(errors.dataDate)}
              />
            </Box>
          </Box>
          <Box className={classes.topHalfRightContainer}>
            <Box>
              <Typography className={classes.initialResultsLabel}>{t('analysis:title-initial-results')}</Typography>
              <Box component="span" className={classes.initialResultsDisplay}>
                {compPoolCountToDisplay === null || compPoolCountToDisplay === undefined
                  ? t('title-updating')
                  : `${compPoolCountToDisplay} ${t('analysis:jurisdiction-comp-search-info-fionas-comparables')}`}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider variant="fullWidth" />
        {isServiceProvider && (
          <RerunCompSearchServiceProvider
            formMethods={formMethods}
            defaultValues={defaultValues}
            selectedModalJurisdictionInfo={selectedModalJurisdictionInfo}
            handleRecalculateProjectCounts={handleRecalculateProjectCounts}
          />
        )}
        {isDistributor && (
          <RerunCompSearchDistributor
            displayedSicIndustries={displayedSicIndustries}
            formMethods={formMethods}
            defaultValues={defaultValues}
            handleRecalculateProjectCounts={handleRecalculateProjectCounts}
          />
        )}
      </form>
    </TPModal>
  );
};
