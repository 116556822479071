import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { PrimaryFunctions, CompSearchExecution, CompSearchState } from '@xbs/xbs-enums';
import { AnalysisSideMenuProps, csRunningStatus } from './AnalysisSideMenu.proptype';
import { Distributor, CharacterizationTypeEnum } from '../../app/TestedPartyDistributor';
import { MajorClassification } from '../../app/TestedPartyServiceProvider/TestedPartyServiceProvider.proptype';
import { csRunningStates } from '../../components/CompSearchAnimation/CompSearchAnimation.proptype';
import documentationReviewAccess from '../../components/HigherOrderComponents/DocumentationReviewAccess';
import {
  AssociatedTransactionsPBA,
  Country,
  PbaJurisdiction,
  CompSearchExecutionContext,
  CompSearchPayload,
  ProjectCompPoolPayload
} from '../../models';
import {
  fetchCompSearchStatus,
  fetchJurisdictionDescription,
  fetchPbaJurisdictions,
  fetchPBAPli,
  runCompSearch,
  doneCSRunning,
  removeCurrentPbaJurisdictions,
  projectCompPoolCount,
  clearPbaData
} from '../../redux/profitBasedAnalyses';
import { fetchCurrentDistributor, fetchSICTypeOptions } from '../../redux/testedParty';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectAllMajorClassifications,
  selectCurrentTestedParty,
  selectTransactionByPbaId,
  selectPBAPli,
  selectCurrentMajorClassification,
  selectCurrentValueAddedDistributor,
  selectPbaJurisdictionCountry,
  selectWorkingContainer,
  selectcsStatus,
  selectPbaJurisdictions,
  selectJurisdictionDescription,
  selectCurrentPBA,
  selectSicTypes
} from '../../selectors';
import { AppDispatch } from '../../store';
import { SearchChecklistIcon, SearchChecklistDarkIcon } from '../../svgs';
import { hasEditAccess } from '../../utils';
import { PliValues } from '../ProfitLevelIndicator/ProfitLevelIndicator.proptype';
import { RerunCompSearchModal, RerunCompsearchModalInputs } from '../RerunCompSearchModal';
import { sicType } from '../TestedPartyDistributor/TestedPartyDistributor.proptype';

const Connector = ({ component: Component }: { component: ComponentType<AnalysisSideMenuProps> }) => {
  const [currentCSStatus, setCurrentCSStatus] = useState<csRunningStatus>('Not Running');
  const workingContainer = useSelector(selectWorkingContainer);
  const pbaJurisdictionDetails = useSelector(selectPbaJurisdictions);
  const csRunningStatus = useSelector(selectcsStatus);
  const jurisdictionDescription = useSelector(
    selectJurisdictionDescription(pbaJurisdictionDetails?.[0] ? pbaJurisdictionDetails[0].jurisdictionId : null)
  );

  const match: any = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const studyId: any = match.params.studyId ?? null;
  const pbaId: any = match.params.pbaId ?? null;
  const jurisdictionId: number = jurisdictionDescription?.jurisdictionId;
  const pba = useSelector(selectCurrentPBA);

  const [isRerunCompSearchModalOpen, setIsRerunCompSearchModalOpen] = useState(false);
  const [selectedModalJurisdictionInfo, setSelectedModalJurisdictioInfo] = useState<PbaJurisdiction | undefined>();
  const [compPoolCountToDisplay, setCompPoolCountToDisplay] = useState<number | null>();

  const navigateToAnalysis = () => {
    // TODO: This fetchPbaJurisdictions is a temporary fix so that the pba info is refetched once the compsearch is finished running.
    // This should be removed in a refactor story.
    void dispatch(fetchPbaJurisdictions(pbaId));
    const path = `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/jurisdiction/${String(
      jurisdictionId
    )}/jurisdiction-analysis-info`;
    history.push(path);
  };

  useEffect(() => {
    void dispatch(fetchCurrentDistributor(pbaId));
    void dispatch(fetchSICTypeOptions(CharacterizationTypeEnum.TypeOfOption));
  }, [dispatch, pbaId]);

  useEffect(() => {
    return () => {
      void dispatch(clearPbaData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (pbaJurisdictionDetails && pbaJurisdictionDetails.length > 0) {
      for (const pbaJurisdictionDetail of pbaJurisdictionDetails) {
        void dispatch(
          fetchJurisdictionDescription({
            compSearchId: pbaJurisdictionDetail.compSearchId,
            jurisdictionId: pbaJurisdictionDetail.jurisdictionId
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(pbaJurisdictionDetails)]);

  useEffect(() => {
    if (csRunningStatus === CompSearchState.ByName.Successful.Id) {
      if (pbaJurisdictionDetails && pbaJurisdictionDetails.length > 0) {
        const testedParty = pbaJurisdictionDetails[0];

        if (!testedParty.pbaJurisdictionInfo) {
          void dispatch(
            fetchJurisdictionDescription({
              compSearchId: testedParty.compSearchId,
              jurisdictionId: testedParty.jurisdictionId
            })
          );
        }
      } else {
        void dispatch(fetchPbaJurisdictions(pbaId));
      }

      if (jurisdictionDescription) {
        setCurrentCSStatus(csRunningStates.completed);
        void dispatch(doneCSRunning());
      }
    }

    const failedCsStates = [
      CompSearchState.ByName.Failed.Id,
      CompSearchState.ByName.OutOfSync.Id,
      CompSearchState.ByName.TimedOut.Id
    ];

    if (failedCsStates.includes(csRunningStatus)) {
      setCurrentCSStatus(csRunningStates.failed);
      void dispatch(doneCSRunning());
    }
  }, [csRunningStatus, dispatch, pbaId, pbaJurisdictionDetails, jurisdictionDescription]);

  useEffect(() => {
    let checkingStatus: NodeJS.Timeout;

    if (csRunningStatus === CompSearchState.ByName.Running.Id) {
      checkingStatus = setInterval(() => {
        void dispatch(fetchCompSearchStatus(pbaId));
      }, 2000);
    }

    return () => {
      clearInterval(checkingStatus);
    };
  }, [dispatch, csRunningStatus, pbaId]);

  useEffect(() => {
    void dispatch(fetchTransactions());
    void dispatch(fetchPBAPli(pbaId));
    void dispatch(fetchPbaJurisdictions(pbaId));
    void dispatch(fetchCurrentDistributor(pbaId));
  }, [dispatch, pbaId]);

  const testedParty = useSelector(selectCurrentTestedParty);
  const transactionsPBA: AssociatedTransactionsPBA[] | null = useSelector(selectTransactionByPbaId(Number(pbaId)));
  const pbaPli = useSelector(selectPBAPli);
  const allMajorClassifications: MajorClassification[] = useSelector(selectAllMajorClassifications);
  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);
  const currentValueAddedDistributor: Distributor = useSelector(selectCurrentValueAddedDistributor);
  const sicTypes: sicType[] = useSelector(selectSicTypes);

  const pbaJurisdictions: Country[] = useSelector(selectPbaJurisdictionCountry);

  // only display 5000 - 5199
  const displayedSicIndustries = sicTypes.length > 0 ? sicTypes[1].codeTypes[0].subtypes : [];

  const isDetailsComplete = Boolean(
    testedParty?.name &&
      testedParty?.justification &&
      testedParty?.segmentType &&
      (testedParty?.primaryFunction.name || testedParty?.otherPrimaryFunction) &&
      transactionsPBA &&
      transactionsPBA.length > 0 &&
      pbaPli?.pliValues.some((val: PliValues) => val.pliValue)
  );
  const isCharacterizationComplete = testedParty ? assessCharacterization() : false;

  const searchButtonStatus = isDetailsComplete && isCharacterizationComplete;

  const searchList = pbaJurisdictions.map((jurisdiction) => {
    const jurisdictionStatus = pba?.jurisdictionStatuses?.find(
      (status) => status.jurisdictionId === jurisdiction?.countryId
    );
    return {
      path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/jurisdiction/${
        jurisdiction?.countryId
      }/jurisdiction-analysis-info`,
      text: jurisdiction?.name,
      disabled: !hasEditAccess(),
      showProgressIcon: true,
      isComplete: true,
      status: jurisdictionStatus?.status,
      listIcon: documentationReviewAccess(<Autorenew style={{ color: theme.palette.secondary.dark }} />),
      onListIconClick: () => {
        setIsRerunCompSearchModalOpen(true);
        const selectedJurisdictionInfo = pbaJurisdictionDetails?.find(
          (detail) => detail.jurisdictionId === jurisdiction?.countryId
        );
        setSelectedModalJurisdictioInfo(selectedJurisdictionInfo);
        setCompPoolCountToDisplay(selectedJurisdictionInfo?.pbaJurisdictionInfo?.numCompsInPool);
      }
    };
  });

  function assessCharacterization() {
    if (PrimaryFunctions.ByType.ServiceProvider.includes(testedParty?.primaryFunction?.primaryFunctionId)) {
      return currentMajorClassifications.some((major) => major.minorServiceClassifications.length);
    }

    return Boolean(currentValueAddedDistributor.codeType && currentValueAddedDistributor.industrialCodeValue);
  }

  const handleClose = () => {
    setIsRerunCompSearchModalOpen(false);
  };

  const buildExecutionContext = (jurisdictionId: number, overrides: RerunCompsearchModalInputs) => {
    if (allMajorClassifications && testedParty?.primaryFunction?.primaryFunctionId && jurisdictionId) {
      const majorClassificationIds = allMajorClassifications
        .filter((majorClassification) => {
          return (
            majorClassification.name === overrides.majorClassification1 ||
            majorClassification.name === overrides.majorClassification2 ||
            majorClassification.name === overrides.majorClassification3
          );
        })
        .map((majorClassification) => majorClassification.majorServiceClassificationId);
      const utcDate = new Date(overrides.dataDate);
      const runOnDate = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
      const executionContext: CompSearchExecutionContext = {
        jurisdictions: {
          [jurisdictionId]: {
            executionMode: CompSearchExecution.ByName.Override.Id,
            jurisdictionId,
            compPoolOverrides: {
              industrialCodeTypeId:
                PrimaryFunctions.ById[testedParty?.primaryFunction?.primaryFunctionId].industrialCodeTypeId,
              locationId: overrides.location.locationId,
              locationTypeId: overrides.location.locationTypeId,
              minIndustrialCodeRange: overrides?.minSicCode?.value || 0,
              maxIndustrialCodeRange: overrides?.maxSicCode?.value || 0,
              majorClassificationIds,
              runOnDate
            }
          }
        }
      };

      return executionContext;
    }
  };

  const handleSubmit = async (formData: RerunCompsearchModalInputs, jurisdictionId: number, isOverride: boolean) => {
    const compSearchPayload: CompSearchPayload = {
      container: workingContainer,
      pbaId,
      jurisdictionId,
      containerId: workingContainer?.containerId
    };

    if (isOverride) {
      const otherJurisdiction = pbaJurisdictionDetails?.find(
        (jurisdictionDetail) => jurisdictionDetail.jurisdictionId !== jurisdictionId
      );
      const executionContext = buildExecutionContext(jurisdictionId, formData);
      if (executionContext && otherJurisdiction?.jurisdictionId) {
        executionContext.jurisdictions[otherJurisdiction.jurisdictionId] = {
          executionMode: CompSearchExecution.ByName.Copy.Id,
          jurisdictionId: otherJurisdiction.jurisdictionId
        };
      }

      compSearchPayload.executionContext = executionContext;
    }

    setCurrentCSStatus(csRunningStates.running);
    void dispatch(runCompSearch({ pbaId, compSearchPayload }));
    void dispatch(removeCurrentPbaJurisdictions());
    handleClose();
  };

  const recalculateProjectedCounts = async (
    formData: RerunCompsearchModalInputs,
    jurisdictionId: number,
    compSearchId: number
  ) => {
    const projectedCompPoolPayload: ProjectCompPoolPayload = {
      container: workingContainer,
      jurisdictionId,
      executionContext: buildExecutionContext(jurisdictionId, formData)
    };
    setCompPoolCountToDisplay(null);
    const response = unwrapResult(
      await dispatch(projectCompPoolCount({ compSearchId, jurisdictionId, projectedCompPoolPayload }))
    );

    setCompPoolCountToDisplay(response?.numCompsInPool);
  };

  return (
    <>
      <Component
        currentCSStatus={currentCSStatus}
        selectedPath={pathname}
        setCurrentCSStatus={setCurrentCSStatus}
        pbaJurisdictionInfo={jurisdictionDescription}
        navigateToAnalysis={navigateToAnalysis}
        sections={[
          {
            title: t('analysis:title-section-tested-party-setup'),
            items: [
              {
                path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/tested-party-details`,
                text: t('analysis:title-section-item-tested-party-details'),
                disabled: false,
                showProgressIcon: true,
                isComplete: isDetailsComplete
              },
              {
                path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/tested-party-characterization`,
                text: t('analysis:title-section-item-tested-party-characterization'),
                disabled: false,
                showProgressIcon: true,
                isComplete: isCharacterizationComplete
              },
              {
                path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/functional-analysis`,
                text: t('analysis:title-section-item-tested-functional-analysis'),
                disabled: false,
                showProgressIcon: false,
                isComplete: false
              }
            ]
          },
          {
            title: t('analysis:title-section-comparable-search'),
            items: [
              {
                path: searchButtonStatus
                  ? '#'
                  : `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/search-checklist`,
                text: searchButtonStatus
                  ? t('analysis:title-section-comparable-run-search')
                  : t('analysis:title-section-comparable-search-checklist'),
                disabled: false,
                isButton: true,
                showProgressIcon: false,
                isComplete: searchButtonStatus,
                icon: searchButtonStatus ? <SearchChecklistDarkIcon /> : null,
                iconPath: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/search-checklist`,
                buttonIcon: searchButtonStatus ? null : <SearchChecklistIcon />
              }
            ]
          },
          {
            title: 'SEARCH RESULTS',
            items: pbaJurisdictions.length > 0 ? searchList : []
          }
        ]}
        onNavigate={(path) => {
          history.push(`${path}`);
        }}
        onRunSearch={() => {
          setCurrentCSStatus(csRunningStates.running);
          const compSearchPayload = {
            container: workingContainer,
            pbaId,
            containerId: workingContainer?.containerId
          };
          void dispatch(runCompSearch({ pbaId, compSearchPayload }));
          void dispatch(removeCurrentPbaJurisdictions());
        }}
      />
      {isRerunCompSearchModalOpen && (
        <RerunCompSearchModal
          compPoolCountToDisplay={compPoolCountToDisplay}
          displayedSicIndustries={displayedSicIndustries}
          selectedModalJurisdictionInfo={selectedModalJurisdictionInfo}
          recalculateProjectedCounts={recalculateProjectedCounts}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default Connector;
