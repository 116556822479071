import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConnectorProps,
  FunctionalAnalysisCharacteristic,
  InitialExpanedSections
} from './FunctionalAnalysisTable.proptype';
import {
  fetchFunctionalAnalysisCharacteristics,
  deleteFunctionalAnalysisCharacteristic,
  createFunctionalAnalysisCharacteristic
} from '../../redux/profitBasedAnalyses';
import { selectCurrentTestedParty, selectWorkingContainer } from '../../selectors';
import { AppDispatch } from '../../store';

const Connector = ({
  component: Component,
  functionalAnalysisCharacteristics,
  editFunctionalAnalysisCharacteristic,
  selectedAnalysis,
  setSelectedAnalysis,
  currentSection,
  setCurrentSection
}: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [expandedSections, setExpandedSections] = useState<string>(InitialExpanedSections);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const currentContainer = useSelector(selectWorkingContainer);

  const createNewCharacteristic = async (characteristicName: string, areaId: number) => {
    const createdCharacteristic = await dispatch(
      createFunctionalAnalysisCharacteristic({
        functionalAnalysisId: currentTestedParty!.testedPartyId,
        areaId,
        characteristic: { characteristicName, container: currentContainer }
      })
    );
    if (createdCharacteristic?.payload) {
      setSelectedAnalysis({
        characteristicName,
        discussion: '',
        otherParticipantContribution: false,
        pbaFunctionalAnalysisDataId: (createdCharacteristic.payload as FunctionalAnalysisCharacteristic)
          .pbaFunctionalAnalysisDataId,
        sortOrder: (createdCharacteristic.payload as FunctionalAnalysisCharacteristic).sortOrder,
        testedPartyContribution: false
      });
    }

    await dispatch(
      fetchFunctionalAnalysisCharacteristics({ functionalAnalysisId: currentTestedParty!.testedPartyId, areaId })
    );
  };

  const deleteFunctionalAnalysisCharacteristicRow = async (characteristicId: number, areaId: number) => {
    await dispatch(deleteFunctionalAnalysisCharacteristic(characteristicId));
    await dispatch(
      fetchFunctionalAnalysisCharacteristics({ functionalAnalysisId: currentTestedParty!.testedPartyId, areaId })
    );
    setSelectedAnalysis((undefined as unknown) as FunctionalAnalysisCharacteristic);
  };

  return (
    <Component
      functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
      editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
      deleteFunctionalAnalysisCharacteristic={deleteFunctionalAnalysisCharacteristicRow}
      createNewCharacteristic={createNewCharacteristic}
      selectedAnalysis={selectedAnalysis}
      setSelectedAnalysis={setSelectedAnalysis}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
      expandedSections={expandedSections}
      setExpandedSections={setExpandedSections}
    />
  );
};

export default Connector;
