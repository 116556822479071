import { useEffect, useState, FunctionComponent, ReactNode } from 'react';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import deepEqual from 'deep-equal';
import {
  EditorInitConfiguration,
  EditorInstance,
  EditorProps,
  EditorSetupActionIcon,
  EditorTheme,
  ThemeProp
} from './Editor.proptype';
import { TransferPricing, Icons } from './theme';

const listenerMap: Record<string, ReactNode> = {
  [EditorTheme.TransferPricing]: TransferPricing
};

const iconsMap: Record<string, EditorSetupActionIcon[]> = {
  [EditorTheme.TransferPricing]: Icons
};

const toolbar =
  'formatting sizeandcolor | bullist numlist | aligntext | table | spellchecker | spellcheckdialog | remainder | undo redo';

const defaults = {
  init: {
    width: '100%',
    height: '300',
    menubar: false,
    branding: false,
    paste_data_images: true, // eslint-disable-line camelcase
    plugins: ['link autolink image table paste lists link image charmap tinymcespellchecker'],
    toolbar: '',
    // eslint-disable-next-line camelcase
    toolbar_groups: {
      formatting: {
        icon: 'format-icon',
        tooltip: 'Formatting',
        items: 'bold italic underline strikethrough superscript subscript'
      },
      aligntext: {
        icon: 'align-left',
        tooltip: 'alignment',
        items: 'alignleft aligncenter alignright alignjustify'
      },
      tables: {
        icon: 'table',
        tooltip: 'Table',
        items: 'table'
      },
      remainder: {
        icon: 'more-drawer',
        tooltip: 'Additional Options',
        items: 'outdent indent image link charmap removeformat'
      }
    }
  }
};

const Editor = ({
  value,
  id,
  disabled,
  error,
  placeholder,
  theme,
  init,
  onEditorChange,
  onBlur,
  onSetupActions,
  height
}: EditorProps) => {
  const Theme = (listenerMap[theme] ?? TransferPricing) as FunctionComponent<ThemeProp>;
  const icons = iconsMap[theme] ?? [];

  defaults.init.toolbar = init?.maximize ? toolbar + ' maximize' : toolbar;

  const [configHasChanged, setRerenderEditor] = useState(false);
  const [internalInit, setInternalInit] = useState<EditorInitConfiguration | undefined>(init);

  const setup = (editor: EditorInstance) => {
    // Adding icons
    for (const item of [...icons, ...(onSetupActions?.icons ?? [])]) {
      editor.ui.registry.addIcon(item.name, item.icon);
    }

    editor.ui.registry.addMenuButton('sizeandcolor', {
      icon: 'change-case',
      fetch: (callback: (items: string) => void) => {
        const items = 'blockformats fontsizes forecolor backcolor';
        callback(items);
      }
    });

    // Adding toggle buttons
    if (onSetupActions?.toggleButtons) {
      for (const item of onSetupActions.toggleButtons) {
        editor.ui.registry.addToggleButton(item.name, {
          icon: item.icon,
          tooltip: item.tooltip,
          onAction: item.onAction
        });
      }
    }
  };

  // Unfortunately, the easiest way to make sure the editor will be updated and respect React lifecycle
  // is by forcing a remount in case of configuration changes.
  // Since this won't happen that often, it shouldn't be a problem in your app
  const forceEditorRerender = () => {
    setRerenderEditor(true);
    requestAnimationFrame(() => {
      setRerenderEditor(false);
    });
  };

  useEffect(() => {
    if (!deepEqual(init, internalInit)) {
      setInternalInit(init);
      forceEditorRerender();
    }
  }, [init, internalInit]);

  return configHasChanged ? null : (
    <Theme error={error}>
      <TinyMCEEditor
        id={id}
        init={{
          ...defaults.init,
          height,
          ...internalInit,
          id,
          placeholder,
          setup
        }}
        disabled={disabled}
        value={value}
        onEditorChange={onEditorChange}
        onBlur={onBlur}
      />
    </Theme>
  );
};

export default Editor;
