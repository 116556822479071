import { PliDisplayFormats } from '@xbs/xbs-enums';
import { roundValue } from './roundValue';
import { PliAverages, PliValues } from '../app/ProfitLevelIndicator/ProfitLevelIndicator.proptype';

export const roundPliValues = (pliValues: any) => {
  (pliValues ?? []).forEach((pliValue: PliValues | PliAverages) => {
    const isDecimal = PliDisplayFormats.ByName[pliValue.pliValueType.name].Value.displayFormat;
    const { roundingValue } = PliDisplayFormats.ByName[pliValue.pliValueType.name].Value;
    const roundedValue = isDecimal
      ? roundValue(Number(`${pliValue.value}`), -roundingValue)
      : roundValue(Number(`${pliValue.value}e2`), -roundingValue);
    const cuttedValue = isDecimal
      ? Number.isNaN(roundedValue)
        ? null
        : `${roundedValue.toFixed(roundingValue)}`
      : Number.isNaN(roundedValue)
      ? null
      : `${roundedValue.toFixed(roundingValue)}%`;
    pliValue.roundedValue = cuttedValue;
  });

  return pliValues;
};
