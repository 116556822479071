import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../redux';
import { PrimaryEntityItem, TradingPartnerItem, TransactionItem } from '../redux/localReports/localReports.proptype';

export const selectLocalReportsData = (state: RootState) => state.localReports;

export const selectLanguageList = (state: RootState) => state.localReports.languageList;

export const selectTemplateList = (state: RootState) => state.localReports.templateList;

export const selectSetupData = (state: RootState) => state.localReports.setup;

export const selectSummariesData = (state: RootState) => state.localReports.summaries;

export const selectPrimaryTradingPartnersMap = (state: RootState) => state.localReports.primaryTradingPartnersMap;

export const selectPrimaryEntitiesList = createSelector(selectPrimaryTradingPartnersMap, (primaryTradingPartnersMap) =>
  primaryTradingPartnersMap ? Object.values(primaryTradingPartnersMap) : []
);

export const selectSelectedPrimaryEntities = createSelector(
  selectPrimaryEntitiesList,
  (primaryEntities: PrimaryEntityItem[]): PrimaryEntityItem[] => primaryEntities.filter((entity) => entity.selected)
);

export const selectTradingPartnersList = createSelector(
  selectSelectedPrimaryEntities,
  (primaryEntities: PrimaryEntityItem[]): TradingPartnerItem[] => {
    const tradingPartners: TradingPartnerItem[] = [];

    for (const primaryEntity of primaryEntities) {
      tradingPartners.push(...Object.values(primaryEntity.tradingPartners));
    }

    return tradingPartners;
  }
);

export const selectSelectedTradingPartners = createSelector(
  selectTradingPartnersList,
  (tradingPartners: TradingPartnerItem[]): TradingPartnerItem[] => tradingPartners.filter((entity) => entity.selected)
);

export const selectLocalFileReports = (state: RootState) => state.localReports.localFileReports;

export const selectWorkingLocalFileReports = (state: RootState) => state.localReports.workingLocalFiles;

export const selectFinalLocalFileReports = (state: RootState) => state.localReports.finalLocalFiles;

export const selectTransactionsListByTradingPartners = createSelector(
  selectSelectedTradingPartners,
  (tradingPartners: TradingPartnerItem[]): TransactionItem[] => {
    const transactions: TransactionItem[] = [];

    tradingPartners.forEach((trading) => {
      for (const transaction of trading.transactions) {
        transactions.push(transaction);
      }
    });

    return transactions;
  }
);

export const selectSelectedTransactions = createSelector(
  selectTransactionsListByTradingPartners,
  (transactions: TransactionItem[]): TransactionItem[] => transactions.filter((trans) => trans.selected)
);

export const selectActiveReport = (state: RootState) => state.localReports.activeReport;

export const selectReportVersions = (state: RootState) => state.localReports.reportVersions;
