import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, makeStyles, Button, Drawer, Tabs, Tab, IconButton } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SearchFunctionalAnalysisWizard } from './SearchFunctionalAnalysisWizard';
import { StandardizedFunctionalAnalysisWizard } from './StandardizedFunctionalAnalysisWizard';
import { fetchFunctionalAnalysisCharacteristics } from '../../../redux/profitBasedAnalyses';
import { selectCurrentTestedParty } from '../../../selectors';
import tokens from '../../../styles/designTokens';
import { title2, title3, body3, body4, buttonText2 } from '../../../styles/typography';
import { PanelClose as CloseIcon } from '../../../svgs';
import {
  AreaIds,
  FunctionalAnalysisCharacteristic
} from '../../FunctionalAnalysisTable/FunctionalAnalysisTable.proptype';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '1rem',
    padding: '2rem 1rem',
    marginLeft: '2rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  smartTextButton: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    marginRight: '2.313rem',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    ...buttonText2,
    boxSizing: 'border-box',
    height: '2.25rem',
    width: '9.375rem',
    borderRadius: '0.25rem'
  },
  smartTextDrawer: {
    width: '30vw',
    height: '100%',
    padding: '0 1rem',
    backgroundColor: theme.palette.secondary.light,

    '& div': {
      margin: '0.5rem 0'
    }
  },
  spacedHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  paperContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    maxHeight: '4rem',
    '& .Mui-selected': {
      color: theme.palette.primary.dark
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  drawerLabel: {
    ...body4,
    color: tokens.product100,
    margin: 'auto 0'
  },
  drawerLabelIcon: {
    background: tokens.product100,
    color: tokens.white,
    width: '2rem',
    height: '2rem',
    marginRight: '0.25rem',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawerHeader: {
    ...title2,
    color: tokens.core3
  }
}));

interface FunctionalAnalysisHeaderProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId?: number
  ) => Promise<void>;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  currentSection: number;
}

export const FunctionalAnalysisHeader = ({
  functionalAnalysisCharacteristics,
  editFunctionalAnalysisCharacteristic,
  selectedAnalysis,
  currentSection
}: FunctionalAnalysisHeaderProps) => {
  const classes = useStyles();
  const flags = useFlags();
  const [tab, setTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const dispatch = useDispatch();
  const tabs = [
    { key: 0, label: 'standardized' },
    { key: 1, label: 'search' }
  ];

  const fetchNewSections = () => {
    Object.values(AreaIds).forEach((id) => {
      if (currentTestedParty && Number.isInteger(id)) {
        dispatch(
          fetchFunctionalAnalysisCharacteristics({
            functionalAnalysisId: currentTestedParty.testedPartyId,
            areaId: Number(id)
          })
        );
      }
    });
  };

  const handleBulkApply = async () => {
    if (functionalAnalysisCharacteristics) {
      const editPromises: Array<Promise<void>> = [];
      [
        ...functionalAnalysisCharacteristics.Assets,
        ...functionalAnalysisCharacteristics.Functions,
        ...functionalAnalysisCharacteristics.Risks
      ].forEach((characteristic: any) => {
        if (characteristic.functionalCharacteristicType) {
          editPromises.push(
            editFunctionalAnalysisCharacteristic({
              discussion: characteristic.functionalCharacteristicType.discussion,
              discussionOnly: true,
              pbaFunctionalAnalysisDataId: characteristic.pbaFunctionalAnalysisDataId
            })
          );
        }
      });
      await Promise.all(editPromises); // edit all characteristics before fetching the sections
      fetchNewSections();
    }

    setIsDrawerOpen(false);
  };

  const selectedContent = [
    <StandardizedFunctionalAnalysisWizard
      key={0}
      selectedAnalysis={selectedAnalysis}
      onApply={() => {
        if (selectedAnalysis?.functionalCharacteristicType) {
          void editFunctionalAnalysisCharacteristic(
            {
              discussion: selectedAnalysis.functionalCharacteristicType.discussion,
              discussionOnly: true,
              pbaFunctionalAnalysisDataId: selectedAnalysis.pbaFunctionalAnalysisDataId
            },
            currentSection
          );
        }

        setIsDrawerOpen(false);
      }}
      onBulkApply={() => {
        void handleBulkApply();
      }}
    />,
    <SearchFunctionalAnalysisWizard key={1} />
  ];

  return (
    <Box className={classes.header}>
      <Box>
        <Typography style={{ ...title3 }}>{t(`analysis:functional-analysis`)}</Typography>
        <Typography style={{ ...body3, color: tokens.core2 }}>
          {t(`analysis:functional-analysis-description`)}
        </Typography>
      </Box>
      <Box>
        {flags.disableSmartTextDrawerButton && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.smartTextButton}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            {t(`analysis:smart-text`)}
          </Button>
        )}
      </Box>
      <Drawer
        open={isDrawerOpen}
        anchor="right"
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      >
        <div className={classes.smartTextDrawer}>
          <div className={classes.spacedHeader}>
            <span className={classes.drawerLabel}>
              <span className={classes.drawerLabelIcon}>T</span>
              {t(`analysis:smart-text`)}
            </span>
            <IconButton
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.drawerHeader}>{t(`analysis:functional-analysis-narrative`)}</div>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={(event, value) => {
              setTab(value);
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.key} label={t(`analysis:standardized_functional-analysis.${tab.label}`)} />
            ))}
          </Tabs>
          <Box className={classes.paperContainer}>{selectedContent[tab]}</Box>
        </div>
      </Drawer>
    </Box>
  );
};
