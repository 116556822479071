import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import tokens from '../../../styles/designTokens';
import { captionOverline, title2Standard, title3 } from '../../../styles/typography';
import { Table } from '../../Table';
import { TestedPartyDistributor } from '../../TestedPartyDistributor';
import { TestedPartyServiceProvider } from '../../TestedPartyServiceProvider';
import { TestedPartyCharacterizationProps } from '../TestedPartyCharacterization.proptype';

const useStyles = makeStyles((theme) => ({
  title3,
  root: {
    padding: '1.5rem',
    background: tokens.neutral70,
    overflow: 'auto',
    height: '100%'
  },
  bandAid: {
    height: '1.5rem',
    width: '102%',
    position: 'absolute',
    top: '-1.5rem',
    left: '-1%',
    zIndex: 3,
    background: tokens.neutral70
  },
  paper: {
    borderRadius: '1.25rem',
    boxShadow: '0 0 4px 4px rgba(226,227,230,0.4)'
  },
  testedPartyTitle: {
    ...title2Standard,
    color: theme.palette.secondary.dark
  },
  majors: {
    ...captionOverline,
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    height: '1.875rem',
    borderRadius: '0.375rem',
    marginLeft: '1rem',
    padding: '0 0.625rem',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'inline-flex'
  }
}));

export const TestedPartyCharacterization = ({
  currentTestedParty,
  numOfSelectedMajor
}: TestedPartyCharacterizationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const classifications = PrimaryFunctions.ByType.ServiceProvider.includes(
    currentTestedParty?.primaryFunction?.primaryFunctionId
  ) ? (
    <TestedPartyServiceProvider />
  ) : (
    <TestedPartyDistributor />
  );

  return (
    <Box className={classes.root}>
      <Table
        className={classes.paper}
        data={[{ classifications }]}
        columns={[
          {
            key: 'classifications',
            header: (
              <>
                <div className={classes.bandAid}>&nbsp;</div>
                <Box className={classes.title}>
                  <Typography className={classes.title3}>
                    {t('analysis:title-section-item-tested-party-characterization')}
                  </Typography>
                  {PrimaryFunctions.ByType.ServiceProvider.includes(
                    currentTestedParty?.primaryFunction?.primaryFunctionId
                  ) && (
                    <Typography className={classes.majors}>
                      {numOfSelectedMajor > 1
                        ? `${numOfSelectedMajor} ${t('analysis:title-majors')}`
                        : `${numOfSelectedMajor} ${t('analysis:title-major')}`}
                    </Typography>
                  )}
                </Box>
                <Typography className={classes.testedPartyTitle}>
                  {currentTestedParty?.otherPrimaryFunction ?? currentTestedParty?.primaryFunction?.name}
                </Typography>
              </>
            ),
            align: 'left'
          }
        ]}
      />
    </Box>
  );
};
