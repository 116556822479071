import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { CenteredProgress } from '../../components';
import { Entity, FinalLocalReport, InternalLocalFileReport } from '../../models';
import { DownloadIcon } from '../../svgs';
import { DashboardCard } from '../DashboardCard';

export interface EntityReportsCardProps {
  entity: Entity | null;
  internalReports?: InternalLocalFileReport[];
  finalReport?: FinalLocalReport | null;
  onDownload: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  topNote: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  content: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    gap: theme.spacing(2)
  },
  downloadButton: {
    height: 'auto',
    '& svg path': {
      fill: theme.palette.primary.contrastText
    }
  },
  highlighted: {
    color: theme.palette.text.primary
  }
}));

export const EntityReportsCard = ({ entity, internalReports, finalReport, onDownload }: EntityReportsCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  return (
    <DashboardCard
      title={t('reports:label-count', { count: internalReports?.length })}
      customAction={
        <>
          {t('reports:label-go-to')}
          <IconButton>
            <Launch />
          </IconButton>
        </>
      }
    >
      {entity && internalReports && finalReport !== undefined ? (
        finalReport ? (
          <>
            <Box className={classes.topNote}>{t('reports:label-latest-published')}</Box>
            <Box className={classes.content}>
              <Button
                className={classes.downloadButton}
                variant="contained"
                color="primary"
                disabled={downloading}
                onClick={handleDownload}
              >
                <DownloadIcon />
              </Button>
              <Box flexGrow={1}>
                <Typography className={classes.highlighted}>{finalReport.name}</Typography>
                <Typography>{entity.code}</Typography>
                <Typography>
                  {t('entities:label-count', {
                    count: finalReport?.internalLocalfileReportInstance?.primaryEntities?.length
                  })}{' '}
                  {t('reports:label-trading-partner-count', {
                    count: finalReport.internalLocalfileReportInstance?.tradingPartners?.length
                  })}
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Typography>{t('reports:message-none-for-entity')}</Typography>
        )
      ) : (
        <CenteredProgress />
      )}
    </DashboardCard>
  );
};
