import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Chip, makeStyles } from '@material-ui/core';
import { FinalRange } from './FinalRange';
import { SplitView } from '../../../../../components/SplitView';
import { RangeComparables, RangeCompany } from '../../../../../models';
import { selectFinalRangeComparables } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { mediumFont } from '../../../../../styles/fonts';
import { captionOverline, titlelarge } from '../../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { CompanyTable } from '../../CompanyTable';
import { FinalRangeProps } from '../FinalRange.proptype';

const useStyles = makeStyles(() => ({
  boxSection: {
    height: '100%'
  },
  primaryChip: {
    border: '0.063rem solid',
    padding: '0.406rem',
    height: 'auto',
    backgroundColor: tokens.product15,
    fontFamily: mediumFont.fontFamily,
    color: tokens.product100,
    '& span': {
      display: 'flex'
    },
    '& span span:first-child': {
      marginRight: '2.25rem',
      display: 'flex',
      alignItems: 'center',
      ...captionOverline
    },
    '& span span:last-child': {
      ...titlelarge
    }
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  }
}));

export const FinalTab = ({ pliFormat, pliId, handleOnRangeDiscussion }: FinalRangeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const finalComparables: RangeComparables | undefined = useSelector(selectFinalRangeComparables);

  const displayableComparables: Array<Record<string, HTMLElement | number>> = useMemo(() => {
    return (finalComparables?.data ?? []).map((comparable: RangeCompany) => {
      const displayableComp: Record<string, any> = {};
      Object.keys(comparable).forEach((key: string) => {
        if (typeof comparable[key] === 'number' && key !== 'sourceId') {
          displayableComp[key] = formatPliDisplayValue(formatType, roundingValue, comparable[key] as number);
        } else if (typeof comparable[key] === 'string') {
          displayableComp[key] = (
            <span title={comparable[key] as string} className={classes.truncate}>
              {comparable[key]}
            </span>
          );
        }
      });
      return displayableComp;
    });
  }, [finalComparables?.data, formatType, roundingValue, classes.truncate]);

  return (
    <SplitView
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            companies={displayableComparables}
            columns={finalComparables?.columns ?? []}
            chips={
              displayableComparables.length > 0 && (
                <Chip
                  color="primary"
                  size="medium"
                  label={
                    <>
                      <span>{t('analysis:comparable-final-range-accepted').toUpperCase()}</span>
                      <span>{displayableComparables.length}</span>
                    </>
                  }
                  className={classes.primaryChip}
                />
              )
            }
          />
        </div>
      }
      right={<FinalRange pliFormat={pliFormat} pliId={pliId} handleOnRangeDiscussion={handleOnRangeDiscussion} />}
      className={classes.boxSection}
    />
  );
};
