import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalGuide, NormalizedStateSlice } from '../../models';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface GlobalGuidesState extends NormalizedStateSlice<GlobalGuide> {
  error?: string;
}

const initialState: GlobalGuidesState = { byId: null, allIds: [] };

export const fetchGlobalGuides = createAsyncThunk<GlobalGuide[], void, { rejectValue: Error }>(
  'globalGuides/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: GlobalGuide[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'wtp/mne-world'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

const globalGuidesSlice = createSlice({
  name: 'globalGuides',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchGlobalGuides.fulfilled, (state, { payload: guides }) => {
        state.byId = {};
        state.allIds = [];
        for (const guide of guides ?? []) {
          state.byId[guide.countryId] = guide;
          state.allIds.push(guide.countryId);
        }
      })
      .addMatcher(
        (action) => action.type.match(/^globalGuides\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^globalGuides\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default globalGuidesSlice.reducer;
