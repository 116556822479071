import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { AnalysisMenu } from './AnalysisMenu';
import { SearchAndSort, SortOrder } from '../../../components';
import { filterData, sortData } from '../../../services/filtering';
import { body2 } from '../../../styles/typography';
import { ArrowMove } from '../../../svgs';
import { Table } from '../../Table';
import { AnalysisProps, AnalysesSortBy } from '../Analysis.proptype';

export const AnalysesTable = ({ studies, onNavigate, onEdit, onDelete }: AnalysisProps) => {
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy?: AnalysesSortBy; sortOrder?: SortOrder }>({});
  const [filterObject, setFilter] = useState<Record<string, string>>({});

  const displayableStudies = useMemo(() => {
    return (studies ?? []).map((study) => {
      const { studyId, name, primaryEntity } = study;
      return {
        name,
        primaryEntity: primaryEntity.code,
        domicile: primaryEntity.domicile.name,
        toDashboard: (
          <IconButton
            onClick={() => {
              onNavigate(`/analysis/${studyId!}`);
            }}
          >
            <ArrowMove />
          </IconButton>
        ),
        menu: <AnalysisMenu {...{ study, onNavigate, onEdit, onDelete }} />
      };
    });
  }, [studies, onNavigate, onEdit, onDelete]);

  return (
    <Table
      data={sortData(filterData(displayableStudies, filterObject), sortObject)}
      columns={[
        {
          key: 'name',
          header: (
            <SearchAndSort
              field={t('analysis:column-name')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'name', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, name: value });
              }}
            />
          ),
          width: '15.63rem'
        },
        {
          key: 'primaryEntity',
          header: (
            <SearchAndSort
              field={t('analysis:column-primary-entity')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'primaryEntity', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, primaryEntity: value });
              }}
            />
          ),
          width: '15.63rem'
        },
        {
          key: 'domicile',
          header: t('column-domicile')
        },
        { key: 'toDashboard', header: '', width: '3.13rem' },
        { key: 'menu', header: '', width: '3.13rem' }
      ]}
      stickyCols={{
        menu: {
          side: 'right',
          position: 0
        }
      }}
      columnSpecificStyles={{
        name: {
          ...body2
        }
      }}
    />
  );
};
