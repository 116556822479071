import { ComponentType, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import { TFunction } from 'i18next';
import { PBADashboardSummaryProps, PBASummaryInputs, PBASummarySubmitData } from './PBADashboardTPDSummary.proptype';
import { CenteredProgress } from '../../components';
import { AssociatedTransactionsPBA, Entity, PrimaryFunction, Transaction } from '../../models';
import { fetchEntities } from '../../redux/entities';
import {
  deletePBAAssociatedTransaction,
  editTestedParty,
  fetchCurrentTestedParty,
  updatePbaTransactions
} from '../../redux/profitBasedAnalyses';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCurrentPBA,
  selectCurrentTestedParty,
  selectEntitiesList,
  selectPrimaryFunctions,
  selectTransactionByPbaId,
  selectTransactionsByEntity,
  selectUPECurrency
} from '../../selectors';

interface ConnectorProps {
  component: ComponentType<PBADashboardSummaryProps>;
}

const getPrimaryFunctionOptions = (
  primaryFunctions: PrimaryFunction[],
  testedPartyPrimaryFunctionsDropDown: string[],
  t: TFunction
) =>
  primaryFunctions
    .filter((primaryFunction) => testedPartyPrimaryFunctionsDropDown.includes(primaryFunction.name))
    .map((primaryFunction: PrimaryFunction) => ({
      title: t(`entities:primary-function-${primaryFunction.name}`),
      value: primaryFunction.primaryFunctionId
    }));

const getFormattedTransactions = (
  testedPartyTransactions: Transaction[],
  transactionsPBA: AssociatedTransactionsPBA[]
) => {
  if (transactionsPBA.length > 0) {
    transactionsPBA.forEach((pbatransaction) => {
      const index = testedPartyTransactions.findIndex(
        (transaction: Transaction) => pbatransaction.transactionId === transaction.transactionId
      );
      if (index > 0) {
        testedPartyTransactions.splice(index, 1);
      }
    });

    const sourcepair = transactionsPBA[0]?.legalEntityTransactions[0]?.entity.code;
    const destpair = transactionsPBA[0]?.legalEntityTransactions[1]?.entity.code;
    const transactions: Transaction[] = [];
    testedPartyTransactions.forEach((transaction: Transaction) => {
      const transSource = transaction?.legalEntityTransactions[0].entity.code;
      const transDest = transaction?.legalEntityTransactions[1].entity.code;

      const isTransactionSourceInPair = transSource === sourcepair || transSource === destpair;
      const isTransactionDestInPair = transDest === sourcepair || transDest === destpair;
      const isValidTransaction = isTransactionSourceInPair && isTransactionDestInPair;
      if (isValidTransaction) {
        transactions.push(transaction);
      }
    });
    return transactions;
  }

  return testedPartyTransactions;
};

const getFormattedEntitiesDropdown = (transactions: Transaction[]) => {
  const tempTransactionObject: any = {};
  const testedPartyEntities: Entity[] = [];
  transactions.forEach((transaction) => {
    if (!tempTransactionObject[transaction.legalEntityTransactions[0].entity.code]) {
      tempTransactionObject[transaction.legalEntityTransactions[0].entity.code] = true;
      testedPartyEntities.push(transaction.legalEntityTransactions[0].entity);
    }

    if (!tempTransactionObject[transaction.legalEntityTransactions[1].entity.code]) {
      tempTransactionObject[transaction.legalEntityTransactions[1].entity.code] = true;
      testedPartyEntities.push(transaction.legalEntityTransactions[1].entity);
    }
  });
  return testedPartyEntities;
};

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pba = useSelector(selectCurrentPBA);
  const primaryFunctions = useSelector(selectPrimaryFunctions);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const upeCurrency = useSelector(selectUPECurrency);
  const transactionsPBA: AssociatedTransactionsPBA[] | null = useSelector(selectTransactionByPbaId(pba?.pbaId));
  const entities: Entity[] | null = useSelector(selectEntitiesList);
  const testedPartyTransactions = useSelector(selectTransactionsByEntity(pba?.primaryLegalEntity?.entityId));

  const testedPartyPrimaryFunctionsDropDown: string[] = [
    PrimaryFunctions.ByName.Distributor.Name,
    PrimaryFunctions.ByName.ServiceProvider.Name,
    PrimaryFunctions.ByName.Manufacturer.Name,
    PrimaryFunctions.ByName.DistributorOther.Name,
    PrimaryFunctions.ByName.NonValueAddedDistributor.Name,
    PrimaryFunctions.ByName.Retail.Name,
    PrimaryFunctions.ByName.ServiceProviderOther.Name,
    PrimaryFunctions.ByName.ValueAddedDistributor.Name
  ];

  useEffect(() => {
    dispatch(fetchTransactions());
    if (!pba) {
      dispatch(selectCurrentPBA);
    }

    if (pba) {
      dispatch(fetchCurrentTestedParty(pba.pbaId));
    }
  }, [dispatch, pba]);

  useEffect(() => {
    if (entities === null) {
      dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    // Add back when Functional Analysis tab page is ready, window.location.replace makes 5 popups
    // window.addEventListener('beforeunload', (event) => {
    //   event.preventDefault();
    //   event.returnValue = '';
    // });

    if (transactionsPBA === null || (Array.isArray(testedPartyTransactions) && testedPartyTransactions.length === 0)) {
      dispatch(fetchTransactions());
    }
  }, [dispatch, transactionsPBA, testedPartyTransactions]);

  const mapSumittedData = (data: PBASummaryInputs) => {
    const { name, segmentType, justification, primaryFunctionId, otherPrimaryFunction } = data;
    const newData = {
      testedPartyId: pba?.testedParty?.testedPartyId,
      name,
      segmentType,
      justification,
      primaryFunction: { primaryFunctionId },
      otherPrimaryFunction
    };
    return newData;
  };

  const onSubmit = (testedParty: PBASummaryInputs) => {
    if (pba && testedParty && Object.keys(testedParty).length > 0) {
      const pbaId = pba?.pbaId;
      const fixedTestedParty: PBASummarySubmitData = mapSumittedData(testedParty);
      dispatch<any>(editTestedParty({ pbaId, testedParty: fixedTestedParty })).then(() => {
        dispatch(fetchCurrentTestedParty(pba.pbaId));
      });
    }
  };

  const onUpdatePbaTransactions = async (transactions: Transaction[], pbaId: number) => {
    const promises: any = [];
    transactions.forEach((transaction) => {
      const params = { transactionId: transaction.transactionId, pbaId };
      promises.push(dispatch(updatePbaTransactions(params)));
    });
    await Promise.all(promises);
    dispatch(fetchTransactions());
  };

  const onDeleteAssociateTransaction = (transactionId: number) => {
    if (pba) {
      const pbaId = pba?.pbaId;
      dispatch<any>(deletePBAAssociatedTransaction({ pbaId, transactionId })).then(() => {
        dispatch(fetchTransactions());
      });
    }
  };

  const formattedTestedPartyTransactions = useMemo(
    () =>
      testedPartyTransactions && testedPartyTransactions.length > 0 && transactionsPBA
        ? getFormattedTransactions(testedPartyTransactions, transactionsPBA)
        : [],
    [testedPartyTransactions, transactionsPBA]
  );

  const dropdownEntities = useMemo(
    () =>
      formattedTestedPartyTransactions.length > 0 ? getFormattedEntitiesDropdown(formattedTestedPartyTransactions) : [],
    [formattedTestedPartyTransactions]
  );

  return pba ? (
    <Component
      pba={pba}
      currentTestedParty={currentTestedParty}
      transactionsPBA={transactionsPBA}
      entities={entities}
      dropdownEntities={dropdownEntities}
      testedPartyTransactions={formattedTestedPartyTransactions}
      primaryFunctionOptions={getPrimaryFunctionOptions(primaryFunctions, testedPartyPrimaryFunctionsDropDown, t)}
      upeCurrency={upeCurrency!}
      onSubmit={onSubmit}
      onTransactionsUpdateSubmit={onUpdatePbaTransactions}
      onDeleteAssociateTransaction={onDeleteAssociateTransaction}
    />
  ) : (
    <CenteredProgress />
  );
};

export default Connector;
