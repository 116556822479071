import { FinancialData, FinancialInfo, FinancialCompletionStatus, HttpRequestStatus } from '../../models';

export const SET_ENTITYFINANCIAL_DATA = 'SET_ENTITYFINANCIAL_DATA';
export const SET_ENTITYFINANCIAL_REQUEST_STATUS = 'SET_ENTITYFINANCIAL_REQUEST_STATUS';
export const SET_ENTITY_FINANCIAL_COMPLETION_STATUS = 'GET_ENTITY_FINANCIAL_COMPLETION_STATUS';
export const SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS = 'GET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS';
export const SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS = 'SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS';
export const SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS =
  'SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS';
export const SET_ENTITY_ADDITIONAL_FINANCIAL_INFO = 'SET_ENTITY_ADDITIONAL_FINANCIAL_INFO';
export interface SetEntityFinancialDataAction {
  type: typeof SET_ENTITYFINANCIAL_DATA;
  payload: FinancialData[];
}
export interface SetEntityFinancialRequestStatus {
  type: typeof SET_ENTITYFINANCIAL_REQUEST_STATUS;
  payload: {
    status: HttpRequestStatus;
    error: unknown;
  };
}
export interface SetEntityFinancialCompletionStatusAction {
  type: typeof SET_ENTITY_FINANCIAL_COMPLETION_STATUS;
  payload: FinancialCompletionStatus[];
}

export interface SetEntityFinancialCompletionRequestStatus {
  type: typeof SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS;
  payload: {
    status: HttpRequestStatus;
    error: unknown;
  };
}

export interface SetUpdateEntityFinancialCompletionStatusAction {
  type: typeof SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS;
  payload: FinancialCompletionStatus;
}

export interface SetEntityAdditionalFinancialInfoAction {
  type: typeof SET_ENTITY_ADDITIONAL_FINANCIAL_INFO;
  payload: string;
}

export interface SetUpdateEntityFinancialCompletionRequestStatus {
  type: typeof SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS;
  payload: {
    status: HttpRequestStatus;
    error: unknown;
  };
}

export type EntityFinancialActions =
  | SetEntityFinancialDataAction
  | SetEntityFinancialRequestStatus
  | SetEntityFinancialCompletionStatusAction
  | SetEntityFinancialCompletionRequestStatus
  | SetUpdateEntityFinancialCompletionStatusAction
  | SetUpdateEntityFinancialCompletionRequestStatus
  | SetEntityAdditionalFinancialInfoAction;

export interface EntityFinancialStateInterface {
  entityFinancial: FinancialData[];
  completionStatus: FinancialCompletionStatus[];
  additionalFinancialInfo: string;
  status: HttpRequestStatus;
  error: unknown;
}

export interface GetEntityFinancialDataType extends FinancialInfo {
  entityId: number;
}

export interface UpdateEntityFinancialDataType extends GetEntityFinancialDataType {
  financialData: FinancialData[];
}
