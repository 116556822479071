import { EntityDetailsBalanceSheetProps } from './EntityDetailsBalanceSheet.proptype';
import { FinancialTable } from '../../FinancialTable/index';

export const EntityDetailsBalanceSheet = ({
  hasFooter,
  title,
  tableData,
  financialFieldIds,
  columnYears,
  completionByYear,
  currencyOptions,
  financialConversions,
  onSetCompletion,
  saveData: onSaveData
}: EntityDetailsBalanceSheetProps) => {
  return (
    <FinancialTable
      title={title}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      hasFooter={hasFooter}
      tableData={tableData}
      financialFieldIds={financialFieldIds}
      columnYears={columnYears}
      completionByYear={completionByYear}
      saveData={onSaveData}
      namespace="entity-balance-sheet"
      onSetCompletion={onSetCompletion}
    />
  );
};
