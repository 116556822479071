import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConnectorProps } from './FunctionalAnalysis.proptype';
import {
  fetchCurrentTestedParty,
  fetchFunctionalAnalysisCharacteristics,
  updateFunctionalAnalysisCharacteristic,
  clearPbaData
} from '../../redux/profitBasedAnalyses';
import {
  selectCurrentTestedParty,
  selectFunctionalAnalysisCharacteristics,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { AreaIds, FunctionalAnalysisCharacteristic } from '../FunctionalAnalysisTable/FunctionalAnalysisTable.proptype';

type functionalAnalysisCharacteristicsKeys = 'Functions' | 'Risks' | 'Assets';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const [selectedAnalysis, setSelectedAnalysis] = useState<FunctionalAnalysisCharacteristic>();
  const [currentSection, setCurrentSection] = useState<number>(1);
  const functionalAnalysisCharacteristics = useSelector(selectFunctionalAnalysisCharacteristics);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const currentContainer = useSelector(selectWorkingContainer);

  useEffect(() => {
    if (functionalAnalysisCharacteristics && selectedAnalysis) {
      Object.keys(functionalAnalysisCharacteristics).forEach((key) => {
        functionalAnalysisCharacteristics[key as functionalAnalysisCharacteristicsKeys].forEach(
          (functionalAnalysisCharacteristic: FunctionalAnalysisCharacteristic) => {
            if (
              functionalAnalysisCharacteristic.pbaFunctionalAnalysisDataId ===
              selectedAnalysis.pbaFunctionalAnalysisDataId
            ) {
              setSelectedAnalysis(functionalAnalysisCharacteristic);
            }
          }
        );
      });
    }
  }, [functionalAnalysisCharacteristics, selectedAnalysis]);

  useEffect(() => {
    return () => {
      void dispatch(clearPbaData());
    };
  }, [dispatch]);

  const editFunctionalAnalysisCharacteristic = async (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId?: number
  ) => {
    await dispatch(
      updateFunctionalAnalysisCharacteristic({
        characteristicId: characteristic.pbaFunctionalAnalysisDataId!,
        characteristic: {
          container: currentContainer,
          ...characteristic
        } as any
      })
    );
    if (areaId) {
      await dispatch(
        fetchFunctionalAnalysisCharacteristics({ functionalAnalysisId: currentTestedParty!.testedPartyId, areaId })
      );
    }
  };

  useEffect(() => {
    if (currentTestedParty) {
      Object.values(AreaIds).forEach(async (id) => {
        if (Number.isInteger(id)) {
          await dispatch(
            fetchFunctionalAnalysisCharacteristics({
              functionalAnalysisId: currentTestedParty.testedPartyId,
              areaId: id as number
            })
          );
        }
      });
    } else {
      void dispatch(fetchCurrentTestedParty(pbaId));
    }
  }, [currentTestedParty, dispatch, pbaId]);

  return (
    <Component
      functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
      editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
      selectedAnalysis={selectedAnalysis}
      setSelectedAnalysis={setSelectedAnalysis}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
    />
  );
};

export default Connector;
