import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchCountries } from './fetch-countries';
import { ConnectorProps } from './GlobalGuide.proptypes';
import { GlobalGuide } from '../../models';
import { fetchGlobalGuides } from '../../redux/globalGuides';
import { selectGlobalGuides } from '../../selectors';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const { countryIso } = useParams<{ countryIso: string }>();
  const history = useHistory();
  const [data, setData] = useState();
  const [selected, setSelected] = useState<GlobalGuide | undefined>();
  const globalGuides = useSelector(selectGlobalGuides);

  useEffect(() => {
    if (globalGuides) {
      if (selected?.isoCode !== countryIso) {
        setSelected(globalGuides.find(({ isoCode }) => countryIso === isoCode));
      }
    } else {
      dispatch(fetchGlobalGuides());
    }
  }, [dispatch, countryIso, selected, globalGuides]);

  useEffect(() => {
    if (Array.isArray(globalGuides) && globalGuides.length > 0) {
      const isoCodes = (globalGuides ?? []).map(({ isoCode }) => isoCode);
      void fetchCountries(isoCodes).then(setData);
    }
  }, [globalGuides]);

  return (
    <Component
      globalGuides={globalGuides}
      geoData={data}
      selected={selected}
      onSelect={(globalGuide) => {
        setSelected(globalGuide);
        history.push(globalGuide ? `/global-guide/${globalGuide.isoCode}` : '/global-guide');
      }}
    />
  );
};

export default Connector;
