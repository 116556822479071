import { useState } from 'react';
import { TestedPartyDetails } from './component';
import { TestedPartyDetailsTabOptions } from '../ProfitBasedAnalysisDashboard/ProfitBasedAnalysisDashboard.proptype';

const Connector = () => {
  const { Summary } = TestedPartyDetailsTabOptions;
  const [selectedKey, setSelectedKey] = useState(Summary);

  function handleSetSelectedKey(newKey: any) {
    setSelectedKey(newKey);
  }

  return <TestedPartyDetails selectedKey={selectedKey} updateSelectedKey={handleSetSelectedKey} />;
};

export default Connector;
