// @ts-nocheck
/* eslint-disable unicorn/no-array-push-push, unicorn/prefer-number-properties, no-multi-assign, @typescript-eslint/restrict-plus-operands */
import React from 'react';
import './D3EntitiesTradingGraph.css';
import * as d3 from 'd3';
import equal from 'deep-equal';
import { currencyValueFormatter } from '../../utils';

class D3NodeGraph extends React.Component<any> {
  itemNodes = [];
  graph = {
    links: [],
    nodes: []
  };

  linksElement = null;
  linksParent = null;
  thislinksTextElement = null;
  nodesElemen = null;
  nodesParent = null;
  simulation = null;
  svg = null;
  timersArray = [];
  isCompAlive = true;
  _nodes = null;
  nodes;
  svgRef: any;
  currentTradingPartner;
  currentEntity;
  active;
  onClicked;

  constructor(props: any) {
    super(props);
    const { currentTradingPartner, currentEntity, onClicked, nodes, upeCurrency } = props;
    this.currentTradingPartner = currentTradingPartner;
    this.currentEntity = currentEntity;
    this.onClicked = onClicked;
    this.nodes = nodes;
    this.upeCurrency = upeCurrency;
    this.svgRef = React.createRef();
  }

  componentWillUnmount() {
    this.clearTimers();
    this.isCompAlive = false;
    try {
      if (this.simulation) {
        this.simulation.stop();
        this.simulation.on('tick', null);
      }
    } catch {}
  }

  clearTimers() {
    for (const timer of this.timersArray) clearTimeout(timer);
    this.timersArray = [];
  }

  /**
   * Initializes the SVG element.
   */
  initialize() {
    const svg = this.svgRef.current;
    this.svg = d3.select(svg);
    this.svg.select('.main').selectAll('*').remove();

    if (this.nodes.length === 0) {
      return;
    }

    this.graph.links = Object.keys(this.nodes).map((name) => {
      const { metrics } = this.nodes[name];
      const node = this.nodes[name].entity;
      return {
        source: node.entityId,
        isActive: node.code === this.currentTradingPartner.code,
        target: this.currentEntity.entityId,
        metrics,
        value: new Intl.NumberFormat('en', currencyValueFormatter(2, 2, this.upeCurrency)).format(
          // eslint-disable-next-line unicorn/no-array-reduce
          Object.keys(metrics).reduce((acc, metricName) => {
            return acc + metrics[metricName];
          }, 0)
        )
      };
    });

    this.graph.nodes = [
      ...Object.keys(this.nodes).map((name) => {
        const { entity } = this.nodes[name];
        return {
          id: entity.entityId,
          code: entity.code,
          fill: '#655DC6'
        };
      }),
      {
        id: this.currentEntity.entityId,
        code: this.currentEntity.code,
        fill: '#655DC6'
      }
    ];

    this.initializeNodes();

    this.simulation = d3
      .forceSimulation()
      .force('collision', d3.forceCollide().radius(60))
      .force('center', d3.forceCenter(svg.clientWidth / 2, svg.clientHeight / 2))
      .on('tick', this.tick.bind(this));

    this.simulation.nodes(this.graph.nodes);
    this.initializeDragEvents();

    // HACK: This code sucks.
    this.timersArray.push(
      setTimeout(() => {
        this.setActiveNodes();
      }, 100)
    );
    this.timersArray.push(
      setTimeout(() => {
        this.setActiveNodes();
      }, 500)
    );
    this.timersArray.push(
      setTimeout(() => {
        this.setActiveNodes();
      }, 2000)
    );
    this.timersArray.push(
      setTimeout(() => {
        this.setActiveNodes();
      }, 3500)
    );
  }

  /**
   * Initializes the ability to drag nodes.
   */
  initializeDragEvents() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const onDragStart = (event, context) => {
      if (!event.active) {
        this.simulation.alphaTarget(0.3).restart();
      }

      context.fx = context.x;
      context.fy = context.y;
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const onDrag = (event, context) => {
      context.fx = event.x;
      context.fy = event.y;
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const onDragEnd = (event, context) => {
      if (!event.active) {
        this.simulation.alphaTarget(0);
      }

      context.fx = null;
      context.fy = null;
    };

    this.svg.selectAll('.draggable').call(d3.drag().on('start', onDragStart).on('drag', onDrag).on('end', onDragEnd));
  }

  /**
   * This set of adjustments matches the spec to give a bit extra space
   * between the connecting line and nodes.  This function updates the lines
   * connecting the nodes and updates the positions of the nodes.
   */
  tick() {
    if (this.isCompAlive) {
      // gapSize should be the total radius of the node circle plus the gap.
      // This means true gap size is |CIRCLE_RADIUS - gapSize|.
      const gapSize = 45;
      this.linksElement
        .selectAll('.line-element')
        .attr('x1', (link) => {
          if (typeof link.source === 'number' || typeof link.target === 'number') {
            return 0;
          }

          const unitVec = Math.sqrt((link.source.x - link.target.x) ** 2 + (link.source.y - link.target.y) ** 2);
          if (isNaN(unitVec) || unitVec === 0) {
            return 0;
          }

          return link.source.x - ((link.source.x - link.target.x) / unitVec) * gapSize;
        })
        .attr('y1', (link) => {
          if (typeof link.source === 'number' || typeof link.target === 'number') {
            return 0;
          }

          const unitVec = Math.sqrt((link.source.x - link.target.x) ** 2 + (link.source.y - link.target.y) ** 2);
          if (isNaN(unitVec) || unitVec === 0) {
            return 0;
          }

          return link.source.y - ((link.source.y - link.target.y) / unitVec) * gapSize;
        })
        .attr('x2', (link) => {
          if (typeof link.source === 'number' || typeof link.target === 'number') {
            return 0;
          }

          const unitVec = Math.sqrt((link.source.x - link.target.x) ** 2 + (link.source.y - link.target.y) ** 2);
          if (isNaN(unitVec) || unitVec === 0) {
            return 0;
          }

          return link.target.x - ((link.target.x - link.source.x) / unitVec) * gapSize;
        })
        .attr('y2', (link) => {
          if (typeof link.source === 'number' || typeof link.target === 'number') {
            return 0;
          }

          const unitVec = Math.sqrt((link.source.x - link.target.x) ** 2 + (link.source.y - link.target.y) ** 2);
          if (isNaN(unitVec) || unitVec === 0) {
            return 0;
          }

          return link.target.y - ((link.target.y - link.source.y) / unitVec) * gapSize;
        });

      this.linksElement.selectAll('.link-label').attr('transform', ({ source, target }) => {
        const xCoord = (source.x + target.x) / 2;
        const yCoord = (source.y + target.y) / 2;
        return `translate(${xCoord || 0}, ${yCoord || 0})`;
      });

      this.nodesElement.attr('transform', ({ x, y }) => 'translate(' + x + ',' + y + ')');
    }
  }

  /**
   * Given the root node, initializes all SVG nodes needed for the chart.  This
   * includes nods and links connecting nodes.
   */
  initializeNodes() {
    this.linksElement = this.svg
      .select('.main')
      .append('g')
      .attr('class', '.link')
      .selectAll('line')
      .data(this.graph.links)
      .enter()
      .append('g')
      .attr('class', 'link');

    this.linksElement
      .append('line')
      .attr('class', 'line-element')
      .attr('stroke-width', '1')
      .attr('stroke', 'lightgray')
      .attr('marker-end', `url(#line-marker)`)
      .attr('marker-start', `url(#line-marker)`);

    const onClicked = this.onClicked.bind(this);
    const group = (this.nodesElement = this.svg
      .select('.main')
      .append('g')
      .attr('class', 'nodes')
      .selectAll('g.nodegroup')
      .data(this.graph.nodes)
      .enter()
      .append('g')
      .attr('class', 'draggable nodegroup')
      .style('cursor', (node) => {
        return this.isActive(node.code) ? 'default' : 'pointer';
      })
      .style('opacity', '0.5')
      .on('click', function (event, ele) {
        onClicked(ele);
      }));

    group
      .append('text')
      .text(({ code }) => code)
      .attr('text-anchor', 'middle')
      .attr('class', 'text')
      .attr('y', -50);
    group.append('circle').attr('r', 38).attr('fill', '#CCEEF1');
    group.append('circle').attr('r', 30).attr('fill', '#C1BEE8');
    group.append('circle').attr('r', 18).attr('fill', '#655DC6');

    const linksText = this.linksElement
      .append('g')
      .attr('class', 'link-label')
      .style('opacity', '0.5')
      .on('click', function (event, { source }) {
        onClicked(source);
      });

    linksText
      .append('rect')
      .style('padding', '12px')
      .style('cursor', 'pointer')
      .attr('rx', '4')
      .attr('ry', '4')
      .attr('x', '-56')
      .attr('y', '-16')
      .attr('width', '112')
      .attr('height', '32')
      .style('fill', '#EFEEF9');

    linksText
      .append('text')
      .style('font-size', '12px')
      .style('cursor', 'pointer')
      .style('font-weight', '500')
      .attr('y', '4')
      .style('fill', '#212121')
      .attr('text-anchor', 'middle')
      .text(({ value }) => value);
  }

  /**
   * Checks if the provided entity code is active, either in the source or
   * destination entity (within the active pair).
   * @param code
   * @returns {Boolean}
   */
  isActive(code) {
    try {
      return code === this.currentTradingPartner.code || code === this.currentEntity.code;
    } catch {
      return false;
    }
  }

  /**
   * This sets the appropriate coloring to help a user identify the node/link
   * that is active.
   */
  setActiveNodes() {
    // Sets the text to be purple.
    d3.selectAll('.nodegroup .text').attr('style', (node: any) => {
      return this.isActive(node.code)
        ? 'font-size: 15px; font-weight: bold; fill: #655DC6'
        : 'font-size: 15px; fill: #707983';
    });

    // Sets the opacity of non-active nodes to be translucent to enhance
    // readability.
    d3.selectAll('.nodegroup').style('opacity', (node: any) => {
      return this.isActive(node.code) ? '1' : '0.5';
    });

    // Sets the line to be purple.
    d3.selectAll('.link .line-element').attr('stroke', (link: any) => {
      return this.isActive(link.source.code) ? '#655DC6' : 'lightgray';
    });

    // Sets the opacity of nearby labels.
    d3.selectAll('.link .link-label').style('opacity', (link: any) => {
      return this.isActive(link.source.code) ? '1' : '0.5';
    });

    // Sets the line to be purple.
    d3.selectAll('.link .link-label')
      .select('rect')
      .attr('stroke', (link: any) => {
        return this.isActive(link.source.code) ? '#655DC6' : 'none';
      });

    // Sets the link text of active node/link to purple.
    d3.selectAll('.link .link-label')
      .select('text')
      .style('fill', (link: any) => {
        return this.isActive(link.source.code) ? '#655DC6' : '#212121';
      });

    // Sets the repel charge of the active nodes so they have just a bit extra
    // space.
    this.simulation.force(
      'charge',
      d3.forceManyBody().strength((node: any) => {
        if (this.isActive(node.code)) {
          return -50;
        }

        return 0;
      })
    );

    // Sets the link to be just a bit shorter for active pairs.
    this.simulation.force(
      'link',
      d3
        .forceLink(this.graph.links)
        .id((node: any) => node.id)
        .distance((link) => {
          if (this.isActive(link.source.code)) {
            return 200;
          }

          return 240;
        })
    );

    // Reinforces the center so even when the size of container changes the
    // chart scales with it.
    const svg = this.svgRef.current;
    this.simulation.force('center', d3.forceCenter(svg.clientWidth / 2, svg.clientHeight / 2));

    // Refreshes the chart.
    this.simulation.alpha(0.3).restart();
  }

  componentDidMount() {
    if (this.nodes && Object.keys(this.nodes).length > 0 && this.nodes !== this._nodes) {
      this._nodes = this.nodes;
      this.initialize();
    } else if (this.svg && this.nodes && Object.keys(this.nodes).length > 0) {
      this.setActiveNodes();
    }
  }

  componentDidUpdate() {
    const { currentTradingPartner, currentEntity, onClicked, nodes } = this.props;
    this.currentTradingPartner = currentTradingPartner;
    this.currentEntity = currentEntity;
    this.onClicked = onClicked;
    if (Object.keys(this.nodes)) this.nodes = nodes;
    if (this.nodes && !equal(this.nodes, this._nodes)) {
      this._nodes = this.nodes;
      this.initialize();
    } else if (this.svg && this.nodes && Object.keys(this.nodes).length > 0) {
      this.setActiveNodes();
    }
  }

  render() {
    return (
      <div className="container">
        <svg ref={this.svgRef} id="svg">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="7px"
            height="13px"
            viewBox="302.5 389.5 7 13"
            enableBackground="new 302.5 389.5 7 13"
            xmlSpace="preserve"
          >
            <defs>
              <marker id="line-marker" refX="6.1" refY="6.5" markerWidth="7" markerHeight="13" orient="auto">
                <g>
                  <path
                    d="M309.392,395.741l-6.251-6.133c-0.147-0.144-0.384-0.144-0.531,0c-0.147,0.145-0.147,0.376,0,0.521l5.986,5.872
                l-5.986,5.871c-0.147,0.145-0.147,0.375,0,0.52c0.073,0.071,0.169,0.109,0.264,0.109c0.094,0,0.192-0.034,0.264-0.109l6.251-6.132
                C309.536,396.117,309.536,395.882,309.392,395.741z"
                  />
                </g>
              </marker>
            </defs>
          </svg>
          <g className="main" />
        </svg>
      </div>
    );
  }
}

export default D3NodeGraph;
