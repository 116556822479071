import { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Distributor,
  CharacterizationTypeEnum,
  sicType,
  IndustrialCodeValue,
  TestedPartyDistributorProps,
  TypeOfPrimaryFunctionOptionEnum,
  CodeType
} from './TestedPartyDistributor.proptype';
import {
  fetchCurrentDistributor,
  saveSICOption,
  updateSICOption,
  toggleValueAddedDistributor,
  createValueAddedDistributor,
  fetchSICTypeOptions
} from '../../redux/testedParty';
import { selectCurrentValueAddedDistributor, selectSicTypes } from '../../selectors/testedParty';

const Connector = ({ component: Component }: { component: ComponentType<TestedPartyDistributorProps> }) => {
  const dispatch = useDispatch();
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const currentValueAddedDistributor: Distributor = useSelector(selectCurrentValueAddedDistributor);
  const sicTypes: sicType[] = useSelector(selectSicTypes);
  const isValueAddedDistributor: boolean = currentValueAddedDistributor.characterizationFunctionsPerformed
    ? currentValueAddedDistributor.characterizationFunctionsPerformed[0].state
    : false;

  const currentSelectedSic = currentValueAddedDistributor.industrialCodeValue
    ? currentValueAddedDistributor.industrialCodeValue
    : null;
  // only display 5000 - 5199
  const displayedSicIndusties = sicTypes.length > 0 ? sicTypes[1].codeTypes[0].subtypes : [];

  useEffect(() => {
    dispatch(fetchCurrentDistributor(pbaId));
    dispatch(fetchSICTypeOptions(CharacterizationTypeEnum.TypeOfOption));
  }, [dispatch, pbaId]);

  const toggleDistributor = (isValueAddedDistributor: boolean) => {
    const payload = {
      characterizationType: CharacterizationTypeEnum.FunctionsPerformed,
      characterizationFunctionsPerformed: [
        {
          state: !isValueAddedDistributor,
          functionPerformed: {
            functionPerformedId: CharacterizationTypeEnum.FunctionsPerformed
          }
        }
      ]
    };
    if (Object.keys(currentValueAddedDistributor).length > 0) {
      dispatch<any>(toggleValueAddedDistributor({ pbaId, payload })).then(() =>
        dispatch(fetchCurrentDistributor(pbaId))
      );
    } else {
      dispatch<any>(createValueAddedDistributor({ pbaId, payload })).then(() =>
        dispatch(fetchCurrentDistributor(pbaId))
      );
    }
  };

  const onSelecteSICIndustry = (event: any, selectedSICIndustry: IndustrialCodeValue) => {
    const payload = {
      characterizationType: CharacterizationTypeEnum.TypeOfOption,
      codeType: {
        codeTypeId: CodeType.SIC
      },
      typeOfPrimaryFunctionOption: {
        typeOfPrimaryFunctionOptionId: TypeOfPrimaryFunctionOptionEnum.Wholesaler
      },
      industrialCodeValue: selectedSICIndustry
    };
    if (currentValueAddedDistributor.characterizationId) {
      dispatch<any>(updateSICOption({ pbaId, payload })).then(() => dispatch(fetchCurrentDistributor(pbaId)));
    } else {
      dispatch<any>(saveSICOption({ pbaId, payload })).then(() => dispatch(fetchCurrentDistributor(pbaId)));
    }
  };

  return (
    <Component
      toggleDistributor={toggleDistributor}
      displayedSicIndusties={displayedSicIndusties}
      currentSelectedSic={currentSelectedSic}
      isValueAddedDistributor={isValueAddedDistributor}
      onSelecteSICIndustry={onSelecteSICIndustry}
    />
  );
};

export default Connector;
