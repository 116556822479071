import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Editor } from '../../../components/Editor';
import { updateTestedPartyFinancialsInfo } from '../../../redux/profitBasedAnalyses';
import { body3, title3 } from '../../../styles/typography';
import useDebounce from '../../../utils/debounce';
import { PbaDashboardFinancialProps } from '../../FinancialTable/FinancialTable.proptype';
import { FinancialTable } from '../../FinancialTable/index';

const useStyles = makeStyles(() => ({
  editorContainer: {
    width: '55vw',
    margin: '5vh auto'
  }
}));

export const PBADashboardIncomeStatement = ({
  hasFooter,
  title,
  tableData,
  financialReadOnlyFieldIds,
  financialFieldIds,
  columnYears,
  currencyOptions,
  headerInfo,
  financialConversions,
  isFieldValueValid,
  isColumnValid,
  calculateFieldValues,
  saveData: onSaveData,
  testedPartyId,
  additionalFinancialInfo,
  workingContainer
}: PbaDashboardFinancialProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [editorText, setEditorText] = useState<string>('');

  useEffect(() => {
    setEditorText(additionalFinancialInfo!);
  }, [additionalFinancialInfo]);

  const [saveAdditionalFinancialInfo] = useDebounce((content: string) => {
    dispatch(
      updateTestedPartyFinancialsInfo({
        container: workingContainer!,
        financialsInfo: content,
        testedPartyId: testedPartyId!
      })
    );
  }, 3000);

  return (
    <>
      <FinancialTable
        title={title}
        headerInfo={headerInfo}
        currencyOptions={currencyOptions}
        financialConversions={financialConversions}
        hasFooter={hasFooter}
        tableData={tableData}
        financialReadOnlyFieldIds={financialReadOnlyFieldIds}
        financialFieldIds={financialFieldIds}
        columnYears={columnYears}
        isFieldValueValid={isFieldValueValid}
        isColumnValid={isColumnValid}
        calculateFieldValues={calculateFieldValues}
        saveData={onSaveData}
        namespace="pba-income-statement"
      />
      <Box className={classes.editorContainer}>
        <Typography style={{ ...title3 }}>{t('analysis:additional-financial-info-title')}</Typography>
        <Typography style={{ ...body3 }}>{t('analysis:additional-financial-info-description')}</Typography>
        <Editor
          theme="TransferPricing"
          id="pbaDashboardIncomeStatement"
          init={{ height: '18.75rem' }}
          value={editorText}
          onEditorChange={(content) => {
            setEditorText(content!);
            saveAdditionalFinancialInfo(content);
          }}
          onBlur={() => {
            dispatch(
              updateTestedPartyFinancialsInfo({
                container: workingContainer!,
                financialsInfo: editorText,
                testedPartyId: testedPartyId!
              })
            );
          }}
        />
      </Box>
    </>
  );
};
