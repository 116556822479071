import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FinancialAreas } from '@xbs/xbs-enums';
import { SearchChecklist } from './component';
import { AssociatedTransactionsPBA } from '../../models';
import { fetchCurrentTestedParty, fetchTestedPartyFinancials } from '../../redux/profitBasedAnalyses';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCurrentMajorClassification,
  selectCurrentPBA,
  selectCurrentTestedParty,
  selectCurrentValueAddedDistributor,
  selectPBAPli,
  selectTestedPartyFinancial,
  selectTransactionByPbaId
} from '../../selectors';
import { PliValues } from '../ProfitLevelIndicator/ProfitLevelIndicator.proptype';
import { MajorClassification } from '../TestedPartyCharacterization';
import { Distributor } from '../TestedPartyDistributor';

const Connector = () => {
  const dispatch = useDispatch();
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const pba = useSelector(selectCurrentPBA);
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const transactionsPBA: AssociatedTransactionsPBA[] | null = useSelector(selectTransactionByPbaId(pba?.pbaId));
  const testedPartyFinancialData = useSelector(selectTestedPartyFinancial);
  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);
  const currentValueAddedDistributor: Distributor = useSelector(selectCurrentValueAddedDistributor);
  const pbaPli = useSelector(selectPBAPli);
  const isValidPba = Boolean(pbaPli?.pliValues.some((val: PliValues) => val.pliValue));

  useEffect(() => {
    dispatch(fetchTransactions());
    if (!pba) {
      dispatch(selectCurrentPBA);
    }

    if (pba && !currentTestedParty) {
      dispatch(fetchCurrentTestedParty(pba.pbaId));
    }

    if (currentTestedParty && !testedPartyFinancialData) {
      dispatch(
        fetchTestedPartyFinancials({
          testedPartyId: currentTestedParty.testedPartyId,
          financialTypeId: FinancialAreas.ByName.IncomeStatement.Id
        })
      );
    }
  }, [dispatch, pba, pbaId, currentTestedParty, testedPartyFinancialData]);

  return (
    <SearchChecklist
      currentTestedParty={currentTestedParty}
      transactionsPBA={transactionsPBA}
      incomeStatementData={testedPartyFinancialData}
      isValidPba={isValidPba}
      currentMajorClassifications={currentMajorClassifications}
      currentValueAddedDistributor={currentValueAddedDistributor}
    />
  );
};

export default Connector;
