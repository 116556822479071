import { ROLES } from './pureConstants';
import { Analysis } from '../Analysis';
import { Entities } from '../Entities';
import { GlobalGuide } from '../GlobalGuide';
import { Reports } from '../Reports';
import { Transactions } from '../Transactions';

// route order matters for default selections (first route default by policy section)
export const tpiqRoutes = [
  {
    name: 'Entities',
    path: `/entities`,
    component: Entities,
    roles: [
      ROLES.documentationReview,
      ROLES.transferPricing,
      ROLES.salesTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.psgAdminTransferPricing
    ]
  },
  {
    name: 'Transactions',
    path: `/transactions`,
    component: Transactions,
    roles: [
      ROLES.documentationReview,
      ROLES.transferPricing,
      ROLES.salesTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.psgAdminTransferPricing
    ]
  },
  {
    name: 'Analysis',
    path: `/analysis`,
    component: Analysis,
    roles: [
      ROLES.documentationReview,
      ROLES.transferPricing,
      ROLES.salesTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.psgAdminTransferPricing
    ]
  },
  {
    name: 'Reports',
    path: `/reports`,
    component: Reports,
    roles: [
      ROLES.reportsOnly,
      ROLES.documentationReview,
      ROLES.transferPricing,
      ROLES.salesTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.psgAdminTransferPricing
    ]
  },
  {
    name: 'GlobalGuide',
    path: `/global-guide/:countryIso?`,
    component: GlobalGuide,
    roles: [
      ROLES.documentationReview,
      ROLES.reportsOnly,
      ROLES.transferPricing,
      ROLES.salesTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.psgAdminTransferPricing
    ]
  },
  {
    name: 'Admin',
    path: `/admin`,
    roles: [
      ROLES.reportsOnly,
      ROLES.documentationReview,
      ROLES.transferPricing,
      ROLES.salesTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.psgAdminTransferPricing
    ]
  }
];
