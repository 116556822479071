import { useEffect, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { Box, Typography, makeStyles, Paper } from '@material-ui/core';
import { EllipsisMenu } from './EllipsisMenu';
import { Tabs } from './Tabs';
import { CenteredProgress, NestedList, NestedListItem, NestedListSection, ProgressChip } from '../../../components';
import {
  BackgroundInfoSubTopic,
  BackgroundInfoTopic,
  StatementOfFactsPerJurisdiction,
  CORE_KEY
} from '../../../models';
import { StatementOfFactsDetails } from '../../StatementOfFactsDetails';
import { StatementOfFactsOverviewProps } from '../StatementOfFactsOverview.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '70vh',
    background: theme.palette.common.white,
    '& > :last-child': {
      flex: 1
    }
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1.5)
  },
  content: {
    alignItems: 'stretch',
    display: 'flex',
    overflow: 'auto',
    '& > :first-child': {
      flex: 0.7
    },
    '& > :last-child': {
      flex: 1
    }
  }
}));

export interface SubtopicItem extends NestedListItem {
  data: BackgroundInfoSubTopic;
}

interface TopicSection extends NestedListSection<SubtopicItem> {
  data: BackgroundInfoTopic;
}

const extractCompletion = (statementOfFacts: StatementOfFactsPerJurisdiction) =>
  Object.values(statementOfFacts)
    .flat()
    .flatMap(({ subTopics }) => (subTopics ?? []).map(({ completed }) => completed));

const makeTopicNestedList = (topics: BackgroundInfoTopic[] | undefined, t: TFunction): TopicSection[] =>
  (topics ?? []).map((topic) => ({
    name: t(`topic-${topic.name}`),
    data: topic,
    children: (topic.subTopics ?? []).map((subtopic) => ({
      id: subtopic.backgroundInfoId,
      name: subtopic.subtopicHeader,
      data: subtopic,
      checked: subtopic.completed,
      extra: subtopic.completed ? null : t('action-add-details')
    })),
    extra: <ProgressChip size="small" progresses={(topic.subTopics ?? []).map((item) => Boolean(item.completed))} />
  }));

export const StatementOfFactsOverview = (props: StatementOfFactsOverviewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [jurisdiction, setJurisdiction] = useState(CORE_KEY);
  const [subtopic, setSubtopic] = useState<BackgroundInfoSubTopic | null>(null);
  const { namespace, name, statementOfFacts, onSubmit } = props;
  const labelCore = t('label-core');

  useEffect(() => {
    // updates display with latest statementOfFacts data, but keep selected subtopic when possible
    setSubtopic(
      statementOfFacts?.[jurisdiction]?.[(subtopic?.topicSortOrder ?? 1) - 1]?.subTopics?.[
        (subtopic?.subtopicSortOrder ?? 1) - 1
      ] ?? null
    );
  }, [subtopic, statementOfFacts, jurisdiction]);

  const tabs = Object.entries(statementOfFacts ?? {}).map(([key, topics]) => ({
    label: key === CORE_KEY ? labelCore : key,
    current: jurisdiction === key,
    checked: (topics ?? []).every(({ subTopics }) => (subTopics ?? []).every((topic) => topic.completed))
  }));

  return statementOfFacts ? (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <Typography>{t(`${namespace}:title-sof`)}</Typography>
        <ProgressChip completed progresses={extractCompletion(statementOfFacts)} size="small" />
        <Box flexGrow={1} />
        <EllipsisMenu subtopic={subtopic} jurisdiction={jurisdiction} {...props} />
      </Box>
      <Tabs
        tabs={tabs}
        onChange={({ label }) => {
          setJurisdiction(label === labelCore ? CORE_KEY : label);
        }}
      />
      {subtopic ? (
        <Box className={classes.content}>
          <NestedList
            data={makeTopicNestedList(statementOfFacts?.[jurisdiction], t)}
            selectedId={subtopic.backgroundInfoId}
            onSelect={({ data }) => {
              setSubtopic(data);
            }}
          />
          <StatementOfFactsDetails
            title={t(`${namespace}:title-details-with-name`, { name })}
            subtopic={subtopic}
            namespace={namespace ?? ''}
            onSubmit={(subtopic) => {
              onSubmit({ subtopic, jurisdiction });
            }}
          />
        </Box>
      ) : (
        <CenteredProgress />
      )}
    </Paper>
  ) : (
    <CenteredProgress />
  );
};
