import {
  Box,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { TPModalProps } from './TPModal.proptype';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius,
    padding: 0
  },
  headerWrapper: {
    padding: theme.spacing(1.5, 2.5),
    '& > *': {
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  },
  modalTitle: {
    flexGrow: 1
  },
  actionsWrapper: {
    padding: theme.spacing(2, 2.5)
  }
}));

const TPModal = ({
  children,
  container,
  containerStyle,
  headerContent,
  actions,
  title,
  isOpen,
  maxWidth,
  closeIcon: Icon,
  onClose
}: TPModalProps) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const hasContainer = Boolean(container);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth={maxWidth ?? 'lg'}
      scroll="paper"
      classes={{ paper: classes.paper }}
      container={container}
      style={hasContainer ? { position: 'absolute' } : {}}
      BackdropProps={hasContainer ? { style: { position: 'absolute' } } : {}}
      disablePortal={hasContainer}
      disableEnforceFocus={hasContainer}
      disableBackdropClick={!hasContainer}
      onClose={handleClose}
    >
      <DialogTitle classes={{ root: classes.headerWrapper }}>
        <Box component="span" className={classes.modalTitle}>
          {title}
        </Box>
        {headerContent}
        <IconButton onClick={handleClose}>{Icon ? <Icon /> : <CloseIcon />}</IconButton>
      </DialogTitle>
      <DialogContent dividers className={containerStyle}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.actionsWrapper}>
          {actions.map(({ name, id, handler, ...otherProps }) => (
            <Button key={name} id={id} onClick={handler} {...otherProps}>
              {name}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TPModal;
