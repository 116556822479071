import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { JurisdictionAnalysisInfo } from './component';
import { JurisdictionPli } from './JurisdictionAnalysisInfo.proptype';
import { CenteredProgress } from '../../components';
import {
  fetchCurrentTestedParty,
  fetchJurisdictionDescription,
  fetchJurisdictionPliFormats,
  fetchJurisdictionPlis,
  fetchPBAs,
  fetchPbaJurisdictions,
  updatePliStatus,
  updatePbaJurisdictionStatus
} from '../../redux/profitBasedAnalyses';
import {
  selectCurrentMajorClassification,
  selectCurrentPBA,
  selectCurrentTestedParty,
  selectJurisdictionDescription,
  selectJurisdictionPliFormat,
  selectJurisdictionPlis,
  selectPBAJurisdictionById,
  selectPBAsList,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { MajorClassification } from '../TestedPartyCharacterization/TestedPartyCharacterization.proptype';

const Connector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pba = useSelector(selectCurrentPBA);
  const pbas = useSelector(selectPBAsList);

  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const studyId = Number(useParams<{ studyId: string }>().studyId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);

  const currentContainer = useSelector(selectWorkingContainer);

  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const pbaJurisdictionDetails = useSelector(selectPBAJurisdictionById(jurisdictionId));

  const jurisdictionDescription = useSelector(selectJurisdictionDescription(jurisdictionId));

  const jurisdictionPli = useSelector(selectJurisdictionPlis(jurisdictionId));
  const jurisdictionPliFormat = useSelector(selectJurisdictionPliFormat(jurisdictionId));

  const [plis, setPlis] = useState<JurisdictionPli[]>(jurisdictionPli);

  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);

  const jurisdictionStatusInfo = pba?.jurisdictionStatuses
    ? pba.jurisdictionStatuses.find((status) => status.jurisdictionId === jurisdictionId)
    : undefined;

  const updateJurisdictionStatus = async (status: number) => {
    if (jurisdictionStatusInfo) {
      const updatedJurisdictionStatus = {
        status,
        jurisdictionId: jurisdictionStatusInfo.jurisdictionId,
        jurisdictionIsoCode: jurisdictionStatusInfo.jurisdictionIsoCode
      };

      await dispatch(updatePbaJurisdictionStatus({ pbaId, jurisdictionStatus: updatedJurisdictionStatus }));
      void dispatch(fetchPBAs(studyId));
    }
  };

  const onChange = (toggle: boolean, index: number) => {
    const updatedPli = [...plis];
    updatedPli[index] = {
      ...updatedPli[index],
      exclude: toggle ? 'global.no' : 'global.yes'
    };
    setPlis(updatedPli);
    void dispatch(
      updatePliStatus({
        compSearchId: Number(pbaJurisdictionDetails?.compSearchId),
        jurisdictionId,
        pliTypeId: updatedPli[index].pliTypeId,
        pliAverageTypeId: updatedPli[index].pliAvgTypeId,
        pliStatus: Number(!toggle),
        currentContainer
      })
    );
  };

  useEffect(() => {
    if (pbas === null) {
      void dispatch(fetchPBAs(studyId));
    }

    if (!currentTestedParty) {
      void dispatch(fetchCurrentTestedParty(pbaId));
    }

    if (!pbaJurisdictionDetails) {
      void dispatch(fetchPbaJurisdictions(pbaId));
    }

    if (pbaJurisdictionDetails) {
      if (!jurisdictionDescription) {
        void dispatch(
          fetchJurisdictionDescription({
            compSearchId: pbaJurisdictionDetails.compSearchId,
            jurisdictionId
          })
        );
      }

      if (!jurisdictionPli) {
        void dispatch(
          fetchJurisdictionPlis({
            compSearchId: pbaJurisdictionDetails.compSearchId,
            jurisdictionId
          })
        );
      }

      if (!jurisdictionPliFormat) {
        void dispatch(
          fetchJurisdictionPliFormats({ compSearchId: pbaJurisdictionDetails?.compSearchId, jurisdictionId })
        );
      }
    }
  }, [
    dispatch,
    currentTestedParty,
    jurisdictionDescription,
    jurisdictionPli,
    pbaId,
    jurisdictionId,
    pbaJurisdictionDetails,
    jurisdictionPliFormat,
    pba,
    pbas,
    studyId
  ]);

  useEffect(() => {
    if (jurisdictionPli) {
      setPlis(jurisdictionPli);
    }
  }, [jurisdictionPli]);

  return currentTestedParty && jurisdictionDescription && plis && jurisdictionPliFormat ? (
    <JurisdictionAnalysisInfo
      compSearchId={pbaJurisdictionDetails?.compSearchId}
      currentTestedParty={currentTestedParty}
      jurisdictionDescription={jurisdictionDescription}
      plis={plis}
      jurisdictionPliFormat={jurisdictionPliFormat}
      jurisdictionStatus={jurisdictionStatusInfo}
      classifications={currentMajorClassifications}
      updateJurisdictionStatus={updateJurisdictionStatus}
      onChange={onChange}
    />
  ) : (
    <CenteredProgress />
  );
};

export default Connector;
