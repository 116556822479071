import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { TOptionsBase } from 'i18next';
import { ConversionTable, NoRates } from '.';
import { CenteredProgress, ConfirmationModal, SearchBox, TPModal, TPModalActions } from '../../../components';
import documentationReviewAccess from '../../../components/HigherOrderComponents/DocumentationReviewAccess';
import { Entity, FinancialConversion } from '../../../models';
import { sortData } from '../../../services/filtering';
import { hasEditAccess } from '../../../utils';
import { CurrencyConversionModalProps } from '../CurrencyConversionModal.proptype';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    paddingRight: theme.spacing(1),
    gap: theme.spacing(1),
    width: '30%',
    alignItems: 'center'
  },
  tableContainer: {
    minHeight: 450,
    overflow: 'auto'
  }
}));

const areRatesInvalid = (rates: FinancialConversion[]) => {
  const entities = new Map<number, boolean>();
  // store a validity flag for each known entities. flag is true as long as we have at least one valid value
  for (const {
    legalEntity: { entityId },
    conversionValue
  } of rates) {
    entities.set(entityId, entities.get(entityId) ? true : Number.isFinite(conversionValue));
  }

  return [...entities.values()].filter((isValid) => !isValid).length > 0 || rates.length === 0;
};

export const CurrencyConversionModal = ({
  isOpen,
  isLoading,
  entities,
  rates,
  years,
  onClose,
  onSave
}: CurrencyConversionModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [editedRates, setEditedRates] = useState<FinancialConversion[]>([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [deleted, setDeleted] = useState<{ entityId: number; name: string } | null>(null);

  const actions: TPModalActions[] = [];

  if (hasEditAccess()) {
    actions.push(
      {
        name: t('action-cancel'),
        handler: onClose
      },
      {
        name: t('action-save'),
        variant: 'contained',
        color: 'primary',
        handler: () => {
          onSave(editedRates.filter(({ conversionValue }) => Number.isFinite(conversionValue)));
        },
        disabled: areRatesInvalid(editedRates)
      }
    );
  } else {
    actions.push({
      name: t('analysis:action-close'),
      handler: onClose
    });
  }

  const selectableEntities = useMemo(
    () =>
      sortData(
        entities.filter(
          ({ entityId, upe }) => !upe && !editedRates.some(({ legalEntity }) => legalEntity.entityId === entityId)
        ),
        { sortBy: 'name', sortOrder: 'asc' }
      ),
    [entities, editedRates]
  );

  useEffect(() => {
    // when opening the modal, or whan receiving rates from caller, erased edited rates
    setEditedRates([...rates]);
  }, [rates, isOpen]);

  // specifying ChangeEvent<Element> here does not work, as eslint will raise an error
  const handleAddEntity = (event: unknown, selected: Entity | null) => {
    // this will reset the selected entity in search box
    setSelectedEntity(null);
    if (selected) {
      setEditedRates([
        ...editedRates,
        ({ legalEntity: selected, taxYear: years[0] } as unknown) as FinancialConversion
      ]);
    }
  };

  const handleEditRate = (entityId: number, year: number, value?: number) => {
    const idx = editedRates.findIndex(
      ({ legalEntity, taxYear }) => legalEntity.entityId === entityId && taxYear === year
    );
    const newRates = idx === -1 ? [...editedRates] : [...editedRates.slice(0, idx), ...editedRates.slice(idx + 1)];
    newRates.push(({
      // when adding an new rate, we reuse the legal entity of a different year (there's always at least one)
      ...(editedRates[idx] ?? editedRates.find(({ legalEntity }) => legalEntity.entityId === entityId)!),
      taxYear: year,
      conversionValue: value
    } as unknown) as FinancialConversion);

    setEditedRates(newRates);
  };

  const handleDeletion = (confirmed: boolean) => {
    setDeleted(null);
    if (confirmed && deleted) {
      setEditedRates(editedRates.filter(({ legalEntity }) => legalEntity.entityId !== deleted.entityId));
    }
  };

  return (
    <>
      <TPModal
        title={t('entities:title-currency-conversion')}
        headerContent={
          <Box className={classes.searchContainer}>
            {documentationReviewAccess(
              <SearchBox
                options={selectableEntities}
                value={selectedEntity}
                getOptionLabel={(entity: Entity) => entity.name}
                onChange={handleAddEntity}
              />
            )}
          </Box>
        }
        isOpen={isOpen}
        actions={actions}
        onClose={onClose}
      >
        <Box className={classes.tableContainer}>
          <ConversionTable
            entities={entities}
            rates={isLoading ? [] : editedRates}
            years={years}
            onEditEntityRate={handleEditRate}
            onDeleteEntityRates={setDeleted}
          />
          {isLoading ? <CenteredProgress /> : editedRates.length === 0 && <NoRates />}
        </Box>
      </TPModal>
      <ConfirmationModal
        open={deleted !== null}
        title={t('entities:title-delete-rate', (deleted as TOptionsBase) ?? { name: '' })}
        subtitle=""
        text={t('entities:message-delete-rate')}
        handleClose={handleDeletion}
      />
    </>
  );
};
