import { ReactNode } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { NOTIFICATION_SNACKBAR_DURATION, NOTIFICATION_SNACKBAR_MAX_COUNT } from '../../constants';
import { Notification } from '../../models';
import httpService from '../../services/http';

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    showNotification({ type, message }: Notification) {
      const content = Array.isArray(message) ? (
        <>
          {message.map((msg, i) => (
            <Box key={String(i)}>{msg}</Box>
          ))}
        </>
      ) : (
        message
      );

      enqueueSnackbar(content, {
        variant: type,
        autoHideDuration: NOTIFICATION_SNACKBAR_DURATION,
        action: (key) => (
          <IconButton
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <Close />
          </IconButton>
        )
      });
    }
  };
};

const HttpServiceConfigurer = (props: { children: any }) => {
  httpService.setShowNotification(useNotifications().showNotification);
  return props.children;
};

export const NotificationsContext = (props: { children: ReactNode }) => (
  <SnackbarProvider maxSnack={NOTIFICATION_SNACKBAR_MAX_COUNT} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <HttpServiceConfigurer {...props} />
  </SnackbarProvider>
);
