import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FunctionalAnalysisSplashImageHolder } from './FunctionalAnalysisSplashImageHolder';
import { Editor } from '../../../components/Editor';
import { title1, body1 } from '../../../styles/typography';
import {
  FunctionalAnalysisDiscussionEditorProps,
  functionalAnalysisHeaders
} from '../FunctionalAnalysisTable.proptype';

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    width: '50%'
  },
  editorHeader: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  editorDesc: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const FunctionalAnalysisDiscussionEditor = ({
  selectedAnalysis,
  currentSection,
  editFunctionalAnalysisCharacteristic
}: FunctionalAnalysisDiscussionEditorProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editorText, setEditorText] = useState<string | undefined>(selectedAnalysis?.discussion);
  const [debounceRef, setDebounceRef] = useState<NodeJS.Timeout>((0 as unknown) as NodeJS.Timeout);

  useEffect(() => {
    setEditorText(selectedAnalysis?.discussion);
  }, [selectedAnalysis?.discussion]);

  return (
    <Box className={classes.editorContainer}>
      {selectedAnalysis ? (
        <>
          <Typography style={{ ...body1 }} className={classes.editorHeader}>
            {functionalAnalysisHeaders[currentSection - 1]}
          </Typography>
          <Typography style={{ ...title1 }}>
            {selectedAnalysis
              ? selectedAnalysis.characteristicName.includes('functional_characteristic_type')
                ? t(`analysis:${selectedAnalysis.characteristicName}`)
                : selectedAnalysis.characteristicName
              : ''}
          </Typography>
          <Typography style={{ ...body1 }} className={classes.editorDesc}>
            {t(`analysis:functional_characteristic.discussion_title`)}
          </Typography>
          <Editor
            theme="TransferPricing"
            id="functionalAnalysis"
            init={{ height: '50vh' }}
            value={editorText}
            onEditorChange={(content) => {
              setEditorText(content);
              clearTimeout(debounceRef);
              setDebounceRef(
                setTimeout(() => {
                  if (content !== selectedAnalysis?.discussion) {
                    void editFunctionalAnalysisCharacteristic(
                      {
                        discussion: content,
                        discussionOnly: true,
                        pbaFunctionalAnalysisDataId: selectedAnalysis.pbaFunctionalAnalysisDataId
                      },
                      currentSection
                    );
                  }
                }, 3000)
              );
            }}
            onBlur={() => {
              clearTimeout(debounceRef);
              if (selectedAnalysis) {
                void editFunctionalAnalysisCharacteristic(
                  {
                    discussion: editorText,
                    discussionOnly: true,
                    pbaFunctionalAnalysisDataId: selectedAnalysis.pbaFunctionalAnalysisDataId
                  },
                  currentSection
                );
              }
            }}
          />
        </>
      ) : (
        <FunctionalAnalysisSplashImageHolder />
      )}
    </Box>
  );
};
