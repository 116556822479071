import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { MoreHoriz } from '@material-ui/icons';
import { ConfirmationModal, CustomIconMenu } from '../../../components';
import { ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../../models';
import { TrashCanIcon } from '../../../svgs';
import { hasEditAccess, handleRoleRedirect } from '../../../utils';
import { StudyDashboardEventsProps } from '../StudyDashboard.proptype';

interface StudyTabsMenuProps extends StudyDashboardEventsProps {
  element: ProfitBasedAnalysis | TransactionBasedAnalysis;
  isPBA: boolean;
}

export const StudyTabsMenu = ({ element, isPBA, onEdit, onDelete }: StudyTabsMenuProps) => {
  const { name } = element;
  const elementId = isPBA ? (element as ProfitBasedAnalysis).pbaId : (element as TransactionBasedAnalysis).tbaId;
  const { t } = useTranslation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const labelType = isPBA ? 'pba' : 'tba';
  const match: any = useRouteMatch('/analysis/:studyId');
  const studyId: any = match.params.studyId ?? null;
  const history = useHistory();
  const location = useLocation();
  const items = [
    {
      text: t('analysis:action-view'),
      handleOnClick: () => {
        if (isPBA) {
          history.push(`${location.pathname}/pba-dashboard/${String(elementId)}`, { element });
        } else {
          // Remove this check once TBA Analysis is setup
          handleRoleRedirect(
            `${window.location.origin}/legacy/local-files/study/${String(studyId)}/tba/${String(elementId)}`
          );
        }
      }
    }
  ];

  if (hasEditAccess()) {
    items.push(
      {
        text: t(`analysis:action-edit-${labelType}`),
        handleOnClick: () => {
          onEdit!(elementId);
        }
      },
      {
        text: t(`analysis:action-delete-${labelType}`),
        handleOnClick: () => {
          setIsConfirmationOpen(true);
        }
      }
    );
  }

  const handleDeletion = (confirmed: boolean) => {
    setIsConfirmationOpen(false);
    if (confirmed && elementId) {
      onDelete!(elementId);
    }
  };

  return (
    <>
      <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
      {isConfirmationOpen && (
        <ConfirmationModal
          open
          title={t(`analysis:action-delete-${labelType}`)}
          subtitle={t('analysis:title-delete', { name })}
          text={t('analysis:message-delete')}
          HeaderIcon={TrashCanIcon}
          handleClose={handleDeletion}
        />
      )}
    </>
  );
};
